import React from 'react';

import { useState, useEffect, useContext } from 'react'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { AppBar, Button, CircularProgress, IconButton, Grid, Toolbar, Paper, Typography, Modal } from '@mui/material';
import { TextField } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import UserService from "./services/compras.service";

import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import { useFormik } from 'formik';
import * as yup from 'yup';
import Excel from "./ComprasExcel.component";

//Validacion del formulario
const validationSchema = yup.object();

export default function ComprasInformes(props) {
    const [isLoading, setIsLoading] = useState(true)
    const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [dataequipos, setDataequipos] = useState([]);

    const [left, setLeft] = useState([]);
    const [data, setData] = useState([]);
    const [modalQR, setModalQR] = useState(false);
    const [values, setValues] = useState([]);
    const [open, setOpen] = React.useState(false);

    const [estado, setEstado] = useState([
        { id: 'En proceso', nombre: 'En proceso' },
        { id: 'Entregado', nombre: 'Entregado' },
        { id: 'Operativo', nombre: 'Operativo' },
        { id: 'En reparacion', nombre: 'En reparacion' },
        { id: 'Fuera de servicio', nombre: 'Fuera de servicio' },
    ]);

    const handleClose = () => {
        setOpen(false);
    };

    const abrirCerrarModalQR = () => {
        setModalQR(!modalQR);
    }

    const peticionPost = async (data) => {
        const response = await UserService.getComprasInforme(data);
        if (response.data == null) {
            setOpen(true);
            handleShowMessage('500');
            //setModalQR(!modalQR);
        } else {
            abrirCerrarModalQR();
            setValues(response.data);
        }
        //cerrarEditar()
    }

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            fechainicio: '',
            fechafin: '',
            empresa: '',
            yacimiento: '',
            serie: '',
            sap: '',
            estado: '',
            equipo: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //abrirCerrarModalQR();
        },
    });

    const styles = useStyles();
    const classes = useStyles();

    const cerrarEditar = () => {
        props.history.push("/empresas");
    }

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const bodyQR = (
        <div className={styles.modal1}>
            <Excel url={values} />
        </div>
    )

    const inicio = () => {
        props.history.goBack();
    }

    //Para el uso del componente de mensajes 
    //const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Informe
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <TextField
                            id="date"
                            type="date"
                            defaultValue="2021-08-25"
                            name="fechainicio"
                            label="Fecha/carga desde:"
                            className={classes.textField}
                            error={formik.touched.fechainicio && Boolean(formik.errors.fechainicio)}
                            helperText={formik.touched.fechainicio && formik.errors.fechainicio}
                            onChange={formik.handleChange}
                            value={formik.values.fechainicio}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id="date"
                            type="date"
                            defaultValue="2021-08-25"
                            name="fechafin"
                            label="Fecha/carga hasta:"
                            className={classes.textField}
                            error={formik.touched.fechafin && Boolean(formik.errors.fechafin)}
                            helperText={formik.touched.fechafin && formik.errors.fechafin}
                            onChange={formik.handleChange}
                            value={formik.values.fechafin}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                </Grid>

                <div align="right">
                    <Button color="primary" type="submit">Generar informe</Button>
                    <Button color="primary" onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"No fue posible generar el informe"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        El informe no posee datos.
                        Intenta modificar los datos de tu consulta.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={modalQR}
                onClose={abrirCerrarModalQR}>
                {bodyQR}
            </Modal>
            <SnackbarMessage
                open={open}
                message={errorMessage}
                severity="success"
                onClose={handleCloseMessage}
            />

        </Paper>
    );
}