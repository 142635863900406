import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { mdiTimeline } from '@mdi/js';
import { mdiWrench } from '@mdi/js';
import { mdiHomeCity } from '@mdi/js';
import { mdiFormatListCheckbox } from '@mdi/js';
import { mdiPlaylistCheck } from '@mdi/js';
import { mdiViewGrid } from '@mdi/js';

import { mdiMicrosoftExcel } from '@mdi/js';
import { mdiToolbox } from '@mdi/js';
import { mdiCar } from '@mdi/js';

import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';
import { useStyles } from '../styles'; // Importa los estilos desde el archivo styles.js

const Mantenimiento = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const { showUser, OwnedProducts } = useContext(AuthContext);

    const classes = useStyles();

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    return (
        <>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Mantenimiento
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <sections>
                {showUser && (
                    <>

                        {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
                                    module => module.name === "Maquinas" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                < items >
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/maquinas`} className="nav-link">
                                                    <Icon
                                                        path={mdiToolbox}
                                                        title="Maquinas"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Maquinas
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>

                            )}

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/mantenimientovehiculos`} className="nav-link">
                                            <Icon path={mdiCar}
                                                title="Vehiculos"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Vehiculos
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
                                    module => module.name === "Repuestos" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/repuestos`} className="nav-link">
                                                    <Icon
                                                        path={mdiWrench}
                                                        title="Repuestos"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Repuestos
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
                                    module => module.name === "Sucursales" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/sucursales`} className="nav-link">
                                                    <Icon
                                                        path={mdiHomeCity}
                                                        title="Sucursales"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Sucursales
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
                                    module => module.name === "Proyectos" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/proyectos`} className="nav-link">
                                                    <Icon
                                                        path={mdiFormatListCheckbox}
                                                        title="Proyectos"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Proyectos
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
                                    module => module.name === "Tareas" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/tareas`} className="nav-link">
                                                    <Icon
                                                        path={mdiPlaylistCheck}
                                                        title="Tareas"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Tareas
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
                                    module => module.name === "Gantt" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/gantt`} className="nav-link">
                                                    <Icon
                                                        path={mdiTimeline}
                                                        title="Gantt por Proyectos"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Gantt por Proyectos
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

{OwnedProducts.find(product => product.name === "Mantenimiento") && (
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
                                    module => module.name === "Gantt" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/ganttmaquinas`} className="nav-link">
                                                    <Icon
                                                        path={mdiTimeline}
                                                        title="Gantt por Elemento"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Gantt por Elemento
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}


                        {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
                                    module => module.name === "Gantt" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/trello`} className="nav-link">
                                                    <Icon
                                                        path={mdiViewGrid}
                                                        title="Tablero"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Tablero
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
                                    module => module.name === "Informe" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Icon
                                                    path={mdiMicrosoftExcel}
                                                    title="Informe"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Informe
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                    </>
                )}

            </sections >
        </>
    );
};

export default Mantenimiento;
