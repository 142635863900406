import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField, Button } from '@mui/material';
import { Modal } from '@mui/material';
import { useParams } from 'react-router';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

import UserService from "./services/mantenimiento.service";

// Esquema de validación del formulario de repuestos
const validationSchema = yup.object({
  nombre: yup.string().required('Nombre requerido'),
  precio: yup.number().required('Precio requerido'),
});


const RepuestosAddModComponent = (props) => {
  const [isLoading, setIsLoading] = useState(false)

  const styles = useStyles();
  const classes = useStyles();
  const [repuestos, setRepuestos] = useState([]);
  const { id } = useParams();
  const [modalEliminar, setModalEliminar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    precio: '',
    cantidad: '',
  })

  const formik = useFormik({
    initialValues: {
      nombre: '',
      precio: '',
      cantidad: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
    },
  });

  const cargarRepuestos = async () => {
    try {
      const result = await UserService.getRepuesto(id);
      if (result) {
        console.log(result)
        formik.setValues(result);
        
      } else {
        //props.history.push(process.env.PUBLIC_URL + "/login");
      }
    } catch (e) {
      props.history.push(process.env.PUBLIC_URL + "/login");
    }
  };

  useEffect(() => {
    if(id){
      cargarRepuestos();
    }
  }, []);

  const peticionPost = async (values) => {
    const response = await UserService.addmodRepuesto(id, values);
    cerrarEditar()
  }

  const peticionDelete = async () => {
    const response = await UserService.delRecurso(consolaSeleccionada.id, 'herramienta');
    var data = response.data;
    //peticionGet();
    abrirCerrarModalEliminar();
  }

  const cerrarEditar = () => {
    props.history.push(process.env.PUBLIC_URL + "/repuestos");
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? props.history.push(process.env.PUBLIC_URL + "/maquinasaddmod/" + consola.id) : abrirCerrarModalEliminar()
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el Repuesto <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/repuestos")
  }

  return (
    <Paper className={classes.root}>

<AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Agregar Modificar Repuesto
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <form onSubmit={formik.handleSubmit}>

        <Grid container spacing={3}>

          <Grid item xs={4}>
            <TextField
              name="nombre"
              label="Nombre"
              autoComplete="off"
              className={styles.inputMaterial}
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="precio"
              label="Precio"
              autoComplete="off"
              className={styles.inputMaterial}
              value={formik.values.precio}
              onChange={formik.handleChange}
              error={formik.touched.precio && Boolean(formik.errors.precio)}
              helperText={formik.touched.precio && formik.errors.precio}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="cantidad"
              label="Cantidad"
              className={styles.inputMaterial}
              autoComplete="off"
              value={formik.values.cantidad}
              onChange={formik.handleChange}
              error={formik.touched.cantidad && Boolean(formik.errors.cantidad)}
              helperText={formik.touched.cantidad && formik.errors.cantidad}
            />
          </Grid>
        </Grid>
        <br /><br />
        <div align="right">
          <Button color="primary" type="submit">Eviar</Button>
        </div>

      </form>
    </Paper>
  );
};

export default RepuestosAddModComponent;
