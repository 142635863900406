import React from 'react';
import { useState, useEffect } from 'react'

import { Document, Page, Text, View, StyleSheet, PDFViewer } from "@react-pdf/renderer";
// Create styles

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COLN_WIDTH = (100 - COL1_WIDTH) / 3
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#d11fb6",
    color: "white",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  body: {
    padding: 10
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol1: {
    width: COL1_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  }
});


// Create Document Component
const BasicDocument = ({ data }) => {
  console.log(data)
  const [result, setResult] = useState([]);
  const [dato, setDato] = useState([]);

  data.map(result => {
    dato.area = result.area;
    dato.vehiculo = result.vehiculo;
    dato.combustible = result.combustible;
    dato.litros = result.litros;
    dato.importe = result.importe;
    dato.fecha = result.fecha;
    dato.nota = result.nota;
  })

  return (
    <PDFViewer style={styles.viewer}>


      <Document>
        <Page style={styles.body}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1Header}>
                <Text style={styles.tableCellHeader}>Comprobante de Combustible</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>---</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Area</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{dato.area}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Dominio</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{dato.vehiculo}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Combustible</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{dato.combustible}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Litros</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{dato.litros}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Importe</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{dato.importe}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Fecha</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{dato.fecha}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Nota</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{dato.nota}</Text>
              </View>
            </View>

          </View>
        </Page>
      </Document>


    </PDFViewer>
  );
}
export default BasicDocument;
