import authHeader from "../../../services/auth-header";
import { handleResponse } from '../../../services/handle-response';
import http from "../../../services/http-common";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { openDB, deleteDB, wrap, unwrap } from 'idb';
import idbcache from 'idbcache';

const API_URL = 'https://devapi.casiba.ar/api/v1/';
const user = JSON.parse(localStorage.getItem('user3'));

/**
 * trae datos pora el informe de recursos 
 * @param {*} row 
 * @returns 
 */
const getInformeRecursos = (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_informe_recursos', requestOptions).then(handleResponse));
};

/**
 * agrega recurso a un empleado 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
const addRecurso = (id, row, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);
  data.append('id_recurso', row.id);
  //data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'add_recurso', requestOptions).then(handleResponse));
};

/**
 * del recurso
 * @param {*} id 
 * @param {*} recurso 
 * @returns 
 */
const delRecurso = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_recurso', requestOptions).then(handleResponse));
};

/**
 * desasignar recurso a un empleado 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
const delRecursoSeleccionado = (id, row, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);
  data.append('id_recurso', row.id);
  //data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_recurso_seleccionado', requestOptions).then(handleResponse));
};

/**
 * trae datos de un recurso, consultado desde el QR
 * @param {*} id 
 * @returns 
 */
const getRecursoCon = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_recurso_con', requestOptions).then(handleResponse));
};

/**
 * trae datos de un recurso
 * @param {*} id 
 * @returns 
 */
const getRecurso = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_recurso_id', requestOptions).then(handleResponse));
};

/**
 * trae recursos 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
const getRecursos = (recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_recursos', requestOptions).then(handleResponse));
};

/**
 * agreda o modifica una recurso 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
const addmodRecurso = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_recurso', requestOptions).then(handleResponse));
};

/**
 * trae lista de herramientas aplicadas como recursos de determinado empleado 
 * @param {*} id 
 * @returns 
 */
const getRecursosNoSeleccionados = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_recursos_no_seleccionados', requestOptions).then(handleResponse));
};

/**
 * trae lista de herramientas aplicadas como recursos de determinado empleado 
 * @param {*} id 
 * @returns 
 */
const getRecursosSeleccionados = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_recursos_seleccionados', requestOptions).then(handleResponse));
};

export default {

  getInformeRecursos,
  getRecursoCon,
  getRecursosNoSeleccionados,
  getRecursosSeleccionados,
  delRecursoSeleccionado,
  delRecurso,
  addRecurso,
  getRecurso,
  getRecursos,
  addmodRecurso,

};
