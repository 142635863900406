import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from '@mui/styles';

import { mdiBookInformationVariant, mdiCar, mdiGasStation, mdiHomeCity } from '@mdi/js'
import { mdiWrench } from '@mdi/js';
import { mdiHanger } from '@mdi/js';
import { mdiLaptop } from '@mdi/js';
import { mdiCellphone } from '@mdi/js';
import { mdiAccountArrowLeft } from '@mdi/js';
import { mdiGlasses } from '@mdi/js';
import { mdiQrcode } from '@mdi/js';
import { mdiCardAccountDetails } from '@mdi/js';
import { mdiMicrosoftExcel } from '@mdi/js';

import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import Paper from '@mui/material/Paper';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import Grid from '@mui/material/Grid';

import { AuthContext } from '../../components/AuthContext';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

const ControlFichadas = (props) => {
    const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const classes = useStyles();

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    return (

        <>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Recursos Humanos
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <sections>
                {showUser && (
                    <>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/fichar`} className="nav-link">
                                            <AlarmAddIcon
                                                sx={{
                                                    fontSize: 60,
                                                    color: "#004F9E"
                                                }}
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Fichar
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/fichadas`} className="nav-link">
                                            <PlaylistAddIcon
                                                sx={{
                                                    fontSize: 60,
                                                    color: "#004F9E"
                                                }}
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Tus Fichadas
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/solicitudes`} className="nav-link">
                                            <PlaylistAddIcon
                                                sx={{
                                                    fontSize: 60,
                                                    color: "#004F9E"
                                                }}
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Tus Solicitudes
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/administrador`} className="nav-link">
                                            <PlaylistAddIcon
                                                sx={{
                                                    fontSize: 60,
                                                    color: "#004F9E"
                                                }}
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Administrador
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    </>
                )}

            </sections>

        </>

    );
};

export default ControlFichadas;
