import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import { useHistory } from "react-router-dom";


//Validacion del formulario
const validationSchema = yup.object({
    nombre: yup
        .string('Nombre del equipo requerido')
        .required('Nombre del equipo requerido'),
    idresponsables: yup
        .array().of(yup.string('Nombre lo responsables requerido'))
        .required('Nombre del responsable requerido'),
    usuarios: yup
        .array().of(yup.string('Nombre los empleados requerido'))
        .required('Nombre los empleados requerido'),
});

export default function Equiuposaddmod() {
    const { id } = useParams();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false)
    const [empleados, setEmpleados] = useState([])

    const [seleccionados, setSeleccionados] = useState([]);
    const [rseleccionados, setRseleccionados] = useState([]);

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            nombre: '',
            //idresponsable: '',
            usuarios: [],
            idresponsables: [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const [showAlert, setShowAlert] = useState(false);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const styles = useStyles();

    const peticionPost = async (values) => {
        const res = await UserService.addmodEquipo(id, values);

        if (res.error) {
            setShowAlert(true)
        } else {
            setShowAlert(false)
            cerrarEditar()
        }

    }

    const cerrarEditar = () => {
        history.push(process.env.PUBLIC_URL + "/equipos");
    }

    const inicio = () => {
        history.push(process.env.PUBLIC_URL + "/equipos")
    }

    const handleChange1 = e => {
        const { name, value } = e.target;
        console.log(name)
        console.log(value)
        if (name == 'idresponsable') {
            formik.values.idresponsable = value;
            getEmpleados(value);
        }
        if (name == 'idresponsables') {
            formik.values.idresponsables = value;
            getEmpleados(value);
        }
    }

    const getEmpleados = async (id) => {
        const response = await UserService.getUsuariosEquipos(id);
        setSeleccionados(response);
    }

    useEffect(() => {

        const GetData = async () => {
            console.log(id)
            if (id) {
                try {
                    setIsLoading(true);
                    const response = await UserService.getEquipo(id);
                    if (response) {
                        formik.setValues(response);
                        //setSeleccionados(response.usuarios_seleccionados);
                        getEmpleados(response.idresponsables);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                } catch (e) {
                    setIsLoading(false);
                    history.push(process.env.PUBLIC_URL + "/login");
                }
            }
        }
        GetData();

        const GetEmpleados = async () => {
            try {
                setIsLoading(true);
                const response = await UserService.getUsuarios(id);
                if (response) {
                    setEmpleados(response.data);
                    setRseleccionados(response.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetEmpleados();

    }, []);

    return (

        <Paper>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Equipo
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            name="nombre"
                            className={styles.inputMaterial}
                            label="Nomre del equipo"
                            autoFocus={true}
                            value={formik.values.nombre}
                            autoComplete='off'
                            onChange={formik.handleChange}
                            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                            helperText={formik.touched.nombre && formik.errors.nombre}
                        />
                    </Grid>

                    {showAlert && (
                        <Grid item xs={12}>
                            <Alert severity="error">El nombre del equipo ya existe, ingrese otro</Alert>
                        </Grid>
                    )}

                    {/*  <Grid item xs={12}> 
                        <InputLabel htmlFor="outlined-age-native-simple">Responsable</InputLabel>
                        <Select
                            className={styles.inputMaterial}
                            native
                            label="Responsable"
                            value={formik.values.idresponsable}
                            //onChange={formik.handleChange}
                            onChange={handleChange1}
                            error={formik.touched.idresponsable && Boolean(formik.errors.idresponsable)}
                            helperText={formik.touched.idresponsable && formik.errors.idresponsable}
                            inputProps={{
                                name: 'idresponsable',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {empleados.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre} [{value.email}]
                                </option>
                            ))}
                        </Select>
                    </Grid> */}

                    <Grid item xs={12}>
                        <InputLabel id="demo-mutiple-name-label">Responsable/s</InputLabel>
                        <Select
                            className={styles.inputMaterial}
                            id="idresponsables"
                            name="idresponsables"
                            multiple
                            value={formik.values.idresponsables}
                            onChange={handleChange1}
                            //onChange={formik.handleChange}
                            input={<Input />}
                            MenuProps={MenuProps}
                            error={formik.touched.idresponsables && Boolean(formik.errors.idresponsables)}
                            helperText={formik.touched.idresponsables && formik.errors.idresponsables}
                        >
                            {rseleccionados.map((name) => (
                                <MenuItem key={name.id} value={name.id}>
                                    {name.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel id="demo-mutiple-name-label">Integrantes</InputLabel>
                        <Select
                            className={styles.inputMaterial}
                            id="usuarios"
                            name="usuarios"
                            multiple
                            value={formik.values.usuarios}
                            onChange={formik.handleChange}
                            input={<Input />}
                            MenuProps={MenuProps}
                            error={formik.touched.usuarios && Boolean(formik.errors.usuarios)}
                            helperText={formik.touched.usuarios && formik.errors.usuarios}
                        >
                            {seleccionados.map((name) => (
                                <MenuItem key={name.key} value={name.key}>
                                    {name.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                </Grid>
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>
        </Paper>
    );
}

