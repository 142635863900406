import React from 'react';
import { useState, useEffect } from 'react'

import Paper from '@mui/material/Paper';
import { useParams } from 'react-router';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import { Grid, ThemeProvider, StyledEngineProvider,} from "@mui/material";
import MaterialTable from 'material-table';
import {useStyles, theme, direction} from '../styles'; // Importa los estilos desde el archivo styles.js
 
import UserService from "./services/mantenimiento.service";

export default function MaquinaRepuestos(props) {
    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [repuestos, setRepuestos] = useState([]);
    const [repuestosrecursos, setRepuestosRecursos] = useState([]);

    const [epp, setEpp] = useState([]);
    const [epprecursos, setEppRecursos] = useState([]);

    const [modalEliminar, setModalEliminar] = useState(false);
    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        id: '',
        nombre: '',
        codigo: '',
    })
    const [consola, setConsola] = useState({
        nombre: '',
        apellido1: '',
    })

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        props.history.push(process.env.PUBLIC_URL + "/asignarrecursoausuario/" + consola.id + "/" + id);
    }

    const recursoPost = async (values) => {
        await UserService.addRepuestoMaquina(id, values);
        peticionGet()
    }

    const recursoDel = async (values, recurso) => {
        await UserService.delRepuestoSeleccionado(id, values, recurso);
        peticionGet()
    }

    const peticionGet = async () => {

        const h = await UserService.getRepuestosNoSeleccionados(id);
        setRepuestos(h);
        const hS = await UserService.getRepuestosSeleccionados(id);
        setRepuestosRecursos(hS);

    }

    useEffect(() => {


        const GetRepuestos = async () => {

            try {
                const result = await UserService.getRepuestosNoSeleccionados(id);
                if (result) {
                    setRepuestos(result);
                } else {
                    props.history.push(process.env.PUBLIC_URL + "/login");
                }
            } catch (e) {
                props.history.push(process.env.PUBLIC_URL + "/login");
            }

        }
        GetRepuestos();

        const GetRepuestosSeleccionados = async () => {

            try {
                const result = await UserService.getRepuestosSeleccionados(id);
                if (result) {
                    setRepuestosRecursos(result);
                } else {
                    props.history.push(process.env.PUBLIC_URL + "/login");
                }
            } catch (e) {
                props.history.push(process.env.PUBLIC_URL + "/login");
            }

        }
        GetRepuestosSeleccionados();

    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    const recursos = () => {
        props.history.push(process.env.PUBLIC_URL + "/recursos")
    }

    const empleados = () => {
        props.history.push(process.env.PUBLIC_URL + "/empleados")
    }

    return (
        <Paper className={classes.root}>

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div style={{ maxWidth: "100%", direction }}>

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="Repuestos de la Maquina" />
                                </Divider>
                            </Grid>
                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="REPUESTOS DISPONIBLES" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Nombre',
                                            field: 'nombre',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Asignar Recurso',
                                            onClick: (event, data) => recursoPost(data),
                                        },
                                    ]}

                                    data={repuestos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="REPUESTO SELECCIONADO" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Nombre',
                                            field: 'nombre',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'delete',
                                            tooltip: 'Eliminar Recurso',
                                            onClick: (event, data) => recursoDel(data),
                                        },
                                    ]}

                                    data={repuestosrecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>

        </Paper>
    );
}