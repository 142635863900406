/**
 * PWA Intranet
 * update 07/2022
 * By Sergio Sam 
 */

import React from "react";
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  <AuthProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthProvider>

);

serviceWorkerRegistration.register();
reportWebVitals();

