import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    marca: yup
        .string('Marca requerido')
        .required('Marca requerido'),
    idarea: yup
        .string('Seleccione un area')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Seleccione un area'),
    modelo: yup
        .string('Modelo requreido ')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Modelo requerido'),
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function Vehiculosadd(props) {
    const [isLoading, setIsLoading] = useState(false)
    //var hoy=selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate();
    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            marca: '',
            modelo: '',
            patente: '',
            kilometraje: '',
            tipo: '',
            mantenimiento: false,
            combustible_tipo: '',
            fecha_compra: '',
            empresa: '',
            nota: '',
            data1: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const [combustible, setCombustible] = useState([
        { id: 'Nafta', nombre: 'Nafta' },
        { id: 'Gas Oil', nombre: 'Gas Oil' },
        { id: 'Gnc', nombre: 'Gnc' },
    ]);

    const [tipos, setTipos] = useState([
        { id: 'Particular', nombre: 'Particular' },
        { id: 'Alquiler', nombre: 'Alquiler' },
    ]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const peticionPost = async (values) => {
        const response = await UserService.addmodVehiculo(id, values, right);
        setData(data.concat(response.data))
        cerrarEditar()
    }

    const cerrarEditar = () => {
        //props.history.push(process.env.PUBLIC_URL + "/vehiculos");
        props.history.goBack();
    }

    useEffect(() => {
        const GetData = async () => {
            try {
                const result = await UserService.getAreas();
                if (result) {
                    setData(result.data);

                    var dataNueva = result.data;
                    dataNueva.map(consola => {
                        setLeft(consola.tareas);
                    })

                } else {
                    props.history.push(process.env.PUBLIC_URL + "/login");
                }
            } catch (e) {
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/vehiculos")
    }

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Nuevo Vehiculo
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <TextField
                            name="marca"
                            className={styles.inputMaterial}
                            label="Marca"
                            autoFocus={true}
                            required
                            value={formik.values.marca}
                            onChange={formik.handleChange}
                            error={formik.touched.marca && Boolean(formik.errors.marca)}
                            helperText={formik.touched.marca && formik.errors.marca}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="modelo"
                            className={styles.inputMaterial}
                            label="Modelo"
                            autoFocus={true}
                            required
                            value={formik.values.modelo}
                            onChange={formik.handleChange}
                            error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                            helperText={formik.touched.modelo && formik.errors.modelo}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="kilometraje"
                            className={styles.inputMaterial}
                            label="Kilometraje"
                            value={formik.values.kilometraje}
                            onChange={formik.handleChange}
                            error={formik.touched.kilometraje && Boolean(formik.errors.kilometraje)}
                            helperText={formik.touched.kilometraje && formik.errors.kilometraje}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="patente"
                            className={styles.inputMaterial}
                            label="Patente"
                            value={formik.values.patente}
                            onChange={formik.handleChange}
                            error={formik.touched.patente && Boolean(formik.errors.patente)}
                            helperText={formik.touched.patente && formik.errors.patente}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Area</InputLabel>
                        <Select
                            native
                            required
                            label="Area"
                            inputProps={{
                                name: 'idarea',
                                id: 'outlined-age-native-simple',
                            }}
                            value={formik.values.idarea}
                            onChange={formik.handleChange}
                            className={styles.inputMaterial}
                            //onChange={handleChange}
                            error={formik.touched.idarea && Boolean(formik.errors.idarea)}
                            helperText={formik.touched.idarea && formik.errors.idarea}
                        >
                            <option aria-label="None" value="" />
                            {data.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>


                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Tipo de combustible</InputLabel>
                        <Select
                            required
                            native
                            label="Combustible"
                            value={formik.values.combustible_tipo}
                            onChange={formik.handleChange}
                            className={styles.inputMaterial}
                            error={formik.touched.combustible_tipo && Boolean(formik.errors.combustible_tipo)}
                            helperText={formik.touched.combustible_tipo && formik.errors.combustible_tipo}
                            inputProps={{
                                name: 'combustible_tipo',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {combustible.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Tipo de vehiculo</InputLabel>
                        <Select
                            required
                            native
                            label="Tipo"
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                            className={styles.inputMaterial}
                            error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                            helperText={formik.touched.tipo && formik.errors.tipo}
                            inputProps={{
                                name: 'tipo',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {tipos.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            required
                            id="date"
                            type="date"
                            defaultValue="2021-08-25"
                            name="fecha_compra"
                            label="Fecha de compra"
                            className={classes.textField}
                            error={formik.touched.fecha_compra && Boolean(formik.errors.fecha_compra)}
                            helperText={formik.touched.fecha_compra && formik.errors.fecha_compra}
                            onChange={formik.handleChange}
                            value={formik.values.fecha_compra}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel>Requiere Mantenimiento</InputLabel>
                        <Checkbox
                            name="mantenimiento"
                            value={formik.values.mantenimiento}
                            checked={formik.values.mantenimiento}
                            onChange={formik.handleChange}
                            color="primary" // Opcional: para cambiar el color del checkbox
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="nota"
                            className={styles.inputMaterial}
                            label="Nota"
                            value={formik.values.nota}
                            onChange={formik.handleChange}
                            error={formik.touched.nota && Boolean(formik.errors.nota)}
                            helperText={formik.touched.nota && formik.errors.nota}
                        />
                    </Grid>

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Agregar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>
        </Paper>
    );
}