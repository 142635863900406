import React, { useState, useEffect } from 'react';
import Board from 'react-trello'
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import UserService from "./services/mantenimiento.service";

const Trello_component = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [res, setRes] = useState([]);  // Especificar el tipo de datos
  const [selected, setSelected] = useState('Todos');  // Cambiado a string
  const [selectedUser, setSelectedUser] = useState('Todos');  // Cambiado a string
  const [data, setData] = useState({
    lanes: [
      {
        id: 'lane1',
        title: 'Planned Tasks',
        label: '2/2',
        cards: [
          { id: 'Card1', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins' },
          { id: 'Card2', title: 'Pay Rent', description: 'Transfer via NEFT', label: '5 mins' }
        ]
      },
      {
        id: 'lane2',
        title: 'Completed',
        label: '0/0',
        cards: []
      }
    ]
  });

  useEffect(() => {
    const GetData = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getTrello("Todos");
        if (result) {
          setData(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        props.history.push(process.env.PUBLIC_URL + "/login");
      }
    }
    GetData();
  }, []);

  useEffect(() => {

    const GetResponsables = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getEmpleadosTarea();
        if (result) {
          setRes(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          //handleShowMessage('500');
        }
      } catch (e) {
        setIsLoading(false);
        //handleShowMessage('500');
      }
    }
    GetResponsables();

  }, []);

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/mantenimiento");
  }

  // Función para manejar los cambios cuando se arrastra una tarea
  const handleCardMove = (fromLaneId, toLaneId, cardIndex) => {
    const newData = { ...data }; // Clonar el estado actual de los datos
    const [movedCard] = newData.lanes.find((lane) => lane.id === fromLaneId).cards.splice(cardIndex, 1);
    newData.lanes.find((lane) => lane.id === toLaneId).cards.push(movedCard);
    console.log(toLaneId)
    console.log(cardIndex)
    setData(newData);
    UserService.setStatusTarea(cardIndex,toLaneId);
  };

  const handleChangeUser = async (event) => {
    try {
      setIsLoading(true);

      setSelectedUser(event.target.value);

      // Llamada a la función getGanttMaquinas con el parámetro seleccionado

      console.log(selected);
      console.log(selectedUser);

      const result = await UserService.getTrello(event.target.value);
      
      if (result ) {
        setData(result);
        setIsLoading(false);
     } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };


  return (
    <>
      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Tablero
          </Typography>
        </Toolbar>
      </AppBar>
      <br />
      <div className="Wrapper">

        {/* Menú desplegable llenado con las opciones de 'res' */}
        <select onChange={handleChangeUser}>
        <option value="Todos">Todos</option>
          {res.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <Board
          data={data}
          draggable // Habilitar la funcionalidad de arrastrar
          //onDataChange={setData} // Esta función se llama cuando los datos cambian (por ejemplo, cuando se arrastra una tarea)
          onCardMoveAcrossLanes={handleCardMove} // Manejar el evento de arrastrar una tarjeta entre columnas

        />
      </div>
    </>
  );
};

export default Trello_component;
