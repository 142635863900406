import React from 'react';

import { makeStyles, useTheme} from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    area: yup
        .string('Nombre de equipo requerido')
        .required('Nombre de equipo requerido'),
    vehiculo: yup
        .string('Seleccione un UP/Almacen')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Seleccione un UP/Almacen'),
    combustible: yup
        .string('Seleccione un yacimiento')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Seleccione un yacimiento'),
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 200,
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function Combustiblesmod(props) {
    //var hoy=selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate();
    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            area: '',
            vehiculo: '',
            combustible: '',
            fechacarga: '',
            litros: '',
            importe: '',
            nota: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //alert(JSON.stringify(values, null, 2));
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);
    const [datus, setDatus] = useState([]);
    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    //const [selectedDate] = useState(new Date());

    const [combustible, setCombustible] = useState([
        { id: 'Nafta', nombre: 'Nafta' },
        { id: 'Gas Oil', nombre: 'Gas Oil' },
        { id: 'Gnc', nombre: 'Gnc' },
    ]);

    const handleChange = e => {
        const { name, value } = e.target;
        if (name == 'area') {
            formik.values.area = value;
            getVehiculos(value);
        }
    }

    const getVehiculos = async (id) => {
        const response = await UserService.getVehiculosid(id);
        setDatayacimientos(response.data);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const peticionPost = async (values) => {
        const response = await UserService.addmodCombustible(id, values, right);
        setData(data.concat(response.data))
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL +"/combustibles");
    }

    useEffect(() => {
        const GetData = async () => {
            try {
                const result = await UserService.getCombustible(id);
                if (result) {
                  var dataNueva = result.data;
                  dataNueva.map(consola => {
                    formik.initialValues.area = consola.idarea;
                    formik.initialValues.vehiculo = consola.idvehiculo;
                    formik.initialValues.combustible = consola.idcombustible;
                    formik.initialValues.fechacarga = consola.fecha;
                    formik.initialValues.litros = consola.litros;
                    formik.initialValues.importe = consola.importe;
                    formik.initialValues.nota = consola.nota;
                    setDatos(consola.areas);
                    setDatus(consola.vehiculos);

                  })
                  //response.data.yacimientos.map(con => {
                  //  data.nombre= con.nombre;
                  //})
        
        

                    //setData(result.data);

                   // var dataNueva = result.data;
                   // dataNueva.map(consola => {
                   //     setLeft(consola.tareas);
                   // })

                } else {
                       props.history.push(process.env.PUBLIC_URL +"/login");
                }
            } catch (e) {
                 props.history.push(process.env.PUBLIC_URL +"/login");
            }
        }
        GetData();
    }, []);

    const customList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value.id}-label`;

                    return (
                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.nombre}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <Paper className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href={`${process.env.PUBLIC_URL}/`}>
                    Inicio
                </Link>
                <Link color="inherit" href={`${process.env.PUBLIC_URL}/combustibles`}>
                    Combustibles
                </Link>
            </Breadcrumbs>

            <div className={styles.modal}>
                <form onSubmit={formik.handleSubmit}>
                    <h3>Agregar Nueva Carga</h3>

                    <Grid container spacing={3}>

                        <Grid item xs={4}>
                            <InputLabel htmlFor="outlined-age-native-simple">Area</InputLabel>
                            <Select
                                native
                                required
                                label="Area"
                                inputProps={{
                                    name: 'area',
                                    id: 'outlined-age-native-simple',
                                }}
                                value={formik.values.area}
                                //onChange={formik.handleChange}
                                onChange={handleChange}
                                error={formik.touched.area && Boolean(formik.errors.area)}
                                helperText={formik.touched.area && formik.errors.area}
                            >
                                <option aria-label="None" value="" />
                                {datos.map((value) => (
                                    <option value={value.id} key={value.id}>
                                        {value.nombre}
                                    </option>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel htmlFor="outlined-age-native-simple">Vehiculo</InputLabel>
                            <Select
                                native
                                required
                                label="Vehiculo"
                                inputProps={{
                                    name: 'vehiculo',
                                    id: 'outlined-age-native-simple',
                                }}
                                value={formik.values.vehiculo}
                                onChange={formik.handleChange}
                                error={formik.touched.vehiculo && Boolean(formik.errors.vehiculo)}
                                helperText={formik.touched.vehiculo && formik.errors.vehiculo}
                            >
                                <option aria-label="None" value="" />
                                {datus.map((value) => (
                                    <option value={value.id} key={value.id}>
                                       {value.patente} {value.marca} {value.modelo} 
                                    </option>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel htmlFor="outlined-age-native-simple">Combustible</InputLabel>
                            <Select
                                required
                                native
                                label="Combustible"
                                value={formik.values.combustible}
                                onChange={formik.handleChange}
                                error={formik.touched.combustible && Boolean(formik.errors.combustible)}
                                helperText={formik.touched.combustible && formik.errors.combustible}
                                inputProps={{
                                    name: 'combustible',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                {combustible.map((value) => (
                                    <option value={value.id} key={value.id}>
                                        {value.nombre}
                                    </option>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                name="litros"
                                className={styles.inputMaterial}
                                label="Litros"
                                value={formik.values.litros}
                                onChange={formik.handleChange}
                                error={formik.touched.litros && Boolean(formik.errors.litros)}
                                helperText={formik.touched.litros && formik.errors.litros}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                name="importe"
                                className={styles.inputMaterial}
                                label="Importe Neto"
                                value={formik.values.importe}
                                onChange={formik.handleChange}
                                error={formik.touched.importe && Boolean(formik.errors.importe)}
                                helperText={formik.touched.importe && formik.errors.importe}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                required
                                id="date"
                                type="date"
                                defaultValue="2021-08-25"
                                name="fechacarga"
                                label="Fecha de carga"
                                className={classes.textField}
                                error={formik.touched.fechacarga && Boolean(formik.errors.fechacarga)}
                                helperText={formik.touched.fechacarga && formik.errors.fechacarga}
                                onChange={formik.handleChange}
                                value={formik.values.fechacarga}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="nota"
                                className={styles.inputMaterial}
                                label="Nota"
                                value={formik.values.nota}
                                onChange={formik.handleChange}
                                error={formik.touched.nota && Boolean(formik.errors.nota)}
                                helperText={formik.touched.nota && formik.errors.nota}
                            />
                        </Grid>

                        {/*
                        <Grid item xs={12}>
                            <FormLabel component="legend">Asignar tareas para producir el equipo</FormLabel>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.root}
                        >
                            <Grid item>{customList('Seleccionar', left)}</Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>{customList('Seleccionadas', right)}</Grid>
                        </Grid>
*/}

                    </Grid>
                    <br /><br />
                    <div align="right">
                        <Button color="primary" type="submit">Modificar</Button>
                        <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                    </div>
                </form>
            </div>
        </Paper>
    );
}