import React from 'react';

import { useState, useEffect, useContext } from 'react'

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Modal } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import UserService from "../../services/user.service";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Excel from "../Excel.component";

//Validacion del formulario
const validationSchema = yup.object();

export default function Informes(props) {
    const [isLoading, setIsLoading] = useState(true)
    const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [dataequipos, setDataequipos] = useState([]);

    const [left, setLeft] = useState([]);
    const [data, setData] = useState([]);
    const [modalQR, setModalQR] = useState(false);
    const [values, setValues] = useState([]);
    const [open, setOpen] = React.useState(false);

    const [estado, setEstado] = useState([
        { id: 'En proceso', nombre: 'En proceso' },
        { id: 'Entregado', nombre: 'Entregado' },
        { id: 'Operativo', nombre: 'Operativo' },
        { id: 'En reparacion', nombre: 'En reparacion' },
        { id: 'Fuera de servicio', nombre: 'Fuera de servicio' },
    ]);

    const handleChange = e => {
        const { name, value } = e.target;
        if (name === 'empresa') {
            formik.values.empresa = value;
            getYacimiento(value);
        }
        if (name === 'yacimiento') {
            formik.values.yacimiento = value;
            getEquipo(value);
        }
    }

    const getYacimiento = async (id) => {
        const response = await UserService.getYacimientosid(id);
        setDatayacimientos(response.data);
    }

    const getEquipo = async (id) => {
        const response = await UserService.getEquiposid(id);
        setDataequipos(response.data);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const abrirCerrarModalQR = () => {
        setModalQR(!modalQR);
    }

    const peticionPost = async (data) => {
        const response = await UserService.getInforme(data);
        if (response.data == null) {
            setOpen(true);
            //setModalQR(!modalQR);
        } else {
            abrirCerrarModalQR();
            setValues(response.data);
        }
        //cerrarEditar()
    }

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            fechainicio: '',
            fechafin: '',
            empresa: '',
            yacimiento: '',
            serie: '',
            sap: '',
            estado: '',
            equipo: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //abrirCerrarModalQR();
        },
    });

    const styles = useStyles();
    const classes = useStyles();

    const cerrarEditar = () => {
        props.history.push("/empresas");
    }

    useEffect(() => {
        const GetData = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getEmpresasyTareas();
                if (result) {
                    setData(result.data);
                    var dataNueva = result.data;
                    dataNueva.map(consola => {
                        setLeft(consola.tareas);
                    })
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                // props.history.push("/login");
            }
        }
        GetData();
    }, []);

    const bodyQR = (
        <div className={styles.modal1}>
            <Excel url={values} />
        </div>
    )

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/flota")
    }

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Informe
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <TextField
                            id="date"
                            type="date"
                            defaultValue="2021-08-25"
                            name="fechainicio"
                            label="Fecha/carga desde:"
                            className={classes.textField}
                            error={formik.touched.fechainicio && Boolean(formik.errors.fechainicio)}
                            helperText={formik.touched.fechainicio && formik.errors.fechainicio}
                            onChange={formik.handleChange}
                            value={formik.values.fechainicio}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id="date"
                            type="date"
                            defaultValue="2021-08-25"
                            name="fechafin"
                            label="Fecha/carga hasta:"
                            className={classes.textField}
                            error={formik.touched.fechafin && Boolean(formik.errors.fechafin)}
                            helperText={formik.touched.fechafin && formik.errors.fechafin}
                            onChange={formik.handleChange}
                            value={formik.values.fechafin}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                </Grid>

                <div align="right">
                    <Button color="primary" type="submit">Generar informe</Button>
                    <Button color="primary" onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"No fue posible generar el informe"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        El informe no posee datos.
                        Intenta modificar los datos de tu consulta.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={modalQR}
                onClose={abrirCerrarModalQR}>
                {bodyQR}
            </Modal>
        </Paper>
    );
}