import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { mdiWrench } from '@mdi/js';
import { mdiMicrosoftExcel } from '@mdi/js';
import { mdiToolbox } from '@mdi/js';
import { mdiTools } from '@mdi/js';
import { mdiCar } from '@mdi/js';
import { mdiTicket } from '@mdi/js';

import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

const Tickets = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const { showUser, OwnedProducts } = useContext(AuthContext);

    const classes = useStyles();

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    return (
        <>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => inicio()}                         
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Tickets
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <sections>
                {showUser && (
                    <>
                        < items >
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/ticketslist`} className="nav-link">
                                            <Icon
                                                path={mdiTicket}
                                                title="Tickets"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Tickets
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>


                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                            <Icon path={mdiTicket}
                                                title="Mis Tickets"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Mis Tickets
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon
                                            path={mdiMicrosoftExcel}
                                            title="Informe"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Informe
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                    </>
                )}

            </sections >
        </>
    );
};

export default Tickets;
