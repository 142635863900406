import React from 'react';
import { useState, useEffect, useContext } from 'react'

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import UserService from "../../services/user.service";
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    area: yup
        .string('Nombre de equipo requerido')
        .required('Nombre de equipo requerido'),
    vehiculo: yup
        .string('Seleccione un UP/Almacen')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Seleccione un UP/Almacen'),
    combustible: yup
        .string('Seleccione un yacimiento')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Seleccione un yacimiento'),
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function Combustiblesadd(props) {
    const [isLoading, setIsLoading] = useState(true)
    const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);

    //var hoy=selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate();
    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            area: '',
            vehiculo: '',
            combustible: '',
            fechacarga: '',
            litros: '',
            importe: '',
            nota: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //alert(JSON.stringify(values, null, 2));
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const [combustible, setCombustible] = useState([
        { id: 'Nafta', nombre: 'Nafta' },
        { id: 'Gas Oil', nombre: 'Gas Oil' },
        { id: 'Gnc', nombre: 'Gnc' },
    ]);

    const handleChange = e => {
        const { name, value } = e.target;
        if (name == 'area') {
            formik.values.area = value;
            getVehiculos(value);
        }
    }

    const getVehiculos = async (id) => {
        const response = await UserService.getVehiculos();
        setDatayacimientos(response.data);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const peticionPost = async (values) => {
        const response = await UserService.addmodCombustible(id, values, right);
        setData(data.concat(response.data))
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/combustibles");
    }

    useEffect(() => {

        const GetData = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getAreas();
                if (result) {
                    setData(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();

    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/combustibles")
    }

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Nueva Carga
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Area</InputLabel>
                        <Select
                            native
                            required
                            label="Area"
                            inputProps={{
                                name: 'area',
                                id: 'outlined-age-native-simple',
                            }}
                            value={formik.values.area}
                            //onChange={formik.handleChange}
                            onChange={handleChange}
                            error={formik.touched.area && Boolean(formik.errors.area)}
                            helperText={formik.touched.area && formik.errors.area}
                        >
                            <option aria-label="None" value="" />
                            {data.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Vehiculo</InputLabel>
                        <Select
                            native
                            required
                            label="Vehiculo"
                            inputProps={{
                                name: 'vehiculo',
                                id: 'outlined-age-native-simple',
                            }}
                            value={formik.values.vehiculo}
                            onChange={formik.handleChange}
                            error={formik.touched.vehiculo && Boolean(formik.errors.vehiculo)}
                            helperText={formik.touched.vehiculo && formik.errors.vehiculo}
                        >
                            <option aria-label="None" value="" />
                            {datayacimientos.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.patente} {value.marca} {value.modelo}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Combustible</InputLabel>
                        <Select
                            required
                            native
                            label="Combustible"
                            value={formik.values.combustible}
                            onChange={formik.handleChange}
                            error={formik.touched.combustible && Boolean(formik.errors.combustible)}
                            helperText={formik.touched.combustible && formik.errors.combustible}
                            inputProps={{
                                name: 'combustible',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {combustible.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="litros"
                            className={styles.inputMaterial}
                            label="Litros"
                            value={formik.values.litros}
                            onChange={formik.handleChange}
                            error={formik.touched.litros && Boolean(formik.errors.litros)}
                            helperText={formik.touched.litros && formik.errors.litros}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="importe"
                            className={styles.inputMaterial}
                            label="Importe Neto"
                            value={formik.values.importe}
                            onChange={formik.handleChange}
                            error={formik.touched.importe && Boolean(formik.errors.importe)}
                            helperText={formik.touched.importe && formik.errors.importe}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            required
                            id="date"
                            type="date"
                            defaultValue="2021-08-25"
                            name="fechacarga"
                            label="Fecha de carga"
                            className={classes.textField}
                            error={formik.touched.fechacarga && Boolean(formik.errors.fechacarga)}
                            helperText={formik.touched.fechacarga && formik.errors.fechacarga}
                            onChange={formik.handleChange}
                            value={formik.values.fechacarga}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="nota"
                            className={styles.inputMaterial}
                            label="Nota"
                            value={formik.values.nota}
                            onChange={formik.handleChange}
                            error={formik.touched.nota && Boolean(formik.errors.nota)}
                            helperText={formik.touched.nota && formik.errors.nota}
                        />
                    </Grid>

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Agregar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>
        </Paper>
    );
}