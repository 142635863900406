export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user3'));

  //console.log(user)
  //console.log(user.stsTokenManarer)
  // Extrae el accessToken del objeto
  //const accessToken = user?.stsTokenManager?.accessToken;
  const accessToken = localStorage.getItem('accessToken');

  //if (user && user.stsTokenManger.accessToken) {
  if (accessToken) {
    
    return { Authorization: 'Bearer ' + accessToken }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};//stsTokenmanger
  }
}