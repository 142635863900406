import authHeader from "./auth-header";
import authHeaderMitre from "./auth-header_mitre";
import { handleResponse } from './handle-response';
import http from "./http-common";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { openDB, deleteDB, wrap, unwrap } from 'idb';
import idbcache from 'idbcache';

//const API_URL = "http://localhost:8080/api/test/";
//const API_URL = 'https://rh.casiba.net/api/v1/';
//const API_URL = 'https://api.devib.ar/api/v1/';
const API_URL = 'https://devapi.casiba.ar/api/v1/';
const API_URL_MITRE = 'https://api.casiba.net/api/v1/';

const user = JSON.parse(localStorage.getItem('user3'));

const getVerdes = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'getverdes', requestOptions).then(handleResponse));
};


const getVerde = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('tipo', 'verde');

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'getverde', requestOptions).then(handleResponse));
};

/**
 * agreda o modifica una planilla verde 
 * @param {*} id 
 * @param {*} row 
 * @param {*} tareas 
 * @returns 
 */
const addmodVerde = async (id, row, tareas) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));
  data.append('tar', JSON.stringify(tareas));

  //const db = await openDB('microsite', 1);

  //Add register to indexDB
  //Search the name of the category
  //let cat = db.transaction('verdes').objectStore('verdes');
  //await db.put('verdes', row);
  //db.close();

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };

  return (fetch(API_URL + 'addmodverdes', requestOptions).then(res => {
    if (res.ok) {
      return res;
    }
  }).then((res) => {
    return res;
  }).catch((error) => {
    //addOfflineAdd(id, row);
    return error
  }));

};

const getPdf = (id,tipo) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('tipo', 'verde');

  //const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  //return (fetch(API_URL_MITRE + 'getverdes', requestOptions).then(handleResponse));
  //return fetch(API_URL2 + 'vprint', requestOptions).then(handleResponse);
    // Llamada a la API

    const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };

    fetch(API_URL_MITRE + 'vprint', requestOptions)
      .then(handleResponse2)
      .catch(error => {
        // Manejar errores
        console.error('Error:', error);
      });

};

function handleResponse2(response) {
  return response.blob().then(blob => {
      // Crea un objeto URL para el blob
      const blobUrl = URL.createObjectURL(blob);

      // Crea un enlace temporal
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'example.pdf'; // Nombre del archivo de descarga
      document.body.appendChild(link);

      // Simula un clic en el enlace para iniciar la descarga
      link.click();

      // Elimina el enlace temporal y libera el objeto URL
      URL.revokeObjectURL(blobUrl);
      link.remove();
  });
}

export default {
  getPdf,
  addmodVerde,
  getVerde,
  getVerdes,
};
