import authHeader from "./auth-header";
import { handleResponse } from './handle-response';
import http from "./http-common";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { openDB, deleteDB, wrap, unwrap } from 'idb';
import idbcache from 'idbcache';

//const API_URL = "http://localhost:8080/api/test/";
//const API_URL = 'https://rh.casiba.net/api/v1/';
//const API_URL = 'https://api.devib.ar/api/v1/';
const API_URL = 'https://devapi.casiba.ar/api/v1/';
const user = JSON.parse(localStorage.getItem('user3'));

//trae lista de areas
const getAreas = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getareas', requestOptions).then(handleResponse));
};

//trae datos de una area
const getArea = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getarea', requestOptions).then(handleResponse));
};

//agreda o modifica un area
const addmodArea = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodarea', requestOptions).then(handleResponse));
};

//elimina una area
const delArea = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delarea', requestOptions).then(handleResponse));
};

export default {
  getAreas,
  getArea,
  addmodArea,
  delArea,
};
