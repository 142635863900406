import React from 'react';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

import { LinearProgress, AppBar, CircularProgress, IconButton, Toolbar, Breadcrumbs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import UserServiceCore from "../../services/user.service";
import UserService from "./services/mantenimiento.service";

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    tipodemantenimiento: yup
        .string('Tipo de mantenimiento requerido')
        .required('Tipo de mantenimiento requerido'),
    maquina: yup
        .string('Maquina requerida')
        .required('Maquina requerida'),
    fecha_inicio: yup
        .date('Fecha de inicio requerida')
        .required('Fecha de inicio requerida')
        .max(yup.ref('fecha_finalizacion'), 'Fecha de inicio debe ser anterior a Fecha de finalización'),
    fecha_finalizacion: yup
        .date('Fecha de finalización requerida')
        .required('Fecha de finalización requerida')
        .min(yup.ref('fecha_inicio'), 'Fecha de finalización debe ser posterior a Fecha de inicio'),
});

export default function Tareasaddmod(props) {
    const [isLoading, setIsLoading] = useState(false)

    //inicializacion de variables y validacion
    var d = new Date();
    var finaldate = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + (d.getDate()).toString().padStart(2, "0")

    const today = new Date().toISOString().substr(0, 10);

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            tipodemantenimiento: '',
            status: '',
            maquina: '',
            value: '',
            fecha_inicio: '',
            fecha_finalizacion: '',
            idresponsable: '',
            idproyecto: '',
            idsucursal: '',
            prioridad: '',
            progress: '',
            alerta: false,
            nombre: '',
            activo: true,
            dependencies: [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);
    const [res, setRes] = useState([]);
    const [proy, setProy] = useState([]);
    const [tar, setTar] = useState([]);
    const [suc, setSuc] = useState([]);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [plantas, setPlantas] = useState([
        { id: 'Mitre', nombre: 'Mitre' },
        { id: 'Sabattini', nombre: 'Sabattini' },
    ]);

    const [status, setStatus] = useState([
        { id: 'Por_Hacer', value: 'Por_Hacer', label: 'POR HACER' },
        { id: 'En_Curso', value: 'En_Curso', label: 'EN CURSO' },
        { id: 'Listo', value: 'Listo', label: 'LISTO' },
    ]);

    const getProgressColor = (progress) => {
        if (progress <= 33) {
            return 'red'; // Rojo para el progreso menor o igual al 33%
        } else if (progress <= 66) {
            return 'yellow'; // Amarillo para el progreso menor o igual al 66%
        } else {
            return 'green'; // Verde para el progreso mayor al 66%
        }
    };

    const [tipos, setTipos] = useState([
        { id: 'Mantenimiento Preventivo', value: 'Mantenimiento Preventivo', label: 'Mantenimiento Preventivo' },
        { id: 'Mantenimiento Correctivo', value: 'Mantenimiento Correctivo', label: 'Mantenimiento Correctivo' },
        { id: 'Mantenimiento Correctivo', value: 'Mantenimiento Correctivo', label: 'Mantenimiento Predictivo' },
        { id: 'Mantenimiento de Rutina', value: 'Mantenimiento de Rutina', label: 'Mantenimiento de Rutina' },
        { id: 'Mantenimiento Correctivo de Emergencia', value: 'Mantenimiento Correctivo de Emergencia', label: 'Mantenimiento Correctivo de Emergencia' },
    ]);

    const [prioridad, setPrioridad] = useState([
        { id: 'Alta', value: 'Alta', label: 'Alta' },
        { id: 'Media', value: 'Media', label: 'Media' },
        { id: 'Baja', value: 'Baja', label: 'Baja' },
    ]);
    const peticionPost = async (values) => {
        const response = await UserService.addmodTarea(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/tareas");
    }

    useEffect(() => {

        const GetData = async () => {

            if (id) {
                try {
                    setIsLoading(true);
                    const response = await UserService.getTarea(id);
                    if (response) {
                        formik.setValues(response);
                    } else {
                        handleShowMessage('500');
                    }
                    setIsLoading(false);
                } catch (e) {
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            }

        }
        GetData();

    }, []);


    useEffect(() => {

        const GetMaquinas = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getBienes();
                console.log(result)
                if (result) {
                    setDatos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetMaquinas();

    }, []);

    useEffect(() => {

        const GetProyectos = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getProyectos();
                if (result) {
                    setProy(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetProyectos();

    }, []);

    useEffect(() => {

        const GetTareas = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getTareasP(id);
                if (result) {
                    setTar(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetTareas();

    }, []);

    useEffect(() => {

        const GetResponsables = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getEmpleadosTarea();
                if (result) {
                    setRes(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetResponsables();

    }, []);

    useEffect(() => {
        const GetSucursales = async () => {
            try {
                setIsLoading(true);
                const result = await UserServiceCore.getSucursales();
                if (result) {
                    setSuc(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e)
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetSucursales();
    }, []);


    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/tareas")
    }

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Tarea
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Typography variant="body1">Progreso</Typography>
                        <LinearProgress
                            variant="determinate"
                            value={formik.values.progress} // Asegúrate de que `formik.values.progress` esté en el rango de 0 a 100
                            sx={{
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: (theme) => getProgressColor(formik.values.progress),
                                    height: '100%', // Grosor de la línea (ajusta el valor según tus preferencias)

                                },
                            }}
                        />
                        <Typography variant="body2" color="textSecondary">{formik.values.progress}%</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={datos}
                            value={datos.find((option) => option.id === formik.values.maquina) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('maquina', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Elemento a reparar..."
                                    name="maquina"
                                    error={Boolean(formik.touched.maquina && formik.errors.maquina)}
                                    helperText={formik.touched.maquina && formik.errors.maquina}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="nombre"
                            className={styles.inputMaterial}
                            label="Descripcion"
                            autoComplete='off'
                            value={formik.values.nombre}
                            onChange={formik.handleChange}
                            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                            helperText={formik.touched.nombre && formik.errors.nombre}
                        />

                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="progress"
                            className={styles.inputMaterial}
                            label="Progreso (%)"
                            type="number"
                            autoComplete='off'
                            value={formik.values.progress}
                            onChange={formik.handleChange}
                            error={formik.touched.progress && Boolean(formik.errors.progress)}
                            helperText={formik.touched.progress && formik.errors.progress}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={status}
                            value={status.find((option) => option.id === formik.values.status) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('status', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Estado de la tarea"
                                    name="status"
                                    error={Boolean(formik.touched.status && formik.errors.status)}
                                    helperText={formik.touched.status && formik.errors.status}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id="date"
                            type="date"
                            className={styles.inputMaterial}
                            autoComplete='off'
                            defaultValue={formik.values.fecha_inicio || finaldate}
                            name="fecha_inicio"
                            label="Fecha inicio"
                            error={formik.touched.fecha_inicio && Boolean(formik.errors.fecha_inicio)}
                            helperText={formik.touched.fecha_inicio && formik.errors.fecha_inicio}
                            onChange={formik.handleChange}
                            value={formik.values.fecha_inicio}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id="date-finalizacion"                        // Nuevo campo
                            type="date"
                            autoComplete='off'
                            name="fecha_finalizacion"                     // Nuevo campo
                            label="Fecha finalizacion"                      // Nuevo campo
                            className={styles.inputMaterial}
                            error={formik.touched.fecha_finalizacion && Boolean(formik.errors.fecha_finalizacion)}
                            helperText={formik.touched.fecha_finalizacion && formik.errors.fecha_finalizacion}
                            onChange={formik.handleChange}
                            value={formik.values.fecha_finalizacion}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={tipos}
                            value={tipos.find((option) => option.id === formik.values.tipodemantenimiento) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('tipodemantenimiento', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Tipo de mantenimiento"
                                    name="tipodemantenimiento"
                                    error={Boolean(formik.touched.tipodemantenimiento && formik.errors.tipodemantenimiento)}
                                    helperText={formik.touched.tipodemantenimiento && formik.errors.tipodemantenimiento}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={4}>
                    <Autocomplete
                            className={styles.inputMaterial}
                            options={proy}
                            value={proy.find((option) => option.id === formik.values.idproyecto) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('idproyecto', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Proyecto"
                                    name="idproyecto"
                                    error={Boolean(formik.touched.idproyecto && formik.errors.idproyecto)}
                                    helperText={formik.touched.idproyecto && formik.errors.idproyecto}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={tar}
                            value={formik.values.dependencies.map((dependencyId) => tar.find((option) => option.id === dependencyId))}
                            getOptionLabel={(option) => (option ? option.nombre : '')}
                            multiple
                            onChange={(_, selectedOptions) => {
                                formik.setFieldValue('dependencies', selectedOptions.map((option) => option.id));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Tarea Dependiente"
                                    name="dependencies"
                                    error={formik.touched.dependencies && Boolean(formik.errors.dependencies)}
                                    helperText={formik.touched.dependencies && formik.errors.dependencies}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={res}
                            value={res.find((option) => option.id === formik.values.idresponsable) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('idresponsable', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Responsable"
                                    name="idresponsable"
                                    error={Boolean(formik.touched.idresponsable && formik.errors.idresponsable)}
                                    helperText={formik.touched.idresponsable && formik.errors.idresponsable}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={prioridad}
                            value={prioridad.find((option) => option.id === formik.values.prioridad) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('prioridad', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Prioridad"
                                    name="prioridad"
                                    error={Boolean(formik.touched.prioridad && formik.errors.prioridad)}
                                    helperText={formik.touched.prioridad && formik.errors.prioridad}
                                    variant="outlined"
                                />
                            )}
                        />

                        <TextField
                            name="data1"
                            type="hidden"
                            value={formik.values.data1}
                        />

                        <TextField
                            name="recurso"
                            type="hidden"
                            value={formik.values.recurso}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={suc}
                            value={suc.find((option) => option.id === formik.values.idsucursal) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('idsucursal', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Sucursal"
                                    name="idsucursal"
                                    error={Boolean(formik.touched.idsucursal && formik.errors.idsucursal)}
                                    helperText={formik.touched.idsucursal && formik.errors.idsucursal}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel>Alerta</InputLabel>
                        <Checkbox
                            name="alerta"
                            value={formik.values.alerta}
                            checked={formik.values.alerta}
                            onChange={formik.handleChange}
                            color="primary" // Opcional: para cambiar el color del checkbox
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel>Activa</InputLabel>
                        <Checkbox
                            name="activo"
                            value={formik.values.activo}
                            checked={formik.values.activo}
                            onChange={formik.handleChange}
                            color="primary" // Opcional: para cambiar el color del checkbox
                        />
                    </Grid>

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>

        </Paper>
    );
}