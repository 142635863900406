
import React, { useState, useEffect, useContext } from "react";
import clsx from 'clsx';
import { Switch, Route, Link } from "react-router-dom";

import { makeStyles, useTheme } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Drawer from '@mui/material/Drawer';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import CssBaseline from '@mui/material/CssBaseline';

import AuthService from "./services/auth.service";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Login from "./components/Usuarios/Login";
//import Register from "./components/Usuarios/Register";
import Home from "./components/Home/Home.component";

import Master from "./components/Master/Master.component";
import Products from "./components/Master/Products.component";
import Productsmod from "./components/Master/Productsmod.component";
import Users from "./components/Master/Users.component";
import Core from "./components/Administrador/Coreaddmod.component";

import Combustibles from "./components/Flota/Combustibles.component";
import Combustiblesadd from "./components/Flota/Combustiblesadd.component";
import Combustiblesmod from "./components/Flota/Combustiblesmod.component";
import Combustiblescon from "./components/Flota/Combustiblescon.component";

import Areas from "./components/Flota/Areas.component";
import Areasadd from "./components/Flota/Areasadd.component";
import Areasmod from "./components/Flota/Areasmod.component";

import Solicitudesfichadasaddmod from "./components/Horarios/SolicitudesFichadasaddmod.component";
import Solicitudfichadas from "./components/Horarios/SolicitudFichadas.component";
import Fichadas from "./components/Horarios/Fichadas.component";
import Fichar from "./components/Horarios/Fichar.component";
import ControlFichadas from "./components/Horarios/ControlFichadas.component";
import Empleados from "./components/Horarios/Empleados.component";
import Empleadosaddmod from "./components/Horarios/Empleadosaddmod.component";
import Horarios from "./components/Horarios/Horarios.component";
import Horariosaddmod from "./components/Horarios/Horariosaddmod.component";

import Solicitudesaddmod from "./components/Horarios/Solicitudesaddmod.component";

import InformeRecursos from "./components/Recursos/InformeRecursos.component";
import MisRecursos from "./components/Recursos/MisRecursos.component";
import RecursoCon from "./components/QR/RecursoCon.component";
import Recursos from "./components/Recursos/Recursos.component";
import AsignarRecursos from "./components/Recursos/AsignarRecursos.component";

import Herramientas from "./components/Recursos/Herramientas.component";
import Herramientasaddmod from "./components/Recursos/Herramientasaddmod.component";

import Indumentarias from "./components/Recursos/Indumentarias.component";
import Indumentariasaddmod from "./components/Recursos/Indumentariasaddmod.component";

import Informaticas from "./components/Recursos/Informaticas.component";
import Informaticasaddmod from "./components/Recursos/Informaticasaddmod.component";

import Celulares from "./components/Recursos/Celulares.component";
import Celularesaddmod from "./components/Recursos/Celularesaddmod.component";

import Epp from "./components/Recursos/Epp.component";
import Eppaddmod from "./components/Recursos/Eppaddmod.component";

import RecursosEmpleados from "./components/Recursos/RecursosEmpleados.component";
import Asignar from "./components/Asignar.component";

import Flota from "./components/Flota/Flota.component";
import Vehiculos from "./components/Flota/Vehiculos.component";
import Vehiculosadd from "./components/Flota/Vehiculosadd.component";
import Vehiculosmod from "./components/Flota/Vehiculosmod.component";

import Planera from "./components/Planera/Planera.component";
import Planos from "./components/Planera/Planos.component";
import PlaneraAddMod from "./components/Planera/PlaneraAddmod.component";
import PlaneraCon from "./components/Planera/PlaneraCon.component";

import Betc from "./components/Betc/Betc.component";
import BetcMod from "./components/Betc/Betcmod.component";

import Profile from "./components/Usuarios/Perfil";

import Administrador from "./components/Administrador/Administrador.component";
import Usuarios from "./components/Administrador/Usuarios.component";
import UsuariosAddMod from "./components/Administrador/Usuariosaddmod.component";
import GruposJerarquicos from "./components/Administrador/GruposJerarquicos.component";
import GruposJerarquicosAddMod from "./components/Administrador/GruposJerarquicosaddmod.component";
import Equipos from "./components/Administrador/Equipos.component";
import EquiposAddMod from "./components/Administrador/Equiposaddmod.component";

import Permisos from "./components/Administrador/Permisos";

import Bas from "./components/Bas/Bas.component";
import Facturacion from "./components/Bas/Facturacion.component";
import Refacturacion from "./components/Bas/Refacturacion.component";
import Stock from "./components/Bas/Stock.component";
import Rpendientes from "./components/Bas/Rpendientes.component";

import Rendicion from "./components/Bas/Rendicion.component";

import Fsinremitos from "./components/Bas/Fsinremitos.component";

import Qr from "./components/QR/Qr.component";
import Qrcon from "./components/QR/Qrcon.component";
import QRread from "./components/QR/QRread";

import Informes from "./components/Flota/Informes.component";

import Compras from "./components/Compras/Compras.component";
import ComprasInformes from "./components/Compras/ComprasInformes.component";
import Transacciones from "./components/Compras/Transacciones.component";

import Crm from "./components/Crm/Crm.component";
import Cuentas from "./components/Crm/Cuentas.component";
import CuentasAddmod from "./components/Crm/CuentasAddmod.component";

import MantenimientoVehiculos from "./components/Mantenimiento/MantenimientoVehiculos.component";
import Mantenimiento from "./components/Mantenimiento/Mantenimiento.component";
import Tareas from "./components/Mantenimiento/Tareas.component";
import Tareasaddmod from "./components/Mantenimiento/Tareasaddmod.component";
import Gantt from "./components/Mantenimiento/Gantt.component.tsx";
import GanttMaquinas from "./components/Mantenimiento/GanttMaquinas.component";
import Trello from "./components/Mantenimiento/Trello.component";

import Sucursales from "./components/Administrador/Sucursales.component";
import Sucursalesaddmod from "./components/Administrador/Sucursalesaddmod.component";

import Sectores from "./components/Administrador/Sectores.component";
import Sectoresaddmod from "./components/Administrador/Sectoresaddmod.component";

import Proyectos from "./components/Mantenimiento/Proyectos.component";
import Proyectosaddmod from "./components/Mantenimiento/Proyectosaddmod.component";

import Tickets from "./components/Tickets/Tickets.component";
import TicketsList from "./components/Tickets/TicketsList.component";
import Ticketsaddmod from "./components/Tickets/Ticketsaddmod.component";

import Maquinas from "./components/Mantenimiento/Maquinas.component";
import Maquinasaddmod from "./components/Mantenimiento/Maquinasaddmod.component";
import Maquinascon from "./components/Mantenimiento/Maquinasaddmod.component";

import Repuestos from "./components/Mantenimiento/Repuestos.component";
import Repuestosaddmod from "./components/Mantenimiento/Repuestosaddmod.component";

import Verdes from "./components/Planillas/Verdes.component";
import VerdesAddMod from "./components/Planillas/Verdesadd.component";

import { IntlProvider } from 'react-intl';
import { messages as allMessages } from './messages';
import { useHistory } from "react-router-dom";
import logo from './logoa.svg';
import fondo from './fondo.svg';
import './styles.css';

import { AuthContext } from './components/AuthContext';

import 'react-toastify/dist/ReactToastify.css';
import SolicitudesFichadasaddmod from "./components/Horarios/SolicitudesFichadasaddmod.component";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDhIHuxwerABnxQws313Fh0gy-WWyrIvuA",
  authDomain: "intranetapp-4bc3b.firebaseapp.com",
  projectId: "intranetapp-4bc3b",
  storageBucket: "intranetapp-4bc3b.appspot.com",
  messagingSenderId: "271144301026",
  appId: "1:271144301026:web:3149fac402f7c6864f89bb",
  measurementId: "G-4XJ12MTFPJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const useStyles = makeStyles((theme) => ({
  pie: {
  },
  root: {
    flexGrow: 1,
    // backgroundImage: "url(https://i.imgur.com/HeGEEbu.jpg)"
  },
  menuButton: {
    marginRight: '2px',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 900,
  },
  modal: {
    position: 'absolute',
    width: 400,
    border: '2px solid #000',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },

}));

const App = (props) => {

  const history = useHistory();
  const [userPhotoURL, setUserPhotoURL] = useState(null);
  const [userName, setUserName] = useState(null);

  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [url, setUrl] = useState("");
  const [modal2QR, setModal2QR] = useState(false);
  const styles = useStyles();

  const { currentUser, showUser, showOwner, showMaster } = useContext(AuthContext);

  const abrirCerrarLeerQR = () => {
    setModal2QR(!modal2QR);
  }

  const leerQR = (id) => {
    setUrl(id);
    abrirCerrarLeerQR()
  }

  const body2QR = (
    <div className={styles.modal}>
      <QRread url={url} />
    </div>
  )

  const logOut = () => {
    AuthService.logout();
  };

  // const currentLocale = 'en-US';
  const currentLocale = 'es';
  //const currentLocale = 'de-DE';
  const messages = allMessages[currentLocale];

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const opena = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePerfil = () => {
    history.push(process.env.PUBLIC_URL + '/perfil');
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    AuthService.logout();
    history.push(process.env.PUBLIC_URL + '/login');
    setAnchorEl(null);
  };

  // Redirigir si no hay usuario autenticado
  if (!AuthService.getCurrentUser()) {
    AuthService.logout();
    history.push(process.env.PUBLIC_URL + '/login');
  }

  useEffect(() => {
    const auth = getAuth();
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Usuario autenticado, actualiza el estado con la URL de la foto del perfil
        user.getIdToken().then((token) => {
          localStorage.setItem('accessToken', token);
        });
  
        // Llamamos a la función de comprobación del token directamente
        const checkTokenExpiration = () => {
          console.log("Chequeando Token")
          if (user && user.metadata && user.metadata.lastSignInTime) {
            const lastSignInTime = new Date(user.metadata.lastSignInTime);
  
            // Obtener la última hora de renovación del token desde el almacenamiento local
            const lastTokenRenewalTime = localStorage.getItem('lastTokenRenewalTime');
            
            // Calcular tokenExpirationTime basado en la última hora de renovación o el último inicio de sesión
            const tokenRenewalTime = lastTokenRenewalTime ? new Date(lastTokenRenewalTime) : lastSignInTime;
            const tokenExpirationTime = tokenRenewalTime.getTime() + 3600 * 1000; // 1 hora
  
            if (tokenExpirationTime <= Date.now()) {
              // Renuevo el token
              console.log("Renovando Token")
              user.getIdToken(true).then((token) => {
                localStorage.setItem('accessToken', token);
                
                // Almacenar la hora de renovación del token
                localStorage.setItem('lastTokenRenewalTime', new Date().toString());
              });
            }
          }
        };
  
        // Llamamos a la función cada 5 minutos
        const intervalId = setInterval(checkTokenExpiration, 300000);
  
        // Comprobamos el token cuando el componente se desmonta
        return () => {
          clearInterval(intervalId);
          unsubscribe();
        };
      } else {
        // No hay usuario autenticado, puedes manejar esto según tus necesidades
        setUserName(null);
        setUserPhotoURL(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('lastTokenRenewalTime'); // Limpiar la hora de renovación del token
      }
    });
  }, []); // El segundo argumento es un array vacío para ejecutar este efecto solo una vez al montar el componente
  

  return (

    <IntlProvider locale={currentLocale} messages={messages}>
      <header className={classes.root}>
        <CssBaseline />
        <AppBar style={{ background: '#fff' }} position="static"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              onClick={handleDrawerOpen}
            >
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <Link to={`${process.env.PUBLIC_URL}/`} className="nav-link">
                <div className={classes.logo}>
                  <img src={logo} alt="Intranet"></img>
                </div>
              </Link>
            </Typography>
            {currentUser ? (
              <div>
                <IconButton onClick={handleMenu}>
                  {userPhotoURL ? (
                    <Avatar alt="User Photo" src={userPhotoURL} />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={opena}
                  onClose={handleClose}
                >
                  <MenuItem>
                    {userName && <Typography variant="subtitle1">{userName}</Typography>}
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>Salir</MenuItem>
                </Menu>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                </li>
                <li className="nav-item">
                </li>
              </div>
            )}
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

        </Drawer>
      </header>
      <main>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Home} />
          <Route exact path={`${process.env.PUBLIC_URL}/informes`} component={Informes} />
          <Route exact path={`${process.env.PUBLIC_URL}/rendicion`} component={Rendicion} />
          <Route exact path={`${process.env.PUBLIC_URL}/combustibles`} component={Combustibles} />
          <Route exact path={`${process.env.PUBLIC_URL}/combustiblesadd`} component={Combustiblesadd} />
          <Route exact path={`${process.env.PUBLIC_URL}/combustiblesmod/:id`} component={Combustiblesmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/combustiblescon/:id`} component={Combustiblescon} />
          <Route exact path={`${process.env.PUBLIC_URL}/areas`} component={Areas} />
          <Route exact path={`${process.env.PUBLIC_URL}/areasadd`} component={Areasadd} />
          <Route exact path={`${process.env.PUBLIC_URL}/areasmod/:id`} component={Areasmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/vehiculos`} component={Vehiculos} />
          <Route exact path={`${process.env.PUBLIC_URL}/vehiculosadd`} component={Vehiculosadd} />
          <Route exact path={`${process.env.PUBLIC_URL}/vehiculosmod/:id`} component={Vehiculosmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/flota`} component={Flota} />
          <Route exact path={`${process.env.PUBLIC_URL}/informerecursos`} component={InformeRecursos} />

          <Route exact path={`${process.env.PUBLIC_URL}/fichar`} component={Fichar} />
          <Route exact path={`${process.env.PUBLIC_URL}/fichadas`} component={Fichadas} />
          <Route exact path={`${process.env.PUBLIC_URL}/solicitudfichadasaddmod`} component={SolicitudesFichadasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/solicitudfichadasaddmod/:id`} component={SolicitudesFichadasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/solicitudfichadasaddmod/:id/:tipo`} component={SolicitudesFichadasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/solicitudfichadas`} component={Solicitudfichadas} />
          <Route exact path={`${process.env.PUBLIC_URL}/controlfichadas`} component={ControlFichadas} />
          <Route exact path={`${process.env.PUBLIC_URL}/empleados`} component={Empleados} />
          <Route exact path={`${process.env.PUBLIC_URL}/empleadosaddmod`} component={Empleadosaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/empleadosaddmod/:id`} component={Empleadosaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/horarios`} component={Horarios} />
          <Route exact path={`${process.env.PUBLIC_URL}/horariosaddmod`} component={Horariosaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/horariosaddmod/:id`} component={Horariosaddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/solicitudesaddmod`} component={Solicitudesaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/solicitudesaddmod/:id`} component={Solicitudesaddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/recursos`} component={Recursos} />
          <Route exact path={`${process.env.PUBLIC_URL}/recursocon/:id`} component={RecursoCon} />
          <Route exact path={`${process.env.PUBLIC_URL}/misrecursos/:id`} component={MisRecursos} />
          <Route exact path={`${process.env.PUBLIC_URL}/asignarrecursos/:id`} component={AsignarRecursos} />

          <Route exact path={`${process.env.PUBLIC_URL}/compras`} component={Compras} />
          <Route exact path={`${process.env.PUBLIC_URL}/comprasinformes`} component={ComprasInformes} />
          <Route exact path={`${process.env.PUBLIC_URL}/transacciones`} component={Transacciones} />
          <Route exact path={`${process.env.PUBLIC_URL}/transacciones`} component={Transacciones} />

          <Route exact path={`${process.env.PUBLIC_URL}/crm`} component={Crm} />
          <Route exact path={`${process.env.PUBLIC_URL}/cuentas`} component={Cuentas} />
          <Route exact path={`${process.env.PUBLIC_URL}/cuentasaddmod`} component={CuentasAddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/cuentasaddmod/:id`} component={CuentasAddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/mantenimientovehiculos`} component={MantenimientoVehiculos} />
          <Route exact path={`${process.env.PUBLIC_URL}/mantenimiento`} component={Mantenimiento} />
          <Route exact path={`${process.env.PUBLIC_URL}/tareas`} component={Tareas} />
          <Route exact path={`${process.env.PUBLIC_URL}/tareasaddmod`} component={Tareasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/tareasaddmod/:id`} component={Tareasaddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/sucursales`} component={Sucursales} />
          <Route exact path={`${process.env.PUBLIC_URL}/sucursalesaddmod`} component={Sucursalesaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/sucursalesaddmod/:id`} component={Sucursalesaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/sucursalescon/:id`} component={Sucursalesaddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/sectores`} component={Sectores} />
          <Route exact path={`${process.env.PUBLIC_URL}/sectoresaddmod`} component={Sectoresaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/sectoresaddmod/:id`} component={Sectoresaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/sectorescon/:id`} component={Sectoresaddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/tickets`} component={Tickets} />
          <Route exact path={`${process.env.PUBLIC_URL}/ticketslist`} component={TicketsList} />
          <Route exact path={`${process.env.PUBLIC_URL}/ticketsaddmod`} component={Ticketsaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/ticketsaddmod/:id`} component={Ticketsaddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/maquinas`} component={Maquinas} />
          <Route exact path={`${process.env.PUBLIC_URL}/maquinasaddmod`} component={Maquinasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/maquinasaddmod/:id`} component={Maquinasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/maquinascon/:id`} component={Maquinasaddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/proyectos`} component={Proyectos} />
          <Route exact path={`${process.env.PUBLIC_URL}/proyectosaddmod`} component={Proyectosaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/proyectosaddmod/:id`} component={Proyectosaddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/repuestos`} component={Repuestos} />
          <Route exact path={`${process.env.PUBLIC_URL}/repuestosaddmod`} component={Repuestosaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/repuestosaddmod/:id`} component={Repuestosaddmod} />

          <Route exact path={`${process.env.PUBLIC_URL}/recursosempleados`} component={RecursosEmpleados} />
          <Route exact path={`${process.env.PUBLIC_URL}/asignar`} component={Asignar} />
          <Route exact path={`${process.env.PUBLIC_URL}/herramientas`} component={Herramientas} />
          <Route exact path={`${process.env.PUBLIC_URL}/herramientasaddmod`} component={Herramientasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/herramientasaddmod/:id`} component={Herramientasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/indumentarias`} component={Indumentarias} />
          <Route exact path={`${process.env.PUBLIC_URL}/indumentariasaddmod`} component={Indumentariasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/indumentariasaddmod/:id`} component={Indumentariasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/informaticas`} component={Informaticas} />
          <Route exact path={`${process.env.PUBLIC_URL}/informaticasaddmod`} component={Informaticasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/informaticasaddmod/:id`} component={Informaticasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/informaticacon/:id`} component={Informaticasaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/celulares`} component={Celulares} />
          <Route exact path={`${process.env.PUBLIC_URL}/celularesaddmod`} component={Celularesaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/celularesaddmod/:id`} component={Celularesaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/epps`} component={Epp} />
          <Route exact path={`${process.env.PUBLIC_URL}/eppaddmod`} component={Eppaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/eppaddmod/:id`} component={Eppaddmod} />
          <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
          {/* <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register} /> */}
          <Route exact path={`${process.env.PUBLIC_URL}/administrador`} component={Administrador} />
          <Route exact path={`${process.env.PUBLIC_URL}/usuarios`} component={Usuarios} />
          <Route exact path={`${process.env.PUBLIC_URL}/usuariosaddmod`} component={UsuariosAddMod} />
          <Route exact path={`${process.env.PUBLIC_URL}/usuariosaddmod/:id`} component={UsuariosAddMod} />
          <Route exact path={`${process.env.PUBLIC_URL}/gruposjerarquicos`} component={GruposJerarquicos} />
          <Route exact path={`${process.env.PUBLIC_URL}/gruposjerarquicosaddmo`} component={GruposJerarquicosAddMod} />
          <Route exact path={`${process.env.PUBLIC_URL}/gruposjerarquicosaddmo:id`} component={GruposJerarquicosAddMod} />
          <Route exact path={`${process.env.PUBLIC_URL}/equipos`} component={Equipos} />
          <Route exact path={`${process.env.PUBLIC_URL}/equiposaddmod`} component={EquiposAddMod} />
          <Route exact path={`${process.env.PUBLIC_URL}/equiposaddmod/:id`} component={EquiposAddMod} />
          <Route exact path={`${process.env.PUBLIC_URL}/permisos`} component={Permisos} />
          <Route exact path={`${process.env.PUBLIC_URL}/core`} component={Core} />

          <Route exact path={`${process.env.PUBLIC_URL}/ganttmaquinas/`} component={GanttMaquinas} />
          <Route exact path={`${process.env.PUBLIC_URL}/gantt/`} component={Gantt} />
          <Route exact path={`${process.env.PUBLIC_URL}/trello/`} component={Trello} />

          {showMaster && (
            <>
              <Route exact path={`${process.env.PUBLIC_URL}/master`} component={Master} />
              <Route exact path={`${process.env.PUBLIC_URL}/products`} component={Products} />
              <Route exact path={`${process.env.PUBLIC_URL}/productsmod`} component={Productsmod} />
              <Route exact path={`${process.env.PUBLIC_URL}/productsmod/:id`} component={Productsmod} />
              <Route exact path={`${process.env.PUBLIC_URL}/users`} component={Users} />
              <Route exact path={`${process.env.PUBLIC_URL}/usersaddmod`} component={Users} />
            </>
          )}

          {showUser && (
            <>
              <Route exact path={`${process.env.PUBLIC_URL}/bas`} component={Bas} />
              <Route exact path={`${process.env.PUBLIC_URL}/facturacion`} component={Facturacion} />
              <Route exact path={`${process.env.PUBLIC_URL}/planera`} component={Planera} />
              <Route exact path={`${process.env.PUBLIC_URL}/planos`} component={Planos} />
              <Route exact path={`${process.env.PUBLIC_URL}/planeraaddmod`} component={PlaneraAddMod} />
              <Route exact path={`${process.env.PUBLIC_URL}/planeracon/:id`} component={PlaneraCon} />
              <Route exact path={`${process.env.PUBLIC_URL}/planeraaddmod/:id`} component={PlaneraAddMod} />
              <Route exact path={`${process.env.PUBLIC_URL}/planeraaddmod/:id/:ids`} component={PlaneraAddMod} />
              <Route exact path={`${process.env.PUBLIC_URL}/betc`} component={Betc} />
              <Route exact path={`${process.env.PUBLIC_URL}/betcmod/:id`} component={BetcMod} />
              <Route exact path={`${process.env.PUBLIC_URL}/refacturacion`} component={Refacturacion} />
              <Route exact path={`${process.env.PUBLIC_URL}/fsinremitos`} component={Fsinremitos} />
              <Route exact path={`${process.env.PUBLIC_URL}/rpendientes`} component={Rpendientes} />
              <Route exact path={`${process.env.PUBLIC_URL}/stock`} component={Stock} />
              <Route exact path={`${process.env.PUBLIC_URL}/qr`} component={Qr} />
              <Route exact path={`${process.env.PUBLIC_URL}/qrcon/:id`} component={Qrcon} />

              <Route exact path={`${process.env.PUBLIC_URL}/verdes`} component={Verdes} />
              <Route exact path={`${process.env.PUBLIC_URL}/verdesaddmod`} component={VerdesAddMod} />
              <Route exact path={`${process.env.PUBLIC_URL}/verdesaddmod/:id`} component={VerdesAddMod} />
            </>
          )}

          <Route path={`${process.env.PUBLIC_URL}/home`} component={Home} />
        </Switch>
      </main>

      <footer>

        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >

          <BottomNavigationAction label="V 2.1" icon={<ChevronLeftIcon />} />

          <BottomNavigationAction label="Leer QR" onClick={() => leerQR()} icon={<ChevronLeftIcon />} />
          <BottomNavigationAction label="." icon={<ChevronLeftIcon />} />

        </BottomNavigation>

      </footer>

      <Modal
        open={modal2QR}
        onClose={abrirCerrarLeerQR}>
        {body2QR}
      </Modal>

    </IntlProvider>

  );
};

export default App;
