import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import Input from '@mui/material/Input';
import { useParams } from 'react-router';

import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import AuthService from "../../services/auth.service.js";
import UserService from "../../services/user.service.js";
import { useStyles, theme, direction } from '../styles.js'; // Importa los estilos desde el archivo styles.js

import { v4 as uuidv4 } from 'uuid';

//Validacion del formulario
const validationSchema = yup.object({
    nombre: yup
        .string('Nombre del equipo requerido')
        .required('Nombre del equipo requerido'),
    responsable: yup
        .string('Nombre del responsable requerido')
        .required('Nombre del responsable requerido'),
    empleados: yup
        .array().of(yup.string('Nombre los empleados requerido'))
        .required('Nombre los empleados requerido'),
});

export default function GruposJerarquicosaddmod() {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false)
    const [empleados, setEmpleados] = useState([])

    const [seleccionados, setSeleccionados] = useState([]);

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            nombre: '',
            responsable: '',
            empleados: [],
            id: uuidv4(),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const [currentUser, setCurrentUser] = useState(undefined);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [personName, setPersonName] = React.useState([]);
    const [names, setNames] = React.useState([]);
    const [showAlert, setShowAlert] = useState(false);

    const handleChange = (event) => {
        console.log(event.target.value)
        //setPersonName(event.target.value);
        //formik.values.empleados = personName;
        formik.values.empleados = event.target.value;
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [sino, setSino] = useState([
        { id: 'true', nombre: 'Si' },
        { id: 'false', nombre: 'No' },
    ]);

    const peticionPost = async (values) => {
        const res = await UserService.addModEquipo(id, values);

        if (res.error) {
            setShowAlert(true)
        } else {
            setShowAlert(false)
            cerrarEditar()
        }

    }

    const cerrarEditar = () => {
        history.push(process.env.PUBLIC_URL + "/equipos");
    }

    const inicio = () => {
        history.push(process.env.PUBLIC_URL + "/equipos")
    }

    const handleChange1 = e => {
        const { name, value } = e.target;
        console.log(name)
        if (name == 'responsable') {
            formik.values.responsable = value;
            getEmpleados(value);
        }
    }

    const getEmpleados = async (id) => {
        const response = await UserService.getEmpleadosEquipos(id);
        setSeleccionados(response);
    }

    useEffect(() => {

        const GetData = async () => {
            if (id) {
                try {
                    setIsLoading(true);
                    const response = await UserService.getEquipoid(id);
                    if (response) {
                        formik.setValues(response);
                        getEmpleados(response.responsable);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                } catch (e) {
                    setIsLoading(false);
                    //history.push(process.env.PUBLIC_URL + "/login");
                }
            }
        }
        GetData();

        const GetEmpleados = async () => {
            try {
                setIsLoading(true);
                const response = await UserService.getEmpleados(id);
                if (response) {
                    setEmpleados(response);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetEmpleados();

    }, []);

    return (

        <Paper>

            <AppBar style={{ background: '#fff159', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#000' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#000' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Equipo
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container fixed>
                <Box sx={{ bgcolor: '#cfe8fc', marginTop: '40px', marginBottom: '40px' }}  >

                    <form onSubmit={formik.handleSubmit}>

                        <Grid container spacing={1} style={{ minHeight: '100vh', padding: '5px' }} >

                            <Grid item xs={12}>
                                <TextField
                                    name="nombre"
                                    className={styles.inputMaterial}
                                    label="Nomre del equipo"
                                    autoFocus={true}
                                    value={formik.values.nombre}
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                    helperText={formik.touched.nombre && formik.errors.nombre}
                                />
                            </Grid>

                            {showAlert && (
                                <Grid item xs={12}>
                                    <Alert severity="error">El nombre del equipo ya existe, ingrese otro</Alert>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <InputLabel htmlFor="outlined-age-native-simple">Responsable</InputLabel>
                                <Select
                                    native
                                    label="Responsable"
                                    value={formik.values.responsable}
                                    //onChange={formik.handleChange}
                                    onChange={handleChange1}
                                    error={formik.touched.responsable && Boolean(formik.errors.responsable)}
                                    helperText={formik.touched.responsable && formik.errors.responsable}
                                    inputProps={{
                                        name: 'responsable',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {empleados.map((value) => (
                                        <option value={value.id} key={value.id}>
                                            {value.nombre} {value.apellido}
                                        </option>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-mutiple-name-label">Name</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="empleados"
                                        name="empleados"
                                        multiple
                                        value={formik.values.empleados}
                                        //onChange={handleChange}
                                        onChange={formik.handleChange}
                                        input={<Input />}
                                        MenuProps={MenuProps}
                                        error={formik.touched.empleados && Boolean(formik.errors.empleados)}
                                        helperText={formik.touched.empleados && formik.errors.empleados}
                                    >
                                        {seleccionados.map((name) => (
                                            <MenuItem key={name.key} value={name.key}>
                                                {name.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <div align="center" style={{ background: '#fff159' }} >
                                    <Button color="primary" type="submit">Guardar</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </Paper>
    );
}

