/**
 * PWA Horario
 * update 01/2023
 * By Sergio Sam 
 */

import React from 'react';
import { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme, useStyles } from "../styles.js"
import CircularProgress from '@mui/material/CircularProgress';

import { useParams } from 'react-router';

import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";

import { v4 as uuidv4 } from 'uuid';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';

import { addDays } from 'date-fns';

//Validacion del formulario
const validationSchema = yup.object({
    novedad: yup
        .string('Novedad requerido')
        .required('Novedad requerido'),
    startTime: yup.date().default(function() {
            return new Date();
    }),       
});

export default function Solicitudesaddmod() {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false)

    const [novedades, setNovedades] = useState([]);

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            novedad: '',
            horas: '',
            nota: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values, state);
        },
    });

    const [currentUser, setCurrentUser] = useState(undefined);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    const { id } = useParams();
    const { idempleado } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [sino, setSino] = useState([
        { id: 'true', nombre: 'Si' },
        { id: 'false', nombre: 'No' },
    ]);

    const peticionPost = async (values, state) => {
        await UserService.addmodSolicitudes(id, values, state[0]);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        history.push(process.env.PUBLIC_URL + "/solicitudes");
    }

    const inicio = () => {
        history.push(process.env.PUBLIC_URL + "/")
    }

    useEffect(() => {
        // si no hay user hay que loguearse 
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
            setShowClienteBoard(user.roles.includes("ROLE_USER"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
        } else {
            history.push(process.env.PUBLIC_URL + "/login");
        }

        const GetData = async () => {
            if (id) {
                try {
                    setIsLoading(true);
                    const response = await UserService.getSolicitudid(id);
                    if (response) {
                        formik.setValues(response);

                        //setState.startDate(response.start);
                        //setState.endDate(response.end);

                        setState([
                            {
                                startDate: new Date(response.start),
                                endDate: new Date(response.end),
                                key: 'selection'
                            }
                        ]);

                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        //  history.push(process.env.PUBLIC_URL + "/login");
                    }
                } catch (e) {
                    console.log(e)
                    //history.push(process.env.PUBLIC_URL + "/login");
                }
            }
        }
        GetData();

        const GetNovedades = async () => {
            try {
                const result = await UserService.getNovedades();
                if (result) {
                    console.log(result)
                    setNovedades(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    //history.push(process.env.PUBLIC_URL +"/login");
                }
            } catch (e) {
                console.log(e)
                //history.push(process.env.PUBLIC_URL +"/login");
            }
        }
        GetNovedades();


    }, []);

    return (

        <Paper>

            <AppBar style={{ background: '#fff159', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#000' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#000' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Solicitud
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container fixed>
                <Box sx={{ bgcolor: '#cfe8fc', marginTop: '40px', marginBottom: '40px' }}  >

                    <form onSubmit={formik.handleSubmit}>

                        <Grid container spacing={1} style={{ minHeight: '100vh', padding: '5px' }} >

                            <Grid item xs={6}>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    name="novedad"
                                    label="Novedad"
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={formik.values.novedad}
                                    helperText={formik.touched.novedad && formik.errors.novedad}
                                    onChange={formik.handleChange}
                                    error={formik.touched.novedad && Boolean(formik.errors.novedad)}
                                >
                                    <option aria-label="None" value="" />
                                    {novedades.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.nombre}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    name="horas"
                                    className={styles.inputMaterial}
                                    label="Horas"
                                    value={formik.values.horas}
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    error={formik.touched.horas && Boolean(formik.errors.horas)}
                                    helperText={formik.touched.horas && formik.errors.horas}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DateRange
                                    editableDateInputs={false}
                                    onChange={item => setState([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={state}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextareaAutosize
                                    name="nota"
                                    aria-label="empty textarea"
                                    className={styles.inputMaterial}
                                    minRows={3} label="Nota"
                                    placeholder="Nota"
                                    value={formik.values.nota}
                                    onChange={formik.handleChange}
                                    error={formik.touched.nota && Boolean(formik.errors.nota)}
                                    helperText={formik.touched.nota && formik.errors.nota}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div align="center" style={{ background: '#fff159' }} >
                                    <Button color="primary" type="submit">Guardar</Button>
                                </div>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </Container>
        </Paper>
    );
}

