import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from 'react-router-dom';

import UploadService from "./services/planera.service";
import UserService from "./services/planera.service";
import UserServiceTicket from "../Tickets/services/tickets.service";

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles, useTheme } from '@mui/styles';
import Modal from '@mui/material/Modal';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import { AuthContext } from '../../components/AuthContext';

import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 400,
        //maxWidth: 400,
        backgroundColor: '#fff',
    },
}));

const AprobarFiles = ({ id, ids, documento }) => {
    const history = useHistory();

    const classes = useStyles();
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const [message, setMessage] = useState([]);
    const [fileInfos, setFileInfos] = useState([]);
    const progressInfosRef = useRef(null)
    const [data, setData] = useState([]);
    const [dato, setDato] = useState([]);
    const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);

    const [openModal, setOpenModal] = useState(false);
    const [newFileName, setNewFileName] = useState('');
    const [selectedFileData, setSelectedFileData] = useState(null); // Store selected file data

    useEffect(() => {

        UploadService.getFilesPlanera(ids, documento)
            .then((response) => {
                // Verificar si la respuesta contiene datos
                if (response.data && response.data.length > 0) {
                    setFileInfos(response.data);
                } else {
                    // Manejar el caso en el que no se obtienen datos
                    console.log('No se encontraron datos');
                }
            })
            .catch((error) => {
                // Manejar errores si ocurren
                console.error('Error al obtener los datos:', error);
            });
    }, []);


    useEffect(() => {

        const GetData = async () => {

            if (id) {
                try {
                    const response = await UserServiceTicket.getTicket(ids);
                    if (response) {
                        setDato(response);
                    }
                } catch (e) {
                    console.log(e)
                }
            }

        }
        GetData();

    }, []);

    const eliminar = async (key, documento, id) => {
        const response = await UserService.delImagePlanera(id, documento, key);
        setData(data.concat(response.data))

        UploadService.getFilesPlanera(id, documento).then((response) => {
            setFileInfos(response.data);
        })
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleInputChange = (event) => {
        setSelectedFileData(event.target.value);
    };

    const handleAprobarClick = (file) => {
        console.log(file)
        setSelectedFileData(file.real);
        handleOpenModal();
    };

    const handleAprobar2Click = async (file, data) => {
        const response = await UserService.aprobarPlano(file, data, id, ids);

        UploadService.getFilesPlanera(id, documento).then((response) => {
            setFileInfos(response.data);
        })

        handleCloseModal();
        //history.push(process.env.PUBLIC_URL + "/planeraaddmod/"+id+"/"+ids)
        window.location.reload();

    };

    return (
        <Paper>
            {progressInfos && progressInfos.val.length > 0 &&
                progressInfos.val.map((progressInfo, index) => (

                    <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" key={index} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="body2" color="textSecondary">{`${Math.round(progressInfo.percentage,)}%`}</Typography>
                        </Box>
                    </Box>
                ))}

            {message.length > 0 && (
                <div className="alert alert-secondary" role="alert">
                    <ul>
                        {message.map((item, i) => {
                            return <li key={i}>{item}</li>;
                        })}
                    </ul>
                </div>
            )}

            <div className={classes.root}>
                <h5> ARCHIVOS ENVIADOS PARA APROBAR </h5>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr style={{ borderBottom: '1px solid #ddd' }}>
                            <th style={cellStyle}>Archivo</th>
                            <th style={cellStyle}>Eliminar</th>
                            <th style={cellStyle}>Aprobar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fileInfos &&
                            fileInfos.map((file, index) => (

                                <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                                    <td style={cellStyle}>
                                        <Link href={file.url}>
                                            {file.real
                                                ? <ListItemText primary={file.real} />
                                                : <ListItemText primary={file.name} />
                                            }
                                        </Link>
                                    </td>

                                    <td style={cellStyle}>
                                        <IconButton edge="end" aria-label="delete">
                                            <Link onClick={() => eliminar(file.name, documento, ids)}>
                                                {showUser && (
                                                    <DeleteIcon />
                                                )}
                                            </Link>
                                        </IconButton>
                                    </td>

                                    <td style={cellStyle}>
                                        {/* Nuevo botón "Aprobar" */}
                                        {showUser && (
                                            <IconButton edge="end" aria-label="aprobar">
                                                <Button onClick={() => handleAprobarClick(file)} color="primary" variant="contained" style={{ marginTop: '16px' }}>Aprobar</Button>
                                            </IconButton>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>

            </div>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        width: 400,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="div" gutterBottom>
                        Datos del Ticket
                    </Typography>

                    <TextField
                        label="Plano"
                        variant="outlined"
                        value={dato.plano}
                        fullWidth
                        sx={{ mb: 2 }} // Agrega un espacio de 2 unidades (puedes ajustarlo según tus necesidades)
                    />

                    <TextField
                        label="Fecha"
                        variant="outlined"
                        value={dato.fecha}
                        fullWidth
                        sx={{ mb: 2 }} // Agrega un espacio de 2 unidades (puedes ajustarlo según tus necesidades)
                    />

                    <TextField
                        label="Motivo"
                        variant="outlined"
                        value={dato.motivo}
                        fullWidth
                        sx={{ mb: 2 }} // Agrega un espacio de 2 unidades (puedes ajustarlo según tus necesidades)
                    />

                    <TextField
                        label="Descripcion"
                        variant="outlined"
                        value={dato.descripcion}
                        fullWidth
                        sx={{ mb: 2 }} // Agrega un espacio de 2 unidades (puedes ajustarlo según tus necesidades)
                    />

                    <Typography variant="h6" component="div" gutterBottom>
                        Cambiar nombre del archivo
                    </Typography>
                    <TextField
                        label="Nuevo nombre"
                        variant="outlined"
                        value={selectedFileData}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ mb: 2 }} // Agrega un espacio de 2 unidades (puedes ajustarlo según tus necesidades)
                    />
                    <Button onClick={() => handleAprobar2Click(selectedFileData, fileInfos)} color="primary" variant="contained" style={{ marginTop: '16px' }}>
                        Aprobar
                    </Button>
                    <Button onClick={() => handleCloseModal()} color="primary" variant="contained" style={{ marginLeft: '16px', marginTop: '16px' }}>
                        Cancelar
                    </Button>
                </Box>
            </Modal>
        </Paper >
    );
};

const cellStyle = {
    padding: '12px',
    textAlign: 'left',
};

export default AprobarFiles;
