import React from 'react';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

import { LinearProgress, AppBar, CircularProgress, IconButton, Toolbar, Breadcrumbs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import UserServiceCore from "../../services/user.service";
import UserService from "./services/tickets.service";
import UserServicePlanera from "../Planera/services/planera.service";

import FilesUploadPlanera from "../Planera/FilesUploadPlanera";

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    tipodeticket: yup
        .string('Tipo de ticket requerido')
        .required('Tipo de ticket requerido'),
    descripcion: yup
        .string('Descripcion requerida')
        .required('Descripcion requerida'),
});

export default function Ticketsaddmod(props) {
    const [isLoading, setIsLoading] = useState(false)

    //inicializacion de variables y validacion
    var d = new Date();
    var finaldate = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + (d.getDate()).toString().padStart(2, "0")

    const today = new Date().toISOString().substr(0, 10);

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            tipodeticket: '',
            status: '',
            value: '',
            idsucursal: '',
            idsector: '',
            idplano: '',
            motivo: '',
            progress: '',
            descripcion: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [suc, setSuc] = useState([]);
    const [sec, setSec] = useState([]);
    const [planos, setPlanos] = useState([]);

    const getProgressColor = (progress) => {
        if (progress <= 33) {
            return 'red'; // Rojo para el progreso menor o igual al 33%
        } else if (progress <= 66) {
            return 'yellow'; // Amarillo para el progreso menor o igual al 66%
        } else {
            return 'green'; // Verde para el progreso mayor al 66%
        }
    };

    const [tipos, setTipos] = useState([
        { id: 'Mantenimiento', value: 'Mantenimiento', label: 'Mantenimiento' },
        { id: 'Recurso', value: 'Recurso', label: 'Recurso' },
        { id: 'Planera', value: 'Planera', label: 'Planera' },
    ]);

    const [prioridad, setPrioridad] = useState([
        { id: 'Alta', value: 'Alta', label: 'Alta' },
        { id: 'Media', value: 'Media', label: 'Media' },
        { id: 'Baja', value: 'Baja', label: 'Baja' },
    ]);

    const [mot, setMot] = useState([
        { id: 'Corregir Medidas', value: 'Corregir Medidas', label: 'Corregir Medidas' },
        { id: 'Alterar Dibujo', value: 'Alterar Dibujo', label: 'Alterar Dibujo' },
        { id: 'Agregar Cotas', value: 'Agregar Cotas', label: 'Agregar Cotas' },
        { id: 'Otros', value: 'Otros', label: 'Otros' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodTicket(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/tickets");
    }

    useEffect(() => {

        const GetData = async () => {

            if (id) {
                try {
                    setIsLoading(true);
                    const response = await UserService.getTicket(id);
                    if (response) {
                        formik.setValues(response);
                    } else {
                        handleShowMessage('500');
                    }
                    setIsLoading(false);
                } catch (e) {
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            }

        }
        GetData();

    }, []);

    useEffect(() => {
        const GetSucursales = async () => {
            try {
                setIsLoading(true);
                const result = await UserServiceCore.getSucursales();
                if (result) {
                    setSuc(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e)
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetSucursales();
    }, []);

    useEffect(() => {
        const GetSectores = async () => {
            try {
                setIsLoading(true);
                const result = await UserServiceCore.getSectores();
                if (result) {
                    setSec(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e)
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetSectores();
    }, []);

    useEffect(() => {
        const GetPlanos = async () => {
            try {
                setIsLoading(true);
                const result = await UserServicePlanera.getPlanos();
                if (result) {
                    setPlanos(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e)
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetPlanos();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/tickets")
    }

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Ticket
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Typography variant="body1">Progreso</Typography>
                        <LinearProgress
                            variant="determinate"
                            value={formik.values.progress} // Asegúrate de que `formik.values.progress` esté en el rango de 0 a 100
                            sx={{
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: (theme) => getProgressColor(formik.values.progress),
                                    height: '100%', // Grosor de la línea (ajusta el valor según tus preferencias)

                                },
                            }}
                        />
                        <Typography variant="body2" color="textSecondary">{formik.values.progress}%</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={tipos}
                            value={tipos.find((option) => option.id === formik.values.tipodeticket) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('tipodeticket', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Tipo de ticket"
                                    name="tipodeticket"
                                    error={Boolean(formik.touched.tipodeticket && formik.errors.tipodeticket)}
                                    helperText={formik.touched.tipodeticket && formik.errors.tipodeticket}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    {formik.values.tipodeticket === 'Mantenimiento' && (
                        <Grid item xs={4}>
                            <Autocomplete
                                className={styles.inputMaterial}
                                options={suc}
                                value={suc.find((option) => option.id === formik.values.idsucursal) || null}
                                getOptionLabel={(option) => `${option.label}`}
                                onChange={(event, selectedOption) => {
                                    formik.setFieldValue('idsucursal', selectedOption ? selectedOption.id : '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={styles.inputMaterial}
                                        label="Sucursal"
                                        name="idsucursal"
                                        error={Boolean(formik.touched.idsucursal && formik.errors.idsucursal)}
                                        helperText={formik.touched.idsucursal && formik.errors.idsucursal}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                    )}

                    {formik.values.tipodeticket === 'Mantenimiento' && (
                        <Grid item xs={4}>
                            <Autocomplete
                                className={styles.inputMaterial}
                                options={sec}
                                value={sec.find((option) => option.id === formik.values.idsector) || null}
                                getOptionLabel={(option) => `${option.label}`}
                                onChange={(event, selectedOption) => {
                                    formik.setFieldValue('idsector', selectedOption ? selectedOption.id : '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={styles.inputMaterial}
                                        label="Sector"
                                        name="idsector"
                                        error={Boolean(formik.touched.idsector && formik.errors.idsector)}
                                        helperText={formik.touched.idsector && formik.errors.idsector}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                    )}

                    {/* Campos de planera */}
                    {formik.values.tipodeticket === 'Planera' && (
                        <>
                            <Grid item xs={4}>
                                <Autocomplete
                                    className={styles.inputMaterial}
                                    options={planos}
                                    value={planos.find((option) => option.id === formik.values.idplano) || null}
                                    getOptionLabel={(option) => `${option.label}`}
                                    onChange={(event, selectedOption) => {
                                        formik.setFieldValue('idplano', selectedOption ? selectedOption.id : '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={styles.inputMaterial}
                                            label="Plano"
                                            name="idplano"
                                            error={Boolean(formik.touched.idplano && formik.errors.idplano)}
                                            helperText={formik.touched.idplano && formik.errors.idplano}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Autocomplete
                                    className={styles.inputMaterial}
                                    options={mot}
                                    value={mot.find((option) => option.id === formik.values.motivo) || null}
                                    getOptionLabel={(option) => `${option.label}`}
                                    onChange={(event, selectedOption) => {
                                        formik.setFieldValue('motivo', selectedOption ? selectedOption.id : '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={styles.inputMaterial}
                                            label="Motivo"
                                            name="motivo"
                                            error={Boolean(formik.touched.motivo && formik.errors.motivo)}
                                            helperText={formik.touched.motivo && formik.errors.motivo}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    )}

                    {/* Descripcion comun a todos  */}
                    <Grid item xs={12}>
                        <TextField
                            maxLines={false} // Esto permitirá múltiples líneas
                            name="descripcion"
                            className={styles.inputMaterial}
                            label="Descripcion"
                            autoComplete='off'
                            value={formik.values.descripcion}
                            onChange={formik.handleChange}
                            error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                            helperText={formik.touched.descripcion && formik.errors.descripcion}
                        />

                    </Grid>

                    <Grid>
                        <TextField
                            name="data1"
                            type="hidden"
                            value={formik.values.data1}
                        />

                        <TextField
                            name="recurso"
                            type="hidden"
                            value={formik.values.recurso}
                        />
                    </Grid>
                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>

            </form>

            {/* Campos de planera */}
            {formik.values.tipodeticket === 'Planera' && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FilesUploadPlanera id={id} documento={"Tickets"} />
                    </Grid>
                </Grid>
            )}

        </Paper>
    );
}