import React, { useState, useEffect } from 'react';
import { Task, ViewMode, Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css"
import { getStartEndDateForProject, initTasks } from "./helper";

import { ViewSwitcher } from "./view-switcher";

import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import UserService from "./services/mantenimiento.service";
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

const Gantt_component = (props: { history: string[]; }) => {
  //console.log(new Date(2023, 8, 1))
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([
    {
      start: new Date(2023, 8, 1),
      end: new Date(2023, 8, 1),
      name: 'Idea',
      id: 'Task 0',
      type: 'task',
      progress: '50',
      isDisabled: true,
      styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    }
  ]);

  const [selected, setSelected] = useState<string>('Maquinas');  // Cambiado a string
  const [selectedUser, setSelectedUser] = useState<string>('Todos');  // Cambiado a string

  const [view, setView] = React.useState<ViewMode>(ViewMode.Day);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);

  interface UserData {
    idusuario: string;
    nombre: string;
    id: string;
    value: string;
    label: string;
  }

  const [res, setRes] = useState<UserData[]>([]);  // Especificar el tipo de datos

  let columnWidth = 65;

  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    const GetData = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getGanttMaquinas("Maquinas","Todos");
        
        if (result && result.length > 0) {
          const data = result.map((task: { start: string | number | Date; end: string | number | Date; }) => {
            task.start = new Date(task.start);
            task.end = new Date(task.end);
            return task;
          });
          setTasks(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          handleShowMessage('500');
        }
      } catch (e) {
        setIsLoading(false);
        handleShowMessage('500');
      }
    }
    GetData();
    // Get tasks from an API or database
  }, []);


  useEffect(() => {

    const GetResponsables = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getEmpleadosTarea();
        if (result) {
          setRes(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          handleShowMessage('500');
        }
      } catch (e) {
        setIsLoading(false);
        handleShowMessage('500');
      }
    }
    GetResponsables();

  }, []);

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/mantenimiento")
  }

  const handleTaskChange = (task: Task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map(t => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map(t =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter(t => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task: Task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleClick = (task: Task) => {
    console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message: React.SetStateAction<string>) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  const handleChangeMaquina = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      setIsLoading(true);

      setSelected(event.target.value);

      // Llamada a la función getGanttMaquinas con el parámetro seleccionado

      console.log(selected);
      console.log(selectedUser);

      const result = await UserService.getGanttMaquinas(event.target.value, selectedUser);
      
      if (result && result.length > 0) {
        const data = result.map((task: { start: string | number | Date; end: string | number | Date; }) => {
          task.start = new Date(task.start);
          task.end = new Date(task.end);
          return task;
        });
        setTasks(result);
        setIsLoading(false);

     } else {
        setIsLoading(false);
        handleShowMessage('500');
      }
    } catch (e) {
      setIsLoading(false);
      handleShowMessage('500');
    }
  };

  const handleChangeUser = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      setIsLoading(true);

      setSelectedUser(event.target.value);

      // Llamada a la función getGanttMaquinas con el parámetro seleccionado

      console.log(selected);
      console.log(selectedUser);

      const result = await UserService.getGanttMaquinas(selected, event.target.value);
      
      if (result && result.length > 0) {
        const data = result.map((task: { start: string | number | Date; end: string | number | Date; }) => {
          task.start = new Date(task.start);
          task.end = new Date(task.end);
          return task;
        });
        setTasks(result);
        setIsLoading(false);

     } else {
        setIsLoading(false);
        handleShowMessage('500');
      }
    } catch (e) {
      setIsLoading(false);
      handleShowMessage('500');
    }
  };

  return (
    <>
      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Grafico de Gantt por Elemento
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <div className="Wrapper">

        {/* Menú desplegable para seleccionar "Por Maquinas" o "Por Vehiculos" */}
        <select onChange={handleChangeMaquina}>
          <option value="Maquinas">Por Maquinas</option>
          <option value="Vehiculos">Por Vehiculos</option>
        </select>

        {/* Menú desplegable llenado con las opciones de 'res' */}
        <select onChange={handleChangeUser}>
        <option value="Todos">Todos</option>
          {res.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <ViewSwitcher
          onViewModeChange={viewMode => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
        />

        <h3>Grafico de Gantt por Elemento</h3>
        <Gantt
          tasks={tasks}
          viewMode={view}
          onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          onDoubleClick={handleDblClick}
          onClick={handleClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "155px" : ""}
          columnWidth={columnWidth}
          locale='es'
        />
        <SnackbarMessage
          open={open}
          message={errorMessage}
          severity="success"
          onClose={handleCloseMessage}
        />

      </div>
    </>
  );
};

export default Gantt_component;
