import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    marca: yup
        .string('Marca requerido')
        .required('Marca requerido'),
    idarea: yup
        .string('Seleccione el area')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Seleccione el area'),
    modelo: yup
        .string('Modelo requreido ')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Modelo requerido'),
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function Vehiculosmod(props) {
    const [isLoading, setIsLoading] = useState(false)
    //var hoy=selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate();

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            marca: '',
            modelo: '',
            patente: '',
            kilometraje: '',
            combustible_tipo: '',
            tipo: '',
            mantenimiento: false,
            fecha_compra: '',
            idarea: '',
            nota: '',
            data1: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //alert(JSON.stringify(values, null, 2));
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    //const [selectedDate] = useState(new Date());

    const [combustible, setCombustible] = useState([
        { id: 'Nafta', nombre: 'Nafta' },
        { id: 'Gas Oil', nombre: 'Gas Oil' },
        { id: 'Gnc', nombre: 'Gnc' },
    ]);

    const [tipos, setTipos] = useState([
        { id: 'Particular', nombre: 'Particular' },
        { id: 'Alquiler', nombre: 'Alquiler' },
    ]);

    const handleChange = e => {
        const { name, value } = e.target;
        if (name == 'empresa') {
            formik.values.empresa = value;
            getYacimiento(value);
        }
    }

    const getYacimiento = async (id) => {
        const response = await UserService.getYacimientosid(id);
        setDatayacimientos(response.data);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const peticionPost = async (values) => {
        const response = await UserService.addmodVehiculo(id, values, right);
        ///setData(data.concat(response.data))
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/vehiculos");
    }

    useEffect(() => {

        const GetData = async () => {
            try {
                const response = await UserService.getVehiculo(id);

                if (response) {
                    var dataNueva = response.data;
                    dataNueva.map(consola => {
                        formik.initialValues.marca = consola.marca;
                        formik.initialValues.idarea = consola.idarea;
                        formik.initialValues.modelo = consola.modelo;
                        formik.initialValues.kilometraje = consola.kilometraje;
                        formik.initialValues.patente = consola.patente;
                        formik.initialValues.localizacion = consola.localizacion;
                        formik.initialValues.nota = consola.nota;
                        formik.initialValues.combustible_tipo = consola.combustible_tipo;
                        formik.initialValues.tipo = consola.tipo;
                        formik.initialValues.mantenimiento = consola.mantenimiento;
                        formik.initialValues.fecha_compra = consola.fecha_compra;
                        setDatos(consola.areas);
                    })
                } else {
                    props.history.push(process.env.PUBLIC_URL + "/login");
                }
            } catch (e) {
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();
    }, []);

    const customList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value.id}-label`;

                    return (
                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.nombre}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    const inicio = () => {
        //props.history.push(process.env.PUBLIC_URL + "/vehiculos")
        props.history.goBack();
    } 

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Editar Vehiculo
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <TextField
                            name="marca"
                            className={styles.inputMaterial}
                            label="Marca"
                            autoFocus={true}
                            required
                            value={formik.values.marca}
                            onChange={formik.handleChange}
                            error={formik.touched.marca && Boolean(formik.errors.marca)}
                            helperText={formik.touched.marca && formik.errors.marca}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="modelo"
                            className={styles.inputMaterial}
                            label="Modelo"
                            autoFocus={true}
                            required
                            value={formik.values.modelo}
                            onChange={formik.handleChange}
                            error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                            helperText={formik.touched.modelo && formik.errors.modelo}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="kilometraje"
                            className={styles.inputMaterial}
                            label="Kilometraje"
                            value={formik.values.kilometraje}
                            onChange={formik.handleChange}
                            error={formik.touched.kilometraje && Boolean(formik.errors.kilometraje)}
                            helperText={formik.touched.kilometraje && formik.errors.kilometraje}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="patente"
                            className={styles.inputMaterial}
                            label="Patente"
                            value={formik.values.patente}
                            onChange={formik.handleChange}
                            error={formik.touched.patente && Boolean(formik.errors.patente)}
                            helperText={formik.touched.patente && formik.errors.patente}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Area</InputLabel>
                        <Select
                            native
                            required
                            label="Area"
                            inputProps={{
                                name: 'idarea',
                                id: 'outlined-age-native-simple',
                            }}
                            value={formik.values.idarea}
                            onChange={formik.handleChange}
                            className={styles.inputMaterial}
                            error={formik.touched.idarea && Boolean(formik.errors.idarea)}
                            helperText={formik.touched.idarea && formik.errors.idarea}
                        >
                            <option aria-label="None" value="" />
                            {datos.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Tipo de combustible</InputLabel>
                        <Select
                            required
                            native
                            label="Combustible"
                            value={formik.values.combustible_tipo}
                            onChange={formik.handleChange}
                            className={styles.inputMaterial}
                            error={formik.touched.combustible_tipo && Boolean(formik.errors.combustible_tipo)}
                            helperText={formik.touched.combustible_tipo && formik.errors.combustible_tipo}
                            inputProps={{
                                name: 'combustible_tipo',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {combustible.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Tipo de vehiculo</InputLabel>
                        <Select
                            required
                            native
                            label="Tipo"
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                            className={styles.inputMaterial}
                            error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                            helperText={formik.touched.tipo && formik.errors.tipo}
                            inputProps={{
                                name: 'tipo',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {tipos.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            required
                            id="date"
                            type="date"
                            defaultValue="2021-08-25"
                            name="fecha_compra"
                            label="Fecha de compra"
                            className={classes.textField}
                            error={formik.touched.fecha_compra && Boolean(formik.errors.fecha_compra)}
                            helperText={formik.touched.fecha_compra && formik.errors.fecha_compra}
                            onChange={formik.handleChange}
                            value={formik.values.fecha_compra}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel>Requiere Mantenimiento</InputLabel>
                        <Checkbox
                            name="mantenimiento"
                            value={formik.values.mantenimiento}
                            checked={formik.values.mantenimiento}
                            onChange={formik.handleChange}
                            color="primary" // Opcional: para cambiar el color del checkbox
                        />
                    </Grid>

                   <Grid item xs={12}>
                        <TextField
                            name="nota"
                            className={styles.inputMaterial}
                            label="Nota"
                            value={formik.values.nota}
                            onChange={formik.handleChange}
                            error={formik.touched.nota && Boolean(formik.errors.nota)}
                            helperText={formik.touched.nota && formik.errors.nota}
                        />
                    </Grid>

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Modificar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>
        </Paper>
    );
}