import React from 'react';

import { makeStyles } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Modal, Button, TextField } from '@mui/material';

import Icon from '@mdi/react'
import { mdiQrcode } from '@mdi/js'

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import Link from '@mui/material/Link';
import MaterialTable from 'material-table';

import QRModal from "./QRModal";
import QRread from "./QRread";
import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

export default function Qr(props) {
  const [isLoading, setIsLoading] = useState(false)

  const styles = useStyles();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [url, setUrl] = useState("");

  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalQR, setModalQR] = useState(false);
  const [modal2QR, setModal2QR] = useState(false);

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    nombre: '',
    descripcion: '',
    data: '',
  })

  const handleChange = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  //mod codigo qr
  const modQr = async () => {
    const response = await UserService.addmodQr(consolaSeleccionada.id, consolaSeleccionada);
    var dataNueva = response.data;
    dataNueva.map(consola => {
      if (consolaSeleccionada.id === consola.id) {
        consola.nombre = consolaSeleccionada.nombre;
        consola.descripcion = consolaSeleccionada.descripcion;
        consola.data = consolaSeleccionada.data;
      }
    })
    setData(dataNueva);
    getQrs();
    abrirCerrarModalEditar();
  }

  //trae lista de codigos qr
  const getQrs = async () => {
    const result = await UserService.getQrs();
    setData(result.data);
  }

  //del codigo qr
  const delQr = async () => {
    const response = await UserService.delQr(consolaSeleccionada.id);
    var data = response.data;
    setData(data.filter(consola => consola.id !== consolaSeleccionada.id));
    getQrs();
    abrirCerrarModalEliminar();
  }

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const abrirCerrarModalQR = () => {
    setModalQR(!modalQR);
  }

  const abrirCerrarLeerQR = () => {
    setModal2QR(!modal2QR);
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? abrirCerrarModalEditar() : abrirCerrarModalEliminar()
  }

  const seleccionarQR = (id) => {
    setUrl(id);
    abrirCerrarModalQR()
  }

  const leerQR = (id) => {
    setUrl(id);
    abrirCerrarLeerQR()
  }

  useEffect(() => {

    const GetData = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getQrs();
        if (result) {
          setData(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        props.history.push(process.env.PUBLIC_URL + "/login");
      }
    }
    GetData();

  }, []);

  const bodyEditar = (
    <div className={styles.modal}>
      <h3>Editar QR</h3>
      <TextField name="nombre" className={styles.inputMaterial} label="Nombre" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.nombre} />
      <br />
      <TextField name="descripcion" className={styles.inputMaterial} label="Descripcion" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.descripcion} />
      <br />
      <TextField name="data" className={styles.inputMaterial} label="Data" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.data} />
      <br />
      <br /><br />
      <div align="right">
        <Button color="primary" onClick={() => modQr()}>Editar</Button>
        <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
      </div>
    </div>
  )

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el QR <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => delQr()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const bodyQR = (
    <div className={styles.modal}>
      <QRModal url={url} />
    </div>
  )

  const body2QR = (
    <div className={styles.modal}>
      <QRread url={url} />
    </div>
  )

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/recursos")
  }

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            QRS
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ maxWidth: "100%", direction }}>
            <Grid container>
              <Grid item xs={12}>
                <MaterialTable
                  title=""

                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Buscar...'
                    },
                    header: {
                      actions: 'Acciones'
                    },
                  }}

                  columns={[
                    {
                      title: 'Descripcion corta',
                      field: 'nombre',
                      headerStyle: {
                        backgroundColor: '#004F9E',
                        color: '#FFF',
                      }
                    },
                  ]}
                  data={data}
                  actions={[
                    {
                      icon: () => <Icon path={mdiQrcode}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#004F9E"
                      />,
                      tooltip: 'Ver QR',
                      onClick: (event, data) => seleccionarQR(data),
                    },
                    {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Eliminar',
                      onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                    }
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: '#004F9E',
                      color: '#FFF',
                    },
                    search: true,
                    actionsColumnIndex: -1
                  }}
                />

                <Modal
                  open={modalEditar}
                  onClose={abrirCerrarModalEditar}>
                  {bodyEditar}
                </Modal>

                <Modal
                  open={modalEliminar}
                  onClose={abrirCerrarModalEliminar}>
                  {bodyEliminar}
                </Modal>

                <Modal
                  open={modalQR}
                  onClose={abrirCerrarModalQR}>
                  {bodyQR}
                </Modal>

                <Modal
                  open={modal2QR}
                  onClose={abrirCerrarLeerQR}>
                  {body2QR}
                </Modal>

              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>

    </Paper>
  );
}