import React from 'react';
import { useState, useEffect } from 'react'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import { Modal, Button, TextField } from '@mui/material';
import { Grid, ThemeProvider, StyledEngineProvider } from "@mui/material";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';

import { useParams } from 'react-router';

import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";

import { v4 as uuidv4 } from 'uuid';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    novedad: yup
        .string('Novedad requerido')
        .required('Novedad requerido'),
});

export default function SolicitudesFichadasaddmod() {
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory();


    const [novedades, setNovedades] = useState([]);

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    
    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            novedad: '',
            horas: '',
            color: '',
            permitir: '',
            rolempleado: '',
            activo: '',
            idempleado: '',
            id: uuidv4(),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values, state);
        },
    });

    const [currentUser, setCurrentUser] = useState(undefined);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    const { id } = useParams();
    const { idempleado } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [sino, setSino] = useState([
        { id: 'true', nombre: 'Si' },
        { id: 'false', nombre: 'No' },
    ]);

    const peticionPost = async (values, state) => {
        await UserService.addmodSolicitudes(id, values, state[0]);
        cerrarEditar()
    }
 
    const cerrarEditar = () => {
        history.push(process.env.PUBLIC_URL + "/solicitudes");
    }

    const inicio = () => {
        history.push(process.env.PUBLIC_URL + "/")
    }

    useEffect(() => {

        const GetData = async () => {
            if (id) {
                try {
                    setIsLoading(true);
                    const response = await UserService.getSolicitudid(id);
                    if (response) {
                        formik.setValues(response);

                        setState([
                            {
                                startDate: new Date(response.start),
                                endDate: new Date(response.end),
                                key: 'selection'
                            }
                        ]);

                        setIsLoading(false);
                    } else {
                        var date = new Date(id);
                        var date1 = new Date(id);
                        date.setDate(date.getDate() + 1);
                        date1.setDate(date.getDate() + 1); 
                        
                        setState([
                            {
                                startDate: new Date(date),
                                endDate: new Date(date1),
                                key: 'selection'
                            }
                        ]);
                        formik.initialValues.idempleado = idempleado;
                        setIsLoading(false);

                    }
                } catch (e) {
                    setIsLoading(false);
                    console.log(e)
                    //history.push(process.env.PUBLIC_URL + "/login");
                }
            }
        }
        GetData();

        const GetNovedades = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getNovedades();
                if (result) {
                    console.log(result)
                    setNovedades(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    //history.push(process.env.PUBLIC_URL +"/login");
                }
            } catch (e) {
                setIsLoading(false);
                console.log(e)
                //history.push(process.env.PUBLIC_URL +"/login");
            }
        }
        GetNovedades();

    }, []);

    return (

        <Paper>

            <AppBar style={{ background: '#fff159', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#000' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#000' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Solicitud Fichada
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container fixed>
                <Box sx={{ bgcolor: '#cfe8fc', marginTop: '40px', marginBottom: '40px' }}  >

                    <form onSubmit={formik.handleSubmit}>

                        <Grid container spacing={1} style={{ minHeight: '100vh', padding: '5px' }} >

                            <Grid item xs={6}>
                                <InputLabel htmlFor="outlined-age-native-simple">Tipo de licencia a solicitar</InputLabel>
                                <Select
                                    native
                                    label="Tipo de licencia a solicitar"
                                    value={formik.values.novedad}
                                    onChange={formik.handleChange}
                                    error={formik.touched.novedad && Boolean(formik.errors.novedad)}
                                    helperText={formik.touched.novedad && formik.errors.novedad}
                                    inputProps={{
                                        name: 'novedad',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {novedades.map((value) => (
                                        <option value={value.id} key={value.id}>
                                            {value.nombre}
                                        </option>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    name="horas"
                                    className={styles.inputMaterial}
                                    label="Horas"
                                    value={formik.values.horas}
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    error={formik.touched.horas && Boolean(formik.errors.horas)}
                                    helperText={formik.touched.horas && formik.errors.horas}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DateRange
                                    editableDateInputs={false}
                                    onChange={item => setState([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={state}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextareaAutosize
                                    name="nota"
                                    aria-label="empty textarea"
                                    className={styles.inputMaterial}
                                    minRows={3} label="Nota"
                                    placeholder="Nota"
                                    value={formik.values.nota}
                                    onChange={formik.handleChange}
                                    error={formik.touched.nota && Boolean(formik.errors.nota)}
                                    helperText={formik.touched.nota && formik.errors.nota}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="idempleado"
                                    className={styles.inputMaterial}
                                    value={formik.values.idempleado}
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    error={formik.touched.idempleado && Boolean(formik.errors.idempleado)}
                                    helperText={formik.touched.idempleado && formik.errors.idempleado}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div align="center" style={{ background: '#fff159' }} >
                                    <Button color="primary" type="submit">Guardar</Button>
                                </div>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </Container>
        </Paper>
    );
}

