import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Modules from './Modulesmod.component';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    nombre: yup
        .string('Nombre de equipo requerido')
        .required('Nombre de equipo requerido'),
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function Productsmod(props) {
    const [isLoading, setIsLoading] = useState(true)
    //var hoy=selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate();
    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            nombre: '',
            precio: '',
            cantidad: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();
    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);
    const [datus, setDatus] = useState([]);
    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    //const [selectedDate] = useState(new Date());

    const [product_tipe, setProduct_tipe] = useState([
        { id: 'porusuario', nombre: 'Por usuario' },
        { id: 'porsucursal', nombre: 'Por sucursal' },
        { id: 'valorfijo', nombre: 'Valor fijo' },
    ]);

    const [evaluate_days, setEvaluate_days] = useState([
        { id: '15', nombre: '15' },
        { id: '30', nombre: '30' },
        { id: '60', nombre: '60' },
    ]);

    const [expirate_days, setExpirate_days] = useState([
        { id: '5', nombre: '5' },
        { id: '10', nombre: '10' },
        { id: '15', nombre: '15' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodProduct(id, values, right);
        setData(data.concat(response.data))
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/products");
    }

    useEffect(() => {
        const GetData = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getProductid(id);
                if (result) {
                    formik.setValues(result);
                }
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();
    }, []);

    const Product = () => {
        props.history.push(process.env.PUBLIC_URL + "/products/")
    }

    const Inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/master")
    }

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Producto
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <TextField
                            name="nombre"
                            className={styles.inputMaterial}
                            label="Nombre"
                            value={formik.values.nombre}
                            onChange={formik.handleChange}
                            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                            helperText={formik.touched.nombre && formik.errors.nombre}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="precio"
                            className={styles.inputMaterial}
                            label="Precio"
                            value={formik.values.precio}
                            onChange={formik.handleChange}
                            error={formik.touched.precio && Boolean(formik.errors.precio)}
                            helperText={formik.touched.precio && formik.errors.precio}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel htmlFor="outlined-age-native-simple">Produc tipe</InputLabel>
                        <Select
                            required
                            native
                            label="Product tipe"
                            value={formik.values.product_tipe}
                            onChange={formik.handleChange}
                            error={formik.touched.product_tipe && Boolean(formik.errors.product_tipe)}
                            helperText={formik.touched.product_tipe && formik.errors.product_tipe}
                            inputProps={{
                                name: 'product_tipe',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {product_tipe.map((value) => (
                                <option value={value.id} key={value.id}>
                                   {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="cantidad"
                            className={styles.inputMaterial}
                            label="Cantidad"
                            value={formik.values.cantidad}
                            onChange={formik.handleChange}
                            error={formik.touched.cantidad && Boolean(formik.errors.cantidad)}
                            helperText={formik.touched.cantidad && formik.errors.cantidad}
                        />
                    </Grid>

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Editar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>
            {id && <Modules id={id} />}
        </Paper>
    );
}