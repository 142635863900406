import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import CoreService from "../../services/user.service";
import UserService from "./services/recursos.service";
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    codigo: yup
        .string('Codigo requerido')
        .required('Codigo requerido'),
    descorta: yup
        .string('Descrpcion corta requerida')
        .required('Descrpcion corta requerida'),
    marca: yup
        .string('Marca requerida')
        .required('Marca requerida'),
    modelo: yup
        .string('Modelo requreido ')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Modelo requerido'),
    tipo: yup
        .string('Tipo requerido')
        .required('Tipo requerido'),
});

export default function Informaticasaddmod(props) {
    const [isLoading, setIsLoading] = useState(false)

    //inicializacion de variables y validacion
    var d = new Date();
    var finaldate = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + (d.getDate()).toString().padStart(2, "0")

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            codigo: '',
            descorta: '',
            marca: '',
            modelo: '',
            serie: '',
            descripcion: '',
            imei: '',
            so: '',
            memoria: '',
            disco: '',
            certificacion: '',
            cantidad: '',
            tipo: 'Uso Unico Personal',
            idsucursal: '',
            fecha_compra: finaldate,
            data1: '',
            recurso: 'informatica',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [plantas, setPlantas] = useState([
        { id: 'Mitre', nombre: 'Mitre' },
        { id: 'Sabattini', nombre: 'Sabattini' },
    ]);

    const [tipos, setTipos] = useState([
        { id: 'Uso Unico Personal', nombre: 'Uso Unico Personal' },
        { id: 'Uso Generico Comunitario', nombre: 'Uso Generico Comunitario' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodRecurso(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/informaticas");
    }

    useEffect(() => {

        if (id) {
            const GetData = async () => {
                try {
                    setIsLoading(true);
                    const response = await UserService.getRecurso(id, 'informatica');

                    if (response) {
                        formik.setValues(response);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                } catch (e) {
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            }
            GetData();
        }

        const GetSucursal = async () => {
            try {
                setIsLoading(true);
                const result = await CoreService.getAreas();
                if (result) {
                    setData(result.data);
                    var dataNueva = result.data;
                    dataNueva.map(consola => {
                        setLeft(consola.tareas);
                    })
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetSucursal();

    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/informaticas")
    }

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Informatica
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <TextField
                            name="codigo"
                            className={styles.inputMaterial}
                            label="Codigo"
                            autoComplete='off'
                            autoFocus={true}
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                            helperText={formik.touched.codigo && formik.errors.codigo}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="descorta"
                            className={styles.inputMaterial}
                            label="Descripcion corta"
                            autoComplete='off'
                            value={formik.values.descorta}
                            onChange={formik.handleChange}
                            error={formik.touched.descorta && Boolean(formik.errors.descorta)}
                            helperText={formik.touched.descorta && formik.errors.descorta}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="marca"
                            className={styles.inputMaterial}
                            label="Marca"
                            autoComplete='off'
                            value={formik.values.marca}
                            onChange={formik.handleChange}
                            error={formik.touched.marca && Boolean(formik.errors.marca)}
                            helperText={formik.touched.marca && formik.errors.marca}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="modelo"
                            className={styles.inputMaterial}
                            label="Modelo"
                            autoComplete='off'
                            value={formik.values.modelo}
                            onChange={formik.handleChange}
                            error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                            helperText={formik.touched.modelo && formik.errors.modelo}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="serie"
                            className={styles.inputMaterial}
                            label="Numero de Serie"
                            autoComplete='off'
                            value={formik.values.serie}
                            onChange={formik.handleChange}
                            error={formik.touched.serie && Boolean(formik.errors.serie)}
                            helperText={formik.touched.serie && formik.errors.serie}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="so"
                            className={styles.inputMaterial}
                            label="SO"
                            autoComplete='off'
                            value={formik.values.so}
                            onChange={formik.handleChange}
                            error={formik.touched.so && Boolean(formik.errors.so)}
                            helperText={formik.touched.so && formik.errors.so}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="memoria"
                            className={styles.inputMaterial}
                            label="Memoria"
                            autoComplete='off'
                            value={formik.values.memoria}
                            onChange={formik.handleChange}
                            error={formik.touched.memoria && Boolean(formik.errors.memoria)}
                            helperText={formik.touched.memoria && formik.errors.memoria}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="disco"
                            className={styles.inputMaterial}
                            label="Disco/Almacenamiento"
                            autoComplete='off'
                            value={formik.values.disco}
                            onChange={formik.handleChange}
                            error={formik.touched.disco && Boolean(formik.errors.disco)}
                            helperText={formik.touched.disco && formik.errors.disco}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Tipo de Uso</InputLabel>
                        <Select
                            native
                            label="Tipo"
                            value={formik.values.tipo}
                            autoComplete='off'
                            onChange={formik.handleChange}
                            error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                            helperText={formik.touched.tipo && formik.errors.tipo}
                            inputProps={{
                                name: 'tipo',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {tipos.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            id="date"
                            type="date"
                            defaultValue="2021-08-25"
                            name="fecha_compra"
                            label="Fecha de compra"
                            autoComplete='off'
                            className={classes.textField}
                            error={formik.touched.fecha_compra && Boolean(formik.errors.fecha_compra)}
                            helperText={formik.touched.fecha_compra && formik.errors.fecha_compra}
                            onChange={formik.handleChange}
                            value={formik.values.fecha_compra}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="descripcion"
                            className={styles.inputMaterial}
                            label="Descripcion"
                            autoComplete='off'
                            value={formik.values.descripcion}
                            onChange={formik.handleChange}
                            error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                            helperText={formik.touched.descripcion && formik.errors.descripcion}
                        />
                    </Grid>

                    <TextField
                        name="data1"
                        type="hidden"
                        value={formik.values.data1}
                    />

                    <TextField
                        name="recurso"
                        type="hidden"
                        value={formik.values.recurso}
                    />
                <SnackbarMessage
                  open={open}
                  message={errorMessage}
                  severity="success"
                  onClose={handleCloseMessage}
                />

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Agregar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>

        </Paper>
    );
}