import authHeader from "../../../services/auth-header";
import { handleResponse } from '../../../services/handle-response';

const API_URL = 'https://devapi.casiba.ar/api/v1/';
const user = JSON.parse(localStorage.getItem('user3'));

const getTickets = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_tickets', requestOptions).then(handleResponse));
};

const addmodTicket = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_ticket', requestOptions).then(handleResponse));
};

const delTicket = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_ticket', requestOptions).then(handleResponse));
};

const getTicket = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_ticket_id', requestOptions).then(handleResponse));
};

export default {

  getTickets,
  addmodTicket,
  delTicket,
  getTicket,
  
};
