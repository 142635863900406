import React, { useState, useEffect, useContext } from "react";
import Alert from '@mui/material/Alert';

import { AuthContext } from '../AuthContext';

import HomeMaster from './HomeMaster.component';
import HomeOwner from './HomeOwner.component';
import HomeUser from './HomeUser.component';

import { ServiceWorkerUpdateListener } from '../../ServiceWorkerUpdateListener.js'
import { useStyles } from '../styles'; // Importa los estilos desde el archivo styles.js

const Home = (props) => {
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [swListener, setSwListener] = useState({});

  const { currentUser, showUser, showOwner, showMaster } = useContext(AuthContext);
    
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      let listener = new ServiceWorkerUpdateListener();
      setSwListener(listener);
      listener.onupdateinstalling = (installingEvent) => {
        console.log("SW installed", installingEvent);
      };
      listener.onupdatewaiting = (waitingEvent) => {
        console.log("New update waiting", waitingEvent);
        setUpdateWaiting(true);
      };
      listener.onupdateready = (event) => {
        console.log("Updateready event");
        window.location.reload();
      };
      navigator.serviceWorker.getRegistration().then((reg) => {
        listener.addRegistration(reg);
        setRegistration(reg);
      });

      return () => listener.removeEventListener(null, null);
    } else {
      //do nothing because no sw in development
    }


  }, []);

  const handleUpdate = () => {
    swListener.skipWaiting(registration.waiting);
  }

  const classes = useStyles();

  return (

    <>
      <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate} />

      <sections>

        {showMaster ? (
          <HomeMaster />
        ) : showOwner ? (
          <HomeOwner />
        ) : (
          <HomeUser />
        )}

      </sections>
    </>

  );
};

export default Home;

const UpdateWaiting = ({ updateWaiting, handleUpdate }) => {
  if (!updateWaiting) return <></>
  return (
    <Alert variant="filled" severity="error" onClick={handleUpdate}>
      Versión nueva disponible, Pulsa aquí para Actualizar!
    </Alert>
  )
}