import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { mdiCart, mdiFactory, mdiBookMultipleOutline, mdiBookMultiple, mdiPackageVariant, mdiCar, mdiClipboardListOutline, mdiBagChecked, mdiArchiveCogOutline } from '@mdi/js'
import { mdiArchiveCog } from '@mdi/js';
import { mdiCogOutline } from '@mdi/js';
import { mdiCardAccountDetails } from '@mdi/js';
import { Checkbox } from '@mui/material';

import Grid from '@mui/material/Grid';

import Icon from '@mdi/react';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../AuthContext';
import UserService from "../../services/user.service";

import { useStyles } from '../styles'; // Importa los estilos desde el archivo styles.js

const HomeOwner = (props) => {

    const { showOwner, showMaster, showUser, OwnedProducts, AvailableProducts } = useContext(AuthContext);

    useEffect(() => {

    }, []);

    const classes = useStyles();

    const handleCheckboxChange = async (moduleName, vent) => {
        console.log(moduleName)
        console.log(OwnedProducts)
        const product = AvailableProducts.find(product => product.name === moduleName);
        console.log(product)
        const activeProduct = await UserService.activeProduct(product.id);

        if (activeProduct) {
            // Do something with the active product.
        }
    };

    return (

        <>
            {showOwner && (
                <>


                    {OwnedProducts.find(product => product.name === "Tickets") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/tickets`} className="nav-link">
                                            <Icon path={mdiCogOutline}
                                                title="Tickets"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Tickets
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiCogOutline}
                                            title="Tickets"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [Tickets]
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('Tickets', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />
                                </Grid>
                            </Grid>
                        </items>

                    }

                    {OwnedProducts.find(product => product.name === "ERP") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/bas`} className="nav-link">
                                            <Icon
                                                path={mdiBookMultiple}
                                                title="BAS"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        BAS
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon
                                            path={mdiBookMultiple}
                                            title="BAS"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [BAS]
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('ERP', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />
                                </Grid>
                            </Grid>
                        </items>
                    }

                    {OwnedProducts.find(product => product.name === "Planera") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/planera`} className="nav-link">
                                            <Icon path={mdiFactory}
                                                title="Planera"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Planera
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiFactory}
                                            title="Planera"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [Planera]
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('Planera', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />

                                </Grid>
                            </Grid>
                        </items>
                    }

                    {OwnedProducts.find(product => product.name === "Betc") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/betc`} className="nav-link">
                                            <Icon path={mdiFactory}
                                                title="Betc"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Betc
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiFactory}
                                            title="Betc"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [Betc]
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('Betc', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />

                                </Grid>
                            </Grid>
                        </items>
                    }

                    {OwnedProducts.find(product => product.name === "Planilla Verde") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/verdes`} className="nav-link">
                                            <Icon path={mdiClipboardListOutline}
                                                title="Planilla Verde"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Planillas Verdes
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiClipboardListOutline}
                                            title="Planilla Verde"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Planillas Verdes
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('Planilla Verde', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />
                                </Grid>
                            </Grid>
                        </items>
                    }

                    {OwnedProducts.find(product => product.name === "Flota") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/flota`} className="nav-link">
                                            <Icon path={mdiCar}
                                                title="Flota"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Flota
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiCar}
                                            title="Flota"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [Flota]
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('Flota', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />
                                </Grid>
                            </Grid>
                        </items>
                    }

                    {OwnedProducts.find(product => product.name === "Recursos") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/recursos`} className="nav-link">
                                            <Icon path={mdiArchiveCog}
                                                title="Recursos"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Recursos
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiArchiveCog}
                                            title="Recursos"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [Recursos]
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('Recursos', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />
                                </Grid>
                            </Grid>
                        </items>

                    }

                    {OwnedProducts.find(product => product.name === "Compras") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/compras`} className="nav-link">
                                            <Icon path={mdiCart}
                                                title="Compras"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Compras
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiCart}
                                            title="Compras"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [Compras]
                                    </Typography>
                                </Grid>
                                <Checkbox
                                    onChange={(event) => handleCheckboxChange('Compras', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                />
                            </Grid>
                        </items>

                    }

                    {OwnedProducts.find(product => product.name === "Mantenimiento") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/mantenimiento`} className="nav-link">
                                            <Icon path={mdiCogOutline}
                                                title="Mantenimiento"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Mantenimiento
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiCogOutline}
                                            title="Mantenimiento"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [Mantenimiento]
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('Mantenimiento', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />
                                </Grid>
                            </Grid>
                        </items>
                    }

                    {OwnedProducts.find(product => product.name === "Crm") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/crm`} className="nav-link">
                                            <Icon path={mdiCogOutline}
                                                title="Crm"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Crm
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiCogOutline}
                                            title="Crm"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [Crm]
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('Crm', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />
                                </Grid>
                            </Grid>
                        </items>
                    }

                    {OwnedProducts.find(product => product.name === "Recursos Humanos") ? (
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/controlfichadas`} className="nav-link">
                                            <Icon path={mdiCardAccountDetails}
                                                title="Recursos Humanos"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Recursos Humanos
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    ) :
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon path={mdiCardAccountDetails}
                                            title="Recursos Humanos"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />

                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Habilitar [Recursos Humanos]
                                    </Typography>
                                    <Checkbox
                                        onChange={(event) => handleCheckboxChange('Recursos Humanos', event)} // Pasa 'bas' como el nombre del módulo al cambiar el checkbox
                                    />
                                </Grid>
                            </Grid>
                        </items>
                    }

                    <items>
                        <Grid item xs>
                            <Grid item xs container direction="column" spacing={2}>
                                <Typography gutterBottom variant="subtitle1">
                                    <Link to={`${process.env.PUBLIC_URL}/administrador`} className="nav-link">
                                        <Icon path={mdiCardAccountDetails}
                                            title="Administrador"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Link>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Adminitrador
                                </Typography>
                            </Grid>
                        </Grid>
                    </items>

                </>

            )}


        </>

    );
};

export default HomeOwner;

