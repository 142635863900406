import React from 'react';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useParams } from 'react-router';
import { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import UserService from "../../services/planillas.service";

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

//import { useOnlineStatus } from "./useOnlineStatus"; //add to offline

import { useFormik } from 'formik';                  //add to offline
import * as yup from 'yup';                          //add to offline
import { mdiConsoleLine } from '@mdi/js';

//add offline Validacion del formulario
const validationSchema = yup.object();
//fin add offline 


const useStyles = styled((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    },
    label: {
        background: '#3c763d',
        color: 'fff',
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3c763d',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const customColumnStyle = {
    Width: "10px",
    padding: 0,
    //backgroundColor: "green",
    textAlign: "right",
};

const styles = useStyles();
const classes = useStyles();
//const id = null;

const cargaDatos = async (data) => {
    //cerrarEditar()
}

const Verdesadd = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    //add to offline
    //  const isOnline = useOnlineStatus();

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            id: '',
            cantidad: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });
    //fin add to offline

    //add to offfline
    const [modalLocal, setModalLocal] = useState(false);
    const [state, setState] = useState({ events: [] });
    const abrirCerrarModalLocal = () => {
        setModalLocal(!modalLocal);
    }

    const { id } = useParams();
    const { setValue, reset, register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        postverde(data);
    };

    // checkbock
    const handlePreresultadoc1 = (e) => {
        if (e.target.checked) {
            setValue("preresultadonc1", 0);
        }
    };
    const handlePreresultadonc1 = (e) => {
        if (e.target.checked) {
            setValue("preresultadoc1", 0);
        }
    };

    // checkbock
    const handlePreresultadoc2 = (e) => {
        if (e.target.checked) {
            setValue("preresultadonc2", 0);
        }
    };
    const handlePreresultadonc2 = (e) => {
        if (e.target.checked) {
            setValue("preresultadoc2", 0);
        }
    };

    // checkbock
    const handleResultadoc = (e) => {
        if (e.target.checked) {
            setValue("resultadonc", 0);
        }
    };
    const handleResultadonc = (e) => {
        if (e.target.checked) {
            setValue("resultadoc", 0);
        }
    };

    // checkbock
    const handleTestc1 = (e) => {
        if (e.target.checked) {
            setValue("testnc1", 0);
        }
    };
    const handleTestnc1 = (e) => {
        if (e.target.checked) {
            setValue("testc1", 0);
        }
    };

    // checkbock
    const handleTestc2 = (e) => {
        if (e.target.checked) {
            setValue("testnc2", 0);
        }
    };
    const handleTestnc2 = (e) => {
        if (e.target.checked) {
            setValue("testc2", 0);
        }
    };

    // checkbock
    const handleTestc3 = (e) => {
        if (e.target.checked) {
            setValue("testnc3", 0);
        }
    };
    const handleTestnc3 = (e) => {
        if (e.target.checked) {
            setValue("testc3", 0);
        }
    };

    // checkbock
    const handleTestc4 = (e) => {
        if (e.target.checked) {
            setValue("testnc4", 0);
        }
    };
    const handleTestnc4 = (e) => {
        if (e.target.checked) {
            setValue("testc4", 0);
        }
    };

    // checkbock
    const handleTestc5 = (e) => {
        if (e.target.checked) {
            setValue("testnc5", 0);
        }
    };
    const handleTestnc5 = (e) => {
        if (e.target.checked) {
            setValue("testc5", 0);
        }
    };

    // checkbock
    const handleTestc6 = (e) => {
        if (e.target.checked) {
            setValue("testnc6", 0);
        }
    };
    const handleTestnc6 = (e) => {
        if (e.target.checked) {
            setValue("testc6", 0);
        }
    };

    // checkbock
    const handleTestc7 = (e) => {
        if (e.target.checked) {
            setValue("testnc7", 0);
        }
    };
    const handleTestnc7 = (e) => {
        if (e.target.checked) {
            setValue("testc7", 0);
        }
    };

    // checkbock
    const handleTestc8 = (e) => {
        if (e.target.checked) {
            setValue("testnc8", 0);
        }
    };
    const handleTestnc8 = (e) => {
        if (e.target.checked) {
            setValue("testc8", 0);
        }
    };

    // checkbock
    const handleTestc9 = (e) => {
        if (e.target.checked) {
            setValue("testnc9", 0);
        }
    };
    const handleTestnc9 = (e) => {
        if (e.target.checked) {
            setValue("testc9", 0);
        }
    };

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/verdes");
    }

    const postverde = async (data) => {
        // if (isOnline) {
        peticionPost(data);
        //        } else {
        //          peticionPostOffline(data);
        //    }
    }

    const peticionPost = async (values) => {
        const response = await UserService.addmodVerde(id, values);
        //setData(data.concat(response.data))
        cerrarEditar()
    }

    const peticionPostOffline = async (values) => {
        let datos = localStorage.getItem('verdes');
        let resultado = JSON.parse(datos);
        let daleee = resultado.concat(values);
        localStorage.setItem('verdes', JSON.stringify(daleee));
        console.log(daleee);
        cerrarEditar()
    }

    const findArrayElementByEdit = (array, element) => {
        return array.find((element) => {
            return element.isEdit === true;
        })
    }

    //data is array, reqField name to file search, value value to find, resField name of fiel to searhc
    // Exampe findBySpecField(data,'id','502100','name')
    const findBySpecField = (data, reqField, value, resField) => {
        var container = data;
        console.log(container);
        console.log(container.length);
        var i;

        for (i = 0; i < container.length; i++) {
            console.log(container[i][reqField]);
            console.log(i);
            if (container[i][reqField] == value) {
                return (container[i][resField]);
            }
        }
        return '';
    }

    useEffect(() => {
        //  if (isOnline) {

        const GetData = async () => {
            try {
                setIsLoading(true);
                const consola = await UserService.getVerde(props.match.params.id);
                if (consola) {
                    console.log(consola[0].cliente);
                    reset({
                        tipo: 'verde',
                        ...consola[0],
                        ack: 'add',
                        id: uuidv4(),
                    });
                    /*reset({
                        tipo: 'verde',
                        informe: consola.informe,
                        ot: consola.ot,
                        hojade: consola.hojade,
                        hojahasta: consola.hojahasta,
                        cliente: consola.cliente,
                        equipo: consola.equipo,
                        sector: consola.sector,
                        modelo: consola.modelo,
                        planta: consola.planta,
                        ndeserie: consola.ndeserie,
                        precantidad1: consola.precantidad1,
                        premodelo1: consola.premodelo1,
                        premedidas1: consola.premedidas1,
                        preobservaciones1: consola.preobservaciones1,
                        resultadonc: consola.resultadonc,
                        resultadonc1: consola.resultadonc1,
                        preresultadoc1: consola.preresultadoc1,
                        preresultadonc1: consola.preresultadonc1,
                        precantidad2: consola.precantidad2,
                        premodelo2: consola.premodelo2,
                        premedidas2: consola.premedidas1,
                        preobservaciones2: consola.preobservaciones2,
                        preresultadoc2: consola.preresultadoc2,
                        preresultadonc2: consola.preresultadonc2,
                        filtrodif0: consola.filtrodif0,
                        medidas0: consola.medidas0,
                        velocidad0_0: consola.velocidad0_0,
                        velocidad0_1: consola.velocidad0_1,
                        velocidad0_2: consola.velocidad0_2,
                        velocidad0_3: consola.velocidad0_3,
                        velocidad0_4: consola.velocidad0_4,
                        velocidad0_5: consola.velocidad0_5,
                        velocidad0_6: consola.velocidad0_6,
                        velocidad0_7: consola.velocidad0_7,
                        velocidadmedia0: consola.velocidadmedia0,
                        p0: consola.p0,
                        filtrodif1: consola.filtrodif1,
                        medidas1: consola.medidas1,
                        velocidad1_0: consola.velocidad1_0,
                        velocidad1_1: consola.velocidad1_1,
                        velocidad1_2: consola.velocidad1_2,
                        velocidad1_3: consola.velocidad1_3,
                        velocidad1_4: consola.velocidad1_4,
                        velocidad1_5: consola.velocidad1_5,
                        velocidad1_6: consola.velocidad1_6,
                        velocidad1_7: consola.velocidad1_7,
                        velocidadmedia1: consola.velocidadmedia1,
                        p1: consola.p1,
                        filtrodif2: consola.filtrodif2,
                        medidas2: consola.medidas2,
                        velocidad2_0: consola.velocidad2_0,
                        velocidad2_1: consola.velocidad2_1,
                        velocidad2_2: consola.velocidad2_2,
                        velocidad2_3: consola.velocidad2_3,
                        velocidad2_4: consola.velocidad2_4,
                        velocidad2_5: consola.velocidad2_5,
                        velocidad2_6: consola.velocidad2_6,
                        velocidad2_7: consola.velocidad2_7,
                        velocidadmedia2: consola.velocidadmedia2,
                        p2: consola.p2,
                        filtrodif3: consola.filtrodif3,
                        medidas3: consola.medidas3,
                        velocidad3_0: consola.velocidad3_0,
                        velocidad3_1: consola.velocidad3_1,
                        velocidad3_2: consola.velocidad3_2,
                        velocidad3_3: consola.velocidad3_3,
                        velocidad3_4: consola.velocidad3_4,
                        velocidad3_5: consola.velocidad3_5,
                        velocidad3_6: consola.velocidad3_6,
                        velocidad3_7: consola.velocidad3_7,
                        velocidadmedia3: consola.velocidadmedia3,
                        p3: consola.p3,
                        filtrodif4: consola.filtrodif4,
                        medidas4: consola.medidas4,
                        velocidad4_0: consola.velocidad4_0,
                        velocidad4_1: consola.velocidad4_1,
                        velocidad4_2: consola.velocidad4_2,
                        velocidad4_3: consola.velocidad4_3,
                        velocidad4_4: consola.velocidad4_4,
                        velocidad4_5: consola.velocidad4_5,
                        velocidad4_6: consola.velocidad4_6,
                        velocidad4_7: consola.velocidad4_7,
                        velocidadmedia4: consola.velocidadmedia4,
                        p4: consola.p4,
                        filtrodif5: consola.filtrodif5,
                        medidas5: consola.medidas5,
                        velocidad5_0: consola.velocidad5_0,
                        velocidad5_1: consola.velocidad5_1,
                        velocidad5_2: consola.velocidad5_2,
                        velocidad5_3: consola.velocidad5_3,
                        velocidad5_4: consola.velocidad5_4,
                        velocidad5_5: consola.velocidad5_5,
                        velocidad5_6: consola.velocidad5_6,
                        velocidad5_7: consola.velocidad5_7,
                        velocidadmedia5: consola.velocidadmedia5,
                        p5: consola.p5,
                        filtrodif6: consola.filtrodif6,
                        medidas6: consola.medidas6,
                        velocidad6_0: consola.velocidad6_0,
                        velocidad6_1: consola.velocidad6_1,
                        velocidad6_2: consola.velocidad6_2,
                        velocidad6_3: consola.velocidad6_3,
                        velocidad6_4: consola.velocidad6_4,
                        velocidad6_5: consola.velocidad6_5,
                        velocidad6_6: consola.velocidad6_6,
                        velocidad6_7: consola.velocidad6_7,
                        velocidadmedia6: consola.velocidadmedia6,
                        p6: consola.p6,
                        filtrodif7: consola.filtrodif7,
                        medidas7: consola.medidas7,
                        velocidad7_0: consola.velocidad7_0,
                        velocidad7_1: consola.velocidad7_1,
                        velocidad7_2: consola.velocidad7_2,
                        velocidad7_3: consola.velocidad7_3,
                        velocidad7_4: consola.velocidad7_4,
                        velocidad7_5: consola.velocidad7_5,
                        velocidad7_6: consola.velocidad7_6,
                        velocidad7_7: consola.velocidad7_7,
                        velocidadmedia7: consola.velocidadmedia7,
                        p7: consola.p7,
                        filtrodif8: consola.filtrodif8,
                        medidas8: consola.medidas8,
                        velocidad8_0: consola.velocidad8_0,
                        velocidad8_1: consola.velocidad8_1,
                        velocidad8_2: consola.velocidad8_2,
                        velocidad8_3: consola.velocidad8_3,
                        velocidad8_4: consola.velocidad8_4,
                        velocidad8_5: consola.velocidad8_5,
                        velocidad8_6: consola.velocidad8_6,
                        velocidad8_7: consola.velocidad8_7,
                        velocidadmedia8: consola.velocidadmedia8,
                        p8: consola.p8,
                        caudal: consola.caudal,
                        resultadoc: consola.resultadoc,
                        redultadonc: consola.redultadonc,
                        concentracion: consola.concentracion,
                        ndefiltro1: consola.ndefiltro1,
                        ndeserie1: consola.ndeserie1,
                        valoroptenido1: consola.valoroptenido1,
                        testc1: consola.testc1,
                        testnc1: consola.testnc1,
                        ndefiltro2: consola.ndefiltro2,
                        ndeserie2: consola.ndeserie2,
                        valoroptenido2: consola.valoroptenido2,
                        testc2: consola.testc2,
                        testnc2: consola.testnc2,
                        ndefiltro3: consola.ndefiltro2,
                        ndeserie3: consola.ndeserie3,
                        valoroptenido3: consola.valoroptenido3,
                        testc3: consola.testc3,
                        testnc3: consola.testnc3,
                        ndefiltro4: consola.ndefiltro4,
                        ndeserie4: consola.ndeserie4,
                        valoroptenido4: consola.valoroptenido4,
                        testc4: consola.testc4,
                        testnc4: consola.testnc4,
                        ndefiltro5: consola.ndefiltro5,
                        ndeserie5: consola.ndeserie5,
                        valoroptenido5: consola.valoroptenido5,
                        testc5: consola.testc5,
                        testnc5: consola.testnc5,
                        ndefiltro6: consola.ndefiltro6,
                        ndeserie6: consola.ndeserie6,
                        valoroptenido6: consola.valoroptenido6,
                        testc6: consola.testc6,
                        testnc6: consola.testnc6,
                        ndefiltro7: consola.ndefiltro7,
                        ndeserie7: consola.ndeserie7,
                        valoroptenido7: consola.valoroptenido7,
                        testc7: consola.testc7,
                        testnc7: consola.testnc7,
                        ndefiltro8: consola.ndefiltro8,
                        ndeserie8: consola.ndeserie8,
                        valoroptenido8: consola.valoroptenido8,
                        testc8: consola.testc8,
                        testnc8: consola.testnc8,
                        ndefiltro9: consola.ndefiltro9,
                        ndeserie9: consola.ndeserie9,
                        valoroptenido9: consola.valoroptenido9,
                        testc9: consola.testc9,
                        testnc9: consola.testnc9,
                        built: consola.built,
                        rest: consola.rest,
                        oper: consola.oper,
                        personas: consola.personas,
                        isoc5: consola.isoc5,
                        isoc6: consola.isoc6,
                        isoc7: consola.isoc7,
                        isoc8: consola.isoc8,
                        gmpa: consola.gmpa,
                        gmpb: consola.gmpb,
                        gmpc: consola.gmpc,
                        gmpd: consola.gmpd,
                        cerocinco: consola.cerocinco,
                        cincoum: consola.cincoum,
                        promediomaximo: consola.promediomaximo,
                        promediomaximo1: consola.promediomaximo1,
                        rparticulasc: consola.rparticulasc,
                        rparticulasnc: consola.rparticulasnc,
                        crimi: consola.crimi,
                        crima: consola.crima,
                        balometro: consola.balometro,
                        fotometro: consola.fotometro,
                        manometro: consola.manometro,
                        balometro1: consola.balometro1,
                        fotometro1: consola.fotometro1,
                        decibelimetro: consola.decibelimetro,
                        contdepart: consola.contdepart,
                        fotometro2: consola.fotometro2,
                        luxometro: consola.luxometro,
                        contdepart1: consola.contdepart1,
                        manometro1: consola.manometro1,
                        luxometro1: consola.luxometro1,
                        contdepart2: consola.contdepart2,
                        manometro2: consola.manometro2,
                        termohigremetro: consola.termohigremetro,
                        observaciones: consola.observaciones,
                        realizadopor: consola.realizadopor,
                        fechacreate: consola.fechacreate,
                        isoc5: consola.isoc5,
                        ack: 'add',
                        id: uuidv4(),
                    });
*/
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                alert(e)
                //props.history.push("/login");
            }
        }
        GetData();
        //let data = JSON.parse(localStorage.getItem('verdes')); 
        //let consola = findBySpecField(data,'id',id,'informe');

        //} else {
        //    let data = JSON.parse(localStorage.getItem('verdes')); 

        //console.log(id);

        //let consola= datos.find(item => item.id === id)            
        //console.log(consola);

        //let resultado = findArrayElementByEdit(datos, yo);
        //console.log(resultado);
        //    let consola = findBySpecField(data,'id',id,'informe');
        //console.log(consola);
        /*    reset({
                tipo: 'verde',
                informe: consola.informe,
                ot: consola.ot,
                hojade: consola.hojade,
                hojahasta: consola.hojahasta,
                cliente: consola.cliente,
                equipo: consola.equipo,
                sector: consola.sector,
                modelo: consola.modelo,
                planta: consola.planta,
                ndeserie: consola.ndeserie,
                precantidad1: consola.precantidad1,
                premodelo1: consola.premodelo1,
                premedidas1: consola.premedidas1,
                preobservaciones1: consola.preobservaciones1,
                resultadonc: consola.resultadonc,
                resultadonc1: consola.resultadonc1,
                preresultadoc1: consola.preresultadoc1,
                preresultadonc1: consola.preresultadonc1,
                precantidad2: consola.precantidad2,
                premodelo2: consola.premodelo2,
                premedidas2: consola.premedidas1,
                preobservaciones2: consola.preobservaciones2,
                preresultadoc2: consola.preresultadoc2,
                preresultadonc2: consola.preresultadonc2,
                filtrodif0: consola.filtrodif0,
                medidas0: consola.medidas0,
                velocidad0_0: consola.velocidad0_0,
                velocidad0_1: consola.velocidad0_1,
                velocidad0_2: consola.velocidad0_2,
                velocidad0_3: consola.velocidad0_3,
                velocidad0_4: consola.velocidad0_4,
                velocidad0_5: consola.velocidad0_5,
                velocidad0_6: consola.velocidad0_6,
                velocidad0_7: consola.velocidad0_7,
                velocidadmedia0: consola.velocidadmedia0,
                p0: consola.p0,
                filtrodif1: consola.filtrodif1,
                medidas1: consola.medidas1,
                velocidad1_0: consola.velocidad1_0,
                velocidad1_1: consola.velocidad1_1,
                velocidad1_2: consola.velocidad1_2,
                velocidad1_3: consola.velocidad1_3,
                velocidad1_4: consola.velocidad1_4,
                velocidad1_5: consola.velocidad1_5,
                velocidad1_6: consola.velocidad1_6,
                velocidad1_7: consola.velocidad1_7,
                velocidadmedia1: consola.velocidadmedia1,
                p1: consola.p1,
                filtrodif2: consola.filtrodif2,
                medidas2: consola.medidas2,
                velocidad2_0: consola.velocidad2_0,
                velocidad2_1: consola.velocidad2_1,
                velocidad2_2: consola.velocidad2_2,
                velocidad2_3: consola.velocidad2_3,
                velocidad2_4: consola.velocidad2_4,
                velocidad2_5: consola.velocidad2_5,
                velocidad2_6: consola.velocidad2_6,
                velocidad2_7: consola.velocidad2_7,
                velocidadmedia2: consola.velocidadmedia2,
                p2: consola.p2,
                filtrodif3: consola.filtrodif3,
                medidas3: consola.medidas3,
                velocidad3_0: consola.velocidad3_0,
                velocidad3_1: consola.velocidad3_1,
                velocidad3_2: consola.velocidad3_2,
                velocidad3_3: consola.velocidad3_3,
                velocidad3_4: consola.velocidad3_4,
                velocidad3_5: consola.velocidad3_5,
                velocidad3_6: consola.velocidad3_6,
                velocidad3_7: consola.velocidad3_7,
                velocidadmedia3: consola.velocidadmedia3,
                p3: consola.p3,
                filtrodif4: consola.filtrodif4,
                medidas4: consola.medidas4,
                velocidad4_0: consola.velocidad4_0,
                velocidad4_1: consola.velocidad4_1,
                velocidad4_2: consola.velocidad4_2,
                velocidad4_3: consola.velocidad4_3,
                velocidad4_4: consola.velocidad4_4,
                velocidad4_5: consola.velocidad4_5,
                velocidad4_6: consola.velocidad4_6,
                velocidad4_7: consola.velocidad4_7,
                velocidadmedia4: consola.velocidadmedia4,
                p4: consola.p4,
                filtrodif5: consola.filtrodif5,
                medidas5: consola.medidas5,
                velocidad5_0: consola.velocidad5_0,
                velocidad5_1: consola.velocidad5_1,
                velocidad5_2: consola.velocidad5_2,
                velocidad5_3: consola.velocidad5_3,
                velocidad5_4: consola.velocidad5_4,
                velocidad5_5: consola.velocidad5_5,
                velocidad5_6: consola.velocidad5_6,
                velocidad5_7: consola.velocidad5_7,
                velocidadmedia5: consola.velocidadmedia5,
                p5: consola.p5,
                filtrodif6: consola.filtrodif6,
                medidas6: consola.medidas6,
                velocidad6_0: consola.velocidad6_0,
                velocidad6_1: consola.velocidad6_1,
                velocidad6_2: consola.velocidad6_2,
                velocidad6_3: consola.velocidad6_3,
                velocidad6_4: consola.velocidad6_4,
                velocidad6_5: consola.velocidad6_5,
                velocidad6_6: consola.velocidad6_6,
                velocidad6_7: consola.velocidad6_7,
                velocidadmedia6: consola.velocidadmedia6,
                p6: consola.p6,
                filtrodif7: consola.filtrodif7,
                medidas7: consola.medidas7,
                velocidad7_0: consola.velocidad7_0,
                velocidad7_1: consola.velocidad7_1,
                velocidad7_2: consola.velocidad7_2,
                velocidad7_3: consola.velocidad7_3,
                velocidad7_4: consola.velocidad7_4,
                velocidad7_5: consola.velocidad7_5,
                velocidad7_6: consola.velocidad7_6,
                velocidad7_7: consola.velocidad7_7,
                velocidadmedia7: consola.velocidadmedia7,
                p7: consola.p7,
                filtrodif8: consola.filtrodif8,
                medidas8: consola.medidas8,
                velocidad8_0: consola.velocidad8_0,
                velocidad8_1: consola.velocidad8_1,
                velocidad8_2: consola.velocidad8_2,
                velocidad8_3: consola.velocidad8_3,
                velocidad8_4: consola.velocidad8_4,
                velocidad8_5: consola.velocidad8_5,
                velocidad8_6: consola.velocidad8_6,
                velocidad8_7: consola.velocidad8_7,
                velocidadmedia8: consola.velocidadmedia8,
                p8: consola.p8,
                caudal: consola.caudal,
                resultadoc: consola.resultadoc,
                redultadonc: consola.redultadonc,
                concentracion: consola.concentracion,
                ndefiltro1: consola.ndefiltro1,
                ndeserie1: consola.ndeserie1,
                valoroptenido1: consola.valoroptenido1,
                testc1: consola.testc1,
                testnc1: consola.testnc1,
                ndefiltro2: consola.ndefiltro2,
                ndeserie2: consola.ndeserie2,
                valoroptenido2: consola.valoroptenido2,
                testc2: consola.testc2,
                testnc2: consola.testnc2,
                ndefiltro3: consola.ndefiltro2,
                ndeserie3: consola.ndeserie3,
                valoroptenido3: consola.valoroptenido3,
                testc3: consola.testc3,
                testnc3: consola.testnc3,
                ndefiltro4: consola.ndefiltro4,
                ndeserie4: consola.ndeserie4,
                valoroptenido4: consola.valoroptenido4,
                testc4: consola.testc4,
                testnc4: consola.testnc4,
                ndefiltro5: consola.ndefiltro5,
                ndeserie5: consola.ndeserie5,
                valoroptenido5: consola.valoroptenido5,
                testc5: consola.testc5,
                testnc5: consola.testnc5,
                ndefiltro6: consola.ndefiltro6,
                ndeserie6: consola.ndeserie6,
                valoroptenido6: consola.valoroptenido6,
                testc6: consola.testc6,
                testnc6: consola.testnc6,
                ndefiltro7: consola.ndefiltro7,
                ndeserie7: consola.ndeserie7,
                valoroptenido7: consola.valoroptenido7,
                testc7: consola.testc7,
                testnc7: consola.testnc7,
                ndefiltro8: consola.ndefiltro8,
                ndeserie8: consola.ndeserie8,
                valoroptenido8: consola.valoroptenido8,
                testc8: consola.testc8,
                testnc8: consola.testnc8,
                ndefiltro9: consola.ndefiltro9,
                ndeserie9: consola.ndeserie9,
                valoroptenido9: consola.valoroptenido9,
                testc9: consola.testc9,
                testnc9: consola.testnc9,
                built: consola.built,
                rest: consola.rest,
                oper: consola.oper,
                personas: consola.personas,
                isoc5: consola.isoc5,
                isoc6: consola.isoc6,
                isoc7: consola.isoc7,
                isoc8: consola.isoc8,
                gmpa: consola.gmpa,
                gmpb: consola.gmpb,
                gmpc: consola.gmpc,
                gmpd: consola.gmpd,
                cerocinco: consola.cerocinco,
                cincoum: consola.cincoum,
                promediomaximo: consola.promediomaximo,
                promediomaximo1: consola.promediomaximo1,
                rparticulasc: consola.rparticulasc,
                rparticulasnc: consola.rparticulasnc,
                crimi: consola.crimi,
                crima: consola.crima,
                balometro: consola.balometro,
                fotometro: consola.fotometro,
                manometro: consola.manometro,
                balometro1: consola.balometro1,
                fotometro1: consola.fotometro1,
                decibelimetro: consola.decibelimetro,
                contdepart: consola.contdepart,
                fotometro2: consola.fotometro2,
                luxometro: consola.luxometro,
                contdepart1: consola.contdepart1,
                manometro1: consola.manometro1,
                luxometro1: consola.luxometro1,
                contdepart2: consola.contdepart2,
                manometro2: consola.manometro2,
                termohigremetro: consola.termohigremetro,
                observaciones: consola.observaciones,
                realizadopor: consola.realizadopor,
                fechacreate: consola.fechacreate,
                isoc5: consola.isoc5,
                ack: 'add',
            });


        }*/

    }, [])

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/verdes")
      }

      return (
        <div>
            <Paper className={classes.root}>

                <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Crear Modificar Planilla
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

                <form onSubmit={handleSubmit(onSubmit)}>

                    <h3>PLANILLA DE SERVICE PR-04 IT-06 F03 / REV.08</h3>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="left"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                <label>Informe: </label>
                                                <input
                                                    {...register("ack")}
                                                    value="add"
                                                    type="hidden"
                                                    name="ack"
                                                    style={{ width: "80px" }}
                                                />
                                                <input
                                                    {...register("tipo")}
                                                    value="verde"
                                                    type="hidden"
                                                    name="tipo"
                                                    style={{ width: "80px" }}
                                                />
                                                <input
                                                    {...register("informe")}
                                                    type="text"
                                                    name="informe"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <label>OT: </label>
                                                <input
                                                    {...register("ot")}
                                                    name="ot"
                                                    className={styles.inputMaterial}
                                                    label="OT"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <label>Hoja: </label>
                                                <input
                                                    {...register("hojade")}
                                                    name="hojade"
                                                    className={styles.inputMaterial}
                                                    label="Hoja desde"
                                                />
                                                <label>de: </label>
                                                <input
                                                    {...register("hojahasta")}
                                                    name="hojahasta"
                                                    className={styles.inputMaterial}
                                                    label="Hoja hasta"
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan='2'>
                                                <label>Cliente: </label>
                                                <input
                                                    {...register("cliente")}
                                                    name="cliente"
                                                    className={styles.inputMaterial}
                                                    label="Cliente"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell colSpan='2'>
                                                <label>Equipo: </label>
                                                <input
                                                    {...register("equipo")}
                                                    name="equipo"
                                                    className={styles.inputMaterial}
                                                    label="Equipo"
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan='2'>
                                                <label>Sector: </label>
                                                <input
                                                    {...register("sector")}
                                                    name="sector"
                                                    className={styles.inputMaterial}
                                                    label="Sector"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell colSpan='2'>
                                                <label>Modelo: </label>
                                                <input
                                                    {...register("modelo")}
                                                    name="modelo"
                                                    className={styles.inputMaterial}
                                                    label="Modelo"
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan='2'>
                                                <label>Planta: </label>
                                                <input
                                                    {...register("planta")}
                                                    name="planta"
                                                    className={styles.inputMaterial}
                                                    label="Planata"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell colSpan='2'>
                                                <label>Nro de serie: </label>
                                                <input
                                                    {...register("ndeserie")}
                                                    name="ndeserie"
                                                    className={styles.inputMaterial}
                                                    label="Ndeserie"
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className={classes.label} >PREFILTROS</InputLabel>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" border='1'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Cantidad</StyledTableCell>
                                            <StyledTableCell align="left">Modelo</StyledTableCell>
                                            <StyledTableCell align="left">Medidas</StyledTableCell>
                                            <StyledTableCell align="left">Observaciones</StyledTableCell>
                                            <StyledTableCell align="left">ResultadoC</StyledTableCell>
                                            <StyledTableCell align="left">ResultadoNC</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                <input
                                                    {...register("precantidad1")}
                                                    name="precantidad1"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("premodelo1")}
                                                    name="premodelo1"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("premedidas1")}
                                                    name="premedidas1"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("preobservaciones1")}
                                                    name="preobservaciones1"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("preresultadoc1")}
                                                        name="preresultadoc1"
                                                        type="checkbox"
                                                        onChange={handlePreresultadoc1}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("preresultadonc1")}
                                                        name="preresultadonc1"
                                                        type="checkbox"
                                                        onChange={handlePreresultadonc1}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                <input
                                                    {...register("precantidad2")}
                                                    name="precantidad2"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("premodelo2")}
                                                    name="premodelo2"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("premedidas2")}
                                                    name="premedidas2"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("preobservaciones2")}
                                                    name="preobservaciones2"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("preresultadoc2")}
                                                        name="preresultadoc2"
                                                        type="checkbox"
                                                        onChange={handlePreresultadoc2}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("preresultadonc2")}
                                                        name="preresultadonc2"
                                                        type="checkbox"
                                                        onChange={handlePreresultadonc2}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12}>
                            REGISTRO DE VELOCIDADES / LAMINARIDAD
                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" border='1'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">
                                                <label>
                                                    Filtro
                                                    <input
                                                        {...register("filtro")}
                                                        name="filtro"
                                                        type="checkbox"
                                                    />
                                                </label>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">Medidas(mm)</StyledTableCell>
                                            <StyledTableCell align="left" colSpan="8" >Velocidad(m/s)</StyledTableCell>
                                            <StyledTableCell align="left">Velocidad media (m/s)</StyledTableCell>
                                            <StyledTableCell align="left">(Pa)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("filtrodif0")}
                                                    name="filtrodif0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("medidas0")}
                                                    name="medidas0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad0_0")}
                                                    name="velocidad0_0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad0_1")}
                                                    name="velocidad0_1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad0_2")}
                                                    name="velocidad0_2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad0_3")}
                                                    name="velocidad0_3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad0_4")}
                                                    name="velocidad0_4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad0_5")}
                                                    name="velocidad0_5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad0_6")}
                                                    name="velocidad0_6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad0_7")}
                                                    name="velocidad0_7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidadmedia0")}
                                                    name="velocidadmedia0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("p0")}
                                                    name="p0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("filtrodif1")}
                                                    name="filtrodif1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("medidas1")}
                                                    name="medidas1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad1_0")}
                                                    name="velocidad1_0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad1_1")}
                                                    name="velocidad1_1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad1_2")}
                                                    name="velocidad1_2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad1_3")}
                                                    name="velocidad1_3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad1_4")}
                                                    name="velocidad1_4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad1_5")}
                                                    name="velocidad1_5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad1_6")}
                                                    name="velocidad1_6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidad1_7")}
                                                    name="velocidad1_7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("velocidadmedia1")}
                                                    name="velocidadmedia1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle} align="right">
                                                <input
                                                    {...register("p1")}
                                                    name="p1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>

                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("filtrodif2")}
                                                    name="filtrodif2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("medidas2")}
                                                    name="medidas2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad2_0")}
                                                    name="velocidad2_0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad2_1")}
                                                    name="velocidad2_1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad2_2")}
                                                    name="velocidad2_2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad2_3")}
                                                    name="velocidad2_3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad2_4")}
                                                    name="velocidad2_4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad2_5")}
                                                    name="velocidad2_5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad2_6")}
                                                    name="velocidad2_6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad2_7")}
                                                    name="velocidad2_7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidadmedia2")}
                                                    name="velocidadmedia2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("p2")}
                                                    name="p2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("filtrodif3")}
                                                    name="filtrodif3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("medidas3")}
                                                    name="medidas3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad3_0")}
                                                    name="velocidad3_0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad3_1")}
                                                    name="velocidad3_1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad3_2")}
                                                    name="velocidad3_2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad3_3")}
                                                    name="velocidad3_3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad3_4")}
                                                    name="velocidad3_4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad3_5")}
                                                    name="velocidad3_5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad3_6")}
                                                    name="velocidad3_6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad3_7")}
                                                    name="velocidad3_7"
                                                    className={styles.inputMaterial}
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidadmedia3")}
                                                    name="velocidadmedia3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("p3")}
                                                    name="p3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("filtrodif4")}
                                                    name="filtrodif4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("medidas4")}
                                                    name="medidas4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad4_0")}
                                                    name="velocidad4_0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad4_1")}
                                                    name="velocidad4_1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad4_2")}
                                                    name="velocidad4_2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad4_3")}
                                                    name="velocidad4_3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad4_4")}
                                                    name="velocidad4_4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad4_5")}
                                                    name="velocidad4_5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad4_6")}
                                                    name="velocidad4_6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad4_7")}
                                                    name="velocidad4_7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidadmedia4")}
                                                    name="velocidadmedia4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("p4")}
                                                    name="p4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("filtrodif5")}
                                                    name="filtrodif5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("medidas5")}
                                                    name="medidas5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad5_0")}
                                                    name="velocidad5_0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad5_1")}
                                                    name="velocidad5_1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad5_2")}
                                                    name="velocidad5_2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad5_3")}
                                                    name="velocidad5_3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad5_4")}
                                                    name="velocidad5_4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad5_5")}
                                                    name="velocidad5_5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad5_6")}
                                                    name="velocidad5_6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad5_7")}
                                                    name="velocidad5_7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidadmedia5")}
                                                    name="velocidadmedia5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("p5")}
                                                    name="p5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("filtrodif6")}
                                                    name="filtrodif6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("medidas6")}
                                                    name="medidas6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad6_0")}
                                                    name="velocidad6_0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad6_1")}
                                                    name="velocidad6_1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad6_2")}
                                                    name="velocidad6_2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad6_3")}
                                                    name="velocidad6_3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad6_4")}
                                                    name="velocidad6_4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad6_5")}
                                                    name="velocidad6_5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad6_6")}
                                                    name="velocidad6_6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad6_7")}
                                                    name="velocidad6_7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidadmedia6")}
                                                    name="velocidadmedia6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("p6")}
                                                    name="p6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("filtrodif7")}
                                                    name="filtrodif7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("medidas7")}
                                                    name="medidas7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad7_0")}
                                                    name="velocidad7_0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad7_1")}
                                                    name="velocidad7_1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad7_2")}
                                                    name="velocidad7_2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad7_3")}
                                                    name="velocidad7_3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad7_4")}
                                                    name="velocidad7_4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad7_5")}
                                                    name="velocidad7_5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad7_6")}
                                                    name="velocidad7_6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad7_7")}
                                                    name="velocidad7_7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidadmedia7")}
                                                    name="velocidadmedia7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("p7")}
                                                    name="p7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("filtrodif8")}
                                                    name="filtrodif8"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("medidas8")}
                                                    name="medidas8"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad8_0")}
                                                    name="velocidad8_0"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad8_1")}
                                                    name="velocidad8_1"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad8_2")}
                                                    name="velocidad8_2"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad8_3")}
                                                    name="velocidad8_3"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad8_4")}
                                                    name="velocidad8_4"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad8_5")}
                                                    name="velocidad8_5"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad8_6")}
                                                    name="velocidad8_6"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidad8_7")}
                                                    name="velocidad8_7"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("velocidadmedia8")}
                                                    name="velocidadmedia8"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell style={customColumnStyle}>
                                                <input
                                                    {...register("p8")}
                                                    name="p8"
                                                    style={{ width: "80px" }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" border='1'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Criterio de aceptacion</StyledTableCell>
                                            <StyledTableCell align="left">Velocidad media total (Vm)</StyledTableCell>
                                            <StyledTableCell align="left">Criterio de aceptacion</StyledTableCell>
                                            <StyledTableCell align="left" colSpan='2'>Resultado</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                <InputLabel htmlFor="outlined-age-native-simple">0,36  Vm  0,54 </InputLabel>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("caudal")}
                                                    name="caudal"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InputLabel htmlFor="outlined-age-native-simple">Vm + / - 20%</InputLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("resultadoc")}
                                                        name="resultadoc"
                                                        type="checkbox"
                                                        onChange={handleResultadoc}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("resultadonc")}
                                                        name="resultadonc"
                                                        type="checkbox"
                                                        onChange={handleResultadonc}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel htmlFor="outlined-age-native-simple">TEST DE INTEGRIDAD DE FILTROS ABSOLUTOS ® (H.E.P.A.)</InputLabel>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" border='1'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Criterio de aceptacion</StyledTableCell>
                                            <StyledTableCell align="left">Nro de Filtro</StyledTableCell>
                                            <StyledTableCell align="left">Nro de Serie</StyledTableCell>
                                            <StyledTableCell align="left">Valor obtenido</StyledTableCell>
                                            <StyledTableCell align="left" colSpan='2'>Resultado</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                <input
                                                    {...register("concentracion")}
                                                    name="concentracion"
                                                    className={styles.inputMaterial}
                                                /><br />% concentracion antes del filtro
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndefiltro1")}
                                                    name="ndefiltro1"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndeserie1")}
                                                    name="ndeserie1"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("valoroptenido1")}
                                                    name="valoroptenido1"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testc1")}
                                                        name="testc1"
                                                        type="checkbox"
                                                        onChange={handleTestc1}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testnc1")}
                                                        name="testnc1"
                                                        type="checkbox"
                                                        onChange={handleTestnc1}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndefiltro2")}
                                                    name="ndefiltro2"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell>
                                                <input
                                                    {...register("ndeserie2")}
                                                    name="ndeserie2"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("valoroptenido2")}
                                                    name="valoroptenido2"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testc2")}
                                                        name="testc2"
                                                        type="checkbox"
                                                        onChange={handleTestc2}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testnc2")}
                                                        name="testnc2"
                                                        type="checkbox"
                                                        onChange={handleTestnc2}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndefiltro3")}
                                                    name="ndefiltro3"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndeserie3")}
                                                    name="ndeserie3"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("valoroptenido3")}
                                                    name="valoroptenido3"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testc3")}
                                                        name="testc3"
                                                        type="checkbox"
                                                        onChange={handleTestc3}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testnc3")}
                                                        name="testnc3"
                                                        type="checkbox"
                                                        onChange={handleTestnc3}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndefiltro4")}
                                                    name="ndefiltro4"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndeserie4")}
                                                    name="ndeserie4"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("valoroptenido4")}
                                                    name="valoroptenido4"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testc4")}
                                                        name="testc4"
                                                        type="checkbox"
                                                        onChange={handleTestc4}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testnc4")}
                                                        name="testnc4"
                                                        type="checkbox"
                                                        onChange={handleTestnc4}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndefiltro5")}
                                                    name="ndefiltro5"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndeserie5")}
                                                    name="ndeserie5"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("valoroptenido5")}
                                                    name="valoroptenido5"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testc5")}
                                                        name="testc5"
                                                        type="checkbox"
                                                        onChange={handleTestc5}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testnc5")}
                                                        name="testnc5"
                                                        type="checkbox"
                                                        onChange={handleTestnc5}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndefiltro6")}
                                                    name="ndefiltro6"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndeserie6")}
                                                    name="ndeserie6"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("valoroptenido6")}
                                                    name="valoroptenido6"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testc6")}
                                                        name="testc6"
                                                        type="checkbox"
                                                        onChange={handleTestc6}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testnc6")}
                                                        name="testnc6"
                                                        type="checkbox"
                                                        onChange={handleTestnc6}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>


                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndefiltro7")}
                                                    name="ndefiltro7"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndeserie7")}
                                                    name="ndeserie7"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("valoroptenido7")}
                                                    name="valoroptenido7"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testc7")}
                                                        name="testc7"
                                                        type="checkbox"
                                                        onChange={handleTestc7}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testnc7")}
                                                        name="testnc7"
                                                        type="checkbox"
                                                        onChange={handleTestnc7}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndefiltro8")}
                                                    name="ndefiltro8"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndeserie8")}
                                                    name="ndeserie8"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("valoroptenido8")}
                                                    name="valoroptenido8"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testc8")}
                                                        name="testc8"
                                                        type="checkbox"
                                                        onChange={handleTestc8}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testnc8")}
                                                        name="testnc8"
                                                        type="checkbox"
                                                        onChange={handleTestnc8}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndefiltro9")}
                                                    name="ndefiltro9"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("ndeserie9")}
                                                    name="ndeserie9"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("valoroptenido9")}
                                                    name="valoroptenido9"
                                                    className={styles.inputMaterial}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testc9")}
                                                        name="testc9"
                                                        type="checkbox"
                                                        onChange={handleTestc9}
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("testnc9")}
                                                        name="testnc9"
                                                        type="checkbox"
                                                        onChange={handleTestnc9}
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12}>
                            TEST DE CONTEO DE PARTICULAS
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" border='1'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="left">Norma y Clase</StyledTableCell>
                                            <StyledTableCell align="left">Criterio de aceptacion</StyledTableCell>
                                            <StyledTableCell align="left">Valor obtenido</StyledTableCell>
                                            <StyledTableCell align="left">ResultadoC</StyledTableCell>
                                            <StyledTableCell align="left">ResultadoNC</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell rowSpan='2'>
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="customized table">
                                                        <TableBody>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    As Built
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("built")}
                                                                            name="built"
                                                                            type="checkbox"
                                                                        />
                                                                        C
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    At Rest
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("rest")}
                                                                            name="rest"
                                                                            type="checkbox"
                                                                        />
                                                                        C
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    In Oper
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("oper")}
                                                                            name="oper"
                                                                            type="checkbox"
                                                                        />
                                                                        C
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    Nro de Personas
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("personas")}
                                                                            name="personas"
                                                                            type="checkbox"
                                                                        />
                                                                        C
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </StyledTableCell>
                                            <StyledTableCell rowSpan='2'>
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="customized table">
                                                        <TableBody>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    ISO Clase 5
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("isoc5")}
                                                                            name="isoc5"
                                                                            type="checkbox"
                                                                        />
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    ISO Clase 6
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("isoc6")}
                                                                            name="isoc6"
                                                                            type="checkbox"
                                                                        />
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    ISO Clase 7
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("isoc7")}
                                                                            name="isoc7"
                                                                            type="checkbox"
                                                                        />
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    ISO Clase 8
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("isoc8")}
                                                                            name="isoc8"
                                                                            type="checkbox"
                                                                        />
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    GMP Grabado A
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("gmpa")}
                                                                            name="gmpa"
                                                                            type="checkbox"
                                                                        />
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    GMP Grabado B
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("gmpb")}
                                                                            name="gmpb"
                                                                            type="checkbox"
                                                                        />
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    GMP Grabado C
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("gmpc")}
                                                                            name="gmpc"
                                                                            type="checkbox"
                                                                        />
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                            <StyledTableRow>
                                                                <StyledTableCell>
                                                                    GMP Grabado D
                                                                </StyledTableCell>

                                                                <StyledTableCell align="right">
                                                                    <label>
                                                                        <input
                                                                            {...register("gmpd")}
                                                                            name="gmpd"
                                                                            type="checkbox"
                                                                        />
                                                                    </label>
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("cerocinco")}
                                                    name="cerocinco"
                                                    className={styles.inputMaterial}
                                                    label="0,5"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("promediomaximo")}
                                                    name="promediomaximo"
                                                    className={styles.inputMaterial}
                                                    label="Promedio Maximo"
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("rparticulasc")}
                                                        name="rparticulasc"
                                                        type="checkbox"
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("rparticulasnc")}
                                                        name="rparticulasnc"
                                                        type="checkbox"
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                <input
                                                    {...register("cincoum")}
                                                    name="cincoum"
                                                    className={styles.inputMaterial}
                                                    label="5 um"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <input
                                                    {...register("promediomaximo1")}
                                                    name="promediomaximo1"
                                                    className={styles.inputMaterial}
                                                    label="Promedio maximo"
                                                />
                                            </StyledTableCell>


                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("crimi")}
                                                        name="crimi"
                                                        type="checkbox"
                                                    />
                                                    C
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("crima")}
                                                        name="crima"
                                                        type="checkbox"
                                                    />
                                                    NC
                                                </label>
                                            </StyledTableCell>

                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12}>
                            INSTRUMENTAL
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" border='1'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Instrumento</StyledTableCell>
                                            <StyledTableCell align="left">Tag</StyledTableCell>
                                            <StyledTableCell align="left">Instrumento</StyledTableCell>
                                            <StyledTableCell align="left">Tag</StyledTableCell>
                                            <StyledTableCell align="left">Instrumento</StyledTableCell>
                                            <StyledTableCell align="left">Tag</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="right">
                                                <label>
                                                    <input
                                                        {...register("balometro")}
                                                        name="balometro"
                                                        type="checkbox"
                                                    />
                                                </label>
                                            </StyledTableCell>

                                            <StyledTableCell>
                                                ANE-001
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("fotometro")}
                                                    name="fotometro"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                FOTO-001
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("manometro")}
                                                    name="manometro"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                MANO-004
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("balometro1")}
                                                    name="balometro1"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                ANE-003
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("fotometro1")}
                                                    name="fotometro1"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                FOTO-002
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("decibelimetro")}
                                                    name="decibelimetro"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                DEC-001
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("contdepart")}
                                                    name="contdepart"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                CP-002
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("fotometro2")}
                                                    name="fotometro2"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                FOTO-003
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("luxometro")}
                                                    name="luxometro"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                LUX-001
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("contdepart1")}
                                                    name="contdepart1"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                CP-003
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("manometro1")}
                                                    name="manometro1"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                MANO-002
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("luxometro1")}
                                                    name="luxometro1"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                LUX-002
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("contdepart2")}
                                                    name="contdepart2"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                CP-004
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("manometro2")}
                                                    name="manometro2"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                MANO-003
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <input
                                                    {...register("termohigremetro")}
                                                    name="termohigremetro"
                                                    type="checkbox"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                TERM-001
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <TextareaAutosize
                                {...register("observaciones")}
                                name="observaciones"
                                aria-label="empty textarea"
                                className={styles.inputMaterial}
                                minRows={3} label="Observaciones"
                                placeholder="Observaciones"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <input
                                {...register("realizadopor")}
                                name="realizadopor"
                                className={styles.inputMaterial}
                                label="Realizado por"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <input
                                {...register("fechacreate")}
                                name="fechacreate"
                                className={styles.inputMaterial}
                                label="Fecha create"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <input
                                {...register("id")}
                                name="fechacreate"
                                className={styles.inputMaterial}
                                label="Fecha create"
                                type="hidden"
                            />
                        </Grid>

                    </Grid>

                    <button type="submit">Submit</button>
                </form>
            </Paper>
        </div>
    )
};

export default Verdesadd;
