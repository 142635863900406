import React from 'react';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';

import { AppBar, CircularProgress, IconButton, Toolbar, Breadcrumbs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import UserServiceCore from "../../services/user.service";
import UserService from "./services/crm.service";
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    codigo: yup
        .string('Codigo requerido')
        .required('Codigo requerido'),
    descorta: yup
        .string('Descrpcion corta requerida')
        .required('Descrpcion corta requerida'),
    marca: yup
        .string('Marca requerida')
        .required('Marca requerida'),
    modelo: yup
        .string('Modelo requreido ')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Modelo requerido'),
});

export default function CuentasAddmod(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [dato, setDato] = useState([]);

    //inicializacion de variables y validacion
    var d = new Date();
    var finaldate = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + (d.getDate()).toString().padStart(2, "0")

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            codigo: '',
            nombre: '',
            contacto_comercial: '',
            descripcion_comercial: '',
            descripcion_cobranzas: '',
            descripcion_adicional: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [plantas, setPlantas] = useState([
        { id: 'Mitre', nombre: 'Mitre' },
        { id: 'Sabattini', nombre: 'Sabattini' },
    ]);

    const [tipos, setTipos] = useState([
        { id: 'Uso Unico Personal', nombre: 'Uso Unico Personal' },
        { id: 'Uso Generico Comunitario', nombre: 'Uso Generico Comunitario' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodMaquina(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/crm");
    }

    useEffect(() => {

        const GetData = async () => {

            if (id) {
                try {
                    setIsLoading(true);
                    const response = await UserService.getCuenta(id);
                    if (response) {
                        formik.setValues(response.data);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                } catch (e) {
                    console.log(e)
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            }

        }
        GetData();

    }, []);

    useEffect(() => {
        const GetSucursales = async () => {
            try {
                setIsLoading(true);
                const result = await UserServiceCore.getSucursales();
                if (result) {
                    setDato(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e)
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetSucursales();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/cuentas")
    }

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => inicio()}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Cuenta
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <TextField
                            name="codigo"
                            className={styles.inputMaterial}
                            label="Codigo"
                            autoFocus={true}
                            autoComplete='off'
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                            helperText={formik.touched.codigo && formik.errors.codigo}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="nombre"
                            className={styles.inputMaterial}
                            label="Empresa"
                            autoFocus={true}
                            autoComplete='off'
                            value={formik.values.nombre}
                            onChange={formik.handleChange}
                            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                            helperText={formik.touched.nombre && formik.errors.nombre}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="contacto_comercial"
                            className={styles.inputMaterial}
                            label="Contacto Comercial"
                            autoFocus={true}
                            autoComplete='off'
                            value={formik.values.contacto_comercial}
                            onChange={formik.handleChange}
                            error={formik.touched.contacto_comercial && Boolean(formik.errors.contacto_comercial)}
                            helperText={formik.touched.contacto_comercial && formik.errors.contacto_comercial}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="descripcion_comercial"
                            multiline
                            rows={4}
                            className={styles.inputMaterial}
                            label="Descripción Comercial"
                            value={formik.values.descripcion_comercial}
                            onChange={formik.handleChange}
                            error={formik.touched.descripcion_comercial && Boolean(formik.errors.descripcion_comercial)}
                            helperText={formik.touched.descripcion_comercial && formik.errors.descripcion_comercial}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="descripcion_cobranzas"
                            multiline
                            rows={4}
                            className={styles.inputMaterial}
                            label="Descripción de Cobranzas"
                            value={formik.values.descripcion_cobranzas}
                            onChange={formik.handleChange}
                            error={formik.touched.descripcion_cobranzas && Boolean(formik.errors.descripcion_cobranzas)}
                            helperText={formik.touched.descripcion_cobranzas && formik.errors.descripcion_cobranzas}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="descripcion_adicional"
                            multiline
                            rows={4}
                            className={styles.inputMaterial}
                            label="Descripción Adicional"
                            value={formik.values.descripcion_adicional}
                            onChange={formik.handleChange}
                            error={formik.touched.descripcion_adicional && Boolean(formik.errors.descripcion_adicional)}
                            helperText={formik.touched.descripcion_adicional && formik.errors.descripcion_adicional}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="data1"
                            type="hidden"
                            value={formik.values.data1}
                        />

                        <TextField
                            name="recurso"
                            type="hidden"
                            value={formik.values.recurso}
                        />

                    </Grid>

                    <SnackbarMessage
                        open={open}
                        message={errorMessage}
                        severity="success"
                        onClose={handleCloseMessage}
                    />

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>
        </Paper>
    );
}