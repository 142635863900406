import React, { useState, useEffect } from "react";

import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import Container from '@mui/material/Container';

import { Grid, ThemeProvider, StyledEngineProvider, } from "@mui/material";
import MaterialTable from 'material-table';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import Breadcrumbs from '@mui/material/Breadcrumbs';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { Modal, Button } from '@mui/material';

const Products = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [total, setTotal] = useState([]);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [data, setData] = useState([]);
  const [modalEliminar, setModalEliminar] = useState(false);
  const styles = useStyles();

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    nombre: '',
    email: '',
  })

  const peticionDelete = async () => {
    const response = await UserService.delProduct(consolaSeleccionada.id);
    var data = response.data;
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const peticionGet = async () => {
    const result = await UserService.getProducts();
    setData(result.data);
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ?
      props.history.push(process.env.PUBLIC_URL + "/productsmod/" + consola.id) :
      abrirCerrarModalEliminar()
  }

  useEffect(() => {

    const GetData = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getProducts();
        if (result.code !== 401) {
          setData(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
        props.history.push(process.env.PUBLIC_URL + "/login");
      }
    }
    GetData();

  }, []);

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el Producto <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const abrirCerrarModalInsertar = () => {
    props.history.push(process.env.PUBLIC_URL + "/productsmod/")
  }

  const Inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/")
  }

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/master")
  }

  return (

    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Productos
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <Breadcrumbs aria-label="breadcrumb">
        <Button style={{ color: "#fff", backgroundColor: "#004F9E" }} variant="contained" onClick={() => abrirCerrarModalInsertar()}>Nuevo Producto</Button>
      </Breadcrumbs>

      <ThemeProvider theme={theme}>
        <div style={{ maxWidth: "100%", direction }}>

          <MaterialTable
            title=""

            localization={{
              toolbar: {
                searchPlaceholder: 'Buscar...'
              },
              header: {
                actions: 'Acciones'
              },
              body: {
                editRow: {
                  deleteText: 'Estas seguro de eliminar este registro ?'
                }
              },

            }}

            columns={[
              {
                title: 'Producto',
                field: 'nombre',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
              {
                title: 'Precio',
                field: 'precio',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
            ]}
            data={data}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Editar',
                onClick: (event, data) => seleccionarConsola(data, 'Editar'),
              },
              {
                icon: 'delete',
                tooltip: 'Eliminar',
                onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
              }
            ]}
            options={{
              headerStyle: {
                backgroundColor: '#004F9E',
                color: '#FFF',
              },
              search: true,
              actionsColumnIndex: -1,
              grouping: true
            }}
          />

        </div>
      </ThemeProvider>
      <Modal
        open={modalEliminar}
        onClose={abrirCerrarModalEliminar}>
        {bodyEliminar}
      </Modal>

    </Container>

  );
};

export default Products;
