import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { mdiWrench } from '@mdi/js';
import { mdiMicrosoftExcel } from '@mdi/js';
import { mdiToolbox } from '@mdi/js';
import { mdiTools } from '@mdi/js';

import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import { mdiDoorOpen, mdiDoorClosed } from '@mdi/js';

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

const Fichar = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);
    const [lat, setLat] = useState("");
    const [lon, setLon] = useState("");
    const [value, setValue] = useState("");
    const [content, setContent] = useState("");

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    console.log("Latitude is :", position.coords.latitude);
                    console.log("Longitude is :", position.coords.longitude);
                    setLat(position.coords.latitude);
                    setLon(position.coords.longitude);
                },
                function (error) {
                    console.error("Error Code = " + error.code + " - " + error.message);
                }
            );
        } else {
            console.log("Geolocation Not Available");
        }
    }, []);

    const { showUser, showMaster, showProducts } = useContext(AuthContext);

    const classes = useStyles();

    const handleClick = (e) => {
        e.preventDefault();
        setIsLoading(true);

        var not = value;
        UserService.setEntrar(lat, lon, not).then(
            response => {
                setIsLoading(false);
                console.log(response.status);
                if (response.status === 401) {
                    logOut();
                    window.location.href = `${process.env.PUBLIC_URL}/home`;
                } else {
                    setContent(response.data);
                }
            },
            error => {
                setIsLoading(false);
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );
    };

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/controlfichadas")
    }

    const logOut = () => {
        AuthService.logout();
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Fichada
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <sections>
                {showUser && (
                    <>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/fichada`} className="nav-link">
                                            <Icon
                                                path={mdiDoorOpen}
                                                title="Entrada"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Entrada
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/fichar`} className="nav-link">
                                            <Icon
                                                path={mdiDoorClosed}
                                                title="Salida"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Salida
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    </>
                )}
                <div>Nota: <textarea id="nota" name="nota" cols="20" rows="5" onChange={handleChange}></textarea></div>

            </sections>
        </>
    );
};

export default Fichar;
