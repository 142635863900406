import React from 'react';

import Paper from '@mui/material/Paper';

import UserService from "../../services/user.service";
import { useParams } from 'react-router';
import { useState, useEffect } from 'react'

export default function Qrcon(props) {
    const { id } = useParams();
    const [consolaSeleccionada] = useState({
        data1: '',
    })

    useEffect(() => {
        (async () => {
            try {
                const response = await UserService.getQrcon(id);
                if (response) {
                    var dataNueva = response.data;
                    dataNueva.map(consola => {
                        consolaSeleccionada.data1 = consola.data;
                    })
                    console.log(consolaSeleccionada.data1);
                    window.location.href = consolaSeleccionada.data1;
                } else {
                    props.history.push(process.env.PUBLIC_URL + "/login");
                }
            } catch (e) {
                console.log(e);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        })();
    }, []);

    return (
        <Paper>
        </Paper>
    );
}