import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { mdiWrench } from '@mdi/js';
import { mdiMicrosoftExcel } from '@mdi/js';
import { mdiToolbox } from '@mdi/js';
import { mdiTools } from '@mdi/js';
import { mdiCar } from '@mdi/js';

import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

const Planera = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const { showUser, OwnedProducts } = useContext(AuthContext);

    const classes = useStyles();

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    return (
        <>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Planera
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <sections>
                {showUser && (
                    <>

                        {OwnedProducts.find(product => product.name === "Planera") && (
                            OwnedProducts.find(product => product.name === "Planera").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Planera").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Planera").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Planera").modules.find(
                                    module => module.name === "Planos" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                < items >
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/planos`} className="nav-link">
                                                    <Icon
                                                        path={mdiToolbox}
                                                        title="Herramientas"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Planos
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>

                            )}

                        {OwnedProducts.find(product => product.name === "Planera") && (
                            OwnedProducts.find(product => product.name === "Planera").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Planera").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Planera").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Planera").modules.find(
                                    module => module.name === "Dibujantes" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/dibujantes`} className="nav-link">
                                                    <Icon
                                                        path={mdiWrench}
                                                        title="Dibujantes"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Dibujantes
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                    </>
                )}

            </sections >
        </>
    );
};

export default Planera;
