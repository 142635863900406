import authHeaderMitre from "../../../services/auth-header_mitre";
import { handleResponse } from '../../../services/handle-response';

const API_URL_MITRE = 'https://api.casiba.net/api/v1/';
const user = JSON.parse(localStorage.getItem('user3'));

const getCuenta = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'get_cuenta', requestOptions).then(handleResponse));
};

const getCuentas = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'get_cuentas', requestOptions).then(handleResponse));
};

const getSensor = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'get_sensor', requestOptions).then(handleResponse));
};

export default {

  getCuenta,
  getCuentas,
  getSensor,

};
