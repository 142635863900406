import React from 'react';
import { useState, useEffect, useContext } from 'react'

import { AppBar, Button, CircularProgress, IconButton, Grid, Toolbar, Breadcrumbs, Paper, Typography, Modal } from '@mui/material';
import { TextField, ThemeProvider, StyledEngineProvider } from "@mui/material";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AuthContext } from '../../components/AuthContext';

import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import MaterialTable from 'material-table';

import UserService from "./services/compras.service";

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

export default function Transacciones(props) {
  const [isLoading, setIsLoading] = useState(true)
  const { OwnedProducts } = useContext(AuthContext);

  const [modalAprobar, setModalAprobar] = useState(false);
  const [modalRechazar, setModalRechazar] = useState(false);
  const [nota, setNota] = useState("");

  const [rowDataAprobar, setRowDataAprobar] = useState(null);
  const [rowDataRechazar, setRowDataRechazar] = useState(null);

  const styles = useStyles();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    codigo: '',
  })

  const peticionDelete = async () => {
    const response = await UserService.delRecurso(consolaSeleccionada.id, 'herramienta');
    var data = response.data;
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const peticionGet = async () => {
    const result = await UserService.getTransacciones();
    setData(result);
  }

  const abrirCerrarModalInsertar = () => {
    props.history.push(process.env.PUBLIC_URL + "/herramientasaddmod/")
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? props.history.push(process.env.PUBLIC_URL + "/herramientasaddmod/" + consola.id) : abrirCerrarModalEliminar()
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  useEffect(() => {
    GetData();
  }, []);

  const GetData = async () => {
    try {
      setIsLoading(true);
      const result = await UserService.getTransacciones();
      if (result) {
        setData(result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      handleShowMessage('500');
    }
  }

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar la Herramienta <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const bodyAprobar = (
    <div className={styles.modal}>
      <p>Ingresa una nota al aprobar la transacción:</p>
      <TextField
        fullWidth
        variant="outlined"
        value={nota}
        onChange={(e) => setNota(e.target.value)}
      />
      <div align="right">
        <Button color="secondary" onClick={() => peticionAprobar(nota)} >Aprobar</Button>
        <Button onClick={() => cerrarModalAprobar()}>Cancelar</Button>
      </div>
    </div>
  );

  const bodyRechazar = (
    <div className={styles.modal}>
      <p>Ingresa una nota al rechazar la transacción:</p>
      <TextField
        fullWidth
        variant="outlined"
        value={nota}
        onChange={(e) => setNota(e.target.value)}
      />
      <div align="right">
        <Button color="secondary" onClick={() => peticionRechazar(nota)} >Rechazar</Button>
        <Button onClick={() => cerrarModalRechazar()}>Cancelar</Button>
      </div>
    </div>
  );

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/compras")
  }

  const peticionAprobar = async (nota) => {
    if (rowDataAprobar) {
      const rowData = rowDataAprobar;
      const result = await UserService.aprobarTransaccion(rowData, '1', nota); // 1 aprobado
      cerrarModalAprobar();
      GetData();
    }
  };

  const peticionRechazar = async (nota) => {
    if (rowDataRechazar) {
      const rowData = rowDataRechazar;
      const result = await UserService.aprobarTransaccion(rowData, '2', nota); // 2 rechazado
      cerrarModalRechazar();
      GetData();
    }
  };

  const handleAprobar = async (rowData) => {
    setRowDataAprobar(rowData); // Guarda los datos de rowData
    abrirModalAprobar();
  };

  const handleRechazar = async (rowData) => {
    setRowDataRechazar(rowData); // Guarda los datos de rowData
    abrirModalRechazar();
  };

  const abrirModalAprobar = () => {
    setModalAprobar(true);
  };

  const cerrarModalAprobar = () => {
    setModalAprobar(false);
  };

  const abrirModalRechazar = () => {
    setModalRechazar(true);
  };

  const cerrarModalRechazar = () => {
    setModalRechazar(false);
  };

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Transacciones
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ maxWidth: "100%", direction }}>
            <Grid container>
              <Grid item xs={12}>

                <MaterialTable
                  title=""

                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Buscar...'
                    },
                    header: {
                      actions: 'Acciones'
                    },
                    body: {
                      editRow: {
                        deleteText: 'Estas seguro de eliminar este registro ?'
                      }
                    },
                  }}

                  columns={[
                    {
                      title: 'Numero',
                      field: 'numero',
                      defaultGroupOrder: 0,
                    },
                    {
                      title: 'Nombre',
                      field: 'nombre',
                    },
                    {
                      title: 'Descripcion',
                      field: 'descripcion',
                    },
                    {
                      title: 'Estado',
                      field: 'estado',
                      render: (rowData) => (
                        <div>
                          {rowData.estado === '0' && (
                            <button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{
                                backgroundColor: 'blue',
                                color: 'white',
                                fontWeight: 'bold',
                                cursor: rowData.estado !== '0' ? 'not-allowed' : 'pointer'
                              }}
                              disabled={rowData.estado !== '0'}
                              onClick={(event) => handleAprobar(rowData)}
                            >
                              Aprobar
                            </button>
                          )}
                          {rowData.estado === '1' && (
                            <button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{
                                backgroundColor: 'green',
                                color: 'white',
                                fontWeight: 'bold',
                                cursor: rowData.estado !== '0' ? 'not-allowed' : 'pointer'
                              }}
                            >
                              APROBADO
                            </button>
                          )}

                          {rowData.estado === '0' && (
                            <button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{
                                backgroundColor: 'blue',
                                color: 'white',
                                fontWeight: 'bold',
                                cursor: rowData.estado !== '0' ? 'not-allowed' : 'pointer'
                              }}
                              disabled={rowData.estado !== '0'}
                              onClick={(event) => handleRechazar(rowData)}
                            >
                              Rechazar
                            </button>
                          )}

                          {rowData.estado === '2' && (
                            <button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{
                                backgroundColor: 'red',
                                color: 'white',
                                fontWeight: 'bold',
                                cursor: rowData.estado !== '0' ? 'not-allowed' : 'pointer'
                              }}
                              disabled={rowData.estado !== '0'}
                            >
                              RECHAZADO
                            </button>
                          )}

                        </div>
                      ),
                    }
                  ]}

                  data={data}
                  options={{
                    headerStyle: {
                      backgroundColor: '#004F9E',
                      color: '#FFF',
                    },
                    search: true,
                    actionsColumnIndex: -1
                  }}
                />

                <Modal
                  open={modalEliminar}
                  onClose={abrirCerrarModalEliminar}>
                  {bodyEliminar}
                </Modal>

                <Modal open={modalAprobar} onClose={cerrarModalAprobar}>
                  {bodyAprobar}
                </Modal>

                <Modal open={modalRechazar} onClose={cerrarModalRechazar}>
                  {bodyRechazar}
                </Modal>

                <SnackbarMessage
                  open={open}
                  message={errorMessage}
                  severity="success"
                  onClose={handleCloseMessage}
                />

              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>

    </Paper>
  );
}