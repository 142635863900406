import authHeader from "./auth-header";
import authHeaderMitre from "./auth-header_mitre";
import { handleResponse } from './handle-response';
import http from "./http-common";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { openDB, deleteDB, wrap, unwrap } from 'idb';
import idbcache from 'idbcache';

//const API_URL = "http://localhost:8000/api/v1/";
//const API_URL = 'https://rh.casiba.net/api/v1/';
//const API_URL = 'https://api.devib.ar/api/v1/';
const API_URL = 'https://devapi.casiba.ar/api/v1/';
const API_URL_MITRE = 'https://api.casiba.net/api/v1/';

const user = JSON.parse(localStorage.getItem('user3'));

//trae dato de un producto
const getCore = async  () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  const data = await(fetch(API_URL + 'get_core?email=' + user.email, requestOptions).then(handleResponse));
  return data;
};

const addmodCore = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_core', requestOptions).then(handleResponse));
};

//elimina un consumo conbustible
const getBetc = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_betc', requestOptions).then(handleResponse));
};

const getBetcs = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'get_betcs', requestOptions).then(handleResponse));
};

const txtSantander = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'get_txt_santander', requestOptions).then(handleResponse));
};

const txtOtrosBancos = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'get_txt_otros_bancos', requestOptions).then(handleResponse));
};

//agreda o modifica un betc
const addmodBetc = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_betc', requestOptions).then(handleResponse));
};

//elimina un betc
const delBetc = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delbetc', requestOptions).then(handleResponse));
};

//trae lista de los archivos adjuntos de betc
const getFilesBetc = (id, documento) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('document', documento);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getfiles_betc', requestOptions).then(handleResponse));
};

const uploadBetc = (id, file, document, onUploadProgress) => {
  let data = new FormData();
  data.append("images", file);
  data.append('email', user.email);
  data.append('document', document);
  data.append('id', id);

  return http.post("/uploadfiles_betc", data, { headers: authHeader(), onUploadProgress, });
};

//elimina un archivo
const delImageBetc = (id, documento, key) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('key', key);
  data.append('document', documento);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delimage_betc', requestOptions).then(handleResponse));
};


//trae lista de combustible segun id
const getCombustibleid = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getcombustibleid', requestOptions).then(handleResponse));
};

//elimina un consumo conbustible
const delCombustible = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delcombustible', requestOptions).then(handleResponse));
};

//trae datos de una carga de combustible
const getCombustible = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getcombustible', requestOptions).then(handleResponse));
};

//trae lista de combustibles
const getCombustibles = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'combustibles', requestOptions).then(handleResponse));
};

//agreda o modifica un combustible
const addmodCombustible = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodcombustible', requestOptions).then(handleResponse));
};

//trae lista de vehiculos segun id
const getVehiculosid = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getvehiculosid', requestOptions).then(handleResponse));
};

//trae listado de roles
const getRoles = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getRoles', requestOptions).then(handleResponse));
};

//trae datos pora el informe
const getInforme = (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getinforme', requestOptions).then(handleResponse));
};

/**
 * trae lista de empleados
 * @returns 
 */
const getEmpleados = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_empleados', requestOptions).then(handleResponse));
};

/**
 * trae datos del empleado
 * @returns 
 */
const getEmpleado = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_empleado', requestOptions).then(handleResponse));
};

//trae lista de vehiculos
const getVehiculos = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'vehiculos', requestOptions).then(handleResponse));
};

//trae datos de un vehiculo
const getVehiculo = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getvehiculo', requestOptions).then(handleResponse));
};

//agreda o modifica un vehiculo
const addmodVehiculo = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodvehiculo', requestOptions).then(handleResponse));
};

//elimina un vehiculo
const delVehiculo = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delvehiculo', requestOptions).then(handleResponse));
};

const getFacturacion = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'facturacion', requestOptions).then(handleResponse));
};

const getRendicion = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'rendicion', requestOptions).then(handleResponse));
};

const getRpendientes = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'rpendiente', requestOptions).then(handleResponse));
};

const getFsinremitos = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'fsinremitos', requestOptions).then(handleResponse));
};

const getRefacturacion = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'refacturacion', requestOptions).then(handleResponse));
};

const getStock = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'stock', requestOptions).then(handleResponse));
};

//trae lista de areas
const getAreas = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getareas', requestOptions).then(handleResponse));
};

//trae datos de una area
const getArea = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getarea', requestOptions).then(handleResponse));
};

//agreda o modifica un area
const addmodArea = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodarea', requestOptions).then(handleResponse));
};

//elimina una area
const delArea = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delarea', requestOptions).then(handleResponse));
};

//trae lista de los archivos adjuntos
const getFiles = (id, documento) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('document', documento);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getfiles', requestOptions).then(handleResponse));
};

//elimina un archivo
const delImage = (id, documento, key) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('key', key);
  data.append('document', documento);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delimage', requestOptions).then(handleResponse));
};

/*****************************************************************************
 * CODIGOS QR   
 *****************************************************************************/
//consulta el codigo qr por su id
const getQrid = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getqrid', requestOptions).then(handleResponse));
};

//consulta lectura codigo qr
const getQrcon = (id, tipo) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('tipo', tipo);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getqrcon', requestOptions).then(handleResponse));
};

//trae lista de codigos qrs
const getQrs = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getqrs', requestOptions).then(handleResponse));
};

//agreda o modifica un qr
const addmodQr = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodqr', requestOptions).then(handleResponse));
};

//elimina un qr
const delQr = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delqr', requestOptions).then(handleResponse));
};

/*****************************************************************************
 * FIN CODIGOS QR   
 *****************************************************************************/

/**
 * Get data empleado id
 * @returns 
 */
const getEmpleadoid = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_empleado_id?id=' + id , requestOptions).then(handleResponse));
};

/**
 * Get data empleados masivas
 * @returns 
 */
const getEmpleadosMasivas = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_empleados_masivas?email=' + user.email, requestOptions).then(handleResponse));
};

//agreda o modifica un empleado
const addModEmpleado = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_empleado', requestOptions).then(handleResponse));
};

//elimina un empleado
const delEmpleado = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_empleado', requestOptions).then(handleResponse));
};

/**
 * Get data horarios
 * @returns 
 */
const getHorarios = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_horarios?email=' + user.email , requestOptions).then(handleResponse));
};

/**
 * Valid emails, not duplicated 
 * @param {*} email 
 * @param {*} producto 
 * @returns 
 */
const validEmails = (email) => {
  const data = new FormData();
  data.append('email', email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'validEmail', requestOptions).then(handleResponse));
};

/**
 * Valid empresa, not duplicated 
 * @param {*} empresa 
 * @param {*} producto 
 * @returns 
 */
 const validEmpresa = (empresa) => {
  const data = new FormData();
  data.append('empresa', empresa);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'validEmpresa', requestOptions).then(handleResponse));
};

//agreda o modifica un horario
const addmodHorario= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_horario', requestOptions).then(handleResponse));
};

/**
 * Register a new user 
 * @param {*} row 
 * @returns 
 */
const register = (row) => {
  const data = new FormData();
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'register', requestOptions).then(handleResponse));
};

const addmodModule = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodModule', requestOptions).then(handleResponse));
};

//lista de modulos
const getModules = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getModules', requestOptions).then(handleResponse));
};

//trae dato de un producto
const getModuleId = async  (id, row) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  const data = await(fetch(API_URL + 'getModuleId?email=' + user.email + '&id=' + id, requestOptions).then(handleResponse));
  return data;
};

/**
 * Delete module
 * @param {*} id
 * @returns
 */
const delModule = async (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };

  return (fetch(API_URL + 'delModule', requestOptions).then(res => {
    if (res.ok) {
      return res;
    }
  }).then((res) => {
    return res;
  }).catch((error) => {
    return error
  }));

};

//lista de productos
const getProducts = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getproducts', requestOptions).then(handleResponse));
};

//trae dato de un producto
const getProductid = async  (id, row) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  const data = await(fetch(API_URL + 'getproductid?email=' + user.email + '&id=' + id, requestOptions).then(handleResponse));
  return data;
};

/**
 * Add modifi product
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
const addmodProduct = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodproduct', requestOptions).then(handleResponse));
};

/**
 * Delete producto
 * @param {*} id
 * @returns
 */
 const delProduct = async (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };

  return (fetch(API_URL + 'delproduct', requestOptions).then(res => {
    if (res.ok) {
      return res;
    }
  }).then((res) => {
    return res;
  }).catch((error) => {
    return error
  }));

};

/**
 * Active product
 * @param {*} id
 * @returns
 */
const activeProduct = async (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };

  return (fetch(API_URL + 'activeproduct', requestOptions).then(res => {
    if (res.ok) {
      return res;
    }
  }).then((res) => {
    return res;
  }).catch((error) => {
    return error
  }));

};

//trae lista de usuarios de Master
const getUsers = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getusers', requestOptions).then(handleResponse));
};

//add mod user de Master
const addmodUser = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmoduser', requestOptions).then(handleResponse));
};

//trae datos de un usuario de Master
const getUserid = async  (id, row) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  const data = await(fetch(API_URL + 'getuserid?email=' + user.email + '&id=' + id, requestOptions).then(handleResponse));
  return data;
};

/**
 * Elimina usuario de Master
 * @param {*} id
 * @returns
 */
 const delUser = async (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };

  return (fetch(API_URL + 'deluser', requestOptions).then(res => {
    if (res.ok) {
      return res;
    }
  }).then((res) => {
    return res;
  }).catch((error) => {
    return error
  }));

};

//trae lista de usuarios de Owner
const getUsuarios = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_usuarios', requestOptions).then(handleResponse));
};

//add mod user de Master
const addmodUsuario = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_usuario', requestOptions).then(handleResponse));
};

const getUsuario = async  (id, row) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  const data = await(fetch(API_URL + 'get_usuario?email=' + user.email + '&id=' + id, requestOptions).then(handleResponse));
  return data;
};

const getUsuarioProfile = async  () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  const data = await(fetch(API_URL + 'get_usuario_profile?email=' + user.email, requestOptions).then(handleResponse));
  return data;
};

const delUsuario = async (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };

  return (fetch(API_URL + 'del_usuario', requestOptions).then(res => {
    if (res.ok) {
      return res;
    }
  }).then((res) => {
    return res;
  }).catch((error) => {
    return error
  }));

};

/**
 * Get data equipos
 * @returns 
 */
const getGruposJerarquicos = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_grupos_jerarquicos?email=' + user.email , requestOptions).then(handleResponse));
};

//agreda o modifica un equipo
const addModGruposJerarquicos= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_grupos_jerarquicos', requestOptions).then(handleResponse));
};

/**
 * Get data  id
 * @returns 
 */
 const getGruposJerarquicosid = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_grupos_jerarquicos_id?id=' + id , requestOptions).then(handleResponse));
};

//elimina un 
const delGruposJerarquicos= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'del_grupos_jerarquicos', requestOptions).then(handleResponse));
};

/**
 * @returns 
 */
const getUsuariosEquipos = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_usuarios_equipos?id=' + id + '&email=' + user.email, requestOptions).then(handleResponse));
};

/**
 * Get data equipos
 * @returns 
 */
const getEquipos = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_equipos?email=' + user.email , requestOptions).then(handleResponse));
};

//agreda o modifica un equipo
const addmodEquipo= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_equipo', requestOptions).then(handleResponse));
};

const getEquipo= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_equipo', requestOptions).then(handleResponse));
};

//elimina un 
const delEquipo= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'del_equipo', requestOptions).then(handleResponse));
};

//
const assignPermission= (idusuario, idmodulo, tipo, trueofalse) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('idusuario', idusuario);
  data.append('idmodulo', idmodulo);
  data.append('tipo', tipo);
  data.append('trueofalse', trueofalse);
  //data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'set_permission', requestOptions).then(handleResponse));
};

const getPermissions= (idusuario) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('idusuario', idusuario);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_permission', requestOptions).then(handleResponse));
};

const getContexto= (idusuario) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('idusuario', idusuario);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_contexto', requestOptions).then(handleResponse));
};

const getSucursal = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_sucursal_id', requestOptions).then(handleResponse));
};

const getSucursales = (recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_sucursales', requestOptions).then(handleResponse));
};

const addmodSucursal = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_sucursal', requestOptions).then(handleResponse));
};

const delSucursal = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_sucursal', requestOptions).then(handleResponse));
};

//
const getSector = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_sector_id', requestOptions).then(handleResponse));
};

const getSectores = (recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_sectores', requestOptions).then(handleResponse));
};

const addmodSector = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_sector', requestOptions).then(handleResponse));
};

const delSector = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_sector', requestOptions).then(handleResponse));
};

export default {
  getCore,
  addmodCore,
  assignPermission,  
  getPermissions,  
  addModGruposJerarquicos,
  getContexto,

  getGruposJerarquicosid,
  getGruposJerarquicos,
  delGruposJerarquicos,

  getUsuariosEquipos,
  getEquipo,
  getEquipos,
  addmodEquipo,
  delEquipo,

  activeProduct,
  getProducts,
  delProduct,
  getProductid,
  addmodProduct,

  getModules,
  getModuleId,
  addmodModule,
  delModule,

  getUsers,
  getUserid,
  addmodUser,
  delUser,

  getUsuarios,
  addmodUsuario,
  getUsuario,
  getUsuarioProfile,
  delUsuario,

  register,
  getHorarios,
  addmodHorario,
  getEmpleadosMasivas,
  getEmpleados,
  getEmpleadoid,
  addModEmpleado,
  delEmpleado,

  getQrcon,
  getQrs,
  addmodQr,
  getQrid,
  delQr,

  getEmpleados,
  getEmpleado,

  getBetc,
  getBetcs,
  addmodBetc,
  getFilesBetc,
  uploadBetc,
  delImageBetc,
  
  getRendicion,
 
  getCombustibleid,
  getCombustible,
  getCombustibles,
  delCombustible,
  addmodCombustible,
  getVehiculosid,

  getAreas,
  getArea,
  addmodArea,
  delArea,

  getVehiculos,
  getVehiculo,
  addmodVehiculo,
  delVehiculo,

  getFacturacion,
  getRefacturacion,
  getRpendientes,
  getFsinremitos,
  getStock,

  getRoles,
  getInforme,

  getFiles,
  delImage,

  validEmails,
  validEmpresa,

  getSucursales,
  getSucursal,
  addmodSucursal,
  delSucursal,

  getSectores,
  getSector,
  addmodSector,
  delSector,

  txtSantander,
  txtOtrosBancos,
};
