import React, { useState, useEffect, useRef, useContext } from "react";
import UploadService from "../../services/user.service";
import AuthService from "../../services/auth.service";

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles, useTheme } from '@mui/styles';
import UserService from "../../services/user.service";

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import { AuthContext } from '../../components/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 400,
    backgroundColor: '#fff',
  },
}));

const UploadFilesBetc = ({ id, documento, uniqid }) => {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const progressInfosRef = useRef(null)
  const [data, setData] = useState([]);
  const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);

  useEffect(() => {
    
    UploadService.getFilesBetc(id, documento).then((response) => {
      setFileInfos(response.data);
    });


  }, []);

  const selectFiles1 = (event) => {
    setSelectedFiles(event.target.files);
    setProgressInfos({ val: [] });
  };

  const selectFiles = (event) => {
    const files = event.target.files;

    // Verificar si hay archivos seleccionados
    if (files.length > 0) {
      // Configurar selectedFiles
      setSelectedFiles(files);
      setProgressInfos({ val: [] });

      // Iniciar automáticamente la carga de archivos
      uploadFiles(files);
    }
  };

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    try {
      await UploadService.uploadBetc(id, file, documento, uniqid, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos({ val: _progressInfos });
      });
        setMessage((prevMessage) => ([
          ...prevMessage,
          "Archivo subido...: " + file.name,
        ]));
    } catch {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });

      setMessage((prevMessage_1) => ([
        ...prevMessage_1,
          "No pudimos subir el archivo: " + file.name,
        ]));
    }
  };

  const eliminar = async (key,documento, id) => {
    const response = await UserService.delImageBetc(id,documento, key);
    setData(data.concat(response.data))
    console.log(id);
    //cerrarEditar()

    UploadService.getFilesBetc(id, documento).then((response) => {
      setFileInfos(response.data);
    })

  }
  
  const uploadFiles = (filess) => {
    //const files = Array.from(selectedFiles);
    const files = Array.from(filess);

    let _progressInfos = files.map(file => ({ percentage: 0, fileName: file.name }));

    progressInfosRef.current = {
      val: _progressInfos,
    }

    const uploadPromises = files.map((file, i) => upload(i, file));

    Promise.all(uploadPromises)
      .then(() => UploadService.getFilesBetc(id, documento, uniqid))
      .then((files) => {
        setFileInfos(files.data);
      });

    setMessage([]);
  };

  return (
    <Paper>
      {progressInfos && progressInfos.val.length > 0 &&
        progressInfos.val.map((progressInfo, index) => (

          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" key={index} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(progressInfo.percentage,)}%`}</Typography>
            </Box>
          </Box>
        ))}

      <div className="row my-3">

        {showUser && (
          <div className="col-8">
            <label className="btn btn-default p-0">
              <input type="file" multiple onChange={selectFiles} />
            </label>
          </div>
        )}

      </div>

      {message.length > 0 && (
        <div className="alert alert-secondary" role="alert">
          <ul>
            {message.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      )}

      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders" dense={true}>
          {fileInfos &&
            fileInfos.map((file, index) => (
              <ListItem id={index}>
                <Link target="_blank" href={file.url}>
                  {file.real
                    ? <ListItemText primary={file.real} />
                    : <ListItemText primary={file.name} />
                  }
                </Link>

                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <Link 
                    onClick={() => eliminar(file.name,documento, id)}>
                      {showUser && (
                        <DeleteIcon />
                      )}
                    </Link>
                  </IconButton>
                </ListItemSecondaryAction>

              </ListItem>
              
          
              
            ))}
        </List>
        <Divider />
      </div>

    </Paper>
  );
};

export default UploadFilesBetc;
