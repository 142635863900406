import React from 'react';
import Paper from '@mui/material/Paper';
import { TablePagination, Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Modal, Button, TextField } from '@mui/material';
import UserService from "./services/mantenimiento.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { Grid, ThemeProvider, StyledEngineProvider, } from "@mui/material";
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import MaterialTable from 'material-table';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

export default function ProyectoTareas(props) {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const styles = useStyles();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [data, setData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);

    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        nombre: '',
        codigo: '',
        nota: '',
        precio: '',
        idproduct: id,
    })

    const handleChange = e => {
        const { name, value } = e.target;
        setConsolaSeleccionada(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    const peticionGet = async () => {
        const result = await UserService.getMantenimientos(id);
        setData(result.data);
    }

    const peticionPut = async () => {
        const response = await UserService.addmodMantenimiento(consolaSeleccionada.id, consolaSeleccionada);
        var dataNueva = response.data;
        dataNueva.map(consola => {
            if (consolaSeleccionada.id === consola.id) {
                return {
                    ...consola,
                    nombre: consolaSeleccionada.nombre,
                    codigo: consolaSeleccionada.codigo,
                    nota: consolaSeleccionada.nota,
                    //                    idproduct: consolaSeleccionada.idproduct,
                };
            }
        })
        setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
    }

    const peticionDelete = async () => {
        const response = await UserService.delTarea(consolaSeleccionada.id);
        peticionGet();
        abrirCerrarModalEliminar();
    }

    const abrirCerrarModalEditar = async (id) => {
        const response = await UserService.getTarea(id);
        if (response) {
            console.log(response);
            setConsolaSeleccionada(response); // Configura los datos obtenidos en el estado
        }
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        if (caso === 'Editar') {
            abrirCerrarModalEditar(consola.id); // Llama a la función para abrir o cerrar el modal de edición
        } else {
            abrirCerrarModalEliminar(); // Llama a la función para abrir o cerrar el modal de eliminación
        }
    }

    useEffect(() => {
        const GetData = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getTareasProyectos(id);
                if (result) {
                    setData(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetData();
    }, []);

    const bodyEditar = (
        <div className={styles.modal}>
            <h3>Editar Tarea</h3>
            <TextField name="nombre" className={styles.inputMaterial} label="Nombre" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.nombre} />
            <br />
            <TextField name="codigo" className={styles.inputMaterial} label="Codigo" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.codigo} />
            <br />
            <TextField name="nota" className={styles.inputMaterial} label="Nota" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.nota} />
            <br />

            <br /><br />
            <div align="right">
                <Button color="primary" onClick={() => peticionPut()}>Editar</Button>
                <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
            </div>
        </div>
    )

    const bodyEliminar = (
        <div className={styles.modal}>
            <p>Estás seguro que deseas eliminar el estado  <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
            <div align="right">
                <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
                <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
            </div>
        </div>
    )

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <Paper className={classes.root}>
            <ThemeProvider theme={theme}>
                <div style={{ maxWidth: "100%", direction }}>

                    <MaterialTable
                        title=""

                        localization={{
                            toolbar: {
                                searchPlaceholder: 'Buscar...'
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            body: {
                                editRow: {
                                    deleteText: 'Estas seguro de eliminar este registro ?'
                                }
                            },

                        }}
                        columns={[
                            {
                              title: 'Tipo',
                              field: 'tipodemantenimiento',
                            },
                            {
                              title: 'Maquina',
                              field: 'maquina',
                            },
                            {
                              title: 'Fecha inicio',
                              field: 'fecha_inicio',
                            },
                            {
                              title: 'Fecha finalizacion',
                              field: 'fecha_finalizacion',
                            },
                            {
                              title: 'Descripicion',
                              field: 'nombre',
                            },
                          ]}
        
                        data={data}
                        options={{
                            headerStyle: {
                                backgroundColor: '#004F9E',
                                color: '#FFF',
                            },
                            search: true,
                            actionsColumnIndex: -1,
                        }}
                    />

                </div>
            </ThemeProvider>

            <Modal
                open={modalEditar}
                onClose={abrirCerrarModalEditar}>
                {bodyEditar}
            </Modal>

            <Modal
                open={modalEliminar}
                onClose={abrirCerrarModalEliminar}>
                {bodyEliminar}
            </Modal>

            <SnackbarMessage
                open={open}
                message={errorMessage}
                severity="success"
                onClose={handleCloseMessage}
            />


        </Paper>
    );
}