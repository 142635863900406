import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Modal } from '@mui/material';
import { useParams } from 'react-router';

import {AppBar, Button, CircularProgress, IconButton, Toolbar, Paper, Breadcrumbs, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, ThemeProvider, StyledEngineProvider} from "@mui/material";

import { AuthContext } from '../../components/AuthContext';

import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import MaterialTable from 'material-table';

import UserService from "./services/mantenimiento.service";

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

// Esquema de validación del formulario de repuestos
const validationSchema = yup.object({
  nombre: yup.string().required('Nombre requerido'),
  precio: yup.number().required('Precio requerido'),
});

const RepuestosComponent = (props) => {
  const { showUser, OwnedProducts } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false)

  const styles = useStyles();
  const classes = useStyles();
  const [repuestos, setRepuestos] = useState([]);
  const { id } = useParams();
  const [modalEliminar, setModalEliminar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    precio: '',
  })

  const formik = useFormik({
    initialValues: {
      nombre: '',
      precio: '',
      cantidad: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
    },
  });

  const cargarRepuestos = async () => {
    try {
      setIsLoading(true);
      const result = await UserService.getRepuestosMaquina(id);
      if (result) {
        setRepuestos(result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      handleShowMessage('500');
    }
  };

  useEffect(() => {
    cargarRepuestos();
  }, []);

  const peticionPost = async (values) => {
    const response = await UserService.addmodRepuesto(id, values);
    //cerrarEditar()
  }

  const peticionDelete = async () => {
    const response = await UserService.delRepuesto(consolaSeleccionada.id);
    var data = response.data;
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const peticionGet = async () => {
    const result = await UserService.getRepuestos();
    setRepuestos(result);
  }

  const cerrarEditar = () => {
    props.history.push(process.env.PUBLIC_URL + "/maquinas");
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? props.history.push(process.env.PUBLIC_URL + "/repuestosaddmod/" + consola.id) : abrirCerrarModalEliminar()
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el Repuesto <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/mantenimiento")
  }

  const abrirCerrarModalInsertar = () => {
    props.history.push(process.env.PUBLIC_URL + "/repuestosaddmod/")
  }

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Repuestos
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      {(
        OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
          module => module.name === "Repuestos" && (module.permissions.agregar)
        )
        &&
        <Breadcrumbs aria-label="breadcrumb">
          <Button style={{ color: "#fff", backgroundColor: "#004F9E" }} variant="contained" onClick={() => abrirCerrarModalInsertar()}>Nuevo Repuesto</Button>
        </Breadcrumbs>
      )}

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ maxWidth: "100%", direction }}>
            <Grid container>
              <Grid item xs={12}>

                <MaterialTable
                  title=""

                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Buscar...'
                    },
                    header: {
                      actions: 'Acciones'
                    },
                    body: {
                      editRow: {
                        deleteText: 'Estas seguro de eliminar este registro ?'
                      }
                    },
                  }}

                  columns={[
                    {
                      title: 'Nombre',
                      field: 'nombre',
                    },
                    {
                      title: 'Precio',
                      field: 'precio',
                    },
                    {
                      title: 'Cantidad',
                      field: 'cantidad',
                    },
                  ]}
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Eliminar',
                      onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                    }
                  ]}

                  data={repuestos}
                  options={{
                    headerStyle: {
                      backgroundColor: '#004F9E',
                      color: '#FFF',
                    },
                    search: true,
                    actionsColumnIndex: -1
                  }}
                />

                <Modal
                  open={modalEliminar}
                  onClose={abrirCerrarModalEliminar}>
                  {bodyEliminar}
                </Modal>

                <SnackbarMessage
                  open={open}
                  message={errorMessage}
                  severity="success"
                  onClose={handleCloseMessage}
                />

              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>


    </Paper>
  );
};

export default RepuestosComponent;
