import React from 'react';
import { useState, useEffect, useContext } from 'react'

import { AppBar, Button, Modal, CircularProgress, IconButton, Toolbar, Paper, Breadcrumbs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, ThemeProvider, StyledEngineProvider } from "@mui/material";

import { AuthContext } from '../../components/AuthContext';
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import MaterialTable, { MTableToolbar } from 'material-table';

import UserService from "./services/mantenimiento.service";
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function Tareas(props) {
  const { showUser, OwnedProducts } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false)

  const styles = useStyles();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    codigo: '',
  })

  const [showActiveOnly, setShowActiveOnly] = useState(true);

  const peticionDelete = async () => {
    const response = await UserService.delTarea(consolaSeleccionada.id);
    var data = response.data;
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const peticionGet = async () => {
    const result = await UserService.getTareas();
    setData(result);
  }

  const abrirCerrarModalInsertar = () => {
    props.history.push(process.env.PUBLIC_URL + "/tareasaddmod/")
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? props.history.push(process.env.PUBLIC_URL + "/tareasaddmod/" + consola.id) : abrirCerrarModalEliminar()
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  useEffect(() => {

    const GetData = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getTareas(true);
        if (result) {
          setData(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        handleShowMessage('500');
      }
    }
    GetData();

  }, []);

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar la Tarea <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/mantenimiento")
  }

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin


  // ...

  const handleChangeShowActiveOnly = async () => {
    try {
      setIsLoading(true);
      setShowActiveOnly(!showActiveOnly);

      // Llama al servicio con el nuevo estado del checkbox
      const result = await UserService.getTareas(!showActiveOnly);

      if (result) {
        setData(result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      handleShowMessage('500');
    }
  };

  // ...

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Tareas
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      {(
        OwnedProducts.find(product => product.name === "Mantenimiento").modules.find(
          module => module.name === "Tareas" && (module.permissions.agregar)
        )
        &&
        <Breadcrumbs aria-label="breadcrumb">
          <Button style={{ color: "#fff", backgroundColor: "#004F9E" }} variant="contained" onClick={() => abrirCerrarModalInsertar()}>Nueva Tarea</Button>
        </Breadcrumbs>
      )}

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ maxWidth: "100%", direction }}>
            <Grid container>
              <Grid item xs={12}>

                <MaterialTable
                  title=""

                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Buscar...'
                    },
                    header: {
                      actions: 'Acciones'
                    },
                    body: {
                      editRow: {
                        deleteText: 'Estas seguro de eliminar este registro ?'
                      }
                    },
                  }}

                  columns={[
                    {
                      title: 'Tipo',
                      field: 'tipodemantenimiento',
                    },
                    {
                      title: 'Maquina',
                      field: 'maquina',
                    },
                    {
                      title: 'Fecha inicio',
                      field: 'fecha_inicio',
                    },
                    {
                      title: 'Fecha finalizacion',
                      field: 'fecha_finalizacion',
                    },
                    {
                      title: 'Descripicion',
                      field: 'nombre',
                    },
                  ]}

                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Eliminar',
                      onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                    }
                  ]}

                  data={data}

                  options={{
                    headerStyle: {
                      backgroundColor: '#004F9E',
                      color: '#FFF',
                    },
                    search: true,
                    actionsColumnIndex: -1
                  }}

                  // Agrega el Checkbox a la barra de herramientas
                  components={{
                    Toolbar: props => (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <FormControlLabel
                            control={<Checkbox checked={showActiveOnly} onChange={handleChangeShowActiveOnly} />}
                            label="Mostrar solo tareas activas"
                          />
                          {/* Puedes agregar estilos adicionales según sea necesario */}
                        </div>
                        <div>
                          <MTableToolbar {...props} />
                        </div>
                      </div>
                    ),
                  }}

                />

                <Modal
                  open={modalEliminar}
                  onClose={abrirCerrarModalEliminar}>
                  {bodyEliminar}
                </Modal>

                <SnackbarMessage
                  open={open}
                  message={errorMessage}
                  severity="success"
                  onClose={handleCloseMessage}
                />

              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>

    </Paper>
  );
}