import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

import { AppBar, CircularProgress, IconButton, Toolbar, Breadcrumbs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import ProyectoTareas from './ProyectoTareas';

import UserServiceCore from "../../services/user.service";
import UserService from "./services/mantenimiento.service";
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

// Validacion del formulario
const validationSchema = yup.object({
    nombre: yup
        .string('Descripcion requerido')
        .required('Descripcion requerido'),
    fecha_inicio: yup
        .date('Fecha de inicio requerida')
        .required('Fecha de inicio requerida')
        .max(yup.ref('fecha_finalizacion'), 'Fecha de inicio debe ser anterior a Fecha de finalización'),
    fecha_finalizacion: yup
        .date('Fecha de finalización requerida')
        .required('Fecha de finalización requerida')
        .min(yup.ref('fecha_inicio'), 'Fecha de finalización debe ser posterior a Fecha de inicio'),
});

export default function Proyectosaddmod(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [dato, setDato] = useState([]);

    //inicializacion de variables y validacion
    var d = new Date();
    var finaldate = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + (d.getDate()).toString().padStart(2, "0")

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            tipo: '',
            maquina: '',
            fecha_inicio: '',
            fecha_finalizacion: '',
            idresponsable: '',
            prioridad: '',
            progress: '',
            alerta: '',
            nombre: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const [prioridad, setPrioridad] = useState([
        { id: 'Alta', nombre: 'Alta' },
        { id: 'Media', nombre: 'Media' },
        { id: 'Baja', nombre: 'Baja' },
    ]);

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [plantas, setPlantas] = useState([
        { id: 'Mitre', nombre: 'Mitre' },
        { id: 'Sabattini', nombre: 'Sabattini' },
    ]);

    const [tipos, setTipos] = useState([
        { id: 'Uso Unico Personal', nombre: 'Uso Unico Personal' },
        { id: 'Uso Generico Comunitario', nombre: 'Uso Generico Comunitario' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodProyecto(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/proyectos");
    }

    useEffect(() => {

        const GetData = async () => {

            if (id) {
                try {
                    setIsLoading(true);
                    const response = await UserService.getProyecto(id);
                    if (response) {
                        formik.setValues(response);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                } catch (e) {
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            }

        }
        GetData();

    }, []);

    useEffect(() => {
        const GetSucursales = async () => {
            try {
                setIsLoading(true);
                const result = await UserServiceCore.getSucursales();
                if (result) {
                    setDato(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetSucursales();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/proyectos")
    }

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Proyecto
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                <Grid item xs={12}>
                        <TextField
                            name="nombre"
                            className={styles.inputMaterial}
                            label="Descripcion"
                            autoComplete='off'
                            value={formik.values.nombre}
                            onChange={formik.handleChange}
                            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                            helperText={formik.touched.nombre && formik.errors.nombre}
                        />

                        <TextField
                            name="data1"
                            type="hidden"
                            value={formik.values.data1}
                        />

                        <TextField
                            name="recurso"
                            type="hidden"
                            value={formik.values.recurso}
                        />

                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id="date"
                            type="date"
                            autoComplete='off'
                            defaultValue={formik.values.fecha_inicio || finaldate}
                            name="fecha_inicio"
                            label="Fecha inicio"
                            className={styles.inputMaterial}
                            error={formik.touched.fecha_inicio && Boolean(formik.errors.fecha_inicio)}
                            helperText={formik.touched.fecha_inicio && formik.errors.fecha_inicio}
                            onChange={formik.handleChange}
                            value={formik.values.fecha_inicio}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id="date-finalizacion"                        // Nuevo campo
                            type="date"
                            className={styles.inputMaterial}
                            autoComplete='off'
                            name="fecha_finalizacion"                     // Nuevo campo
                            label="Fecha finalizacion"                      // Nuevo campo
                            error={formik.touched.fecha_finalizacion && Boolean(formik.errors.fecha_finalizacion)}
                            helperText={formik.touched.fecha_finalizacion && formik.errors.fecha_finalizacion}
                            onChange={formik.handleChange}
                            value={formik.values.fecha_finalizacion}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        {/* Agrega un componente de entrada para el progreso */}
                        <TextField
                            name="progress"
                            className={styles.inputMaterial}
                            label="Progreso (%)"
                            type="number"
                            autoComplete='off'
                            value={formik.values.progress}
                            onChange={formik.handleChange}
                            error={formik.touched.progress && Boolean(formik.errors.progress)}
                            helperText={formik.touched.progress && formik.errors.progress}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel htmlFor="outlined-age-native-simple">Prioridad</InputLabel>
                        <Select
                            native
                            label="Prioridad"
                            className={styles.inputMaterial}
                            autoComplete='off'
                            value={formik.values.prioridad}
                            onChange={formik.handleChange}
                            error={formik.touched.prioridad && Boolean(formik.errors.prioridad)}
                            helperText={formik.touched.prioridad && formik.errors.prioridad}
                            inputProps={{
                                name: 'prioridad',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {prioridad.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <SnackbarMessage
                  open={open}
                  message={errorMessage}
                  severity="success"
                  onClose={handleCloseMessage}
                />

                 </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>

            {id && <ProyectoTareas id={id} />}
        </Paper>
    );
}