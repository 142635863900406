import React, { useState, useEffect, useRef, useContext } from "react";

import UploadService from "./services/planera.service";

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles, useTheme } from '@mui/styles';
import UserService from "./services/planera.service";

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import { AuthContext } from '../../components/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    //maxWidth: 400,
    backgroundColor: '#fff',
  },
}));

const UploadFiles = ({ id, documento }) => {

  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const progressInfosRef = useRef(null)
  const [data, setData] = useState([]);

  const { OwnedProducts, showUser } = useContext(AuthContext);
  const [selectedFilesToDelete, setSelectedFilesToDelete] = useState([]);

  useEffect(() => {
    UploadService.getFilesPlanera(id, documento)
      .then((response) => {
        // Verificar si la respuesta contiene datos
        if (response.data && response.data.length > 0) {
          setFileInfos(response.data);
        } else {
          // Manejar el caso en el que no se obtienen datos
          console.log('No se encontraron datos');
        }
      })
      .catch((error) => {
        // Manejar errores si ocurren
        console.error('Error al obtener los datos:', error);
      });
  }, []);

  const selectFiles = (event) => {
    const files = event.target.files;

    // Verificar si hay archivos seleccionados
    if (files.length > 0) {
      // Configurar selectedFiles
      setSelectedFiles(files);
      setProgressInfos({ val: [] });

      // Iniciar automáticamente la carga de archivos
      uploadFiles(files);
    }
  };

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    try {
      await UploadService.upload(id, file, documento, (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        setProgressInfos({ val: _progressInfos });
      });
      setMessage((prevMessage) => ([
        ...prevMessage,
        "Archivo subido...: " + file.name,
      ]));
    } catch {
      _progressInfos[idx].percentage = 0;
      setProgressInfos({ val: _progressInfos });

      setMessage((prevMessage_1) => ([
        ...prevMessage_1,
        "No pudimos subir el archivo: " + file.name,
      ]));
    }
  };

  const eliminar = async (key, documento, id) => {
    const response = await UserService.delImagePlanera(id, documento, key);
    setData(data.concat(response.data))

    UploadService.getFilesPlanera(id, documento).then((response) => {
      setFileInfos(response.data);
    })

  }

  const uploadFiles = (filess) => {
    //const files = Array.from(selectedFiles);
    const files = Array.from(filess);

    let _progressInfos = files.map(file => ({ percentage: 0, fileName: file.name }));

    progressInfosRef.current = {
      val: _progressInfos,
    }

    const uploadPromises = files.map((file, i) => upload(i, file));

    Promise.all(uploadPromises)
      .then(() => UploadService.getFilesPlanera(id, documento))
      .then((files) => {
        setFileInfos(files.data);
      });

    setMessage([]);
  };

  const toggleFileSelection = async (file) => {
    console.log(file)
    const updatedFile = { ...file, activo: !file.activo };
    console.log(updatedFile)

    const response = await UserService.activarPlano(id, documento, updatedFile);
  }

  return (
    <Paper>
      {progressInfos && progressInfos.val.length > 0 &&
        progressInfos.val.map((progressInfo, index) => (

          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" key={index} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(progressInfo.percentage,)}%`}</Typography>
            </Box>
          </Box>
        ))}

      <div className="row my-3">

        {
          OwnedProducts.find(product => product.name === "Planera").modules.find(
            module => module.name === "Planos" && (module.permissions.agregar)
          ) && (
            <div className="col-8">
              <label className="btn btn-default p-0">
                <input type="file" multiple onChange={selectFiles} />
              </label>
            </div>
          )}

        {documento === "Tickets" && (
          <div className="col-8">
            <label className="btn btn-default p-0">
              <input type="file" multiple onChange={selectFiles} />
            </label>
          </div>
        )}

      </div>

      {message.length > 0 && (
        <div className="alert alert-secondary" role="alert">
          <ul>
            {message.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      )}

      <div className={classes.root}>

        {documento === 'Planos' ? 'ARCHIVOS APROBADOS' : 'ARCHIVOS PENDIENTES'}

        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #ddd' }}>
              <th style={cellStyle}>Archivo</th>
              {
                OwnedProducts.find(product => product.name === "Planera").modules.find(
                  module => module.name === "Planos" && (module.permissions.agregar)
                ) && (
                  <th style={cellStyle}>Eliminar</th>
                )
              }

              {documento === "Tickets" && (
                <>
                  <th style={cellStyle}>Eliminar</th>
                  <th style={cellStyle}>Activo</th>
                </>
              )}

              {documento === "Planos" && (
                <>
                  <th style={cellStyle}>Activo</th>
                  <th style={cellStyle}>Fecha/h</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {fileInfos &&
              fileInfos.map((file, index) => (

                <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>

                  <td style={cellStyle}>
                    {file.activo || documento === "Tickets" ? (
                      <Link href={file.url}>
                        {file.real
                          ? <ListItemText primary={file.real} />
                          : <ListItemText primary={file.name} />
                        }
                      </Link>
                    ) : (
                      <>
                        {file.real
                          ? <ListItemText primary={file.real} />
                          : <ListItemText primary={file.name} />
                        }
                      </>
                    )}
                  </td>

                  <>
                    {
                      OwnedProducts.find(product => product.name === "Planera").modules.find(
                        module => module.name === "Planos" && (module.permissions.agregar)
                      ) && (
                        <td style={cellStyle}>
                          <IconButton edge="end" aria-label="delete">
                            <Link onClick={() => eliminar(file.name, documento, id)}>
                              {showUser && (
                                <DeleteIcon />
                              )}
                            </Link>
                          </IconButton>
                        </td>
                      )
                    }

                    {documento === "Tickets" && (
                      <td style={cellStyle}>
                        <IconButton edge="end" aria-label="delete">
                          <Link onClick={() => eliminar(file.name, documento, id)}>
                            {showUser && (
                              <DeleteIcon />
                            )}
                          </Link>
                        </IconButton>
                      </td>


                    )}
                  </>

                  {documento === "Planos" && (
                    <>
                      {
                        OwnedProducts.find(product => product.name === "Planera").modules.find(
                          module => module.name === "Planos" && (module.permissions.agregar)
                        ) ? (

                          <td style={cellStyle}>
                            <Checkbox
                              checked={file.activo}
                              onChange={() => toggleFileSelection(file)}
                            />
                          </td>

                        ) : (
                          <td style={cellStyle}>
                            <Checkbox
                              checked={file.activo}
                              readOnly
                            />
                          </td>


                        )
                      }
                    </>
                  )}
                  <td style={cellStyle}>
                    {file.fecha}
                  </td>

                </tr>
              ))}
          </tbody>
        </table>

      </div>

    </Paper >
  );
};

const cellStyle = {
  padding: '12px',
  textAlign: 'left',
};


export default UploadFiles;
