import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { mdiBookInformationVariant, mdiCar, mdiGasStation, mdiHomeCity } from '@mdi/js'

import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

const Flota = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);

    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const classes = useStyles();

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    return (

        <>
            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Flota
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />
            
            <sections>

                {showUser && (
                    <>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/areas`} className="nav-link">
                                            <Icon path={mdiHomeCity}
                                                title="Areas"
                                                size={3}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Areas
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/vehiculos`} className="nav-link">
                                            <Icon path={mdiCar}
                                                title="Vehiculos"
                                                size={3}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Vehiculos
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                        
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/combustibles`} className="nav-link">
                                            <Icon path={mdiGasStation}
                                                title="Combustible"
                                                size={3}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Combustible
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/informes`} className="nav-link">
                                            <Icon path={mdiBookInformationVariant}
                                                title="Informe"
                                                size={3}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Informe
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    </>
                )}
            </sections>
        </>

    );
};

export default Flota;
