/**
 * Intranet
 * update 07/2022
 * By Sergio Sam 
 */

import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    codigo: yup
        .string('Codigo requerido')
        .required('Codigo requerido'),
    descorta: yup
        .string('Descrpcion corta requerida')
        .required('Descrpcion corta requerida'),
    marca: yup
        .string('Marca requerida')
        .required('Marca requerida'),
    modelo: yup
        .string('Modelo requreido ')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Modelo requerido'),
    tipo: yup
        .string('Tipo requerido')
        .required('Tipo requerido'),
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    },
    formControl: {
        margin: 1,
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: 2,
    },
    cardHeader: {
        padding: (1, 2),
    },
    list: {
        width: 200,
        height: 230,
        backgroundColor: '#fff',
        overflow: 'auto',
    },
}));

export default function RecursoCon(props) {

    //inicializacion de variables y validacion
    var d = new Date();
    var finaldate = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + (d.getDate()).toString().padStart(2, "0")

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            codigo: '',
            descorta: '',
            marca: '',
            modelo: '',
            serie: '',
            descripcion: '',
            imei:'',
            so:'',
            memoria:'',
            disco:'',
            certificacion:'',
            cantidad:'',
            tipo: 'Uso Unico Personal',
            idsucursal: '',
            fecha_compra: finaldate,
            data1: '',
            recurso:'informatica',            
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [plantas, setPlantas] = useState([
        { id: 'Mitre', nombre: 'Mitre' },
        { id: 'Sabattini', nombre: 'Sabattini' },
    ]);

    const [tipos, setTipos] = useState([
        { id: 'Uso Unico Personal', nombre: 'Uso Unico Personal' },
        { id: 'Uso Generico Comunitario', nombre: 'Uso Generico Comunitario' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodRecurso(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/recursos");
    }

    useEffect(() => {

        if (id) {
            const GetData = async () => {
                try {
                    const response = await UserService.getRecursoCon(id);

                    if (response) {
                        formik.setValues(response);
                    } else {
                        props.history.push(process.env.PUBLIC_URL + "/login");
                    }

                } catch (e) {
                    props.history.push(process.env.PUBLIC_URL + "/login");
                }
            }
            GetData();
        }
        
        const GetSucursal = async () => {
            try {
                const result = await UserService.getAreas();
                if (result) {
                    setData(result.data);

                    var dataNueva = result.data;
                    dataNueva.map(consola => {
                        setLeft(consola.tareas);
                    })

                } else {
                    props.history.push(process.env.PUBLIC_URL + "/login");
                }
            } catch (e) {
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetSucursal();

    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    const abrirCerrarModalInsertar = () => {
        props.history.push(process.env.PUBLIC_URL + "/recursos/")
    }

    const recursos = () => {
        props.history.push(process.env.PUBLIC_URL + "/recursos")
    }

    return (
        <Paper className={classes.root}>

            <Breadcrumbs aria-label="breadcrumb">
                <Button style={{ color: "#fff", backgroundColor: "rgb(0, 79, 158)" }} variant="contained" onClick={() => inicio()}>Inicio</Button>
                <Button style={{ color: "#fff", backgroundColor: "rgb(0, 79, 158)" }} variant="contained" onClick={() => recursos()}>Recursos</Button>
            </Breadcrumbs>
            <br></br>

            <div className={styles.modal}>
                <form onSubmit={formik.handleSubmit}>
                    <h3>Consulta de Recursos</h3>

                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="codigo"
                                className={styles.inputMaterial}
                                label="Codigo"
                                autoComplete='off'
                                autoFocus={true}
                                value={formik.values.codigo}
                                onChange={formik.handleChange}
                                error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                                helperText={formik.touched.codigo && formik.errors.codigo}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="descorta"
                                className={styles.inputMaterial}
                                label="Descripcion corta"
                                autoComplete='off'
                                value={formik.values.descorta}
                                onChange={formik.handleChange}
                                error={formik.touched.descorta && Boolean(formik.errors.descorta)}
                                helperText={formik.touched.descorta && formik.errors.descorta}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="marca"
                                className={styles.inputMaterial}
                                label="Marca"
                                autoComplete='off'
                                value={formik.values.marca}
                                onChange={formik.handleChange}
                                error={formik.touched.marca && Boolean(formik.errors.marca)}
                                helperText={formik.touched.marca && formik.errors.marca}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="modelo"
                                className={styles.inputMaterial}
                                label="Modelo"
                                autoComplete='off'
                                value={formik.values.modelo}
                                onChange={formik.handleChange}
                                error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                                helperText={formik.touched.modelo && formik.errors.modelo}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="serie"
                                className={styles.inputMaterial}
                                label="Numero de Serie"
                                autoComplete='off'
                                value={formik.values.serie}
                                onChange={formik.handleChange}
                                error={formik.touched.serie && Boolean(formik.errors.serie)}
                                helperText={formik.touched.serie && formik.errors.serie}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="so"
                                className={styles.inputMaterial}
                                label="SO"
                                autoComplete='off'
                                value={formik.values.so}
                                onChange={formik.handleChange}
                                error={formik.touched.so && Boolean(formik.errors.so)}
                                helperText={formik.touched.so && formik.errors.so}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="memoria"
                                className={styles.inputMaterial}
                                label="Memoria"
                                autoComplete='off'
                                value={formik.values.memoria}
                                onChange={formik.handleChange}
                                error={formik.touched.memoria && Boolean(formik.errors.memoria)}
                                helperText={formik.touched.memoria && formik.errors.memoria}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="disco"
                                className={styles.inputMaterial}
                                label="Disco/Almacenamiento"
                                autoComplete='off'
                                value={formik.values.disco}
                                onChange={formik.handleChange}
                                error={formik.touched.disco && Boolean(formik.errors.disco)}
                                helperText={formik.touched.disco && formik.errors.disco}
                            />
                        </Grid>

                        <Grid item xs={4}>
                        <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="tipo"
                                className={styles.inputMaterial}
                                label="Tipo"
                                autoComplete='off'
                                value={formik.values.tipo}
                                onChange={formik.handleChange}
                                error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                                helperText={formik.touched.tipo && formik.errors.tipo}
                            />
                        </Grid>        
                        <Grid item xs={4}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                id="date"
                                type="date"
                                defaultValue="2021-08-25"
                                name="fecha_compra"
                                label="Fecha de compra"
                                autoComplete='off'
                                className={classes.textField}
                                error={formik.touched.fecha_compra && Boolean(formik.errors.fecha_compra)}
                                helperText={formik.touched.fecha_compra && formik.errors.fecha_compra}
                                onChange={formik.handleChange}
                                value={formik.values.fecha_compra}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="descripcion"
                                className={styles.inputMaterial}
                                label="Descripcion"
                                autoComplete='off'
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                                helperText={formik.touched.descripcion && formik.errors.descripcion}
                            />
                        </Grid>

                        <TextField
                            name="data1"
                            type="hidden"
                            value={formik.values.data1}
                        />

                        <TextField
                            name="recurso"
                            type="hidden"
                            value={formik.values.recurso}
                        />

                    </Grid>
                    <br /><br />
                    <div align="right">
                        <Button onClick={() => cerrarEditar()}>Cerrar</Button>
                    </div>
                </form>
            </div>

        </Paper>
    );
}