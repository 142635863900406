import React, { useState, useEffect } from "react";

import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import Container from '@mui/material/Container';
import MaterialTable from 'material-table';

import { Grid, ThemeProvider, StyledEngineProvider, } from "@mui/material";
import { useTheme, useStyles, direction,theme } from "../styles.js"
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { Modal, Button } from '@mui/material';

const Users = (props) => {
  const [total, setTotal] = useState([]);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [data, setData] = useState([]);
  const [modalEliminar, setModalEliminar] = useState(false);
  const styles = useStyles();

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    nombre: '',
    email: '',
  })

  const peticionDelete = async () => {

    if (consolaSeleccionada.product == 'horario') {
      await UserService.delUsuarioHorario(consolaSeleccionada.id);
    } else {
      await UserService.delUsuario(consolaSeleccionada.id);
    }

    //console.log(consolaSeleccionada.product);
    //var data = response.data;
    peticionGet();
    abrirCerrarModalEliminar();

  }

  const peticionGet = async () => {
    const result = await UserService.getUsers();
    setData(result.data);
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ?
      props.history.push(process.env.PUBLIC_URL + "/usersmod/" + consola.id) :
      abrirCerrarModalEliminar()
  }

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setShowClienteBoard(user.roles.includes("ROLE_USER"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }

    const GetData = async () => {
      try {
        const result = await UserService.getUsers('findemes');
        if (result.code !== 401) {
          setData(result.data);
        } else {
          props.history.push(process.env.PUBLIC_URL + "/login");
          //history.push({ pathname: process.env.PUBLIC_URL + '/login', state: { response: '' } });
        }
      } catch (e) {
        console.log(e);
        props.history.push(process.env.PUBLIC_URL + "/login");
        //history.push({ pathname: process.env.PUBLIC_URL + '/login', state: { response: '' } });
      }
    }
    GetData();

  }, []);

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el Usuario <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const Inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/")
  }

  return (

    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

      <Breadcrumbs aria-label="breadcrumb">
        <Button color="primary" variant="contained" onClick={() => Inicio()}>Inicio</Button>
      </Breadcrumbs>

      <ThemeProvider theme={theme}>
        <div style={{ maxWidth: "100%", direction }}>

          <MaterialTable
            title=""

            localization={{
              toolbar: {
                searchPlaceholder: 'Buscar...'
              },
              header: {
                actions: 'Acciones'
              },
              body: {
                editRow: {
                  deleteText: 'Estas seguro de eliminar este registro ?'
                }
              },

            }}

            columns={[
              {
                title: 'Email',
                field: 'email',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
              {
                title: 'Producto',
                field: 'product',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
              {
                title: 'Empresa',
                field: 'empresa',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
              {
                title: 'Product_tipe',
                field: 'product_tipe',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
              {
                title: 'Evaluate_days',
                field: 'evaluate_days',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
              {
                title: 'Evaluate_date',
                field: 'evaluate_date',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
              {
                title: 'Expirate_days',
                field: 'expirate_days',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
              {
                title: 'Expirate_date',
                field: 'expirate_date',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
              {
                title: 'Total',
                field: 'total',
                align: "center",
                cellStyle: { textAlign: "center" }
              },
            ]}
            data={data}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Editar',
                onClick: (event, data) => seleccionarConsola(data, 'Editar'),
              },
              {
                icon: 'delete',
                tooltip: 'Eliminar',
                onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
              }
            ]}
            options={{
              headerStyle: {
                backgroundColor: '#2e7d32',
                color: '#FFF',
              },
              search: true,
              actionsColumnIndex: -1,
              grouping: true
            }}
          />
        </div>
      </ThemeProvider>
      <Modal
        open={modalEliminar}
        onClose={abrirCerrarModalEliminar}>
        {bodyEliminar}
      </Modal>

    </Container>

  );
};

export default Users;
