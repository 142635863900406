import React from 'react';
import { useState, useEffect } from 'react'

import Paper from '@mui/material/Paper';
import { Modal, Button } from '@mui/material';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import PrintIcon from '@mui/icons-material/Print';

import { saveAs } from 'file-saver';
import BlobStream from 'blob-stream';
import { PDFViewer, Document, Page, pdf } from '@react-pdf/renderer';

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import MaterialTable from 'material-table';

import UserService from "../../services/planillas.service";

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

export default function Verdes(props) {
  const [isLoading, setIsLoading] = useState(true)
  const styles = useStyles();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    codigo: '',
  })

  const peticionGet = async () => {
    const result = await UserService.getVehiculos();
    setData(result.data);
  }

  const peticionDelete = async () => {
    const response = await UserService.delVehiculo(consolaSeleccionada.id);
    var data = response.data;
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const abrirCerrarModalInsertar = () => {
    props.history.push(process.env.PUBLIC_URL + "/verdesaddmod/")
  }

  const seleccionarConsola = async (consola, caso) => {
    setConsolaSeleccionada(consola);

    if (caso === 'Editar') {
      props.history.push(process.env.PUBLIC_URL + "/verdesaddmod/" + consola._id);
    } else if (caso === 'Eliminar') {
      abrirCerrarModalEliminar();
    } else if (caso === 'Imprimir') {
      const response = await UserService.getPdf(consola._id, 'verde');

      const stream = BlobStream();
      const doc = (
        <Document>
          <Page>
            {/* Aquí puedes incluir tu HTML obtenido de la API */}
            <div dangerouslySetInnerHTML={{ __html: response }} />
          </Page>
        </Document>
      );

      pdf().updateContainer(doc).toStream(stream).then(() => {
        const blob = stream.toBlob();
        saveAs(blob, 'archivo.pdf');
      });

    }
  };

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  useEffect(() => {
    const GetData = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getVerdes();
        if (result) {
          setData(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
console.log(e)
        setIsLoading(true);
        props.history.push(process.env.PUBLIC_URL + "/login");
      }
    }
    GetData();
  }, []);

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el Vehiculo <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/")
  }

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Planillas Verdes
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ maxWidth: "100%", direction }}>
            <Grid container>
              <Grid item xs={12}>

                <MaterialTable
                  title=""

                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Buscar...'
                    },
                    header: {
                      actions: 'Acciones'
                    },
                    body: {
                      editRow: {
                        deleteText: 'Estas seguro de eliminar este registro ?'
                      }
                    },
                  }}

                  columns={[
                    {
                      title: 'Id',
                      field: '_id',
                    },
                    {
                      title: 'Informe',
                      field: 'informe',
                    },
                    {
                      title: 'Cliente',
                      field: 'cliente',
                    },
                    {
                      title: 'Sector',
                      field: 'sector',
                    },
                    {
                      title: 'Equipo',
                      field: 'equipo',
                    },
                    {
                      title: 'Modificado',
                      field: 'fechaupdate',
                    },
                  ]}

                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                    },
                    {
                      icon: 'print',
                      tooltip: 'Imprimir',
                      onClick: (event, data) => seleccionarConsola(data, 'Imprimir'),
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Eliminar',
                      onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                    }
                  ]}

                  data={data}
                  options={{
                    headerStyle: {
                      backgroundColor: '#004F9E',
                      color: '#FFF',
                    },
                    search: true,
                    actionsColumnIndex: -1
                  }}
                />

                <Modal
                  open={modalEliminar}
                  onClose={abrirCerrarModalEliminar}>
                  {bodyEliminar}
                </Modal>

              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>

    </Paper>
  );
}