import React from 'react';
import { useState, useEffect } from 'react'

import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useHistory } from "react-router-dom";

import { Modal, Button } from '@mui/material';
import { useTheme, useStyles } from "../styles.js"
import { Grid, ThemeProvider, StyledEngineProvider } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import MaterialTable from 'material-table';

import UserService from "../../services/horario.service.js";

let direction = "ltr";

export default function Fichadas() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true)

  const styles = useStyles();
  const classes = useStyles();
  const theme = useTheme;

  const [data, setData] = useState([]);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    nombre: '',
    codigo: '',
  })

  const peticionDelete = async () => {
    await UserService.delFichadas(consolaSeleccionada.id);
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const peticionGet = async () => {
    const result = await UserService.getFichadas();
    setData(result);
  }

  const abrirCerrarModalInsertar = () => {
    history.push(process.env.PUBLIC_URL + "/solicitudesaddmod/")
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola); 
    (caso === 'Editar') ? history.push(process.env.PUBLIC_URL + "/solicitudesaddmod/" + consola.id + "/" +  consola.fecha + "/" +consola.idempleado ) : abrirCerrarModalEliminar()
  }

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar la Fichada <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const inicio = () => {
    history.push(process.env.PUBLIC_URL + "/controlfichadas")
  }

  useEffect(() => {

    const getDatos = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getFichadas();
        if (result) {
          setData(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e)
        setIsLoading(false);
        history.push(process.env.PUBLIC_URL + "/login");
      }
    }
    getDatos();

  }, []);

  return (
    <Paper className={classes.root}>

      <CssBaseline />

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Fichadas
          </Typography>
        </Toolbar>
      </AppBar>

      <br />
      <Breadcrumbs aria-label="breadcrumb">
        <Button style={{ color: "#fff", backgroundColor: "#004F9E" }} variant="contained" onClick={() => abrirCerrarModalInsertar()}>Nueva Solicitud</Button>
      </Breadcrumbs>

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ maxWidth: "100%", direction }}>
            <Grid container>
              <Grid item xs={12}>

                <MaterialTable
                  title=""

                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Buscar...'
                    },
                    header: {
                      actions: 'Acciones'
                    },
                    body: {
                      editRow: {
                        deleteText: 'Estas seguro de eliminar este registro ?'
                      }
                    },
                  }}

                  columns={[
                    {
                      title: 'Legajo',
                      field: 'legajo',
                    },
                    {
                      title: 'Nombre',
                      field: 'nombre',
                    },
                    {
                      title: 'Apellido',
                      field: 'apellido',
                    },
                    {
                      title: 'Fecha',
                      field: 'fecha',
                    },
                    {
                      title: 'Fichada',
                      field: 'fichada',
                    },
                    {
                      title: 'Horario',
                      field: 'horario',
                    },
                    {
                      title: 'H/Trabajar',
                      field: 'hatrabajar',
                    },
                    {
                      title: 'H/Trabajadas',
                      field: 'htrabajadas',
                    },
                    {
                      title: 'Status',
                      field: 'status',
                    },
                    {
                      title: 'Novedades',
                      field: 'novedades',
                    },
                    {
                      title: 'Detalle',
                      field: 'detalle',
                    },
                    {
                      title: 'Chequeada',
                      field: 'chequeada',
                    },
                    {
                      title: 'Cheq/RRHH',
                      field: 'cheqrrhh',
                    },
                  ]}
                  data={data}
                  options={{
                    headerStyle: {
                      backgroundColor: '#004F9E',
                      color: '#fff',
                    },
                    search: true,
                    actionsColumnIndex: -1
                  }}
                />

                <Modal
                  open={modalEliminar}
                  onClose={abrirCerrarModalEliminar}>
                  {bodyEliminar}
                </Modal>

              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>

    </Paper>
  );
}