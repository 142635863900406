import React from 'react';
import { useState, useEffect, useContext } from 'react'

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import UserService from "../../services/areas.service";
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    nombre: yup
        .string('Nombre de UP/Almacen requerido')
        .required('Nombre de UP/Almacen requerido'),
});

export default function Areasadd(props) {
    const [isLoading, setIsLoading] = useState(false)
    const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            nombre: '',
            codigo: '',
            yasimiento: '',
            localidad: '',
            provincia: '',
            localizacion: '',
            nota: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //alert(JSON.stringify(values, null, 2));
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const peticionPost = async (data) => {
        const response = await UserService.addmodArea(id, data);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/areas");
    }

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/flota")
    }

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Nueva Area
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <TextField
                            name="nombre"
                            className={styles.inputMaterial}
                            label="Area"
                            autoFocus={true}
                            required
                            value={formik.values.nombre}
                            onChange={formik.handleChange}
                            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                            helperText={formik.touched.nombre && formik.errors.nombre}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="codigo"
                            className={styles.inputMaterial}
                            label="Nro de Area"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                            helperText={formik.touched.codigo && formik.errors.codigo}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="localidad"
                            className={styles.inputMaterial}
                            label="Localidad"
                            value={formik.values.localidad}
                            onChange={formik.handleChange}
                            error={formik.touched.localidad && Boolean(formik.errors.localidad)}
                            helperText={formik.touched.localidad && formik.errors.localidad}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="provincia"
                            className={styles.inputMaterial}
                            label="Provincia"
                            value={formik.values.provincia}
                            onChange={formik.handleChange}
                            error={formik.touched.provincia && Boolean(formik.errors.provincia)}
                            helperText={formik.touched.provincia && formik.errors.provincia}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="localizacion"
                            className={styles.inputMaterial}
                            label="Localizacion"
                            value={formik.values.localizacion}
                            onChange={formik.handleChange}
                            error={formik.touched.localizacion && Boolean(formik.errors.localizacion)}
                            helperText={formik.touched.localizacion && formik.errors.localizacion}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextareaAutosize
                            name="nota"
                            aria-label="empty textarea"
                            className={styles.inputMaterial}
                            minRows={3} label="Nota"
                            placeholder="Nota"
                            value={formik.values.nota}
                            onChange={formik.handleChange}
                            error={formik.touched.nota && Boolean(formik.errors.nota)}
                            helperText={formik.touched.nota && formik.errors.nota}
                        />
                    </Grid>

                </Grid>

                <div align="right">
                    <Button color="primary" type="submit">Agregar</Button>
                    <Button color="primary" onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>

        </Paper>
    );
}