import React from 'react';
import { useState, useEffect, useContext } from 'react'

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        //padding: (2, 4, 3),
        padding: '2px 4px 3px', // Corrige la definición de padding
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

const dataSet1 = [];

const ComprasExcel = ({ url }) => {

    const styles = useStyles();
    const classes = useStyles();

    // Personaliza los textos del archivo Excel
    const excelData = {
        sheets: [
            {
                data: url,
                columns: [
                    { label: 'Numero', value: 'numero' },
                    { label: 'Responsable', value: 'responsable' },
                    { label: 'Solicitante', value: 'agente' },
                    { label: 'Sector', value: 'sector' },
                    { label: 'Estado', value: 'estado' },
                    { label: 'Fecha de Novedad', value: 'fecha_de_novedad' },
                    { label: 'Fecha de Compra', value: 'fecha_de_compra' },
                    { label: 'Fecha de Entrega', value: 'fecha_de_entrega' },
                ],
                name: 'Empleados',
            },
        ],
        fileName: 'MisDatos', // Cambia el nombre del archivo
    };

    return (
        <Paper className={classes.root}>

            <ExcelFile
                filename={excelData.fileName}
                element={<button className="mi-boton">Descargar Excel</button>} // Personaliza el botón
                hideElement // Oculta el elemento original de descarga
            >

                {excelData.sheets.map((sheet, index) => (
                    <ExcelSheet key={index} data={sheet.data} name={sheet.name}>
                        {sheet.columns.map((column, index) => (
                            <ExcelColumn key={index} label={column.label} value={column.value} />
                        ))}
                    </ExcelSheet>
                ))}


            </ExcelFile>

        </Paper>
    );
}
export default ComprasExcel;
