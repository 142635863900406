import authHeader from "../../../services/auth-header";
import authHeaderMitre from "../../../services/auth-header_mitre";
import { handleResponse } from '../../../services/handle-response';

const API_URL = 'https://devapi.casiba.ar/api/v1/';
const API_URL_MITRE = 'https://api.casiba.net/api/v1/';

const user = JSON.parse(localStorage.getItem('user3'));

const getTransacciones = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeaderMitre() };
  return (fetch(API_URL_MITRE + 'transacciones', requestOptions).then(handleResponse));
};

const aprobarTransaccion = (row,estado, nota) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('nota', nota);
  data.append('estado', estado);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'aprobar_transaccion', requestOptions).then(handleResponse));
};

//trae datos pora el informe
const getComprasInforme = (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_compras_informe', requestOptions).then(handleResponse));
};

export default {
  getTransacciones,
  getComprasInforme,
  aprobarTransaccion,
};
