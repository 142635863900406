import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from '@mui/styles';

import { mdiShopping, mdiBookInformationVariant, mdiCar, mdiGasStation, mdiHomeCity } from '@mdi/js'
import { mdiWrench } from '@mdi/js';
import { mdiHanger } from '@mdi/js';
import { mdiLaptop } from '@mdi/js';
import { mdiCellphone } from '@mdi/js';
import { mdiAccountArrowLeft } from '@mdi/js';
import { mdiGlasses } from '@mdi/js';
import { mdiQrcode } from '@mdi/js';
import { mdiCardAccountDetails } from '@mdi/js';
import { mdiMicrosoftExcel } from '@mdi/js';
import { mdiToolbox } from '@mdi/js';
import { mdiTools } from '@mdi/js';

import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../AuthContext';
import { useStyles } from '../styles'; // Importa los estilos desde el archivo styles.js

const Administrador = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const { currentUser, showAdmin, showOwner, showUser } = useContext(AuthContext);
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    return (
        <>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Administrador
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <sections>

                {showOwner && (
                    <>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/usuarios`} className="nav-link">
                                            <Icon
                                                path={mdiToolbox}
                                                title="Usuarios"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Usuarios
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/gruposjerarquicos`} className="nav-link">
                                            <Icon
                                                path={mdiWrench}
                                                title="Grupos Jerarquicos"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Grupos Jerarquicos
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/equipos`} className="nav-link">
                                            <Icon
                                                path={mdiWrench}
                                                title="Equipos"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Equipos
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/permisos`} className="nav-link">
                                            <Icon
                                                path={mdiTools}
                                                title="Permisos"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Permisos
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/core`} className="nav-link">
                                            <Icon
                                                path={mdiTools}
                                                title="core"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Core
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Icon
                                            path={mdiMicrosoftExcel}
                                            title="Informe"
                                            size={2}
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="#004F9E"
                                        />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Informe
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/sucursales`} className="nav-link">
                                            <Icon
                                                path={mdiWrench}
                                                title="Sucursales"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Sucursales
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/sectores`} className="nav-link">
                                            <Icon
                                                path={mdiWrench}
                                                title="Sectores"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Sectores
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>

                    </>
                )}

            </sections>
        </>
    );
};

export default Administrador;
