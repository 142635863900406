import React from 'react';
import { useState, useEffect, useContext } from 'react'

import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';

import BasicDocument from "../BasicDocument";
import { useParams } from 'react-router';
import UserService from "../../services/user.service";

export default function Combustiblescon(props) {
    const [isLoading, setIsLoading] = useState(true)
    const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const GetData = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getCombustibleid(id);
                if (result) {
                    setData(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(true);
                }
            } catch (e) {
                setIsLoading(true);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/combustibles")
    }

    return (
        <Paper>
            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Comprobante
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <BasicDocument data={data} />
        </Paper>
    );
}