import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const SnackbarMessage = ({ open, message, severity, onClose }) => {
  if(message=='Login')
  message='¡Credenciales no validas!';
  if(message=='500')
  message='<div><h4>Error al cargar los datos.<br/>Es posible que haya un problema con la conexión.<br/> Para recargar la página, puedes presionar F5 en tu computadora o deslizar la pantalla hacia abajo en tu celular.<br/> Si el problema persiste, por favor, inténtalo de nuevo más tarde o contacta a Sistemas.</h4></div>';

  const handleClose = () => {
    onClose();
  };

  return (
    <Snackbar 
    open={open} 
    autoHideDuration={30000} 
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}    
    >
      <MuiAlert elevation={6} variant="filled" severity={severity} onClose={handleClose}>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarMessage;
