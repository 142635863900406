/**
 * Intranet
 * update 04/2022
 * By Sergio Sam 
 */

import React from 'react';

import { makeStyles } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Modal, Button } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import MaterialTable from 'material-table';

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography';

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

export default function Rendicion(props) {
    const [isLoading, setIsLoading] = useState(true)
    const styles = useStyles();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        id: '',
        nombre: '',
        codigo: '',
    })

    const peticionGet = async () => {
        const result = await UserService.getFacturacion();
        setData(result.data);
    }

    const peticionDelete = async () => {
        const response = await UserService.delVerdes(consolaSeleccionada.id);
        var data = response.data;
        //setData(data.filter(consola => consola.id !== consolaSeleccionada.id));
        peticionGet();
        abrirCerrarModalEliminar();
    }

    const abrirCerrarModalInsertar = () => {
        props.history.push(process.env.PUBLIC_URL + "/verdesadd/")
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        //console.log(consola.id);
        (caso === 'Editar') ? props.history.push("/verdesmod/" + consola.id) : abrirCerrarModalEliminar()
    }

    useEffect(() => {
        const GetData = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getRendicion();
                if (result) {
                    setData(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();
    }, []);

    const bodyEliminar = (
        <div className={styles.modal}>
            <p>Estás seguro que deseas eliminar el Cliente <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
            <div align="right">
                <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
                <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
            </div>
        </div>
    )

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/bas")
    }

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Rendicion
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div style={{ maxWidth: "100%", direction }}>
                        <Grid container>
                            <Grid item xs={12}>

                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },

                                    }}

                                    columns={[
                                        {
                                            title: 'Rendicion',
                                            field: 'expr6',
                                            defaultGroupOrder: 0,
                                        },
                                        {
                                            title: 'Comprobante',
                                            field: 'nombrecmp',
                                        },
                                        {
                                            title: 'Numero',
                                            field: 'numero',
                                        },
                                        {
                                            title: 'Nombre',
                                            field: 'nombre',
                                        },
                                        {
                                            title: 'Importe',
                                            field: 'total',
                                            align: 'right',
                                        },
                                        {
                                            title: 'Total Parcial',
                                            field: 'suptotal',
                                            align: 'right'
                                        }
                                    ]}
                                    data={data}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1,
                                        grouping: true
                                    }}
                                />

                                <Modal
                                    open={modalEliminar}
                                    onClose={abrirCerrarModalEliminar}>
                                    {bodyEliminar}
                                </Modal>

                            </Grid>
                        </Grid>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>

        </Paper>
    );
}