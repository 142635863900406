import { getAuth, onIdTokenChanged, getIdToken } from "firebase/auth";

export default function authHeader() {
  //const user = JSON.parse(localStorage.getItem('user3'));
  //const accessToken = user?.stsTokenManager?.accessToken;
  const accessToken = localStorage.getItem('accessToken');
  
  if (accessToken) {
   return { Authorization: 'Bearer ' + accessToken };
  } else {
    return {};
  }
}

