import React from 'react';

import { withStyles, makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import { Grid, InputLabel, Select, MenuItem } from '@mui/material';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import InputAdornment from '@mui/material/InputAdornment';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

const validationSchema = yup.object({
  nombre: yup
    .string('Nombre del usuario requerido')
    .required('Nombre del usuario requerido'),
  email: yup
    .string('Email del usuario requerido')
    .email('Email del usuario requerido')
    .required('Email del usuario requerido'),
  password: yup.string()
    .required('Password es requerido')
    .matches(
      /^(?=.*[A-Z]).{6,40}$/,
      'Password debe tener al menos 6 caracteres y contener al menos una letra mayúscula'
    ),
  confirmPassword: yup.string()
    .required('Confirm Password es requerido')
    .oneOf([yup.ref('password'), null], 'Confirm Password no coinciden'),
  acceptTerms: yup.bool().oneOf([true], 'Debes aceptar los términos y condiciones'),
});

export default function Perfil(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [shown, setShown] = useState(true);
  const [shown1, setShown1] = useState(true);
  const switchShown = () => setShown(!shown);
  const switchShown1 = () => setShown1(!shown1);

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      nombre: '',
      username: '',
      email: '',
      password: '',
      agente: '',
      empresa: '',
      idsector: '',
      roles: [], // Inicialmente, no hay roles seleccionados
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
      //alert(JSON.stringify(values, null, 2));
    },
  });

  const { id } = useParams();

  const styles = useStyles();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [sectores, setSectores] = useState([]);
  const [dataempresas, setDataEmpresas] = useState([]);

  const [roles] = useState([
    { id: 'ROLE_MASTER', nombre: 'Master' },
    { id: 'ROLE_OWNER', nombre: 'Dueño' },
    { id: 'ROLE_ADMIN', nombre: 'Administrador' },
    { id: 'ROLE_ADMINISTRADORES', nombre: 'Administradores' },
    { id: 'ROLE_USER', nombre: 'Usuario' },
  ]);

  const peticionPost = async (values) => {
    const response = await UserService.addmodUsuario(id, values);
    setData(data.concat(response.data))
    cerrarEditar()
  }

  const cerrarEditar = () => {
    props.history.push(process.env.PUBLIC_URL + "/home");
  }

  useEffect(() => {
    const GetData = async () => {
      try {
        const response = await UserService.getUsuarioProfile();
        if (response) {
          formik.setValues(response);
        } else {
        }
      } catch (e) {
        handleShowMessage('500');
      }
    };
    GetData();
  }, []);

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/usuarios")
  }

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Perfil
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <form onSubmit={formik.handleSubmit}>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              name="nombre"
              className={styles.inputMaterial}
              label="Nombre"
              autoFocus={true}
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="email"
              className={styles.inputMaterial}
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type={shown ? 'text' : 'password'}
              name="password"
              className={styles.inputMaterial}
              label="Password debe tener como minimo una mayucula y 6 caracteres"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)} // Agrega esto
              helperText={formik.touched.password && formik.errors.password} // Agrega esto
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={switchShown}>
                      {shown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type={shown1 ? 'text' : 'password'}
              variant="outlined"
              margin="normal"
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              autoComplete="confirmPassword"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={switchShown1}>
                      {shown1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <SnackbarMessage
            open={open}
            message={errorMessage}
            severity="success"
            onClose={handleCloseMessage}
          />

        </Grid>
        <br /><br />
        <div align="right">
          <Button color="primary" type="submit">Editar</Button>
          <Button onClick={() => cerrarEditar()}>Cancelar</Button>
        </div>
      </form>
    </Paper>
  );
}