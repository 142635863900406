import React from 'react';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import UserService from "../../services/user.service";

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
  nombre: yup
    .string('Nombre de Sucursal requerido')
    .required('Nombre de Sucursal requerido'),
});

export default function Sucursalesmod(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState([
    {
      id: 'Id',
      field: 'id',
      hidden: true,
    },
    {
      title: 'Nombre',
      field: 'nombre'
    },
  ]);

  //  const [data, setData] = useState([
  //    { nombre: 'Mehmet' },
  //    { nombre: 'Zerya Betül' },
  //  ]);
  const [data, setData] = useState([]);

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      nombre: '',
      codigo: '',
      localidad: '',
      provincia: '',
      localizacion: '',
      nota: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
    },
  });

  const { id } = useParams();

  const styles = useStyles();
  const classes = useStyles();

  const [datos, setDatos] = useState([]);

  const peticionPost = async (values) => {
    const response = await UserService.addmodSucursal(id, values);
    cerrarEditar()
  }

  const cerrarEditar = () => {
    props.history.push(process.env.PUBLIC_URL + "/sucursales");
  }

  useEffect(() => {
    if (id) {

      const GetData = async () => {
        try {
          setIsLoading(true);
          const response = await UserService.getSucursal(id);
          if (response) {
            formik.setValues(response);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } catch (e) {
          setIsLoading(false);
          props.history.push(process.env.PUBLIC_URL + "/login");
        }
      }
      GetData();
    }
  }, []);

  const inicio = () => {
    props.history.goBack();
  }

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Agregar/Modificar Sucursal
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <form onSubmit={formik.handleSubmit}>

        <Grid container spacing={3}>

          <Grid item xs={6}>
            <TextField
              name="nombre"
              className={styles.inputMaterial}
              label="Sucursal"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="codigo"
              className={styles.inputMaterial}
              label="Nro de Sucursal"
              value={formik.values.codigo}
              onChange={formik.handleChange}
              error={formik.touched.codigo && Boolean(formik.errors.codigo)}
              helperText={formik.touched.codigo && formik.errors.codigo}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="localidad"
              className={styles.inputMaterial}
              label="Localidad"
              value={formik.values.localidad}
              onChange={formik.handleChange}
              error={formik.touched.localidad && Boolean(formik.errors.localidad)}
              helperText={formik.touched.localidad && formik.errors.localidad}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="provincia"
              className={styles.inputMaterial}
              label="Provincia"
              value={formik.values.provincia}
              onChange={formik.handleChange}
              error={formik.touched.provincia && Boolean(formik.errors.provincia)}
              helperText={formik.touched.provincia && formik.errors.provincia}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="localizacion"
              className={styles.inputMaterial}
              label="Localizacion"
              value={formik.values.localizacion}
              onChange={formik.handleChange}
              error={formik.touched.localizacion && Boolean(formik.errors.localizacion)}
              helperText={formik.touched.localizacion && formik.errors.localizacion}
            />
          </Grid>

          <Grid item xs={12}>
            <TextareaAutosize
              name="nota"
              aria-label="empty textarea"
              className={styles.inputMaterial}
              minRows={3} label="Nota"
              placeholder="Nota"
              value={formik.values.nota}
              onChange={formik.handleChange}
              error={formik.touched.nota && Boolean(formik.errors.nota)}
              helperText={formik.touched.nota && formik.errors.nota}
            />
          </Grid>

        </Grid>

        <div align="right">
          <Button color="primary" type="submit">Enviar</Button>
          <Button color="primary" onClick={() => cerrarEditar()}>Cancelar</Button>
        </div>
      </form>


    </Paper>
  );
}