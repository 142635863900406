import React from "react";
import QRCode from "qrcode.react";
import { useHistory } from "react-router-dom";

const QRModal = ({ url }) => {
  const history = useHistory();
  const cerrarEditar = (e) => {
    history.push(process.env.PUBLIC_URL + "/recursos");
  };

  return (
    <div className="modal fade" id="qrModalToggle" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              QR CODE
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => cerrarEditar()}>
              <span aria-hidden="true" className="align-top">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <QRCode
              size={260}
              value={url}
            />
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary ml-auto" data-dismiss="modal" onClick={() => cerrarEditar()}>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRModal;
