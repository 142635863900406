import React from 'react';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import FilesUploadPlanera from "./FilesUploadPlanera";
import FilesAprobarPlanera from "./FilesAprobarPlanera";
import Autocomplete from '@mui/material/Autocomplete';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import UserService from "./services/planera.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    status: yup
        .string('Estado requerido')
        .required('Estado requerido'),
    titulo: yup
        .string('Archivo requerido')
        .required('Archivo requerido'),
    subtitulo1: yup
        .string('Seleccione un area')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Seleccione un area'),
    obra: yup
        .string('Modelo requreido ')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Modelo requerido'),
});

export default function PlaneraAddmod(props) {
    const [isLoading, setIsLoading] = useState(true)

    const formik = useFormik({
        initialValues: {
            archivo: '',
            titulo: '',
            modelo: '',
            subtitulo1: '',
            subtitulo2: '',
            cliente: '',
            obra: '',
            linea: '',
            modelo: '',
            tipo: '',
            dibujante: '',
            nota: '',
            id: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id, ids } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [data, setData] = useState([]);

    const [state, setState] = useState([
        { id: 'Aprobado', value: 'Aprobado', label: 'Aprobado' },
        { id: 'En Revision', value: 'En Revision', label: 'En Revision' },
    ]);

    const [combustible, setCombustible] = useState([
        { id: 'Nafta', nombre: 'Nafta' },
        { id: 'Gas Oil', nombre: 'Gas Oil' },
        { id: 'Gnc', nombre: 'Gnc' },
    ]);

    const [tipo, setTipo] = useState([
        { id: 'C', value: 'C', label: 'Comercial' },
        { id: 'S', value: 'S', label: 'Standard' },
    ]);

    const [linea, setLinea] = useState([
        { id: 'ADS', value: 'ADS', label: 'ADS' },
        { id: 'BA', value: 'BA', label: 'BA' },
        { id: 'BE', value: 'BE', label: 'BE' },
        { id: 'BL', value: 'BL', label: 'BL' },
        { id: 'BPFM', value: 'BPFM', label: 'BPFM' },
        { id: 'BPFM', value: 'BPFM', label: 'BPFM' },
        { id: 'CEG', value: 'CEG', label: 'CEG' },
        { id: 'CL', value: 'CL', label: 'CL' },
        { id: 'CTM', value: 'CTM', label: 'CTM' },
        { id: 'CMP', value: 'CMP', label: 'CMP' },
        { id: 'DBS', value: 'DBS', label: 'DBS' },
        { id: 'DAE', value: 'DAE', label: 'DAE' },
        { id: 'DPS', value: 'DPS', label: 'DPS' },
        { id: 'Filtros', value: 'Filtros', label: 'Filtros' },
        { id: 'FL', value: 'FL', label: 'FL' },
        { id: 'GB', value: 'GB', label: 'GB' },
        { id: 'GP-GPA', value: 'GP-GPA', label: 'GP-GPA' },
        { id: 'GP', value: 'GP', label: 'GP' },
        { id: 'GVT', value: 'GVT', label: 'GVT' },
        { id: 'HL', value: 'HL', label: 'HL' },
        { id: 'IN', value: 'IN', label: 'IN' },
        { id: 'Passtru', value: 'Passtru', label: 'Passtru' },
        { id: 'TM', value: 'TM', label: 'TM' },
        { id: 'UTA', value: 'UTA', label: 'UTA' },
        { id: 'MFU', value: 'MFU', label: 'MFU' },
        { id: 'MH', value: 'MH', label: 'MH' },
        { id: 'MT', value: 'MT', label: 'MT' },
        { id: 'MR', value: 'MR', label: 'MR' },
        { id: 'LVA', value: 'LVA', label: 'LVA' },
        { id: 'TNR', value: 'TNR', label: 'TNR' },
        { id: 'TNR', value: 'TNR', label: 'TNR' },
        { id: 'TK', value: 'TK', label: 'TK' },
        { id: 'VE', value: 'VE', label: 'VE' },
        { id: 'VL', value: 'VL', label: 'VL' },
        { id: 'VM', value: 'VM', label: 'VM' },
        { id: 'VM', value: 'VM', label: 'VM' },
        { id: 'ZF', value: 'ZF', label: 'ZF' },
    ]);

    const [dibujante, setDibujante] = useState([
        { id: '1', value: '1', label: 'Carlos P' },
        { id: '2', value: '2', label: 'Javier B' },
        { id: '3', value: '3', label: 'Luis N' },
        { id: '5', value: '5', label: 'Daniel N' },
        { id: '6', value: '6', label: 'Nahuel' },
        { id: '8', value: '8', label: 'Mariano R' },
        { id: '9', value: '9', label: 'Gabriel A' },
        { id: '10', value: '10', label: 'Brian' },
        { id: '11', value: '11', label: 'Juan' },
        { id: '60', value: '60', label: 'Federico' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodPlano(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/planera");
    }

    useEffect(() => {

        const GetData = async () => {
            try {
                setIsLoading(true);
                const response = await UserService.getPlano(id);
                if (response) {
                    formik.setValues(response);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetData();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/planera")
    }

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h6" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Editar Plano
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={state}
                            value={state.find((option) => option.id === formik.values.status) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('status', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Estado"
                                    name="status"
                                    error={Boolean(formik.touched.status && formik.errors.status)}
                                    helperText={formik.touched.status && formik.errors.status}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="titulo"
                            className={styles.inputMaterial}
                            label="Titulo"
                            autoFocus={true}
                            required
                            value={formik.values.titulo}
                            onChange={formik.handleChange}
                            error={formik.touched.titulo && Boolean(formik.errors.titulo)}
                            helperText={formik.touched.titulo && formik.errors.titulo}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="subtitulo1"
                            className={styles.inputMaterial}
                            label="Subtitulo1"
                            value={formik.values.subtitulo1}
                            onChange={formik.handleChange}
                            error={formik.touched.subtitulo1 && Boolean(formik.errors.subtitulo1)}
                            helperText={formik.touched.subtitulo1 && formik.errors.subtitulo1}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="subtitulo2"
                            className={styles.inputMaterial}
                            label="Subtitulo2"
                            value={formik.values.subtitulo2}
                            onChange={formik.handleChange}
                            error={formik.touched.subtitulo2 && Boolean(formik.errors.subtitulo2)}
                            helperText={formik.touched.subtitulo2 && formik.errors.subtitulo2}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="cliente"
                            className={styles.inputMaterial}
                            label="Cliente"
                            value={formik.values.cliente}
                            onChange={formik.handleChange}
                            error={formik.touched.cliente && Boolean(formik.errors.cliente)}
                            helperText={formik.touched.cliente && formik.errors.cliente}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="obra"
                            className={styles.inputMaterial}
                            label="Obra"
                            value={formik.values.obra}
                            onChange={formik.handleChange}
                            error={formik.touched.obra && Boolean(formik.errors.obra)}
                            helperText={formik.touched.obra && formik.errors.obra}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="modelo"
                            className={styles.inputMaterial}
                            label="Modelo"
                            value={formik.values.modelo}
                            onChange={formik.handleChange}
                            error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                            helperText={formik.touched.modelo && formik.errors.modelo}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={linea}
                            value={linea.find((option) => option.id === formik.values.linea) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('linea', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Linea"
                                    name="linea"
                                    error={Boolean(formik.touched.linea && formik.errors.linea)}
                                    helperText={formik.touched.linea && formik.errors.linea}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={tipo}
                            value={tipo.find((option) => option.id === formik.values.tipo) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('tipo', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Tipo"
                                    name="tipo"
                                    error={Boolean(formik.touched.tipo && formik.errors.tipo)}
                                    helperText={formik.touched.tipo && formik.errors.tipo}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={dibujante}
                            value={dibujante.find((option) => option.id === formik.values.dibujante) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('dibujante', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Dibujante"
                                    name="dibujante"
                                    error={Boolean(formik.touched.dibujante && formik.errors.dibujante)}
                                    helperText={formik.touched.dibujante && formik.errors.dibujante}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="nota"
                            className={styles.inputMaterial}
                            label="Nota"
                            value={formik.values.nota}
                            onChange={formik.handleChange}
                            error={formik.touched.nota && Boolean(formik.errors.nota)}
                            helperText={formik.touched.nota && formik.errors.nota}
                        />
                    </Grid>

                    <SnackbarMessage
                        open={open}
                        message={errorMessage}
                        severity="success"
                        onClose={handleCloseMessage}
                    />

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>

            </form>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <FilesUploadPlanera id={id} documento={"Planos"} />
                </Grid>
                <Grid item xs={6}>
                    <FilesAprobarPlanera id={id} ids={ids} documento={"Tickets"} />
                </Grid>
            </Grid>

        </Paper>
    );
}