import React from 'react';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import FilesUploadBetc from "./FilesUploadBetc";

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    coditm: yup
        .string('Archivo requerido')
        .required('Archivo requerido'),
});


export default function Planeramod(props) {
    const { id } = useParams();
    const betc = JSON.parse(decodeURIComponent(id));
    console.log(betc);

    const [isLoading, setIsLoading] = useState(true)
    //var hoy=selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate();
    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            archivo: '',
            coditm: betc.coditm,
            nota: '',
            precio: betc.precio,
            descripcion: betc.descripcion,
            id: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //alert(JSON.stringify(values, null, 2));
        },
    });

    const styles = useStyles();
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);
    //const [selectedDate] = useState(new Date());

    const peticionPost = async (values) => {
        const response = await UserService.addmodBetc(betc.coditm, values);
        setData(data.concat(response.data))
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/betc");
    }

    useEffect(() => {
        const GetData = async () => {
            try {
                setIsLoading(true);
                const response = await UserService.getBetc(betc.coditm);
                if (response) {
                    var dataNueva = response.data;
                    dataNueva.map(consola => {
                        formik.initialValues.archivo = consola.archivo;
                        formik.initialValues.nota = consola.nota;
                        formik.initialValues.id = consola.id;
                        setDatos(consola.areas);
                    })
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                //props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/betc")
    }

    const planera = () => {
        props.history.push(process.env.PUBLIC_URL + "/betc")
    }

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Editar Producto
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <TextField
                            name="coditm"
                            className={styles.inputMaterial}
                            label="Codigo"
                            autoFocus={true}
                            required
                            value={formik.values.coditm}
                            onChange={formik.handleChange}
                            error={formik.touched.coditm && Boolean(formik.errors.coditm)}
                            helperText={formik.touched.coditm && formik.errors.coditm}
                            disabled  // Agregamos la propiedad disabled aquí
                        />
                    </Grid>
 
                    <Grid item xs={6}>
                        <TextField
                            name="precio"
                            className={styles.inputMaterial}
                            label="Precio"
                            autoFocus={true}
                            required
                            value={formik.values.precio}
                            onChange={formik.handleChange}
                            error={formik.touched.precio && Boolean(formik.errors.precio)}
                            helperText={formik.touched.precio && formik.errors.precio}
                            disabled  // Agregamos la propiedad disabled aquí
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="descripcion"
                            className={styles.inputMaterial}
                            label="Descripcion"
                            autoFocus={true}
                            required
                            value={formik.values.descripcion}
                            onChange={formik.handleChange}
                            error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                            helperText={formik.touched.descripcion && formik.errors.descripcion}
                            disabled  // Agregamos la propiedad disabled aquí
                        />
                    </Grid>
 
                    <Grid item xs={12}>
                        <TextField
                            name="nota"
                            className={styles.inputMaterial}
                            label="Nota"
                            value={formik.values.nota}
                            onChange={formik.handleChange}
                            error={formik.touched.nota && Boolean(formik.errors.nota)}
                            helperText={formik.touched.nota && formik.errors.nota}
                        />
                    </Grid>

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>

            <Grid item xs={12}>
                <FilesUploadBetc id={betc.coditm} documento={"Productos"} />
            </Grid>

        </Paper>
    );
}