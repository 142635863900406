import React, { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";

import { mdiMicrosoftExcel,mdiCardAccountDetails,mdiQrcode,mdiGlasses,mdiWrench,mdiHanger,mdiLaptop,mdiCellphone,mdiAccountArrowLeft } from '@mdi/js';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';

import { AuthContext } from '../../components/AuthContext';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

const Recursos = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);

    useEffect(() => {

    }, []);

    const { showUser, OwnedProducts } = useContext(AuthContext);

    const classes = useStyles();

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    return (

        <>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Recursos
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <sections>
                {showUser && (
                    <>

                        {OwnedProducts.find(product => product.name === "Recursos") && (
                            OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Recursos").modules.find(
                                    module => module.name === "Herramientas" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/herramientas`} className="nav-link">
                                                    <Icon path={mdiWrench}
                                                        title="Herramientas"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Herramientas
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Recursos") && (
                            OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Recursos").modules.find(
                                    module => module.name === "EPP" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/epps`} className="nav-link">
                                                    <Icon path={mdiGlasses}
                                                        title="EPP"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                EPP
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}


                        {OwnedProducts.find(product => product.name === "Recursos") && (
                            OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Recursos").modules.find(
                                    module => module.name === "Indumentaria" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/indumentarias`} className="nav-link">
                                                    <Icon path={mdiHanger}
                                                        title="Indumentaria"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Indumentaria
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}


                        {OwnedProducts.find(product => product.name === "Recursos") && (
                            OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Recursos").modules.find(
                                    module => module.name === "Informatica" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/informaticas`} className="nav-link">
                                                    <Icon path={mdiLaptop}
                                                        title="Informatica"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Informatica
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Recursos") && (
                            OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Recursos").modules.find(
                                    module => module.name === "Celulares" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/celulares`} className="nav-link">
                                                    <Icon path={mdiCellphone}
                                                        title="Celulares"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Celulares
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Recursos") && (
                            OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Recursos").modules.find(
                                    module => module.name === "Asignar Recursos" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/recursosempleados`} className="nav-link">
                                                    <Icon path={mdiAccountArrowLeft}
                                                        title="Asignar Recursos"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Asignar Recursos
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Recursos") && (
                            OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Recursos").modules.find(
                                    module => module.name === "Codigos QR" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/qr`} className="nav-link">
                                                    <Icon path={mdiQrcode}
                                                        title="Codigos QR"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Codigos QR
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Recursos") && (
                            OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Recursos").modules.find(
                                    module => module.name === "Mis Recursos" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/misrecursos/mis`} className="nav-link">
                                                    <Icon path={mdiCardAccountDetails}
                                                        title="Mis Recusros"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Mis Recursos
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}

                        {OwnedProducts.find(product => product.name === "Recursos") && (
                            OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                            OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                        ) && (
                                OwnedProducts.find(product => product.name === "Recursos").modules.find(
                                    module => module.name === "Informe" &&
                                        (module.permissions.consultar ||
                                            module.permissions.modificar ||
                                            module.permissions.eliminar)
                                )
                                &&
                                <items>
                                    <Grid item xs>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1">
                                                <Link to={`${process.env.PUBLIC_URL}/informerecursos`} className="nav-link">
                                                    <Icon path={mdiMicrosoftExcel}
                                                        title="Informe"
                                                        size={2}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#004F9E"
                                                    />
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Informe
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </items>
                            )}
                    </>
                )}

            </sections>

        </>

    );
};

export default Recursos;
