import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from '@mui/styles';

import { mdiShopping, mdiBookInformationVariant, mdiCar, mdiGasStation, mdiHomeCity } from '@mdi/js'
import { mdiWrench } from '@mdi/js';
import { mdiHanger } from '@mdi/js';
import { mdiLaptop } from '@mdi/js';
import { mdiCellphone } from '@mdi/js';
import { mdiAccountArrowLeft } from '@mdi/js';
import { mdiGlasses } from '@mdi/js';
import { mdiQrcode } from '@mdi/js';
import { mdiCardAccountDetails } from '@mdi/js';
import { mdiMicrosoftExcel } from '@mdi/js';

import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { AuthContext } from '../AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

const Compras = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([]);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const { currentUser, showUser, showOwner, showMaster } = useContext(AuthContext);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const classes = useStyles();

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    return (

        <>
            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Compras
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <sections>
                {showUser && (
                    <>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/transacciones`} className="nav-link">
                                            <Icon path={mdiShopping}
                                                title="Herramientas"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Transacciones
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                        <items>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/comprasinformes`} className="nav-link">
                                            <Icon path={mdiMicrosoftExcel}
                                                title="Informe"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#004F9E"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Informe
                                    </Typography>
                                </Grid>
                            </Grid>
                        </items>
                    </>
                )}

            </sections>

        </>

    );
};

export default Compras;
