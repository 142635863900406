import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { mdiBookMultipleOutline, mdiBookMultiple, mdiPackageVariant, mdiClipboardListOutline, mdiBagChecked, mdiArchiveCogOutline } from '@mdi/js'

import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';
import Typography from '@mui/material/Typography';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';
import { useStyles } from '../styles'; // Importa los estilos desde el archivo styles.js

import UserService from "../../services/user.service";

const Bas = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([]);

  const { showUser, showOwner, OwnedProducts } = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/")
  }

  const descargarArchivo = (contenido, nombreArchivo) => {
    const blob = new Blob([contenido], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = nombreArchivo;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const santander = async () => {
    try {
      const response = await UserService.txtSantander();
      const data = response.data;

      if (data) {
        descargarArchivo(data, 'santander.txt');
      } else {
        console.error('No se recibió contenido de archivo TXT desde el servidor.');
      }
    } catch (error) {
      console.error('Error al obtener el archivo TXT:', error.message);
    }
  };

  const otrosbancos = async () => {
    try {
      const response = await UserService.txtOtrosBancos();
      const data = response.data;

      if (data) {
        descargarArchivo(data, 'otrosbancos.txt');
      } else {
        console.error('No se recibió contenido de archivo TXT desde el servidor.');
      }
    } catch (error) {
      console.error('Error al obtener el archivo TXT:', error.message);
    }
  };

  return (
    <>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Bas
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <sections>

        {showUser && (
          <>
            {OwnedProducts.find(product => product.name === "ERP") &&
              OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Facturacion") &&
              (
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Facturacion").permissions.consultar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Facturacion").permissions.modificar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Facturacion").permissions.eliminar ||
                showOwner
              ) && (
                <items>
                  <Grid item xs>
                    <Grid item xs container direction="column" spacing={2}>
                      <Typography gutterBottom variant="subtitle1">
                        <Link to={`${process.env.PUBLIC_URL}/facturacion`} className="nav-link">
                          <Icon path={mdiBookMultiple}
                            title="Facturacion"
                            size={2}
                            horizontal
                            vertical
                            rotate={180}
                            color="#004F9E"
                          />
                        </Link>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Facturacion
                      </Typography>
                    </Grid>
                  </Grid>
                </items>
              )}

            {OwnedProducts.find(product => product.name === "ERP") &&
              OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Refacturacion") &&
              (
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Refacturacion").permissions.consultar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Refacturacion").permissions.modificar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Refacturacion").permissions.eliminar ||
                showOwner
              ) && (
                <items>
                  <Grid item xs>
                    <Grid item xs container direction="column" spacing={2}>
                      <Typography gutterBottom variant="subtitle1">
                        <Link to={`${process.env.PUBLIC_URL}/refacturacion`} className="nav-link">
                          <Icon path={mdiBookMultipleOutline}
                            title="Refacturacion"
                            size={2}
                            horizontal
                            vertical
                            rotate={180}
                            color="#004F9E"
                          />
                        </Link>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Refacturacion
                      </Typography>
                    </Grid>
                  </Grid>
                </items>
              )}

            {OwnedProducts.find(product => product.name === "ERP") &&
              OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Rendicion") &&
              (
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Rendicion").permissions.consultar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Rendicion").permissions.modificar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Rendicion").permissions.eliminar ||
                showOwner
              ) && (
                <items>
                  <Grid item xs>
                    <Grid item xs container direction="column" spacing={2}>
                      <Typography gutterBottom variant="subtitle1">
                        <Link to={`${process.env.PUBLIC_URL}/rendicion`} className="nav-link">
                          <Icon path={mdiBagChecked}
                            title="Rendicion"
                            size={2}
                            horizontal
                            vertical
                            rotate={180}
                            color="#004F9E"
                          />
                        </Link>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Rendicion
                      </Typography>
                    </Grid>
                  </Grid>
                </items>
              )}

            {OwnedProducts.find(product => product.name === "ERP") &&
              OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Stock") &&
              (
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Stock").permissions.consultar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Stock").permissions.modificar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Stock").permissions.eliminar ||
                showOwner
              ) && (
                <items>
                  <Grid item xs>
                    <Grid item xs container direction="column" spacing={2}>
                      <Typography gutterBottom variant="subtitle1">
                        <Link to={`${process.env.PUBLIC_URL}/stock`} className="nav-link">
                          <Icon path={mdiPackageVariant}
                            title="Stock"
                            size={2}
                            horizontal
                            vertical
                            rotate={180}
                            color="#004F9E"
                          />
                        </Link>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Stock
                      </Typography>
                    </Grid>
                  </Grid>
                </items>
              )}

            {OwnedProducts.find(product => product.name === "ERP") &&
              OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Rpendientes") &&
              (
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Rpendientes").permissions.consultar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Rpendientes").permissions.modificar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Rpendientes").permissions.eliminar ||
                showOwner
              ) && (
                <items>
                  <Grid item xs>
                    <Grid item xs container direction="column" spacing={2}>
                      <Typography gutterBottom variant="subtitle1">
                        <Link to={`${process.env.PUBLIC_URL}/rpendientes`} className="nav-link">
                          <Icon path={mdiClipboardListOutline}
                            title="Remitos Pendientes"
                            size={2}
                            horizontal
                            vertical
                            rotate={180}
                            color="#004F9E"
                          />
                        </Link>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Remitos pendientes
                      </Typography>
                    </Grid>
                  </Grid>
                </items>
              )}

            {OwnedProducts.find(product => product.name === "ERP") &&
              OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Fsinremitos") &&
              (
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Fsinremitos").permissions.consultar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Fsinremitos").permissions.modificar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Fsinremitos").permissions.eliminar ||
                showOwner
              ) && (
                <items>
                  <Grid item xs>
                    <Grid item xs container direction="column" spacing={2}>
                      <Typography gutterBottom variant="subtitle1">
                        <Link to={`${process.env.PUBLIC_URL}/fsinremitos`} className="nav-link">
                          <Icon path={mdiClipboardListOutline}
                            title="Facturas sin Remitos"
                            size={2}
                            horizontal
                            vertical
                            rotate={180}
                            color="#004F9E"
                          />
                        </Link>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Facturas sin Remitos
                      </Typography>
                    </Grid>
                  </Grid>
                </items>
              )}

            {OwnedProducts.find(product => product.name === "ERP") &&
              OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Txt") &&
              (
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Txt").permissions.consultar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Txt").permissions.modificar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Txt").permissions.eliminar ||
                showOwner
              ) && (
                <items>
                  <Grid item xs>
                    <Grid item xs container direction="column" spacing={2}>
                      <Typography gutterBottom variant="subtitle1" onClick={santander}  >
                        <Icon path={mdiClipboardListOutline}
                          title="TXT Banco Santander"
                          size={2}
                          horizontal
                          vertical
                          rotate={180}
                          color="#004F9E"
                        />
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        TXT Banco Santander
                      </Typography>
                    </Grid>
                  </Grid>
                </items>
              )}

            {OwnedProducts.find(product => product.name === "ERP") &&
              OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Txt") &&
              (
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Txt").permissions.consultar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Txt").permissions.modificar ||
                OwnedProducts.find(product => product.name === "ERP").modules.find(module => module.name === "Txt").permissions.eliminar ||
                showOwner
              ) && (
                <items>
                  <Grid item xs>
                    <Grid item xs container direction="column" spacing={2}>
                      <Typography gutterBottom variant="subtitle1" onClick={otrosbancos}  >
                        <Icon path={mdiClipboardListOutline}
                          title="TXT Otros Bancos"
                          size={2}
                          horizontal
                          vertical
                          rotate={180}
                          color="#004F9E"
                        />
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        TXT Otros Bancos
                      </Typography>
                    </Grid>
                  </Grid>
                </items>
              )}

          </>
        )}

      </sections>
    </>
  );
};

export default Bas;
