import React from 'react';
import { useState, useEffect } from 'react'

import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { useParams } from 'react-router';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import MaterialTable from 'material-table';

import CoreService from "../../services/user.service";
import UserService from "./services/recursos.service";
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

export default function AsignarRecursos(props) {
    const [isLoading, setIsLoading] = useState(false)
    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [herramientas, setHerramientas] = useState([]);
    const [herramientasrecursos, setHerramientasRecursos] = useState([]);

    const [epp, setEpp] = useState([]);
    const [epprecursos, setEppRecursos] = useState([]);

    const [indumentarias, setIndumentarias] = useState([]);
    const [indumentariasrecursos, setIndumentariasRecursos] = useState([]);

    const [informaticas, setInformaticas] = useState([]);
    const [informaticasrecursos, setInformaticasRecursos] = useState([]);

    const [celulares, setCelulares] = useState([]);
    const [celularesrecursos, setCelularesRecursos] = useState([]);

    const [modalEliminar, setModalEliminar] = useState(false);
    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        id: '',
        nombre: '',
        codigo: '',
    })
    const [consola, setConsola] = useState({
        nombre: '',
        apellido1: '',
    })

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        props.history.push(process.env.PUBLIC_URL + "/asignarrecursoausuario/" + consola.id + "/" + id);
    }

    const recursoPost = async (values, recurso) => {
        await UserService.addRecurso(id, values, recurso);
        peticionGet()
    }

    const recursoDel = async (values, recurso) => {
        await UserService.delRecursoSeleccionado(id, values, recurso);
        peticionGet()
    }

    const peticionGet = async () => {

        const h = await UserService.getRecursosNoSeleccionados(id, 'herramienta');
        setHerramientas(h);
        const hS = await UserService.getRecursosSeleccionados(id, 'herramienta');
        setHerramientasRecursos(hS);

        const h1 = await UserService.getRecursosNoSeleccionados(id, 'indumentaria');
        setIndumentarias(h1);
        const hS1 = await UserService.getRecursosSeleccionados(id, 'indumentaria');
        setIndumentariasRecursos(hS1);

        const h2 = await UserService.getRecursosNoSeleccionados(id, 'informatica');
        setInformaticas(h2);
        const hS2 = await UserService.getRecursosSeleccionados(id, 'informatica');
        setInformaticasRecursos(hS2);

        const h3 = await UserService.getRecursosNoSeleccionados(id, 'celular');
        setCelulares(h3);
        const hS3 = await UserService.getRecursosSeleccionados(id, 'celular');
        setCelularesRecursos(hS3);

        const h4 = await UserService.getRecursosNoSeleccionados(id, 'epp');
        setEpp(h4);
        const hS4 = await UserService.getRecursosSeleccionados(id, 'epp');
        setEppRecursos(hS4);

    }

    useEffect(() => {

        const GetUsuario = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getUsuario(id);
                if (result) {
                    consola.nombre = result.nombre;
                    consola.apellido1 = result.apellido1;
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetUsuario();

        const GetHerramientas = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosNoSeleccionados(id, 'herramienta');
                if (result) {
                    setHerramientas(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetHerramientas();

        const GetEpp = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosNoSeleccionados(id, 'indumentaria');
                if (result) {
                    setEpp(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetEpp();

        const GetIndumentarias = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosNoSeleccionados(id, 'indumentaria');
                if (result) {
                    setIndumentarias(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetIndumentarias();

        const GetInformaticas = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosNoSeleccionados(id, 'informatica');
                if (result) {
                    setInformaticas(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetInformaticas();

        const GetCelulares = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosNoSeleccionados(id, 'celular');
                if (result) {
                    setCelulares(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetCelulares();

        const GetHerramientasN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'herramienta');
                if (result) {
                    setHerramientasRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetHerramientasN();

        const GetIndumentariasN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'indumentaria');
                if (result) {
                    setIndumentariasRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetIndumentariasN();

        const GetEppN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'epp');
                if (result) {
                    setEppRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetEppN();

        const GetInformaticasN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'informatica');
                if (result) {
                    setInformaticasRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetInformaticasN();

        const GetCelularesN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'celular');
                if (result) {
                    setCelularesRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }

        }
        GetCelularesN();

    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/recursos")
    }

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Asignar Recursos
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div style={{ maxWidth: "100%", direction }}>

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="EMPLEADO" />
                                </Divider>

                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {consola.nombre}
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {consola.apellido1}
                                        </Typography>
                                    </CardContent>
                                </Card>

                            </Grid>

                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="HERRAMIENTAS DISPONIBLES" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Asignar Recurso',
                                            onClick: (event, data) => recursoPost(data, 'herramienta'),
                                        },
                                    ]}

                                    data={herramientas}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="HERRAMIENTAS SELECCIONADAS" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'delete',
                                            tooltip: 'Eliminar Recurso',
                                            onClick: (event, data) => recursoDel(data, 'herramienta'),
                                        },
                                    ]}

                                    data={herramientasrecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="INDUMENTARIA" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Asignar Recursos',
                                            onClick: (event, data) => recursoPost(data, 'indumentaria'),
                                        },
                                    ]}

                                    data={indumentarias}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="INDUMENTARIA SELECCIONADA" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'del',
                                            tooltip: 'Eliminar Recursos',
                                            onClick: (event, data) => recursoDel(data, 'indumentaria'),
                                        },
                                    ]}

                                    data={indumentariasrecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="EPP" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Asignar Recursos',
                                            onClick: (event, data) => recursoPost(data, 'epp'),
                                        },
                                    ]}

                                    data={epp}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="EPP SELECCIONADO" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'del',
                                            tooltip: 'Eliminar Recursos',
                                            onClick: (event, data) => recursoDel(data, 'epp'),
                                        },
                                    ]}

                                    data={epprecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="INFORMATICA" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Asignar Recursos',
                                            onClick: (event, data) => recursoPost(data, 'informatica'),
                                        },
                                    ]}

                                    data={informaticas}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="INFORMATICA SELECCIONADA" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'del',
                                            tooltip: 'Eliminar Recursos',
                                            onClick: (event, data) => recursoDel(data, 'informatica'),
                                        },
                                    ]}

                                    data={informaticasrecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="CELULARES" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Asignar Recursos',
                                            onClick: (event, data) => recursoPost(data, 'celular'),
                                        },
                                    ]}

                                    data={celulares}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Divider>
                                    <Chip label="CELULARES SELECCIONADOS" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                        {
                                            title: 'Des.Corta',
                                            field: 'descorta',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'del',
                                            tooltip: 'Eliminar Recurso',
                                            onClick: (event, data) => recursoDel(data, 'celular'),
                                        },
                                    ]}

                                    data={celularesrecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <SnackbarMessage
                  open={open}
                  message={errorMessage}
                  severity="success"
                  onClose={handleCloseMessage}
                />

                        </Grid>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>

        </Paper>
    );
}