import React, { useState, useEffect, useContext } from "react";

import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useHistory } from "react-router-dom";

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { Accordion, AccordionSummary, AccordionDetails, TextField, Autocomplete, Paper, Typography, Divider, FormControl, InputLabel, Select, MenuItem, Button, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import UserService from "../../services/user.service";

const Permisos = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true)
  const [checked, setChecked] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [OwnedProducts, setOwnedProducts] = useState([]); // Cambiado a un array para múltiple selección
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const handleToggle = (nodeId) => () => {
    setChecked((prevChecked) => {
      if (prevChecked.includes(nodeId)) {
        return prevChecked.filter((id) => id !== nodeId);
      } else {
        return [...prevChecked, nodeId];
      }
    });
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography>{nodes.name}</Typography>

          <div style={{ display: "flex", marginLeft: "auto" }}>

            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nodes.permisos?.agregar || false}
                    onChange={(event) => handlePermissionChange(event, nodes, "agregar")}
                  />
                }
                label="Agregar"
                style={{ marginLeft: "10px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nodes.permisos?.modificar || false}
                    onChange={(event) => handlePermissionChange(event, nodes, "modificar")}
                  />
                }
                label="Modificar"
                style={{ marginLeft: "10px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nodes.permisos?.eliminar || false}
                    onChange={(event) => handlePermissionChange(event, nodes, "eliminar")}
                  />
                }
                label="Eliminar"
                style={{ marginLeft: "10px" }}
              />
            </>

            <FormControlLabel
              control={
                <Checkbox
                  checked={nodes.permisos?.consultar || false}
                  onChange={(event) => handlePermissionChange(event, nodes, "consultar")}
                />
              }
              label="Consultar"
              style={{ marginLeft: "10px" }}
            />

          </div>
        </div>
      }
    >
      {Array.isArray(nodes.modules) ? nodes.modules.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  // Tu estructura de productos y módulos
  const productModules = [
    {
      id: "product1",
      name: "Producto 1",
      modules: [
        { id: "module1", name: "Módulo 1" },
        { id: "module2", name: "Módulo 2" },
      ],
    },
    {
      id: "product2",
      name: "Producto 2",
      modules: [
        { id: "module3", name: "Módulo 3" },
        { id: "module4", name: "Módulo 4" },
      ],
    },
    // ... Agrega más productos y módulos aquí
  ];

  useEffect(() => {
    // Cargar la lista de usuarios y módulos disponibles desde la API
    UserService.getUsuarios().then(response => {
      setUsers(response.data);
      setIsLoading(false);
    });
  }, []);

  const handleUserChange = (event, newValue) => {
    setSelectedUser(newValue); // 'newValue' contiene el objeto del usuario seleccionado

    if (newValue) {
      UserService.getPermissions(newValue.id).then(response => {
        console.log(response)
        setOwnedProducts(response);
        setIsLoading(false);
      });
    } else {
      // Si newValue es null, puedes tomar la acción apropiada, como limpiar los datos o mostrar un mensaje de error.
      // Ejemplo: setOwnedProducts([]);
    }
  };

  const handlePermissionChange = (event, rowData, tipo) => {

    const id = rowData.id;
    const newPermissionValue = event.target.checked;

    setSelectedPermissions(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [tipo]: newPermissionValue
      }
    }));
    rowData.permisos[tipo] = newPermissionValue;

    UserService.assignPermission(selectedUser.id, rowData.id, tipo, event.target.checked).then(response => {
      // Manejar la respuesta de la API según tus necesidades
    });

  };

  const inicio = () => {
    history.push(process.env.PUBLIC_URL + "/administrador")
  }

  return (

    <Paper style={{ padding: '20px' }}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#ff' }} sx={{ flexGrow: 1 }}>
            Asignación de Permisos
          </Typography>
        </Toolbar>
      </AppBar>

      <Divider style={{ margin: '10px 0' }} />

      <FormControl fullWidth style={{ marginBottom: '20px' }}>
        <Autocomplete
          options={users}
          value={selectedUser}
          onChange={handleUserChange}
          getOptionLabel={(user) => user.username}
          renderInput={(params) => <TextField {...params} label="Seleccionar Usuario" />}
        />
      </FormControl>

      <FormControl component="fieldset">
        <FormGroup>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {OwnedProducts.map((product) => renderTree(product))}
          </TreeView>
        </FormGroup>
      </FormControl>

    </Paper>
  );
};

export default Permisos;
