
import React from 'react';
import { useState, useEffect } from 'react'

import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import MaterialTable from 'material-table';

import UserService from "../../services/user.service";

export default function RecursosEmpleados(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [currentUser, setCurrentUser] = useState(undefined);

    const styles = useStyles();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        id: '',
        nombre: '',
        codigo: '',
    })

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        //console.log(consola.id);
        props.history.push(process.env.PUBLIC_URL + "/asignarrecursos/" + consola.id);
    }

    useEffect(() => {

        const GetData = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getEmpleados();
                if (result) {
                    setData(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/recursos")
    }

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Seleccione el Usuario
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div style={{ maxWidth: "100%", direction }}>
                        <Grid container>
                            <Grid item xs={12}>

                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Legajo',
                                            field: 'legajo',
                                        },
                                        {
                                            title: 'Tarjeta',
                                            field: 'tarjeta',
                                        },
                                        {
                                            title: 'Nombre',
                                            field: 'nombre',
                                        },
                                        {
                                            title: 'Apellido',
                                            field: 'apellido1',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'share',
                                            tooltip: 'Recursos',
                                            onClick: (event, data) => seleccionarConsola(data, 'Recursos'),
                                        },
                                    ]}

                                    data={data}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />

                            </Grid>
                        </Grid>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>

        </Paper>
    );
}