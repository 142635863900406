import React from 'react';
import { useState, useEffect } from 'react'

import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { useParams } from 'react-router';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import MaterialTable from 'material-table';

import UserService from "../../services/user.service";

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js


export default function MisRecursos(props) {
    const [isLoading, setIsLoading] = useState(false)
    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [herramientasrecursos, setHerramientasRecursos] = useState([]);

    const [epprecursos, setEppRecursos] = useState([]);

    const [indumentariasrecursos, setIndumentariasRecursos] = useState([]);

    const [informaticasrecursos, setInformaticasRecursos] = useState([]);

    const [celularesrecursos, setCelularesRecursos] = useState([]);

    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        id: '',
        nombre: '',
        codigo: '',
    })
    const [consola, setConsola] = useState({
        nombre: '',
        apellido1: '',
    })

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        props.history.push(process.env.PUBLIC_URL + "/asignarrecursoausuario/" + consola.id + "/" + id);
    }

    const recursoPost = async (values, recurso) => {
        await UserService.addRecurso(id, values, recurso);
        peticionGet()
    }

    const recursoDel = async (values, recurso) => {
        await UserService.delRecursoSeleccionado(id, values, recurso);
        peticionGet()
    }

    const peticionGet = async () => {

        const hS = await UserService.getRecursosSeleccionados(id, 'herramienta');
        setHerramientasRecursos(hS);

        const hS1 = await UserService.getRecursosSeleccionados(id, 'indumentaria');
        setIndumentariasRecursos(hS1);

        const hS2 = await UserService.getRecursosSeleccionados(id, 'informatica');
        setInformaticasRecursos(hS2);

        const hS3 = await UserService.getRecursosSeleccionados(id, 'celular');
        setCelularesRecursos(hS3);

        const hS4 = await UserService.getRecursosSeleccionados(id, 'epp');
        setEppRecursos(hS4);

    }

    useEffect(() => {

        const GetEmpleado = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getUsuario(id);
                if (result) {
                    consola.nombre = result.nombre;
                    consola.apellido1 = result.apellido1;
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetEmpleado();

        const GetHerramientasN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'herramienta');
                if (result) {
                    setHerramientasRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }

        }
        GetHerramientasN();

        const GetIndumentariasN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'indumentaria');
                if (result) {
                    setIndumentariasRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }

        }
        GetIndumentariasN();

        const GetEppN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'epp');
                if (result) {
                    setEppRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }

        }
        GetEppN();

        const GetInformaticasN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'informatica');
                if (result) {
                    setInformaticasRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }

        }
        GetInformaticasN();

        const GetCelularesN = async () => {

            try {
                setIsLoading(true);
                const result = await UserService.getRecursosSeleccionados(id, 'celular');
                if (result) {
                    setCelularesRecursos(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }

        }
        GetCelularesN();

    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/recursos")
    }

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Mis Recursos
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div style={{ maxWidth: "100%", direction }}>

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="EMPLEADO" />
                                </Divider>

                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {consola.nombre}
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {consola.apellido1}
                                        </Typography>
                                    </CardContent>
                                </Card>

                            </Grid>

                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="MIS HERRAMIENTAS" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'delete',
                                            tooltip: 'Eliminar Recurso',
                                            onClick: (event, data) => recursoDel(data, 'herramienta'),
                                        },
                                    ]}

                                    data={herramientasrecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="MI INDUMENTARIA" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'del',
                                            tooltip: 'Eliminar Recursos',
                                            onClick: (event, data) => recursoDel(data, 'indumentaria'),
                                        },
                                    ]}

                                    data={indumentariasrecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="MI EPP" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'del',
                                            tooltip: 'Eliminar Recursos',
                                            onClick: (event, data) => recursoDel(data, 'epp'),
                                        },
                                    ]}

                                    data={epprecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="MIS ELEMENTOS DE INFORMATICA" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'del',
                                            tooltip: 'Eliminar Recursos',
                                            onClick: (event, data) => recursoDel(data, 'informatica'),
                                        },
                                    ]}

                                    data={informaticasrecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="MI CELULAR" />
                                </Divider>
                                <MaterialTable
                                    title=""

                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Buscar...'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            editRow: {
                                                deleteText: 'Estas seguro de eliminar este registro ?'
                                            }
                                        },
                                    }}

                                    columns={[
                                        {
                                            title: 'Codigo',
                                            field: 'codigo',
                                        },
                                        {
                                            title: 'Marca',
                                            field: 'marca',
                                        },
                                        {
                                            title: 'Modelo',
                                            field: 'modelo',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: 'del',
                                            tooltip: 'Eliminar Recurso',
                                            onClick: (event, data) => recursoDel(data, 'celular'),
                                        },
                                    ]}

                                    data={celularesrecursos}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#004F9E',
                                            color: '#FFF',
                                        },
                                        search: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>

        </Paper>
    );
}