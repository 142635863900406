/**
 * PWA Horario
 * update 01/2023
 * By Sergio Sam 
 */

import React from 'react';
import { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme, useStyles } from "../styles.js"
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { FormLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';

import { useParams } from 'react-router';

import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";

import { v4 as uuidv4 } from 'uuid';

var mailant = "";
var resmailant = false;
var idant = "";

//Validacion del formulario
const validationSchema = yup.object({
  nombre: yup
    .string('Nombre del empleado requerido')
    .required('Nombre del empleado requerido'),
  apellido1: yup
    .string('Apellido del empleado requerido')
    .required('Apellido del empleado requerido'),
  fechaingreso: yup
    .string('Fecha de ingreso requerido')
    .required('Fecha de ingreso requerido'),
  dnicif: yup
    .string('DNI requerido')
    .required('DNI requerido'),
  horarioactual: yup
    .string('Horario actual requerido')
    .required('Horario actual requerido'),
  email: yup
    .string('Email requerido')
    .email('Email no valido')
    .required('Email requerido')
    .test('checkDuplUsername', 'El email ya esta en uso, utilize otro email', async function (value) {
      if (value) {
        if (value !== mailant) {
          mailant = value;
          const res = await checkEmail(value);
          if (res) {
            resmailant = false;
            return false;
          } else {
            resmailant = true;
            return true;
          }
        } else {
          return resmailant;
        }
      }
    }),
  password: yup.string()
    .required('Password es requerido')
    .min(6, 'Password debe tener al menos 6 caracteres')
    .max(40, 'Password no debe exeder los 40 caracteres'),
  confirmPassword: yup.string()
    .required('Confirm Password es requerido')
    .oneOf([yup.ref('password'), null], 'Confirm Password no coinciden'),

});

const checkEmail = async (values) => {
  const response = await UserService.validEmails(values, 'horario', idant);
  return response.data;
}

export default function Empleadosaddmod() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false)

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      nombre: '',
      email: '',
      password: '',
      pin: '',
      fechaingreso: '',
      apellido1: '',
      apellido2: '',
      legajo: '',
      tarjeta: '',
      dnicif: '',
      cuil: '',
      estado: '',
      empresa: '',
      cargo: '',
      departamento: '',
      calendario: '',
      convenio: '',
      telefono: '',
      movil: '',
      nss: '',
      contrato: '',
      anoanterior: '',
      vacaciones: '',
      horarioactual: '',
      primerhorario: '',
      primerhorarioactivo: false,
      segundohorario: '',
      segundohorarioactivo: '',
      fechainiciorotacion: '',
      fechainiciorotacion: '',
      moneda: '',
      diasarotar: '',
      salario: '',
      ncuenta: '',
      movilpersonal: '',
      emailpersonal: '',
      fechadenacimiento: '',
      carnetcoche: '',
      carnetmoto: '',
      sexo: '',
      notas: '',
      localidad: '',
      provincia: '',
      direccion: '',
      codigopostal: '',
      pais: '',
      nacionalidad: '',
      telefonopersonal: '',
      idioma: '',
      //id: uuidv4(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
    },
  });

  const [currentUser, setCurrentUser] = useState(undefined);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showEmailAlert, setShowEmailAlert] = useState(false);

  const [shown, setShown] = useState(false);
  const [shown1, setShown1] = useState(false);

  const switchShown = () => setShown(!shown);
  const switchShown1 = () => setShown1(!shown1);

  const { id } = useParams();
  idant = id;
  const styles = useStyles();
  const classes = useStyles();

  const [sino, setSino] = useState([
    { id: 'true', nombre: 'Si' },
    { id: 'false', nombre: 'No' },
  ]);

  const [estado, setEstado] = useState([
    { id: 'true', nombre: 'Habilitado' },
    { id: 'false', nombre: 'Deshabilitado' },
  ]);

  const [horarios, setHorarios] = useState([]);

  const peticionPost = async (values) => {
    const res =await UserService.addModEmpleado(id, values);

    if (res.error_email) {
      setShowEmailAlert(true)
    } else {
      setShowEmailAlert(false)
      cerrarEditar()
    }

  }

  const cerrarEditar = () => {
    history.push(process.env.PUBLIC_URL + "/empleados");
  }

  const inicio = () => {
    history.push(process.env.PUBLIC_URL + "/empleados")
  }

  useEffect(() => {
    // si no hay user hay que loguearse 
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setShowClienteBoard(user.roles.includes("ROLE_USER"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    } else {
      //history.push(process.env.PUBLIC_URL + "/login");
    }

    const GetData = async () => {
      if (id) {
        try {
          setIsLoading(true);
          const response = await UserService.getEmpleadoid(id);
          if (response) {
            formik.setValues(response);
            setIsLoading(false);
          } else {
            //history.push(process.env.PUBLIC_URL + "/login");
          }
        } catch (e) {
          history.push(process.env.PUBLIC_URL + "/login");
        }
      }
    }
    GetData();

    const GetHorario = async () => {
      try {
        setIsLoading(true);
        const response = await UserService.getHorarios();
        console.log(response)
        if (response) {
          setHorarios(response)
          setIsLoading(false);
        } else {
          history.push(process.env.PUBLIC_URL + "/login");
        }
      } catch (e) {
        history.push(process.env.PUBLIC_URL + "/login");
      }
    }
    GetHorario();

  }, []);

  return (

    <Paper>

      <AppBar style={{ background: '#fff159', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#000' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#000' }} sx={{ flexGrow: 1 }}>
            Agregar/Modificar Empleado
          </Typography>
        </Toolbar>
      </AppBar>

      <Container fixed>
        <Box sx={{ bgcolor: '#cfe8fc', marginTop: '40px', marginBottom: '40px' }}  >

          <form onSubmit={formik.handleSubmit}>

            <Grid container spacing={1} style={{ minHeight: '100vh', padding: '5px' }} >

              <Grid item xs={6}>
                <TextField
                  name="nombre"
                  className={styles.inputMaterial}
                  label="Nomre del empleado"
                  autoFocus={true}
                  value={formik.values.nombre}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                  helperText={formik.touched.nombre && formik.errors.nombre}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="apellido1"
                  className={styles.inputMaterial}
                  label="Appellido"
                  value={formik.values.apellido1}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.apellido1 && Boolean(formik.errors.apellido1)}
                  helperText={formik.touched.apellido1 && formik.errors.apellido1}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="email"
                  className={styles.inputMaterial}
                  label="Email"
                  value={formik.values.email}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              {showEmailAlert && (
                <Grid item xs={12}>
                  <Alert severity="error">El email del empleado ya existe, ingrese otro</Alert>
                </Grid>
              )}

              <Grid item xs={6}>
                <TextField
                  name="pin"
                  className={styles.inputMaterial}
                  label="PIN"
                  value={formik.values.pin}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.pin && Boolean(formik.errors.pin)}
                  helperText={formik.touched.pin && formik.errors.pin}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type={shown ? 'text' : 'password'}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="password"
                  label="Password"
                  autoComplete="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />

                <span class="input-group-btn">
                  <button class="btn btn-default" type="button" onClick={switchShown}>
                    {shown ? 'Ocultar' : 'Ver'}
                  </button>
                </span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type={shown1 ? 'text' : 'password'}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  autoComplete="confirmPassword"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />

                <span class="input-group-btn">
                  <button class="btn btn-default" type="button" onClick={switchShown1}>
                    {shown1 ? 'Ocultar' : 'Ver'}
                  </button>
                </span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="date"
                  type="date"
                  name="fechaingreso"
                  label="Fecha de Ingreso:"
                  className={classes.textField}
                  error={formik.touched.fechaingreso && Boolean(formik.errors.fechaingreso)}
                  helperText={formik.touched.fechaingreso && formik.errors.fechaingreso}
                  onChange={formik.handleChange}
                  value={formik.values.fechaingreso}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="date"
                  type="date"
                  name="fechadenacimiento"
                  label="Fecha de Nacimiento:"
                  className={classes.textField}
                  error={formik.touched.fechadenacimiento && Boolean(formik.errors.fechadenacimiento)}
                  helperText={formik.touched.fechadenacimiento && formik.errors.fechadenacimiento}
                  onChange={formik.handleChange}
                  value={formik.values.fechadenacimiento}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="dnicif"
                  className={styles.inputMaterial}
                  label="DNI"
                  value={formik.values.dnicif}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.dnicif && Boolean(formik.errors.dnicif)}
                  helperText={formik.touched.dnicif && formik.errors.dnicif}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="cuil"
                  className={styles.inputMaterial}
                  label="CUIL"
                  value={formik.values.cuil}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.cuil && Boolean(formik.errors.cuil)}
                  helperText={formik.touched.cuil && formik.errors.cuil}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="legajo"
                  className={styles.inputMaterial}
                  label="Legajo"
                  value={formik.values.legajo}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.legajo && Boolean(formik.errors.legajo)}
                  helperText={formik.touched.legajo && formik.errors.legajo}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="tarjeta"
                  className={styles.inputMaterial}
                  label="Tarjeta"
                  value={formik.values.tarjeta}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.tarjeta && Boolean(formik.errors.tarjeta)}
                  helperText={formik.touched.tarjeta && formik.errors.tarjeta}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="movil"
                  className={styles.inputMaterial}
                  label="Movil"
                  value={formik.values.movil}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.movil && Boolean(formik.errors.movil)}
                  helperText={formik.touched.movil && formik.errors.movil}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="telefono"
                  className={styles.inputMaterial}
                  label="Telefono"
                  value={formik.values.telefono}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                  helperText={formik.touched.telefono && formik.errors.telefono}
                />
              </Grid>

              <Grid item xs={12}>
                <FormLabel>HORARIOS</FormLabel>
              </Grid>

              <Grid item xs={12}>
                <InputLabel htmlFor="outlined-age-native-simple">Horario Actual?</InputLabel>
                <Select
                  native
                  label="Horario Actual"
                  value={formik.values.horarioactual}
                  onChange={formik.handleChange}
                  error={formik.touched.horarioactual && Boolean(formik.errors.horarioactual)}
                  helperText={formik.touched.horarioactual && formik.errors.horarioactual}
                  inputProps={{
                    name: 'horarioactual',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {horarios.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.primerhorarioactivo}
                      onChange={formik.handleChange}
                      name="primerhorarioactivo"
                    />}
                  label="Activar primer horario a rotar"
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Primer Horario</InputLabel>
                <Select
                  native
                  label="Primer Horario"
                  value={formik.values.primerhorario}
                  onChange={formik.handleChange}
                  error={formik.touched.primerhorario && Boolean(formik.errors.primerhorario)}
                  helperText={formik.touched.primerhorario && formik.errors.primerhorario}
                  inputProps={{
                    name: 'primerhorario',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {horarios.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
              <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.segundohorarioactivo}
                      onChange={formik.handleChange}
                      name="segundohorarioactivo"
                    />}
                  label="Activar segundo horario a rotar"
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Segundo Horario</InputLabel>
                <Select
                  native
                  label="Segundo Horario"
                  value={formik.values.segundohorario}
                  onChange={formik.handleChange}
                  error={formik.touched.segundohorario && Boolean(formik.errors.segundohorario)}
                  helperText={formik.touched.segundohorario && formik.errors.segundohorario}
                  inputProps={{
                    name: 'segundohorario',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {horarios.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="date"
                  type="date"
                  name="fechainiciorotacion"
                  label="Fecha inicio de rotacion:"
                  className={classes.textField}
                  error={formik.touched.fechainiciorotacion && Boolean(formik.errors.fechainiciorotacion)}
                  helperText={formik.touched.fechainiciorotacion && formik.errors.fechainiciorotacion}
                  onChange={formik.handleChange}
                  value={formik.values.fechainiciorotacion}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="diasarotar"
                  className={styles.inputMaterial}
                  label="Dias a Rotar"
                  value={formik.values.diasarotar}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.diasarotar && Boolean(formik.errors.diasarotar)}
                  helperText={formik.touched.diasarotar && formik.errors.diasarotar}
                />
              </Grid>

              <Grid item xs={12}>
                <FormLabel>OTROS DATOS</FormLabel>
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Estado</InputLabel>
                <Select
                  native
                  label="Estado"
                  value={formik.values.estado}
                  onChange={formik.handleChange}
                  error={formik.touched.estado && Boolean(formik.errors.estado)}
                  helperText={formik.touched.estado && formik.errors.estado}
                  inputProps={{
                    name: 'estado',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {estado.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Empresa</InputLabel>
                <Select
                  native
                  label="Empresa"
                  value={formik.values.empresa}
                  onChange={formik.handleChange}
                  error={formik.touched.empresa && Boolean(formik.errors.empresa)}
                  helperText={formik.touched.empresa && formik.errors.empresa}
                  inputProps={{
                    name: 'empresa',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {sino.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Cargo</InputLabel>
                <Select
                  native
                  label="Cargo"
                  value={formik.values.cargo}
                  onChange={formik.handleChange}
                  error={formik.touched.cargo && Boolean(formik.errors.cargo)}
                  helperText={formik.touched.cargo && formik.errors.cargo}
                  inputProps={{
                    name: 'cargo',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {sino.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Departamento</InputLabel>
                <Select
                  native
                  label="Departamento"
                  value={formik.values.departamento}
                  onChange={formik.handleChange}
                  error={formik.touched.departamento && Boolean(formik.errors.departamento)}
                  helperText={formik.touched.departamento && formik.errors.departamento}
                  inputProps={{
                    name: 'departamento',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {sino.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Convenio</InputLabel>
                <Select
                  native
                  label="Convenio"
                  value={formik.values.convenio}
                  onChange={formik.handleChange}
                  error={formik.touched.convenio && Boolean(formik.errors.convenio)}
                  helperText={formik.touched.convenio && formik.errors.convenio}
                  inputProps={{
                    name: 'convenio',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {sino.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Contrato</InputLabel>
                <Select
                  native
                  label="Contrato"
                  value={formik.values.contrato}
                  onChange={formik.handleChange}
                  error={formik.touched.contrato && Boolean(formik.errors.contrato)}
                  helperText={formik.touched.contrato && formik.errors.contrato}
                  inputProps={{
                    name: 'contrato',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {sino.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Acumular vacaciones del año anterior?</InputLabel>
                <Select
                  native
                  label="Año anterior"
                  value={formik.values.anoanterior}
                  onChange={formik.handleChange}
                  error={formik.touched.anoanterior && Boolean(formik.errors.anoanterior)}
                  helperText={formik.touched.anoanterior && formik.errors.anoanterior}
                  inputProps={{
                    name: 'anoanterior',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {sino.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="vacaciones"
                  className={styles.inputMaterial}
                  label="Vacaciones"
                  value={formik.values.vacaciones}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.vacaciones && Boolean(formik.errors.vacaciones)}
                  helperText={formik.touched.vacaciones && formik.errors.vacaciones}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="salario"
                  className={styles.inputMaterial}
                  label="Salario"
                  value={formik.values.salario}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.salario && Boolean(formik.errors.salario)}
                  helperText={formik.touched.salario && formik.errors.salario}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="moneda"
                  className={styles.inputMaterial}
                  label="Moneda"
                  value={formik.values.moneda}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.moneda && Boolean(formik.errors.moneda)}
                  helperText={formik.touched.moneda && formik.errors.moneda}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="ncuenta"
                  className={styles.inputMaterial}
                  label="Nro de Cuenta"
                  value={formik.values.ncuenta}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.ncuenta && Boolean(formik.errors.ncuenta)}
                  helperText={formik.touched.ncuenta && formik.errors.ncuenta}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="direccion"
                  className={styles.inputMaterial}
                  label="Direccion"
                  value={formik.values.direccion}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.direccion && Boolean(formik.errors.direccion)}
                  helperText={formik.touched.direccion && formik.errors.direccion}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="codigopostal"
                  className={styles.inputMaterial}
                  label="Codigo Postal"
                  value={formik.values.codigopostal}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.codigopostal && Boolean(formik.errors.codigopostal)}
                  helperText={formik.touched.codigopostal && formik.errors.codigopostal}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="movilpersonal"
                  className={styles.inputMaterial}
                  label="Movil Personal"
                  value={formik.values.movilpersonal}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.movilpersonal && Boolean(formik.errors.movilpersonal)}
                  helperText={formik.touched.movilpersonal && formik.errors.movilpersonal}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="emailpersonal"
                  className={styles.inputMaterial}
                  label="Email Personal"
                  value={formik.values.emailpersonal}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.emailpersonal && Boolean(formik.errors.emailpersonal)}
                  helperText={formik.touched.emailpersonal && formik.errors.emailpersonal}
                />
              </Grid>

              {/*
              <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Carnet Coche</InputLabel>
                <Select
                  native
                  label="Carnet Coche"
                  value={formik.values.carnetcoche}
                  onChange={formik.handleChange}
                  error={formik.touched.carnetcoche && Boolean(formik.errors.carnetcoche)}
                  helperText={formik.touched.carnetcoche && formik.errors.carnetcoche}
                  inputProps={{
                    name: 'activo',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {sino.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>
*/}

              {/*          <Grid item xs={6}>
                <InputLabel htmlFor="outlined-age-native-simple">Carnet Moto</InputLabel>
                <Select
                  native
                  label="Carnet Moto"
                  value={formik.values.carnetmoto}
                  onChange={formik.handleChange}
                  error={formik.touched.carnetmoto && Boolean(formik.errors.carnetmoto)}
                  helperText={formik.touched.carnetmoto && formik.errors.carnetmoto}
                  inputProps={{
                    name: 'activo',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {sino.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.nombre}
                    </option>
                  ))}
                </Select>
              </Grid>
*/}
              <Grid item xs={6}>
                <TextField
                  name="sexo"
                  className={styles.inputMaterial}
                  label="Sexo"
                  value={formik.values.sexo}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sexo && Boolean(formik.errors.sexo)}
                  helperText={formik.touched.sexo && formik.errors.sexo}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="notas"
                  className={styles.inputMaterial}
                  label="Notas"
                  value={formik.values.notas}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.notas && Boolean(formik.errors.notas)}
                  helperText={formik.touched.notas && formik.errors.notas}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="localidad"
                  className={styles.inputMaterial}
                  label="Localidad"
                  value={formik.values.localidad}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.localidad && Boolean(formik.errors.localidad)}
                  helperText={formik.touched.localidad && formik.errors.localidad}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="provincia"
                  className={styles.inputMaterial}
                  label="Provincia"
                  value={formik.values.provincia}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.provincia && Boolean(formik.errors.provincia)}
                  helperText={formik.touched.provincia && formik.errors.provincia}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="pais"
                  className={styles.inputMaterial}
                  label="Pais"
                  value={formik.values.pais}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.pais && Boolean(formik.errors.pais)}
                  helperText={formik.touched.pais && formik.errors.pais}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="telefonopersonal"
                  className={styles.inputMaterial}
                  label="Telefono Personal"
                  value={formik.values.telefonopersonal}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.telefonopersonal && Boolean(formik.errors.telefonopersonal)}
                  helperText={formik.touched.telefonopersonal && formik.errors.telefonopersonal}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="idioma"
                  className={styles.inputMaterial}
                  label="Idioma"
                  value={formik.values.idioma}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.idioma && Boolean(formik.errors.idioma)}
                  helperText={formik.touched.idioma && formik.errors.idioma}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="nacionalidad"
                  className={styles.inputMaterial}
                  label="Nacionalidad"
                  value={formik.values.nacionalidad}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.nacionalidad && Boolean(formik.errors.nacionalidad)}
                  helperText={formik.touched.nacionalidad && formik.errors.nacionalidad}
                />
              </Grid>

              <Grid item xs={12}>
                <div align="center" style={{ background: '#fff159' }} >
                  <Button color="primary" type="submit">Guardar</Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Paper>
  );
}

