import React from 'react';
import { useState, useEffect, useContext } from 'react'

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../components/AuthContext';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

import UserService from "../../services/areas.service";
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
  nombre: yup
    .string('Nombre de UP/Almacen requerido')
    .required('Nombre de UP/Almacen requerido'),
});

export default function Areasmod(props) {
  const [isLoading, setIsLoading] = useState(false)
  const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);

  const [columns, setColumns] = useState([
    {
      id: 'Id',
      field: 'id',
      hidden: true,
    },
    {
      title: 'Nombre',
      field: 'nombre'
    },
  ]);

  const [data, setData] = useState([]);

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      nombre: '',
      codigo: '',
      localidad: '',
      provincia: '',
      localizacion: '',
      nota: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
    },
  });

  const { id } = useParams();

  const styles = useStyles();
  const classes = useStyles();

  const [datos, setDatos] = useState([]);

  const peticionPost = async (values) => {
    const response = await UserService.addmodArea(id, values);
    cerrarEditar()
  }

  const cerrarEditar = () => {
    props.history.push(process.env.PUBLIC_URL + "/areas");
  }

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/areas")
  }

  useEffect(() => {
    const GetData = async () => {
      try {
        setIsLoading(true);
        const response = await UserService.getArea(id);
        if (response) {
          var dataNueva = response.data;
          dataNueva.map(consola => {
            formik.initialValues.nombre = consola.nombre;
            formik.initialValues.codigo = consola.codigo;
            formik.initialValues.localidad = consola.localidad;
            formik.initialValues.provincia = consola.provincia;
            formik.initialValues.localizacion = consola.localizacion;
            formik.initialValues.nota = consola.nota;
            setData(consola.yasimientos);
          })
          //response.data.yacimientos.map(con => {
          //  data.nombre= con.nombre;
          //})

          setDatos(dataNueva);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        props.history.push(process.env.PUBLIC_URL + "/login");
      }
    }
    GetData();
  }, []);

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Modificar Area
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

        <form onSubmit={formik.handleSubmit}>
 
          <Grid container spacing={3}>

            <Grid item xs={6}>
              <TextField
                name="nombre"
                className={styles.inputMaterial}
                label="Area"
                required
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="codigo"
                className={styles.inputMaterial}
                label="Nro de Area"
                value={formik.values.codigo}
                onChange={formik.handleChange}
                error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                helperText={formik.touched.codigo && formik.errors.codigo}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="localidad"
                className={styles.inputMaterial}
                label="Localidad"
                value={formik.values.localidad}
                onChange={formik.handleChange}
                error={formik.touched.localidad && Boolean(formik.errors.localidad)}
                helperText={formik.touched.localidad && formik.errors.localidad}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="provincia"
                className={styles.inputMaterial}
                label="Provincia"
                value={formik.values.provincia}
                onChange={formik.handleChange}
                error={formik.touched.provincia && Boolean(formik.errors.provincia)}
                helperText={formik.touched.provincia && formik.errors.provincia}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="localizacion"
                className={styles.inputMaterial}
                label="Localizacion"
                value={formik.values.localizacion}
                onChange={formik.handleChange}
                error={formik.touched.localizacion && Boolean(formik.errors.localizacion)}
                helperText={formik.touched.localizacion && formik.errors.localizacion}
              />
            </Grid>

            <Grid item xs={12}>
              <TextareaAutosize
                name="nota"
                aria-label="empty textarea"
                className={styles.inputMaterial}
                minRows={3} label="Nota"
                placeholder="Nota"
                value={formik.values.nota}
                onChange={formik.handleChange}
                error={formik.touched.nota && Boolean(formik.errors.nota)}
                helperText={formik.touched.nota && formik.errors.nota}
              />
            </Grid>

          </Grid>

          <div align="right">
            <Button color="primary" type="submit">Editar</Button>
            <Button color="primary" onClick={() => cerrarEditar()}>Cancelar</Button>
          </div>
        </form>


    </Paper>
  );
}