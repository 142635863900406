import React from 'react';

import { withStyles, makeStyles, useTheme} from '@mui/styles';

import Paper from '@mui/material/Paper';
import { TableCell, Modal, Button, TextField } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { Grid, ThemeProvider, StyledEngineProvider, } from "@mui/material";
import MaterialTable from 'material-table';
 
import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'

import {useStyles, theme, direction} from '../styles'; // Importa los estilos desde el archivo styles.js

export default function Usuarios(props) {
  const [isLoading, setIsLoading] = useState(true)
  const styles = useStyles();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    nombre: '',
    email: '',
    empresa: '',
    rol: '',
  })

  const handleChange = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const peticionGet = async () => {
    const result = await UserService.getUsuarios();
    setData(result.data);
  }

  const peticionPost = async () => {
    const response = await UserService.getUsuario(consolaSeleccionada.id, consolaSeleccionada);
    setData(data.concat(response.data))
    abrirCerrarModalInsertar()
  }

  const peticionPut = async () => {
    const response = await UserService.getUsuario(consolaSeleccionada.id, consolaSeleccionada);
    var dataNueva = response.data;
    dataNueva.map(consola => {
      if (consolaSeleccionada.id === consola.id) {
        consola.nombre = consolaSeleccionada.nombre;
        consola.email = consolaSeleccionada.email;
        consola.empresa = consolaSeleccionada.empresa;
        consola.rol = consolaSeleccionada.rol;
      }
    })
    setData(dataNueva);
    peticionGet();
    abrirCerrarModalEditar();
  }

  const peticionDelete = async () => {
    const response = await UserService.delUsuario(consolaSeleccionada.id);
    var data = response.data;
    setData(data.filter(consola => consola.id !== consolaSeleccionada.id));
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const abrirCerrarModalInsertar = () => {
    props.history.push(process.env.PUBLIC_URL +"/usuariosaddmod/")
  }

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? props.history.push("/usuariosaddmod/"+consola.id) : abrirCerrarModalEliminar()
  }

  useEffect(() => {
    const GetData = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getUsuarios();
        if (result) {
          setData(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        props.history.push(process.env.PUBLIC_URL +"/login");
      }
    }
    GetData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const bodyInsertar = (
    <div className={styles.modal}>
      <h3>Agregar Nuevo Usuario</h3>
      <TextField name="nombre" className={styles.inputMaterial} label="Nombre" onChange={handleChange} />
      <br />
      <TextField name="descripcion" className={styles.inputMaterial} label="Descripcion" onChange={handleChange} />
      <br />
      <TextField name="nota" className={styles.inputMaterial} label="Nota" onChange={handleChange} />
      <br />
      <TextField name="data" className={styles.inputMaterial} label="Data" onChange={handleChange} />
      <br />
      <br /><br />
      <div align="right">
        <Button color="primary" onClick={() => peticionPost()}>Insertar</Button>
        <Button onClick={() => abrirCerrarModalInsertar()}>Cancelar</Button>
      </div>
    </div>
  )

  const bodyEditar = (
    <div className={styles.modal}>
      <h3>Editar Usuario</h3>
      <TextField name="nombre" className={styles.inputMaterial} label="Nombre" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.nombre} />
      <br />
      <TextField name="email" className={styles.inputMaterial} label="Email" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.descripcion} />
      <br />
      <TextField name="empresa" className={styles.inputMaterial} label="empresa" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.nota} />
      <br />
      <TextField name="rol" className={styles.inputMaterial} label="Rol" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.data} />
      <br />
      <br /><br />
      <div align="right">
        <Button color="primary" onClick={() => peticionPut()}>Editar</Button>
        <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
      </div>
    </div>
  )

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el usuario <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/administrador")
  }

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Usuarios
          </Typography>
        </Toolbar>
      </AppBar>

      <br />

      <Breadcrumbs aria-label="breadcrumb">
        <Button style={{ color: "#fff", backgroundColor: "#004F9E" }} variant="contained" onClick={() => abrirCerrarModalInsertar()}>Nuevo Usuario</Button>
      </Breadcrumbs>

      <ThemeProvider theme={theme}>
        <div style={{ maxWidth: "100%", direction }}>

      <MaterialTable
        title=""

        localization={{
          toolbar: {
            searchPlaceholder:  'Buscar...' 
          },
          header: {
            actions: 'Acciones'
          },
        }}

        columns={[
          {
            title: 'Nombre',
            field: 'nombre',
          },
          {
            title: 'Email',
            field: 'email',
          },
        ]}
        data={data}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (event, data) =>  seleccionarConsola(data, 'Editar'),
          },
          {
            icon: 'delete',
            tooltip: 'Eliminar',
            onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
          }
        ]}
        options={{
          headerStyle: {
            backgroundColor: '#004F9E',
            color: '#FFF',
          },
          search: true,
          actionsColumnIndex: -1
        }}
      />
        </div>
      </ThemeProvider>

      <Modal
        open={modalInsertar}
        onClose={abrirCerrarModalInsertar}>
        {bodyInsertar}
      </Modal>

      <Modal
        open={modalEditar}
        onClose={abrirCerrarModalEditar}>
        {bodyEditar}
      </Modal>

      <Modal
        open={modalEliminar}
        onClose={abrirCerrarModalEliminar}>
        {bodyEliminar}
      </Modal>
    </Paper>
  );
}