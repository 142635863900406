import React, { useState, useEffect, useRef, useContext } from "react";

import UploadService from "./services/planera.service";

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles, useTheme } from '@mui/styles';
import UserService from "./services/planera.service";

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import { AuthContext } from '../../components/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 400,
    backgroundColor: '#fff',
  },
}));

const DownloadFiles = ({ id, documento }) => {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const progressInfosRef = useRef(null)
  const [data, setData] = useState([]);
  const { currentUser, showAdmin, showMaster, showUser } = useContext(AuthContext);

  useEffect(() => {
    UploadService.getFilesPlanera(id, documento)
      .then((response) => {
        // Verificar si la respuesta contiene datos
        if (response.data && response.data.length > 0) {
          setFileInfos(response.data);
        } else {
          // Manejar el caso en el que no se obtienen datos
          console.log('No se encontraron datos');
        }
      })
      .catch((error) => {
        // Manejar errores si ocurren
        console.error('Error al obtener los datos:', error);
      });
  }, []);

  const selectFiles = (event) => {
    setSelectedFiles(event.target.files);
    setProgressInfos({ val: [] });
  };

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    try {
      await UploadService.upload(id, file, documento, (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        setProgressInfos({ val: _progressInfos });
      });
      setMessage((prevMessage) => ([
        ...prevMessage,
        "Archivo subido...: " + file.name,
      ]));
    } catch {
      _progressInfos[idx].percentage = 0;
      setProgressInfos({ val: _progressInfos });

      setMessage((prevMessage_1) => ([
        ...prevMessage_1,
        "No pudimos subir el archivo: " + file.name,
      ]));
    }
  };

  const eliminar = async (key, documento, id) => {
    const response = await UserService.delImagePlanera(id, documento, key);
    setData(data.concat(response.data))
    console.log(id);
    //cerrarEditar()

    UploadService.getFilesPlanera(id, documento).then((response) => {
      setFileInfos(response.data);
    })

  }

  const uploadFiles = () => {
    const files = Array.from(selectedFiles);

    let _progressInfos = files.map(file => ({ percentage: 0, fileName: file.name }));

    progressInfosRef.current = {
      val: _progressInfos,
    }

    const uploadPromises = files.map((file, i) => upload(i, file));

    Promise.all(uploadPromises)
      .then(() => UploadService.getFilesPlanera(id, documento))
      .then((files) => {
        setFileInfos(files.data);
      });

    setMessage([]);
  };

  return (
    <Paper>
      {progressInfos && progressInfos.val.length > 0 &&
        progressInfos.val.map((progressInfo, index) => (

          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" key={index} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(progressInfo.percentage,)}%`}</Typography>
            </Box>
          </Box>
        ))}

      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders" dense={true}>
          {fileInfos &&
            fileInfos.map((file, index) => (
              <ListItem id={index}>
                <Link href={file.url}>
                  {file.real
                    ? <ListItemText primary={file.real} />
                    : <ListItemText primary={file.name} />
                  }
                </Link>

              </ListItem>
            ))}
        </List>
        <Divider />
      </div>

    </Paper>
  );
};

export default DownloadFiles;
