import React from 'react';
import Paper from '@mui/material/Paper';
import { TablePagination, Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Modal, Button, TextField } from '@mui/material';
import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { Grid, ThemeProvider, StyledEngineProvider, } from "@mui/material";

import MaterialTable from 'material-table';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

export default function Modulesmod(props) {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const styles = useStyles();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [data, setData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);

    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        nombre: '',
        codigo: '',
        nota: '',
        precio: '',
        idproduct: id,
    })

    const handleChange = e => {
        const { name, value } = e.target;
        setConsolaSeleccionada(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }
    
    const peticionGet = async () => {
        const result = await UserService.getModules(id);
        setData(result.data);
    }

    const peticionPut = async () => {
        const response = await UserService.addmodModule(consolaSeleccionada.id, consolaSeleccionada);
        var dataNueva = response.data;
        dataNueva.map(consola => {
            if (consolaSeleccionada.id === consola.id) {
                return {
                    ...consola,
                    nombre: consolaSeleccionada.nombre,
                    codigo: consolaSeleccionada.codigo,
                    nota: consolaSeleccionada.nota,
//                    idproduct: consolaSeleccionada.idproduct,
                };              
            }
        })
        setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
    }

    const peticionDelete = async () => {
        const response = await UserService.delModule(consolaSeleccionada.id);
        peticionGet();
        abrirCerrarModalEliminar();
    }

    const abrirCerrarModalEditar = async (id) => {
        const response = await UserService.getModuleId(id);
        if (response) {
            console.log(response);
            setConsolaSeleccionada(response); // Configura los datos obtenidos en el estado
        }
        setModalEditar(!modalEditar);
    }
    
    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        if (caso === 'Editar') {
            abrirCerrarModalEditar(consola.id); // Llama a la función para abrir o cerrar el modal de edición
        } else {
            abrirCerrarModalEliminar(); // Llama a la función para abrir o cerrar el modal de eliminación
        }
    }
    
    useEffect(() => {
        const GetData = async () => {
            try {
                setIsLoading(true);
                const result = await UserService.getModules(id);
                if (result) {
                    setData(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();
    }, []);

    const bodyEditar = (
        <div className={styles.modal}>
            <h3>Editar un Modulo</h3>
            <TextField name="nombre" className={styles.inputMaterial} label="Nombre" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.nombre} />
            <br />
            <TextField name="codigo" className={styles.inputMaterial} label="Codigo" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.codigo} />
            <br />
            <TextField name="nota" className={styles.inputMaterial} label="Nota" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.nota} />
            <br />
            <br /><br />
            <div align="right">
                <Button color="primary" onClick={() => peticionPut()}>Editar</Button>
                <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
            </div>
        </div>
    )

    const bodyEliminar = (
        <div className={styles.modal}>
            <p>Estás seguro que deseas eliminar el estado  <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
            <div align="right">
                <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
                <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
            </div>
        </div>
    )

    return (
        <Paper className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Button style={{ color: "#fff", backgroundColor: "#004F9E" }} variant="contained" onClick={() => abrirCerrarModalEditar()}>Nuevo Modulo</Button>
            </Breadcrumbs>

            <ThemeProvider theme={theme}>
                <div style={{ maxWidth: "100%", direction }}>

                    <MaterialTable
                        title=""

                        localization={{
                            toolbar: {
                                searchPlaceholder: 'Buscar...'
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            body: {
                                editRow: {
                                    deleteText: 'Estas seguro de eliminar este registro ?'
                                }
                            },

                        }}

                        columns={[
                            {
                                title: 'Producto',
                                field: 'nombre',
                                align: "center",
                                cellStyle: { textAlign: "center" }
                            },
                            {
                                title: 'Precio',
                                field: 'precio',
                                align: "center",
                                cellStyle: { textAlign: "center" }
                            },
                        ]}
                        data={data}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Editar',
                                onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Eliminar',
                                onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                            }
                        ]}
                        options={{
                            headerStyle: {
                                backgroundColor: '#004F9E',
                                color: '#FFF',
                            },
                            search: true,
                            actionsColumnIndex: -1,
                        }}
                    />

                </div>
            </ThemeProvider>

            <Modal
                open={modalEditar}
                onClose={abrirCerrarModalEditar}>
                {bodyEditar}
            </Modal>

            <Modal
                open={modalEliminar}
                onClose={abrirCerrarModalEliminar}>
                {bodyEliminar}
            </Modal>
        </Paper>
    );
}