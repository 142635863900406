import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from '@mui/styles';
import { mdiCart, mdiFactory, mdiBookMultipleOutline, mdiBookMultiple, mdiPackageVariant, mdiCar, mdiClipboardListOutline, mdiBagChecked, mdiArchiveCogOutline } from '@mdi/js'
import { mdiArchiveCog } from '@mdi/js';
import { mdiCogOutline } from '@mdi/js';
import { mdiCardAccountDetails } from '@mdi/js';
import { Checkbox } from '@mui/material';

import Grid from '@mui/material/Grid';

import Icon from '@mdi/react';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../AuthContext';

import { useStyles } from '../styles'; // Importa los estilos desde el archivo styles.js

const HomeMaster = (props) => {

    const { showMaster,  showProducts } = useContext(AuthContext);

    useEffect(() => {

    }, []);

    const classes = useStyles();

    const handleCheckboxChange = async (moduleName, vent) => {
        const Product = showProducts.find(product => product.name === moduleName);
    }

    return (

        <>

            {showMaster && (
                <items>
                    <Grid item xs>
                        <Grid item xs container direction="column" spacing={2}>
                            <Typography gutterBottom variant="subtitle1">
                                <Link to={`${process.env.PUBLIC_URL}/master`} className="nav-link">
                                    <Icon path={mdiArchiveCog}
                                        title="Master"
                                        size={2}
                                        horizontal
                                        vertical
                                        rotate={180}
                                        color="#004F9E"
                                    />
                                </Link>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Master
                            </Typography>
                        </Grid>
                    </Grid>
                </items>
            )}


        </>

    );
};

export default HomeMaster;

