import axios from "axios";
import jwt_decode from "jwt-decode";
import { getAuth, signOut as firebaseSignOut } from 'firebase/auth';

const API_URL = "https://devapi.casiba.ar/api/";
const API_URL_MITRE = 'https://api.casiba.net/api/';
  
const register = (_username, _email, _password) => {
  return axios.post(API_URL + "signup", {
    _username,
    _email,
    _password,
  });
};

const login = async (_username, _password) => {
  var details = {
    '_username': _username,
    '_password': _password
  };

  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  formBody = formBody.join("&");

  const response = await axios
    .post(API_URL + "login_check", formBody, { headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' } });
  if (response.data.token) {
    var data = jwt_decode(response.data.token);
    data.accessToken = response.data.token;
    data.username = data.email;
    localStorage.setItem("user", JSON.stringify(data));
  }

  const response_mitre = await axios
    .post(API_URL_MITRE + "login_check", formBody, { headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' } });
  if (response_mitre.data.token) {
    var data = jwt_decode(response_mitre.data.token);
    data.accessToken = response_mitre.data.token;
    data.username = data.email;
    localStorage.setItem("user_mitre", JSON.stringify(data));
  }

//  return response.data.token;
};

const logout = () => {
  // Cierre de sesión en Firebase
  const auth = getAuth();
  firebaseSignOut(auth)
    .then(() => {
      console.log("Cierre de sesión en Firebase exitoso");
    })
    .catch((error) => {
      console.error("Error al cerrar sesión en Firebase:", error);
    });

  // Eliminar datos del localStorage
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user3");
  localStorage.removeItem("user_mitre");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user3"));
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
};
