import React from 'react';

import Paper from '@mui/material/Paper';
import { Modal, Button, TextField } from '@mui/material';
import { Grid, ThemeProvider, StyledEngineProvider } from "@mui/material";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';

import { useHistory } from "react-router-dom";
import { useParams } from 'react-router';

import AuthService from "../../services/auth.service";
import UserService from "../../services/horario.service";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';

import MaterialTable from 'material-table';

import { useState, useEffect } from 'react'

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
  //  direccion: yup
  //    .string('Direccion requerido')
  //    .required('Direcicon requerido'),
  //  codigopostal: yup
  //    .string('Codigo Postal requerido')
  //    //.min(8, 'Password should be of minimum 8 characters length')
  //    .required('Codigo Postal requerido'),//
  //  localidad: yup
  //    .string('Localidad requerido ')
  //    //.min(8, 'Password should be of minimum 8 characters length')
  //    .required('Localida requerido'),
  //  predeterminada: yup
  //    .string('Localidad requerido ')
  //.min(8, 'Password should be of minimum 8 characters length')
  //    .required('Localida requerido'),
});

export default function SolicitudFichadas() {

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      novedad: '',
      start: '',
      end: '',
      horas: '',
      nota: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values, state);
    },
  });

  const [novedades, setNovedades] = useState([]);

  const { id } = useParams();
  const { fecha } = useParams();
  const { idempleado } = useParams();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const styles = useStyles();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState([]);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);

  const [currentUser, setCurrentUser] = useState(undefined);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    apellido: '',
    finicio: '',
    fincorporacion: '',
  })

  const abrirCerrarModalInsertar = () => {
    //setModalInsertar(!modalInsertar);
    history.push(process.env.PUBLIC_URL + "/solicitudesfichadasaddmod/"+fecha+"/"+idempleado)
  }

  const abrirCerrarModalEditar = () => {
    console.log(consolaSeleccionada);
    //console.log();
    getSolicitud();
    setModalInsertar(!modalInsertar);
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? history.push(process.env.PUBLIC_URL + "/solicitudesfichadasaddmod/" + consola.id + "/" + fecha + "/" + idempleado ) : abrirCerrarModalEliminar()
    //(caso === 'Editar') ? abrirCerrarModalEditar() : abrirCerrarModalEliminar()
  }

  const peticionDelete = async () => {
    await UserService.delSolicitud(consolaSeleccionada.id);
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const getSolicitud = async () => {
      setIsLoading(true);
      const response = await UserService.getSolicitudFichadasid(consolaSeleccionada.id);
      if (response) {
        formik.setValues(response);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //history.push(process.env.PUBLIC_URL + "/login");
      }
  }

  const peticionGet = async () => {
    const result = await UserService.getSolicitudes();
    setData(result);
  }

  const peticionPost = async (values, state) => {
    console.log(values)
    console.log(state[0])

    await UserService.addmodSolicitudes(id, values, state[0]);
    cerrarEditar()
  }

  const cerrarEditar = () => {
    history.push(process.env.PUBLIC_URL + "/category");
  }

  const inicio = () => {
    history.push(process.env.PUBLIC_URL + "/")
  }

  useEffect(() => {

    const GetData = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getSolicitudFichadasid(id,fecha);
        if (result) {
          console.log(result)
          setData(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          //history.push(process.env.PUBLIC_URL +"/login");
        }
      } catch (e) {
        console.log(e)
        setIsLoading(false);
        //history.push(process.env.PUBLIC_URL +"/login");
      }
    }
    GetData();

    const GetNovedades = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getNovedades();
        if (result) {
          console.log(result)
          setNovedades(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          //history.push(process.env.PUBLIC_URL +"/login");
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e)
        //history.push(process.env.PUBLIC_URL +"/login");
      }
    }
    GetNovedades();

  }, []);

  const bodyInsertar = (
    <div className={styles.modal}>
      <h3>Nueva Solicitud Vacaciones/Licencias</h3>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>

          <Grid item xs={6}>
            <InputLabel htmlFor="outlined-age-native-simple">Tipo de licencia a solicitar</InputLabel>
            <Select
              native
              label="Tipo de licencia a solicitar"
              value={formik.values.novedad}
              onChange={formik.handleChange}
              error={formik.touched.novedad && Boolean(formik.errors.novedad)}
              helperText={formik.touched.novedad && formik.errors.novedad}
              inputProps={{
                name: 'novedad',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {novedades.map((value) => (
                <option value={value.id} key={value.id}>
                  {value.nombre}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="horas"
              className={styles.inputMaterial}
              label="Horas"
              value={formik.values.horas}
              autoComplete='off'
              onChange={formik.handleChange}
              error={formik.touched.horas && Boolean(formik.errors.horas)}
              helperText={formik.touched.horas && formik.errors.horas}
            />
          </Grid>

          <Grid item xs={12}>
            <DateRange
              editableDateInputs={false}
              onChange={item => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </Grid>

          <Grid item xs={12}>
            <TextareaAutosize
              name="nota"
              aria-label="empty textarea"
              className={styles.inputMaterial}
              minRows={3} label="Nota"
              placeholder="Nota"
              value={formik.values.nota}
              onChange={formik.handleChange}
              error={formik.touched.nota && Boolean(formik.errors.nota)}
              helperText={formik.touched.nota && formik.errors.nota}
            />
          </Grid>

          <Grid item xs={12}>
            <div align="right">
              <Button color="primary" onClick={() => peticionPost()}>Insertar</Button>
              <button type="submit" class="btn btn-primary">Guardar</button>

              <Button onClick={() => abrirCerrarModalInsertar()}>Cancelar</Button>
            </div>
          </Grid>
        </Grid>
      </form>

    </div>
  )

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar la consola <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  return (
    <Paper className={classes.root}>

      <CssBaseline />

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Solicitudes de la Fichada
          </Typography>
        </Toolbar>
      </AppBar>

      <br />
      <Breadcrumbs aria-label="breadcrumb">
        <Button style={{ color: "#fff", backgroundColor: "#004F9E" }} variant="contained" onClick={() => abrirCerrarModalInsertar()}>Nueva Solicitud</Button>
      </Breadcrumbs>

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ maxWidth: "100%", direction }}>
            <Grid container>
              <Grid item xs={12}>

                <MaterialTable
                  title=""

                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Buscar...'
                    },
                    header: {
                      actions: 'Acciones'
                    },
                    body: {
                      editRow: {
                        deleteText: 'Estas seguro de eliminar este registro ?'
                      }
                    },

                  }}

                  columns={[
                    {
                      title: 'Nombre',
                      field: 'nombre',
                    },
                    {
                      title: 'Apellido',
                      field: 'apellido',
                    },
                    {
                      title: 'F.Inicio',
                      field: 'finicio',
                      type: 'date',
                      defaultSort: 'desc',
                      align: "center",
                      cellStyle: { textAlign: "center" }
                    },
                    {
                      title: 'F.Reincorporacion',
                      field: 'fincorporacion',
                      type: 'date',
                      align: "center",
                      cellStyle: { textAlign: "center" }
                    },
                    {
                      title: 'Novedad',
                      field: 'novedad',
                    },
                    {
                      title: 'Estado',
                      field: 'status',
                    },
                  ]}
                  data={data}
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Eliminar',
                      onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                    }
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: '#004F9E',
                      color: '#fff',
                    },
                    search: true,
                    actionsColumnIndex: -1
                  }}
                />

              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>

      <Modal
        open={modalInsertar}
        onClose={abrirCerrarModalInsertar}>
        {bodyInsertar}
      </Modal>

      <Modal
        open={modalEliminar}
        onClose={abrirCerrarModalEliminar}>
        {bodyEliminar}
      </Modal>
    </Paper>
  );
}