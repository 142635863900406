/**
 * Mine
 * By Sergio Sam 
 */

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { mdiCart, mdiFactory, mdiBookMultipleOutline, mdiBookMultiple, mdiPackageVariant, mdiCar, mdiClipboardListOutline, mdiBagChecked, mdiArchiveCogOutline } from '@mdi/js'
import { mdiArchiveCog } from '@mdi/js';
import { mdiTicket } from '@mdi/js';
import { mdiCogOutline } from '@mdi/js';
import { mdiCardAccountDetails } from '@mdi/js';
import { Checkbox } from '@mui/material';

import Grid from '@mui/material/Grid';

import Icon from '@mdi/react';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../AuthContext';
import UserService from "../../services/user.service";

import { useStyles } from '../styles'; // Importa los estilos desde el archivo styles.js

const HomeUser = (props) => {

    const { showUser, OwnedProducts } = useContext(AuthContext);

    useEffect(() => {
    }, []);

    const classes = useStyles();

    const handleCheckboxChange = async (moduleName, vent) => {
        const Product = OwnedProducts.find(product => product.name === moduleName);
    }

    return (

        <>
            {showUser && (
                <>

                    {OwnedProducts.find(product => product.name === "Crm") && (
                        OwnedProducts.find(product => product.name === "Crm").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Crm").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Crm").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/crm`} className="nav-link">
                                                <Icon path={mdiTicket}
                                                    title="Crm"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Crm
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                        OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/tickets`} className="nav-link">
                                                <Icon path={mdiTicket}
                                                    title="Tickets"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Tickets
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "ERP") && (
                        OwnedProducts.find(product => product.name === "ERP").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "ERP").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "ERP").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/bas`} className="nav-link">
                                                <Icon
                                                    path={mdiBookMultiple}
                                                    title="BAS"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            BAS
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "Planera") && (
                        OwnedProducts.find(product => product.name === "Planera").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Planera").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Planera").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/planera`} className="nav-link">
                                                <Icon path={mdiFactory}
                                                    title="Planera"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Planera
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "Betc") && (
                        OwnedProducts.find(product => product.name === "Betc").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Betc").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Betc").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/betc`} className="nav-link">
                                                <Icon path={mdiFactory}
                                                    title="Betc"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Betc
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "Planilla Verde") && (
                        OwnedProducts.find(product => product.name === "Planilla Verde").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Planilla Verde").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Planilla Verde").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/verdes`} className="nav-link">
                                                <Icon path={mdiClipboardListOutline}
                                                    title="Planilla Verde"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Planillas Verdes
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "Flota") && (
                        OwnedProducts.find(product => product.name === "Flota").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Flota").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Flota").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/flota`} className="nav-link">
                                                <Icon path={mdiCar}
                                                    title="Flota"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Flota
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "Recursos") && (
                        OwnedProducts.find(product => product.name === "Recursos").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Recursos").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Recursos").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/recursos`} className="nav-link">
                                                <Icon path={mdiArchiveCog}
                                                    title="Recursos"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Recursos
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "Compras") && (
                        OwnedProducts.find(product => product.name === "Compras").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Compras").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Compras").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/compras`} className="nav-link">
                                                <Icon path={mdiCart}
                                                    title="Compras"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Compras
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "Mantenimiento") && (
                        OwnedProducts.find(product => product.name === "Mantenimiento").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Mantenimiento").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Mantenimiento").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/mantenimiento`} className="nav-link">
                                                <Icon path={mdiCogOutline}
                                                    title="Mantenimiento"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Mantenimiento
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                    {OwnedProducts.find(product => product.name === "Recursos Humanos") && (
                        OwnedProducts.find(product => product.name === "Recursos Humanos").permissions.consultar ||
                        OwnedProducts.find(product => product.name === "Recursos Humanos").permissions.modificar ||
                        OwnedProducts.find(product => product.name === "Recursos Humanos").permissions.eliminar
                    ) && (
                            <items>
                                <Grid item xs>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1">
                                            <Link to={`${process.env.PUBLIC_URL}/controlfichadas`} className="nav-link">
                                                <Icon path={mdiCardAccountDetails}
                                                    title="Recursos Humanos"
                                                    size={2}
                                                    horizontal
                                                    vertical
                                                    rotate={180}
                                                    color="#004F9E"
                                                />
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Recursos Humanos
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </items>
                        )}

                </>

            )}


        </>

    );
};

export default HomeUser;

