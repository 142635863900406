/**
 * PWA Horario
 * update 02/2023
 * By Sergio Sam 
 */

import React from 'react';
import { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme, useStyles } from "../styles.js"
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
                    
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';


import { useParams } from 'react-router';

import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";


import { v4 as uuidv4 } from 'uuid';

//Validacion del formulario
const validationSchema = yup.object({
  nombre: yup
    .string('Nombre de horario requerido')
    .required('Nombre de horario requerido'),
  minentrada: yup
    .number("numero")
    .min(1, "Al meno debes ingresar un digito")
    .required('Minutos de toleracia requerido'),
  minsalida: yup
    .number('Minutos de tolerancia requerido')
    .required('Minutos de tolerancia requerido'),
  minantes: yup
    .number('Minutos horas extras requerido')
    .required('Minutos horas extras requerido'),

});

export default function Horariosaddmod() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false)

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      nombre: '',
      minentrada: '',
      minsalida: '',
      minantes: '',
      dial: '',
      diama: '',
      diami: '',
      diaj: '',
      diav: '',
      diass: '',
      diad: '',
      enlunes: '',
      saclunes: '',
      enclunes: '',
      salunes: '',
      enmartes: '',
      sacmartes: '',
      encmartes: '',
      samartes: '',
      enmiercoles: '',
      sacmiercoles: '',
      encmiercoles: '',
      samiercoles: '',
      enjueves: '',
      sacjueves: '',
      encjueves: '',
      sajueves: '',
      enviernes: '',
      sacviernes: '',
      encviernes: '',
      saviernes: '',
      ensabado: '',
      sacsabado: '',
      encsabado: '',
      sasabado: '',
      endomingo: '',
      sacdomingo: '',
      encdomingo: '',
      sadomingo: '',
      id: uuidv4(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
    },
  });

  const [currentUser, setCurrentUser] = useState(undefined);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { id } = useParams();

  const styles = useStyles();
  const classes = useStyles();

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [sino, setSino] = useState([
    { id: 'true', nombre: 'Si' },
    { id: 'false', nombre: 'No' },
  ]);

  const peticionPost = async (values) => {
    const res = await UserService.addmodHorario(id, values);

    if (res.error) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
      cerrarEditar()
    }

  }

  const cerrarEditar = () => {
    history.push(process.env.PUBLIC_URL + "/horarios");
  }

  const inicio = () => {
    history.push(process.env.PUBLIC_URL + "/horarios")
  }

  useEffect(() => {
    // si no hay user hay que loguearse 
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setShowClienteBoard(user.roles.includes("ROLE_USER"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    } else {
      history.push(process.env.PUBLIC_URL + "/login");
    }

    const GetData = async () => {
      if (id) {
        try {
          setIsLoading(true);
          const response = await UserService.getHorarioid(id);
          if (response) {
            formik.setValues(response);
            setIsLoading(false);
          } else {
            history.push(process.env.PUBLIC_URL + "/login");
          }
        } catch (e) {
          history.push(process.env.PUBLIC_URL + "/login");
        }
      }
    }
    GetData();

  }, []);

  return (

    <Paper>

      <AppBar style={{ background: '#fff159', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#000' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h4" component="div" style={{ color: '#000' }} sx={{ flexGrow: 1 }}>
            Agregar/Modificar Horario
          </Typography>
        </Toolbar>
      </AppBar>

      <Container fixed>
        <Box sx={{ bgcolor: '#cfe8fc', marginTop: '40px', marginBottom: '40px' }}  >

          <form onSubmit={formik.handleSubmit}>

            <Grid container spacing={1} style={{ minHeight: '100vh', padding: '5px' }} >

              <Grid item xs={6}>
                <TextField
                  name="nombre"
                  className={styles.inputMaterial}
                  label="Nomre del horario"
                  autoFocus={true}
                  value={formik.values.nombre}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                  helperText={formik.touched.nombre && formik.errors.nombre}
                />
              </Grid>

              {showAlert && (
                <Grid item xs={12}>
                  <Alert severity="error">El nombre del horario ya existe, ingrese otro</Alert>
                </Grid>
              )}

              <Grid item xs={6}>
                <TextField
                  name="minentrada"
                  className={styles.inputMaterial}
                  label="Minutos de toleracia entrada (llegada tarde)"
                  value={formik.values.minentrada}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.minentrada && Boolean(formik.errors.minentrada)}
                  helperText={formik.touched.minentrada && formik.errors.minentrada}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="minsalida"
                  className={styles.inputMaterial}
                  label="Minutos de toleracia salida (horas extras)"
                  value={formik.values.minsalida}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.minsalida && Boolean(formik.errors.minsalida)}
                  helperText={formik.touched.minsalida && formik.errors.minsalida}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="minantes"
                  className={styles.inputMaterial}
                  label="Minutos horas extras anticipadas (entrada antes de hora)"
                  value={formik.values.minantes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.minantes && Boolean(formik.errors.minantes)}
                  helperText={formik.touched.minantes && formik.errors.minantes}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.dial}
                      onChange={formik.handleChange}
                      error={formik.touched.dial && Boolean(formik.errors.dial)}
                      helperText={formik.touched.dial && formik.errors.dial}
                      name="dial"
                    />}
                  label="Lunes"
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="enlunes"
                  className={styles.inputMaterial}
                  label="Entrada Lunes"
                  value={formik.values.enlunes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.enlunes && Boolean(formik.errors.enlunes)}
                  helperText={formik.touched.enlunes && formik.errors.enlunes}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="saclunes"
                  className={styles.inputMaterial}
                  label="Salida.Cor"
                  value={formik.values.saclunes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.saclunes && Boolean(formik.errors.saclunes)}
                  helperText={formik.touched.saclunes && formik.errors.saclunes}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="enclunes"
                  className={styles.inputMaterial}
                  label="Entr.Cor"
                  value={formik.values.enclunes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.enclunes && Boolean(formik.errors.encelunes)}
                  helperText={formik.touched.encelunes && formik.errors.encelunes}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="salunes"
                  className={styles.inputMaterial}
                  label="Salida Lunes"
                  value={formik.values.salunes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.salunes && Boolean(formik.errors.salunes)}
                  helperText={formik.touched.salunes && formik.errors.salunes}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.diama}
                      onChange={formik.handleChange}
                      name="diama"
                      error={formik.touched.diama && Boolean(formik.errors.diama)}
                      helperText={formik.touched.diama && formik.errors.diama}
                    />}
                  label="Martes"
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="enmartes"
                  className={styles.inputMaterial}
                  label="Entrada Martes"
                  value={formik.values.enmartes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.enmartes && Boolean(formik.errors.enmartes)}
                  helperText={formik.touched.enmartes && formik.errors.enmartes}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="sacmartes"
                  className={styles.inputMaterial}
                  label="Salida.Cor"
                  value={formik.values.sacmartes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sacmartes && Boolean(formik.errors.sacmartes)}
                  helperText={formik.touched.sacmartes && formik.errors.sacmartes}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="encmartes"
                  className={styles.inputMaterial}
                  label="Entr.Cor"
                  value={formik.values.encmartes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.enclunes && Boolean(formik.errors.encemartes)}
                  helperText={formik.touched.encemartes && formik.errors.encemartes}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="samartes"
                  className={styles.inputMaterial}
                  label="Salida Martes"
                  value={formik.values.samartes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.samartes && Boolean(formik.errors.samartes)}
                  helperText={formik.touched.samartes && formik.errors.samartes}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.diami}
                      onChange={formik.handleChange}
                      name="diami"
                    />}
                  label="Miercoles"
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="enmiercoles"
                  className={styles.inputMaterial}
                  label="Entrada Miercoles"
                  value={formik.values.enmiercoles}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.enmiercoles && Boolean(formik.errors.enmiercoles)}
                  helperText={formik.touched.enmiercoles && formik.errors.enmiercoles}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="sacmiercoles"
                  className={styles.inputMaterial}
                  label="Salida.Cor"
                  value={formik.values.sacmiercoles}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sacmiercoles && Boolean(formik.errors.sacmiercoles)}
                  helperText={formik.touched.sacmiercoles && formik.errors.sacmiercoles}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="encmiercoles"
                  className={styles.inputMaterial}
                  label="Entr.Cor"
                  value={formik.values.encmiercoles}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.encmiercoles && Boolean(formik.errors.encemiercoles)}
                  helperText={formik.touched.encemiercoles && formik.errors.encemiercoles}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="samiercoles"
                  className={styles.inputMaterial}
                  label="Salida Miercoles"
                  value={formik.values.samiercoles}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.samiercoles && Boolean(formik.errors.samiercoles)}
                  helperText={formik.touched.samiercoles && formik.errors.samiercoles}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.diaj}
                      onChange={formik.handleChange}
                      name="diaj"
                    />}
                  label="Jueves"
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="enjueves"
                  className={styles.inputMaterial}
                  label="Entrada Jueves"
                  value={formik.values.enjueves}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.enjueves && Boolean(formik.errors.enjueves)}
                  helperText={formik.touched.enjueves && formik.errors.enjueves}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="sacjueves"
                  className={styles.inputMaterial}
                  label="Salida.Cor"
                  value={formik.values.sacjueves}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sacjueves && Boolean(formik.errors.sacjueves)}
                  helperText={formik.touched.sacjueves && formik.errors.sacjueves}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="encjueves"
                  className={styles.inputMaterial}
                  label="Entr.Cor"
                  value={formik.values.encjueves}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.encjueves && Boolean(formik.errors.encjueves)}
                  helperText={formik.touched.encjueves && formik.errors.encjueves}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="sajueves"
                  className={styles.inputMaterial}
                  label="Salida Jueves"
                  value={formik.values.sajueves}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sajueves && Boolean(formik.errors.sajueves)}
                  helperText={formik.touched.sajueves && formik.errors.sajueves}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.diav}
                      onChange={formik.handleChange}
                      name="diav"
                    />}
                  label="Viernes"
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="enviernes"
                  className={styles.inputMaterial}
                  label="Entrada Viernes"
                  value={formik.values.enviernes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.enviernes && Boolean(formik.errors.enviernes)}
                  helperText={formik.touched.enviernes && formik.errors.enviernes}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="sacviernes"
                  className={styles.inputMaterial}
                  label="Salida.Cor"
                  value={formik.values.sacviernes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sacviernes && Boolean(formik.errors.sacviernes)}
                  helperText={formik.touched.sacviernes && formik.errors.sacviernes}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="encviernes"
                  className={styles.inputMaterial}
                  label="Entr.Cor"
                  value={formik.values.encviernes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.encviernes && Boolean(formik.errors.encviernes)}
                  helperText={formik.touched.encviernes && formik.errors.encviernes}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="saviernes"
                  className={styles.inputMaterial}
                  label="Salida Viernes"
                  value={formik.values.saviernes}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.saviernes && Boolean(formik.errors.saviernes)}
                  helperText={formik.touched.saviernes && formik.errors.saviernes}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.diass}
                      onChange={formik.handleChange}
                      name="diass"
                    />}
                  label="Sabado"
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="ensabado"
                  className={styles.inputMaterial}
                  label="Entrada Sabado"
                  value={formik.values.ensabado}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.ensabado && Boolean(formik.errors.ensabado)}
                  helperText={formik.touched.ensabado && formik.errors.ensabado}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="sacsabado"
                  className={styles.inputMaterial}
                  label="Salida.Cor"
                  value={formik.values.sacsabado}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sacviernes && Boolean(formik.errors.sacsabado)}
                  helperText={formik.touched.sacsabado && formik.errors.sacsabado}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="encsabado"
                  className={styles.inputMaterial}
                  label="Entr.Cor"
                  value={formik.values.encsabado}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.encsabado && Boolean(formik.errors.encesabado)}
                  helperText={formik.touched.encesabado && formik.errors.encesabado}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="sasabado"
                  className={styles.inputMaterial}
                  label="Salida Sabado"
                  value={formik.values.sasabado}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sasabado && Boolean(formik.errors.sasabado)}
                  helperText={formik.touched.sasabado && formik.errors.sasabado}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.diad}
                      onChange={formik.handleChange}
                      name="diad"
                    />}
                  label="Domingo"
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="endomingo"
                  className={styles.inputMaterial}
                  label="Entrada Domingo"
                  value={formik.values.endomingo}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.endomingo && Boolean(formik.errors.endomingo)}
                  helperText={formik.touched.endomingo && formik.errors.endomingo}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="sacdomingo"
                  className={styles.inputMaterial}
                  label="Salida.Cor"
                  value={formik.values.sacdomingo}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sacdomingo && Boolean(formik.errors.sacdomingo)}
                  helperText={formik.touched.sacdomingo && formik.errors.sacdomingo}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="encdomingo"
                  className={styles.inputMaterial}
                  label="Entr.Cor"
                  value={formik.values.encdomingo}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.encdomingo && Boolean(formik.errors.encdomingo)}
                  helperText={formik.touched.encdomingo && formik.errors.encdomingo}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="sadomingo"
                  className={styles.inputMaterial}
                  label="Salida Domingo"
                  value={formik.values.sadomingo}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.sadomingo && Boolean(formik.errors.sadomingo)}
                  helperText={formik.touched.sadomingo && formik.errors.sadomingo}
                />
              </Grid>

              <Grid item xs={12}>
                <div align="center" style={{ background: '#fff159' }} >
                  <Button color="primary" type="submit">Guardar</Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Paper>
  );
}

