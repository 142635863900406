import authHeader from "./auth-header";
import { handleResponse } from './handle-response';
import http from "./http-common";

import AuthService from "../services/auth.service";

//const API_URL = "http://localhost:8080/api/test/";

//const API_URL = 'https://rh.casiba.net/api/v1/';
//const API_URL = 'https://api.sersam.com.ar/api/v1/';
//const API_URL = 'https://devapi.findemes.ar/api/v1/';
const API_URL = 'https://devapi.casiba.ar/api/v1/';

const user = JSON.parse(localStorage.getItem('user3'));

/**
 * Get product permisions 
 * @param {*} row 
 * @returns 
 
 const getProduct = async (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', row);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  const res = await fetch(API_URL + 'getProduct', requestOptions).then(handleResponse);

  if(!res.data[0].udpp){
    console.log(res.data[0].udpp)
    //AuthService.logout();
    //window.location.reload();
  }

  return res.data;
};
*/
/**
 * Register a new user 
 * @param {*} _username 
 * @param {*} _email 
 * @param {*} _password 
 * @param {*} _empresa 
 * @param {*} _producto 
 * @returns 
 */
 const register_horario = (_username, _email, _password, _empresa, _producto) => {
  const data = new FormData();
  data.append('_username', _username);
  data.append('_email', _email);
  data.append('_password', _password);
  data.append('_empresa', _empresa);
  data.append('_producto', _producto);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'register_horarios', requestOptions).then(handleResponse));
};

/**
 * Olvide clave 
 * @param {*} _email 
 * @param {*} _producto 
 * @returns 
 */
const olvide_horario = (_email, _producto) => {
  const data = new FormData();
  data.append('_email', _email);
  data.append('_producto', _producto);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'olvide_horarios', requestOptions).then(handleResponse));
};

/**
 * Recuperar clave 
 * @param {*} _email 
 * @param {*} _producto 
 * @returns 
 */
const rc_horario = (_email, _producto, _id, _password) => {
  const data = new FormData();
  data.append('_producto', _producto);
  data.append('_id', _id);
  data.append('_password', _password);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'rc_horarios', requestOptions).then(handleResponse));
};

/**
 * Valid emails, not duplicated 
 * @param {*} email 
 * @param {*} producto 
 * @returns 
 */
 const validEmailsHorario = (email, producto,idant) => {
  const data = new FormData();
  data.append('id', idant);
  data.append('email', email);
  data.append('producto', producto);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'validEmailHorario', requestOptions).then(handleResponse));
};

/**
 * Valid empresa, not duplicated 
 * @param {*} empresa 
 * @param {*} producto 
 * @returns 
 */
 const validEmpresaHorario = (empresa, producto) => {
  const data = new FormData();
  data.append('empresa', empresa);
  data.append('producto', producto);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'validEmpresaHorario', requestOptions).then(handleResponse));
};

const upload = (id, file, onUploadProgress) => {
  let data = new FormData();
  data.append("images", file);
  data.append('email', user.email);
  data.append('id', id);
  data.append('uniqid', 'update');

  return http.post("/uploadfiles", data, {headers: authHeader(),onUploadProgress,});

  //const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  //return (fetch(API_URL + 'uploadfiles', requestOptions).then(handleResponse),onUploadProgress);
};

//trae estado del vida
const getlive= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getlive', requestOptions).then(handleResponse));
};

const setEntrar= (latitud, longitud, nota, accion) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('latitud', latitud);
  data.append('longitud', longitud);
  data.append('accion', accion);
  data.append('momento', 'remota');
  data.append('nota', nota);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return fetch(API_URL + 'entrar1', requestOptions);
}

//trae datos pora el informe
const getInforme= (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getinforme', requestOptions).then(handleResponse));
};

/**
 * Get data solicitudes
 * @returns 
 */
 const getSolicitudes = async () => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return (fetch(API_URL + 'get_solicitudes?email=' + user.email , requestOptions).then(handleResponse));
};

/**
 * Get data solicitudes masivas
 * @returns 
 */
 const getSolicitudesMasivas = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_solicitudes_masivas?email=' + user.email , requestOptions).then(handleResponse));
};

/**
 * Get data solicitud masiva id
 * @returns 
 */
 const getSolicitudMasivaid = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_solicitud_masiva_id?id=' + id + '&email=' + user.email , requestOptions).then(handleResponse));
};

//agreda o modifica soliciutdes masivas
const addmodSolicitudesMasivas= (id,values,state, right) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(values));
  data.append('fechas', JSON.stringify(state));
  data.append('right', JSON.stringify(right));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_solicitudes_masivas', requestOptions).then(handleResponse));
};

/**
 * Get data solicitudes empleados
 * @returns 
 */
 const getSolicitudesEmpleados = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_solicitudes_empleados?email=' + user.email , requestOptions).then(handleResponse));
};

//agreda o modifica soliciutdes
const addmodSolicitudes= (id,values,state) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(values));
  data.append('fechas', JSON.stringify(state));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_solicitudes', requestOptions).then(handleResponse));
};

//elimina una solicitud
const delSolicitud= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'del_solicitud', requestOptions).then(handleResponse));
};

/**
 * Get data solicitud id
 * @returns 
 */
 const getSolicitudid = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_solicitud_id?id=' + id , requestOptions).then(handleResponse));
};

/**
 * Get data solicitud fichadas id
 * @returns 
 */
 const getSolicitudFichadasid = async (id, fecha, idempleado) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_solicitud_fichadas_id?id=' + id +'&fecha=' + fecha + '&idempleado=' + idempleado  , requestOptions).then(handleResponse));
};

/**
 * Get data novedades
 * @returns 
 */
 const getNovedades = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_novedades?email=' + user.email , requestOptions).then(handleResponse));
};

/**
 * Get data novedad id
 * @returns 
 */
 const getNovedadid = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_novedad_id?id=' + id , requestOptions).then(handleResponse));
};

//agreda o modifica una novedad
const addModNovedad= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_novedad', requestOptions).then(handleResponse));
};

//agreda o modifica un horario
const addModHorario= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_horario', requestOptions).then(handleResponse));
};

//elimina una novedad
const delNovedad= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'del_novedad', requestOptions).then(handleResponse));
};

//trae lista de los archivos adjuntos
const getFiles= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getfiles', requestOptions).then(handleResponse));
};

//consulta lectura codigo qr
const getQrcon= (id,tipo) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('tipo', tipo);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getqrcon', requestOptions).then(handleResponse));
};

//trae lista de codigos qrs
const getQrs= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getqrs', requestOptions).then(handleResponse));
};

//agreda o modifica un qr
const addmodQr= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmodqr', requestOptions).then(handleResponse));
};

//elimina un qr
const delQr= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'delqr', requestOptions).then(handleResponse));
};

/**
 * Get data empleado id
 * @returns 
 */
 const getEmpleadoid = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_empleado_id?id=' + id , requestOptions).then(handleResponse));
};

/**
 * Get data empleados
 * @returns 
 */
 const getEmpleados = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_empleados?email=' + user.email , requestOptions).then(handleResponse));
};

/**
 * Get data empleados masivas
 * @returns 
 */
 const getEmpleadosMasivas = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_empleados_masivas?email=' + user.email , requestOptions).then(handleResponse));
};

/**
 * Get data empleados equipos
 * @returns 
 */
 const getEmpleadosEquipos = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_empleados_equipos?id=' + id +'&email=' + user.email , requestOptions).then(handleResponse));
};

//agreda o modifica un empleado
const addModEmpleado= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_empleado', requestOptions).then(handleResponse));
};

//elimina un empleado
const delEmpleado= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'del_empleado', requestOptions).then(handleResponse));
};

/**
 * Get data fichadas
 * @returns 
 */
 const getFichadas = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_fichadas?email=' + user.email , requestOptions).then(handleResponse));
};

/**
 * Get data fichadas empleados
 * @returns 
 */
 const getFichadasEmpleados = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_fichadas_empleados?email=' + user.email , requestOptions).then(handleResponse));
};

/**
 * Get data horarios
 * @returns 
 */
 const getHorarios = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_horarios?email=' + user.email , requestOptions).then(handleResponse));
};

//elimina un horario
const delHorario= (id) => {
  const data = new FormData();
  data.append('email', user.email);

  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'del_horario', requestOptions).then(handleResponse));
};


/**
 * Get data horario id
 * @returns 
 */
 const getHorarioid = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_horario_id?id=' + id , requestOptions).then(handleResponse));
};

/**
 * Get data equipos
 * @returns 
 */
 const getEquipos = async () => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_equipos?email=' + user.email , requestOptions).then(handleResponse));
};

//agreda o modifica un equipo
const addModEquipo= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_equipo', requestOptions).then(handleResponse));
};

/**
 * Get data equipo id
 * @returns 
 */
 const getEquipoid = async (id) => {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return (fetch(API_URL + 'get_equipo_id?id=' + id , requestOptions).then(handleResponse));
};

//elimina un equipo
const delEquipo= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'del_equipo', requestOptions).then(handleResponse));
};

export default {
  //getProduct,
  register_horario,
  olvide_horario,
  rc_horario,
  getlive,
  setEntrar,
  getInforme,

  getSolicitudesMasivas,
  getSolicitudMasivaid,
  addmodSolicitudesMasivas,

  getSolicitudes,
  getSolicitudesEmpleados,
  getSolicitudid,
  addmodSolicitudes,
  delSolicitud,
  getSolicitudFichadasid,

  getEmpleadosMasivas,
  getEmpleadosEquipos,
  getEmpleados,
  getEmpleadoid,
  addModEmpleado,
  delEmpleado,

  getNovedades,
  getNovedadid,
  addModNovedad,
  delNovedad,

  getEquipos,
  getEquipoid,
  addModEquipo,
  delEquipo,

  getHorarios,
  getHorarioid,
  addModHorario,
  delHorario,
  
  getFichadas,
  getFichadasEmpleados,

  getQrcon,
  getQrs,
  addmodQr,
  delQr,
  validEmailsHorario,
  validEmpresaHorario,

  upload,
  getFiles,
};
