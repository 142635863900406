import React from 'react';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

import { AppBar, CircularProgress, IconButton, Toolbar, Breadcrumbs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MaquinaRepuestos from './MaquinaRespuestos';
import UserServiceCore from "../../services/user.service";
import UserService from "./services/mantenimiento.service";
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    codigo: yup
        .string('Codigo requerido')
        .required('Codigo requerido'),
    descorta: yup
        .string('Descrpcion corta requerida')
        .required('Descrpcion corta requerida'),
    marca: yup
        .string('Marca requerida')
        .required('Marca requerida'),
    modelo: yup
        .string('Modelo requreido ')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Modelo requerido'),
});

export default function Maquinasaddmod(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [dato, setDato] = useState([]);

    //inicializacion de variables y validacion
    var d = new Date();
    var finaldate = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + (d.getDate()).toString().padStart(2, "0")

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            codigo: '',
            descorta: '',
            marca: '',
            modelo: '',
            serie: '',
            idsucursal: '',
            descripcion: '',
            fecha: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [plantas, setPlantas] = useState([
        { id: 'Mitre', nombre: 'Mitre' },
        { id: 'Sabattini', nombre: 'Sabattini' },
    ]);

    const [tipos, setTipos] = useState([
        { id: 'Uso Unico Personal', nombre: 'Uso Unico Personal' },
        { id: 'Uso Generico Comunitario', nombre: 'Uso Generico Comunitario' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodMaquina(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/maquinas");
    }

    useEffect(() => {

        const GetData = async () => {

            if (id) {
                try {
                    setIsLoading(true);
                    const response = await UserService.getMaquina(id);
                    if (response) {
                        formik.setValues(response);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                } catch (e) {
                    console.log(e)
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            }

        }
        GetData();

    }, []);

    useEffect(() => {
        const GetSucursales = async () => {
            try {
                setIsLoading(true);
                const result = await UserServiceCore.getSucursales();
                if (result) {
                    setDato(result.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e)
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetSucursales();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/maquinas")
    }

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Maquina
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={4}>
                        <TextField
                            name="codigo"
                            className={styles.inputMaterial}
                            label="Codigo"
                            autoFocus={true}
                            autoComplete='off'
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                            helperText={formik.touched.codigo && formik.errors.codigo}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="descorta"
                            className={styles.inputMaterial}
                            label="Descripcion corta"
                            autoComplete='off'
                            value={formik.values.descorta}
                            onChange={formik.handleChange}
                            error={formik.touched.descorta && Boolean(formik.errors.descorta)}
                            helperText={formik.touched.descorta && formik.errors.descorta}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            id="date"
                            type="date"
                            autoComplete='off'
                            defaultValue="2021-08-25"
                            name="fecha"
                            label="Fecha de Compra"
                            className={styles.inputMaterial}
                            error={formik.touched.fecha && Boolean(formik.errors.fecha)}
                            helperText={formik.touched.fecha && formik.errors.fecha}
                            onChange={formik.handleChange}
                            value={formik.values.fecha}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>


                    <Grid item xs={4}>
                        <TextField
                            name="marca"
                            className={styles.inputMaterial}
                            label="Marca"
                            autoComplete='off'
                            value={formik.values.marca}
                            onChange={formik.handleChange}
                            error={formik.touched.marca && Boolean(formik.errors.marca)}
                            helperText={formik.touched.marca && formik.errors.marca}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="modelo"
                            className={styles.inputMaterial}
                            label="Modelo"
                            autoComplete='off'
                            value={formik.values.modelo}
                            onChange={formik.handleChange}
                            error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                            helperText={formik.touched.modelo && formik.errors.modelo}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            name="serie"
                            className={styles.inputMaterial}
                            label="Numero de Serie"
                            autoComplete='off'
                            value={formik.values.serie}
                            onChange={formik.handleChange}
                            error={formik.touched.serie && Boolean(formik.errors.serie)}
                            helperText={formik.touched.serie && formik.errors.serie}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel htmlFor="outlined-age-native-simple">Sucursal</InputLabel>
                        <Select
                            native
                            label="Sucursal"
                            autoComplete='off'
                            className={styles.inputMaterial}
                            value={formik.values.idsucursal}
                            onChange={formik.handleChange}
                            error={formik.touched.idsucursal && Boolean(formik.errors.idsucursal)}
                            helperText={formik.touched.idsucursal && formik.errors.idsucursal}
                            inputProps={{
                                name: 'idsucursal',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {dato.map((value) => (
                                <option value={value.id} key={value.id}>
                                    {value.codigo}_{value.nombre}
                                </option>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="descripcion"
                            className={styles.inputMaterial}
                            label="Descripcion"
                            autoComplete='off'
                            value={formik.values.descripcion}
                            onChange={formik.handleChange}
                            error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                            helperText={formik.touched.descripcion && formik.errors.descripcion}
                        />

                        <TextField
                            name="data1"
                            type="hidden"
                            value={formik.values.data1}
                        />

                        <TextField
                            name="recurso"
                            type="hidden"
                            value={formik.values.recurso}
                        />

                    </Grid>

                    <SnackbarMessage
                        open={open}
                        message={errorMessage}
                        severity="success"
                        onClose={handleCloseMessage}
                    />

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>
            {id && <MaquinaRepuestos id={id} />}
        </Paper>
    );
}