import authHeader from "../../../services/auth-header";
import { handleResponse } from '../../../services/handle-response';
import http from "../../../services/http-common";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { openDB, deleteDB, wrap, unwrap } from 'idb';
import idbcache from 'idbcache';

const API_URL = 'https://devapi.casiba.ar/api/v1/';
const user = JSON.parse(localStorage.getItem('user3'));

const getGantt = (recurso, usuario) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);
  data.append('user', usuario);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_gantt', requestOptions).then(handleResponse));
};

const getGanttMaquinas = (recurso, usuario) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);
  data.append('user', usuario);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_gantt_maquinas', requestOptions).then(handleResponse));
};

const getTrello = (recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_trello', requestOptions).then(handleResponse));
};

const getProyecto = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_proyecto_id', requestOptions).then(handleResponse));
};

const getProyectos = (recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_proyectos', requestOptions).then(handleResponse));
};

const addmodProyecto = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_proyecto', requestOptions).then(handleResponse));
};

const delProyecto = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_proyecto', requestOptions).then(handleResponse));
};

const getMaquina = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_maquina_id', requestOptions).then(handleResponse));
};

const getMaquinas = (recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_maquinas', requestOptions).then(handleResponse));
};

const getBienes = (recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_bienes', requestOptions).then(handleResponse));
};

const addmodMaquina = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_maquina', requestOptions).then(handleResponse));
};

const delMaquina = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_maquina', requestOptions).then(handleResponse));
};

const addmodRepuesto = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_repuesto', requestOptions).then(handleResponse));
};

const getRepuestosMaquina = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_repuestos_maquina', requestOptions).then(handleResponse));
};

const getRepuesto = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_repuesto_id', requestOptions).then(handleResponse));
};

const getRepuestos = (recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_repuestos', requestOptions).then(handleResponse));
};

const getRepuestosNoSeleccionados = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_repuestos_no_seleccionados', requestOptions).then(handleResponse));
};

const addRepuestoMaquina = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('id_repuesto', row.id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'add_repuesto_maquina', requestOptions).then(handleResponse));
};

const delRepuestoMaquina = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_repuesto_maquina', requestOptions).then(handleResponse));
};

const getRepuestosSeleccionados = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_repuestos_seleccionados', requestOptions).then(handleResponse));
};

const delRepuestoSeleccionado = (id, row, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('id_repuesto', row.id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_repuesto_seleccionado', requestOptions).then(handleResponse));
};

const delRepuesto = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_repuesto', requestOptions).then(handleResponse));
};

const getTarea = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_tarea_id', requestOptions).then(handleResponse));
};

const getTareas = (activo) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('activo', activo);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_tareas', requestOptions).then(handleResponse));
};

const getTareasP = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_tareasP', requestOptions).then(handleResponse));
};

const getTareasProyectos = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_tareasProyectos', requestOptions).then(handleResponse));
};

const setStatusTarea = (id, status) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('status', status);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'setStatus_tarea', requestOptions).then(handleResponse));
};

const addmodTarea = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_tarea', requestOptions).then(handleResponse));
};

const delTarea = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_tarea', requestOptions).then(handleResponse));
};

const getEmpleadosTarea = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_empleados_tarea', requestOptions).then(handleResponse));
};


export default {

  getTrello,
  getGantt,
  getGanttMaquinas,
  addRepuestoMaquina,
  delRepuestoMaquina,
  delRepuestoSeleccionado,
  delRepuesto,

  setStatusTarea,
  getTarea,
  getTareas,
  getTareasP,
  getTareasProyectos,
  addmodTarea,
  delTarea,
  getEmpleadosTarea,
  
  getProyectos,
  getProyecto,
  addmodProyecto,
  delProyecto,

  getBienes,
  getMaquinas,
  getMaquina,
  addmodMaquina,
  delMaquina,


  addmodRepuesto,
  getRepuesto,
  getRepuestos,
  getRepuestosMaquina,
  getRepuestosNoSeleccionados,
  getRepuestosSeleccionados,

};
